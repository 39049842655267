import template from './index.html';

/** @type {import('@srcAjs/components2/adminRoutes').IComponentOptions2} */
const trHomeSubDashboard = {
	template,
	bindings: {
		//someInput: '@string,<object,&function'
	},
	transclude: {
		// #slotName: '?optionalPublicName'
	},
};

function trHomeSubDashboardCmpController(
	$mdToast,
	$mdDialog,
	$state,
	$rootScope,
	$localStorage,
	$cacheFactory,
	canSyncSubjectFilter,
	isSubjectAgCashedFilter,
	$timeout,
	/** @type {ng.IScope}*/ $scope,
	/** @type {API}*/ API,
	/** @type {lsfactory}*/ lsfactory,
	/** @type {TRFactory}*/ TRFactory,
	/** @type {import('restangular').IService}*/ Restangular,
	/** @type {ErrorService}*/ ErrorService,
	/** @type {getDateWithinTermBoundsFilter}*/ getDateWithinTermBoundsFilter,
	/** @type {atomosService} */ atomosService
) {
	/**
	 * @type {
	 * 	typeof $scope & {
	 *
	 * [key:string]: {}|any
	 * }
	 * }
	 */
	const svm = $scope;

	svm.teacher = TRFactory.loadEnvironment();
	svm.school = svm.teacher.school;

	TRFactory.loadNotifications();
	/*
	 * &lsaquo;
	 * This is a
	 */

	svm.subjects = [];
	svm.term_students = [];
	svm.obj = { selectedItem: null };
	svm.years = [];
	svm.groups = [];
	svm.isReady = false;

	svm.subjects = [];
	svm.showOne = true;
	svm.selected_term_handle = null;
	$rootScope.isCTermDisabled = false;
	svm.hasStudents;
	svm.isClassTeacher;
	svm.subjects_meta = [];
	svm.section;
	svm.isTermMisConfigured = false;

	$('#teacher-students-available').hide();
	$('#teacher-students-not-available').hide();

	// //Entry point
	// svm.$on("system.startupHook", function () {
	// 	TRFactory.loadEnvironment()
	// })

	svm.$on('system.tr.noTerm', (event, useless) => {
		svm.isReady = true;
		$('#term_chosen_not').show();
		$('#term_chosen').hide();
	});

	svm.$on('system.tr.noTermYear', (event, useless) => {
		svm.isReady = true;
		$('#term_chosen_not').show();
		$('#term_chosen').hide();
	});

	/**
	 * @return {Promise<CachedSection>}
	 */
	function attemptLoadTermSection(term, teacher, refresh = false) {
		if (refresh) {
			const /** @type {API_Params} */ params = {
					filters: [
						{ condition: 'where', column: 'teacher_id', value: teacher.id },
						{ condition: 'where', column: 'term_id', value: term.term_type.version === '1.2' ? term.id : 'null' },
					],
					has_fields: null,
					fields: [
						{
							field: 'section_histories',
							condition: 'with',
							conditions: [
								{ condition: 'where', column: 'term_id', value: term.id },
								{ condition: 'with', column: 'student' },
							],
						},
						{ field: 'sclass.class_level', condition: 'with' },
					],
				};

			return Restangular.all(`section?${API.buildParams(params.filters, params.has_fields, params.fields, null, '1.4')}`)

				.getList()
				.then((res) => res.plain())
				.then((/** @type {Section[]} */ sections) => {
					if (!sections.length) {
						svm.isClassTeacher = false;
						return null;
					}

					svm.isClassTeacher = true;

					if (sections.length > 1) {
						$mdToast.showSimple('Alert: Teacher assigned multiple sections');
					}

					const sh_cache = TRFactory.trServiceAtom.get().termsCache;

					const [{ section_histories, ...section }] = sections;

					sections.forEach(({ section_histories, ...section }) => {
						const temp_csection = { section, section_histories };
						sh_cache[term.id].sections[section.id] = temp_csection;
						// return temp_csection;
					});

					TRFactory.updateAtomCache(sh_cache);

					// const _sections = TRFactory.getCachedTeacherSections(svm.teacher);

					const _sections = (svm.tsections = sh_cache[term.id].sections);
					const _section = _sections[svm.section?.id ?? Object.keys(_sections).at(0)];

					return _section ?? sh_cache[term.id].sections[section.id];
				});
		}

		return TRFactory.fetchTermDetails(term.id, refresh).then((cached_term) => {
			const cached_section = Object.values(cached_term.sections).find(({ section }) => section.teacher_id === teacher.id);

			return cached_section ?? attemptLoadTermSection(term, teacher, true);
		});
	}

	atomosService(TRFactory.trServiceAtom.stream$, this).stream$.subscribe((atom) => {
		if (!atom.selectedTerm) return;

		initDashBoardPage(atom.selectedTerm);
	});

	/**
	 *
	 * @param {Term} term
	 * @returns {Promise<CachedSection>}
	 */
	function promptToCorrectCachedTermError(term, teacher) {
		svm.isTermMisConfigured = true;

		return $mdDialog
			.show(
				$mdDialog.confirm({
					textContent: 'Clean up required for term misconfiguration in your offline cache. Its recommended to proceed',
					ok: 'Yes please!',
					cancel: 'Nope',
				})
			)
			.then((res) => {
				if (!res) {
					throw new Error('Wrong configuration must be corrected, either use the refresh button or allow the clean up to proceed if prompted again');
				}

				svm.isTermMisConfigured = false;
				return refreshCache(true, true).then(() => attemptLoadTermSection(term, teacher));
			});
	}

	async function initDashBoardPage(term, refresh) {
		if (!term || term.updated_at === svm.dterm?.updated_at) return;

		svm.dterm = term;
		svm.date_in_term = getDateWithinTermBoundsFilter(term, new Date());
		svm.g_tid = term.id;

		svm.isReady = false;

		svm.isClassTeacher = null;
		const section = angular.copy(svm.section);
		svm.section = null;
		delete $scope?.teacher?.section;

		return attemptLoadTermSection(term, svm.teacher, refresh)
			.then(async (cached_section) => {
				if (!cached_section) return cached_section;

				// check if possibly loaded a section that does not match this term
				let new_cached_section;

				if ((term.term_type.version === '1.2' && cached_section?.section?.term_id !== term.id) || (term.term_type.version !== '1.2' && _.isFinite(cached_section.term_id))) {
					new_cached_section = await promptToCorrectCachedTermError(term, svm.teacher);
				}

				return new_cached_section ?? cached_section;
			})
			.then((temp_cached_section) => {
				if (temp_cached_section) {
					svm.section = temp_cached_section.section;
					svm.teacher.section = temp_cached_section.section;
					TRFactory.trServiceAtom.set((prev) => ({ ...prev, selectedSectionId: svm.section.id }));
				}

				lsfactory.setEnv('teacher', svm.teacher);
				loadSubjects(term.id);
			}, ErrorService.handleError)
			.then(() => loadSubjects(term.id))
			.catch((error) => {
				ErrorService.handleError(error);
				svm.isClassTeacher = !!section;
			})
			.finally(() => {
				svm.tsections = TRFactory.trServiceAtom.get().termsCache[term.id].sections;
				svm.section = section ?? Object.values(svm.tsections)[0]?.section;
				// svm.teacher.section = section;
				svm.teacher = {
					...svm.teacher,
					get section() {
						return svm.section;
					},
					set section(v) {
						svm.section = v;
					},
				};
				svm.isReady = true;
			});
	}

	svm.updateSelectedSection = (/** @type {Section} */ section) => {
		TRFactory.updateAtom((atom) => {
			atom.selectedSectionId = section.id;
			return atom;
		});
	};

	svm.sync = function (subject_mount) {
		const confirm = $mdDialog.confirm({
			title: `Sync ${subject_mount.title}`,
			textContent: 'Local changes will be uploaded and saved online',
			ok: 'Go ahead',
			cancel: 'I dont think so',
		});

		$mdDialog.show(confirm).then(async () => {
			svm.isReady = false;
			try {
				const { count_ags, count_saes } = await TRFactory.syncSubjectMount(subject_mount, subject_mount.term_id);
				const template = $mdToast.simple({
					textContent: `Synced Assessment Groups:${count_ags}, Assessments: ${count_saes}`,
					hideDelay: 10000,
				});
				$mdToast.show(template);
			} catch (error) {
				ErrorService.handleError(error);
			} finally {
				loadSubjects(subject_mount.term_id);
				svm.isReady = true;
				$timeout(() => {}, 10);
			}
		});
	};

	svm.clear = function (subject_mount, ev) {
		const _alert = $mdDialog.alert({
			textContent: 'Feature in development',
			title: 'Clear changes',
			ariaLabel: 'Feature in development',
			ok: 'Got it',
			targetEvent: ev,
		});

		$mdDialog.show(_alert);
	};

	svm.next = function (year) {
		const y = parseInt(year);
		return y + 1;
	};

	svm.refreshCache = refreshCache;

	/**
	 * @return {Promise<boolean>}
	 */
	function refreshCache(_attemptTermReload, refresh = false) {
		const sh_cache = angular.copy(lsfactory.getCache());

		svm.isReady = false;
		/*
		 * await attemptLoadTermSection( lsfactory.getEnv( "g_selected_term_id" ), svm.teacher, true ).
		 * 	then( () =>
		 */
		return TRFactory.attemptRefresh(svm.teacher)
			.then(() => {
				if (!_attemptTermReload) return;
				return attemptLoadTermSection(svm.dterm, svm.teacher, refresh);
			})

			.then((cached_section) => {
				$mdToast.showSimple('Refresh successful');
				if (cached_section) {
					return initDashBoardPage(svm.dterm, refresh).then(() => cached_section);
				}

				loadSubjects(lsfactory.getEnv('g_selected_term_id'));
				return cached_section;
			})
			.catch((error) => {
				TRFactory.updateAtomCache(sh_cache);
				ErrorService.handleError(error);
			})
			.finally(() => (svm.isReady = true));

		// subjects_meta.
	}
	function loadSubjects(term_id, refresh = false) {
		TRFactory.fetchTermDetails(term_id, refresh).then((cached_term) => {
			svm.selected_term_handle = cached_term.term;
			if (!svm.section) {
				// This means the teacher in question has not been assigned to a classroom yet.
				loadTeacherSubjectMounts(cached_term, refresh);
			} else if (svm.section) {
				// This means the teacher has been successfully assigned to a classroom as at the time.
				loadClassSectionTeacherSubjectMounts(cached_term, refresh);
			}
		}, ErrorService.handleError);

		function loadTeacherSubjectMounts(cached_term, refresh = false) {
			TRFactory.fetchNonClassTeacherSubjectMounts(cached_term, svm.teacher, term_id, refresh)
				.then((cached_subject_mounts) => {
					const list = Object.values(cached_subject_mounts).map(({ subject_mount }) => subject_mount);

					svm.hasSubjects = !!list.length;
					svm.subjects = list;
					svm.subjects_meta = TRFactory.buildSubjectsMeta(cached_subject_mounts);
					svm.cached_subject_mounts = cached_subject_mounts;

					$timeout(() => {}, 10);

					$('#term_chosen_not').hide();
					$('#term_chosen').show();
				})
				.catch(ErrorService.handleError)
				.finally(() => {
					$rootScope.isCTermLoading = false;
					svm.isReady = true;
				});
		}

		function loadClassSectionTeacherSubjectMounts(cached_term, refresh = false) {
			svm.isClassTeacher = !!svm.section;

			if (!Object.keys(cached_term.sections).includes('' + svm.section.id)) {
				cached_term.sections[svm.section.id] = { section: svm.section, section_histories: [] };
				const sh_cache = TRFactory.trServiceAtom.get().termsCache;
				sh_cache[cached_term.term.id] = cached_term;
				TRFactory.updateAtomCache(sh_cache);
			}

			TRFactory.fetchClassTeacherSubjectMounts(cached_term, svm.teacher, term_id, refresh)
				.then((cached_subject_mounts) => {
					// pull subject from cached_subject_mount = [{subjec_mount:{},assessment_groups:{}}]
					svm.subjects = Object.values(cached_subject_mounts).map((subj) => subj.subject_mount);
					svm.cached_subject_mounts = cached_subject_mounts;
					svm.subjects_meta = TRFactory.buildSubjectsMeta(cached_subject_mounts);
				})
				.then(() =>
					// Get Students
					new Promise(async (res, rej) => {
						const sh_cache = TRFactory.trServiceAtom.get().termsCache;
						if (!sh_cache[term_id].sections[svm.section.id].section_histories.length) {
							const section_histories = await TRFactory.fetchSectionHistories(svm.section, term_id);
							sh_cache[term_id].sections[svm.section.id].section_histories = section_histories;
							TRFactory.updateAtomCache(sh_cache);
							res(section_histories);
						} else {
							// retrieve from cache
							res(sh_cache[term_id].sections[svm.section.id].section_histories);
						}
					}).then((data) => {
						const list = data;
						svm.term_students = list;
						console.log('Student List');
						console.dir(list);

						svm.isReady = true;
						$rootScope.isCTermLoading = false;
						$('#term_chosen_not').hide();
						$('#term_chosen').show();
						$timeout(() => {}, 10);
					})
				)
				.catch(ErrorService.handleError);
		}
	}

	svm.getZStyle = (student_index) => ({
		'margin-left': '-5px',
		'box-shadow': '0 0 0 3px #fff',
		'border-radius': '50%',
		position: 'relative',
		'z-index': student_index,
	});

	svm.handleClick = (/** @type SubjectMount */ subject_mount) =>
		$state.go('tr.subject.assessment', { subject_mount, subject_mount_id: subject_mount.id }).catch(ErrorService.handleError);
}

trHomeSubDashboard.controller = trHomeSubDashboardCmpController;

appModule.component('trHomeSubDashboard', trHomeSubDashboard);

trHomeSubDashboard.selector = 'trHomeSubDashboard';

export { trHomeSubDashboard };

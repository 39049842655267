import { trClassDialogsStudentReport } from '../TrClassDialogsStudentReport/index.component';
import template from './index.html';

/** @type {import('@srcAjs/components2/adminRoutes').IComponentOptions2} */
const trClassSubReports = {
	template,
	bindings: {
		//someInput: '@string,<object,&function'
	},
	transclude: {
		// #slotName: '?optionalPublicName'
	},
	_imports: [trClassDialogsStudentReport],
};

function trClassSubReportsCmpController(
	$scope,
	$state,
	$rootScope,
	$mdDialog,
	/** @type {lsfactory} */ lsfactory,
	/** @type {TRFactory} */ TRFactory,
	/** @type {ErrorService} */ ErrorService,
	/** @type {EffectService} */ EffectService,
	/** @type {atomosService} */ atomosService,
	/** @type {auth} */ auth,
	/** @type {compSetupService} */ compSetupService
) {
	compSetupService(this, trClassSubReports.bindings);
	lsfactory.setEnv('backLog', 'tr.home.dashboard');

	const [rankedStudents$, { mutate: queryRankedStudents }] = TRFactory.rankedSectionHistoriesRxQ(this);

	EffectService.setup(this, ({ onInit }) => {
		if (onInit) {
			const authAtom = atomosService(auth.authData$, this);
			atomosService((get) => [get(TRFactory.trServiceAtom), get(authAtom)], this).stream$.subscribe(([atom, _auth]) => {
				/** @type {TrServiceAtom} */
				const data = atom;
				$scope.teacher = $scope.teacher = auth.getEngagedUserRole().role_instance;
				const { section } = TRFactory.getCachedTeacherSections($scope.teacher).at(0);
				$scope.section = section;
				$scope.g_tid = data.selectedTermId;
				$state.current.data.title = $scope.section.sclass.class_level.title + ' (' + $scope.section.title + ')';

				if (!data.selectedTerm) return;

				$scope.term = data.selectedTerm;
				queryRankedStudents({ section_id: section.id, term_id: data.selectedTerm.id })
					.then(({ data, error }) => {
						if (error) return ErrorService.handleError(error);
						if (!data) return;
						$scope.section_histories = data.plain();
					})
					.catch(ErrorService.handleError);
			});
		}
	});

	$scope.section_histories = [];
	$scope.isReady = false;
	// $rootScope.isCTermLoading = true;
	$rootScope.isCTermDisabled = false;

	$scope.showStudent = function () {
		lsfactory.setEnv('dialogStudentListReport', $scope.section_histories); // Send something to the dialog
		$mdDialog.show({
			escapeToClose: true,
			clickOutsideToClose: true,
			bindToController: true,
			locals: {
				dialogStudentListReport: [...$scope.section_histories],
				section: $scope.section,
				term_id: $scope.g_tid,
			},
			templateUrl: 'views/pages/roles/tr/class/dialogs/student_report.html',
			controller: 'TeacherStudentReportController',
		});
	};
}

trClassSubReports.controller = trClassSubReportsCmpController;

appModule.component('trClassSubReports', trClassSubReports);

trClassSubReports.selector = 'trClassSubReports';

export { trClassSubReports };

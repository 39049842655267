import template from './index.html';

/** @type {import('@srcAjs/components2/adminRoutes').IComponentOptions2} */
const trHomeDetail = {
	template,
	bindings: {
		//someInput: '@string,<object,&function'
	},
	transclude: {
		// #slotName: '?optionalPublicName'
	},
};

function trHomeDetailCmpController($scope, TRFactory, lsfactory, Restangular) {
	TRFactory.loadEnvironment();
	$scope.school = lsfactory.getEnv('school');

	function fetchYears(school_id) {
		if (!school_id) {
			console.log('school_id is not set');
			return;
		}
		const cached_years = lsfactory.getEnv('cached_years') ?? false;

		if (cached_years && cached_years[0].school_id == school_id) {
			console.log('loading cached years');
			$scope.year = cached_years;
		} else {
			Restangular.all('year/by-school/' + school_id)
				.getList()
				.then(function (data) {
					var list = data.plain();
					$scope.years = list;
					lsfactory.setEnv('cached_years', $scope.years);
				});
		}
	}

	//    fetchYears($scope.school.id);
}

trHomeDetail.controller = trHomeDetailCmpController;

appModule.component('trHomeDetail', trHomeDetail);

trHomeDetail.selector = 'trHomeDetail';

export { trHomeDetail };

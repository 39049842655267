import template from './TrLeaderboardShellCmp.tpl.html';

/** @type {import('@srcAjs/components2/adminRoutes').IComponentOptions2} */
const trLeaderboardShellCmp = {
	template,
	bindings: {
		//someInput: '@string,<object,&function'
	},
	transclude: {
		// #slotName: '?optionalPublicName'
	},
	selector: 'trLeaderboardShellCmp',
};

/** @this {ng.IController} */
function trLeaderboardShellCmpController(/**@type {auth} */ auth, /**@type {TRFactory} */ TRFactory, /**@type {atomosService*/ atomosService) {
	atomosService(auth.authData$, this).stream$.subscribe((atom) => {
		this.school = atom.engagedUserRole.school;
	});

	atomosService(TRFactory.trServiceAtom.stream$, this).stream$.subscribe((atom) => {
		this.trServiceAtom = atom;
	});
}

trLeaderboardShellCmp.controller = trLeaderboardShellCmpController;

appModule.component('trLeaderboardShellCmp', trLeaderboardShellCmp);

export { trLeaderboardShellCmp };

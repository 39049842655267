/**
 * @ngdoc function
 * @name app.config:uiRouter
 * @description
 * @author Ashley Gillies (xylaray37@gmail.com)
 * # Config
 * Configure router for the Teacher routes
 */

import { gradebookCmp } from '@srcAjs/components/SubjectGradebookCmp/SubjectGradebookCmp.component';
import { appShellCmp } from './AppShellCmp/AppShellCmp.component';
import { trClassSubAnalytics } from './TrClassSubAnalytics/index.component';
import { trClassSubAttendance } from './TrClassSubAttendance/index.component';
import { trClassSubParents } from './TrClassSubParents/index.component';
import { trClassSubPromotions } from './TrClassSubPromotions/index.component';
import { trClassSubReports } from './TrClassSubReports/index.component';
import { trClassSubSignature } from './TrClassSubSignature/index.component';
import { trClassSubStudents } from './TrClassSubStudents/index.component';
import { trClassSubSubjects } from './TrClassSubSubjects/index.component';
import { trHomeDetail } from './TrHomeDetail/index.component';
import { trHomeSubDashboard } from './TrHomeSubDashboard/index.component';
import { trHomeSubProfile } from './TrHomeSubProfile/index.component';
import { trLeaderboardShellCmp } from './TrLeaderboardShellCmp/TrLeaderboardShellCmp.component';
import { trShellCmp } from './TrShellCmp/TrShellCmp.component';
import { trSubjectSubAssessment } from './TrSubjectSubAssessment/index.component';
import { subjectStudentsPageCmp } from '@srcAjs/components/SubjectStudentsPageCmp/SubjectStudentsPageCmp.component';
import { trSubjectDetail } from './TrSubjectDetail/index.component';
import { trSubjectSubStudentsAnalytics } from './TrSubjectSubStudentsAnalytics/index.component';
import { trClassDetail } from './TrClassDetail/index.component';

function schoolTeacherRoutesConfig(/** @type {ng.ui.IStateProvider} */ $stateProvider) {
	$stateProvider
		// Teacher Routes
		.state('tr', {
			url: '/tr/:user_id/:role_instance_id/:school_id',
			params: { user_id: null, role_instance_id: null, school_id: null },
			views: {
				'': {
					// templateUrl: 'views/layout.html',
					component: appShellCmp.selector,
				},
				'aside@tr': {
					templateUrl: 'views/aside.html',
				},
				// 'view@tr': {
				// 	// templateUrl: 'views/content.html',
				// 	// controller: 'TeacherContentController',
				// 	component: trShellCmp.selector,
				// },
			},
		})
		// Major Home State
		.state('tr.home', {
			url: '/home',
			component: trHomeDetail.selector,
			// views: {
			// 	'': {
			// 		templateUrl: 'views/pages/roles/tr/home/detail.html',
			// 		controller: 'TR_TeachersHomeDetailController',
			// 	},
			// },
			// data: { child: false },
		})
		.state('tr.home.dashboard', {
			url: '/dashboard',
			// controller: 'TR_DashboardController',
			// templateUrl: 'views/pages/roles/tr/home/sub/dashboard.html',
			component: trHomeSubDashboard.selector,
			data: { title: '', ngDynamicTitle: 'Teacher Home ‹ Schrep®', folded: false },
		})

		.state('tr.home.profile', {
			url: '/profile',
			// controller: 'TR_ProfileController',
			// templateUrl: 'views/pages/roles/tr/home/sub/profile.html',
			component: trHomeSubProfile.selector,
			data: { title: '', ngDynamicTitle: 'Settings Teacher ‹ Schrep®', folded: false },
			// resolve: load(['ngImgCrop'])
		})
		// Major Class Teacher Based State
		.state('tr.class', {
			url: '/class/:section_id',
			// views: {
			// 	'': {
			// 		templateUrl: 'views/pages/roles/tr/class/detail.html',
			// 		controller: 'TR_ClassDetailController',
			// 	},
			// },
			component: trClassDetail.selector,
			data: { child: true },
		})
		// Class > Attendance
		.state('tr.class.attendance', {
			url: '/attendance',
			// controller: 'TR_ClassDetailAttendanceController',
			// templateUrl: 'views/pages/roles/tr/class/sub/attendance.html',
			component: trClassSubAttendance.selector,
			data: { title: 'Class Attendance', folded: false, ngDynamicTitle: 'Take Attendance ‹ Schrep®', child: true },
		})
		// Class > Subjects
		.state('tr.class.subjects', {
			url: '/subjects',
			// controller: 'TR_ClassDetailSubjectsController',
			// templateUrl: 'views/pages/roles/tr/class/sub/subjects.html',
			component: trClassSubSubjects.selector,
			data: { title: 'Subjects', folded: false, ngDynamicTitle: 'My Subjects ‹ Schrep®', child: true },
		})
		// Class > Students
		.state('tr.class.students', {
			url: '/students/:id',
			params: { id: '' },
			// controller: 'TR_ClassDetailStudentsController',
			// templateUrl: 'views/pages/roles/tr/class/sub/students.html',
			component: trClassSubStudents.selector,
			data: { title: 'Class Students', folded: false, ngDynamicTitle: 'My Students ‹ Schrep®', child: true },
		})
		// Class > Reports
		.state('tr.class.reports', {
			url: '/reports',
			// controller: 'TR_ClassDetailReportsController',
			// templateUrl: 'views/pages/roles/tr/class/sub/reports.html',
			component: trClassSubReports.selector,
			data: { title: 'Reports', folded: false, ngDynamicTitle: 'Generate Reports ‹ Schrep®', child: true },
		})
		// Class > Analytics
		.state('tr.class.analytics', {
			url: '/analytics',
			// controller: 'TR_ClassDetailAnalyticsController',
			// templateUrl: 'views/pages/roles/tr/class/sub/analytics.html',
			component: trClassSubAnalytics.selector,
			data: { title: 'Analytics', folded: false, ngDynamicTitle: 'Class Analytics ‹ Schrep®', child: true },
		})
		// Class > Parents
		.state('tr.class.parents', {
			url: '/parents',
			// controller: 'TR_ClassDetailParentsController',
			// templateUrl: 'views/pages/roles/tr/class/sub/parents.html',
			component: trClassSubParents.selector,
			data: { title: 'Parents ', folded: false, ngDynamicTitle: 'Parents & Guardians ‹ Schrep®', child: true },
		})
		// Class > Promotions
		.state('tr.class.promotions', {
			url: '/promotions',
			// controller: 'TR_ClassDetailPromotionsController',
			// templateUrl: 'views/pages/roles/tr/class/sub/promotions.html',
			component: trClassSubPromotions.selector,
			data: { title: 'Promotions', folded: false, ngDynamicTitle: 'Promotions ‹ Schrep®', child: true },
		})
		// Class > Signature
		.state('tr.class.signature', {
			url: '/signature',
			// controller: 'TR_ClassTeacherSignatureController',
			// templateUrl: 'views/pages/roles/tr/class/sub/signature.html',
			component: trClassSubSignature.selector,
			data: { title: 'Signature', folded: false, ngDynamicTitle: 'Signature ‹ Schrep®', child: true },
		})

		// Major Subject Teacher Based State
		.state('tr.subject', {
			url: '/subject/:subject_mount_id',
			params: { subject_mount_id: null, subject_mount: null },
			// views: {
			// 	'': {
			// 		templateUrl: 'views/pages/roles/tr/subject/detail.html',
			// 		controller: 'TR_SubjectDetailController',
			// 	},
			// },
			component: trSubjectDetail.selector,
			data: { child: true },
		})
		// Subject > Students Analytics
		.state('tr.subject.students_analytics', {
			url: '/analytics',
			// controller: 'TR_SubjectDetailStudentsAnalyticsController',
			// templateUrl: 'views/pages/roles/tr/subject/sub/students_analytics.html',
			component: trSubjectSubStudentsAnalytics.selector,
			data: { title: 'Students Analytics', folded: false, ngDynamicTitle: 'Student Analytics ‹ Schrep®', child: true },
		})
		// Subject > Students
		.state('tr.subject.students', {
			url: '/students',
			resolve: { ...transitionResolve },
			component: subjectStudentsPageCmp.selector,
			data: { title: 'Students Analytics', folded: false, ngDynamicTitle: 'Student Analytics ‹ Schrep®', child: true },
		})
		// Subject > Assessment
		.state('tr.subject.assessment', {
			url: '/assessment/:id',
			params: { id: '' },
			component: trSubjectSubAssessment.selector,
			data: { title: 'Subject Assessment', folded: false, ngDynamicTitle: 'Assessment Buckets ‹ Schrep®', child: true },
		})
		// Subject > Gradebook
		.state('tr.subject.gradebook', {
			url: '/gradebook',
			component: gradebookCmp.selector,
			resolve: {
				...transitionResolve,
			},
			data: { title: 'Subject Gradebook', folded: false, ngDynamicTitle: 'Gradebook ‹ Schrep®', child: true },
		})
		// Subject > Summary
		.state('tr.subject.summary', {
			url: '/summary',
			controller: 'TR_SubjectDetailSummaryController',
			templateUrl: 'views/pages/roles/tr/subject/sub/summary.html',
			data: { title: 'Summary', folded: false, ngDynamicTitle: 'Subject Summary ‹ Schrep®', child: true },
		})

		.state('tr.home.leaderboard', {
			url: '/leaderboard',
			resolve: {
				termId: (/** @type {TRFactory}*/ TRFactory) => TRFactory.trServiceAtom.get().selectedTermId,
				school: (/**@type {auth} */ auth) => auth.getEngagedUserRole().school,
			},
			component: trLeaderboardShellCmp.selector,
			data: { title: 'Leaderboard', folded: false, ngDynamicTitle: 'Leaderboard ‹ Schrep® Cloud' },
		})

		.state('tr.class.student_creation_request', {
			url: '/student_creation_requests',
			template: "<students-creation-request-list-cmp class='h-full'></students-creation-request-list-cmp>",
			data: { title: 'Create Students', folded: false, ngDynamicTitle: 'Ceate Students ‹ Schrep® Cloud' },
		});
}

appModule.config(['$stateProvider', '$urlRouterProvider', 'MODULE_CONFIG', 'CONST_APP_NAME', schoolTeacherRoutesConfig]);

export { schoolTeacherRoutesConfig };

import template from './AccessSuccessCmp.tpl.html';

import './AccessSuccessCmp.scss';

const accessSuccessCmp = {
	template,
	bindings: {},
};

appModule.component('accessSuccessCmp', accessSuccessCmp);

import template from './index.html';

/** @type {import('@srcAjs/components2/adminRoutes').IComponentOptions2} */
const saSchoolSetupPhotosIndex = {
	template,
	bindings: {
		//someInput: '@string,<object,&function'
	},
	transclude: {
		// #slotName: '?optionalPublicName'
	},
};



function saSchoolSetupPhotosIndexCmpController(SAFactory, $scope, $cookies, $mdToast, lsfactory, $state, $rootScope, $localStorage, $mdDialog, Restangular, NgMap) {
	SAFactory.loadEnvironment();
	SAFactory.loadNotifications();
	$scope.env = $localStorage.environment;
	$scope.school = lsfactory.getEnv('school');
	$scope.isProcessing = false;
	$scope.active_tab = null;
	$scope.l_photos = null;
	$scope.selectedItem = null;
	$scope.items = ['By Latest Date', 'By Oldest Date', 'By Popularity'];
	$scope.isReady = true;
	//Configuring the light gallery for the school photos
	// $("div#lightGallery").lightGallery();
	//Defining the tab title and content indicators
	var tabs = [
			{
				title: 'Overview',
				sub_content: 'No Photos yet',
				content: 'Upload general pictures of various activies and gathering taking place at your school',
			},
			{
				title: 'Interior',
				sub_content: 'No Photos yet',
				content: 'Upload the interiors of your school so that others can know the internal environment',
			},
			{
				title: 'Exterior',
				sub_content: 'No Photos yet',
				content: 'Upload the exteriors of your school so that others can know the external environment',
			},
			{
				title: 'Occasions',
				sub_content: 'No Photos yet',
				content: 'Upload pictures of school gatherings so that others can know various occasions or happenings',
			},
			{
				title: 'Team',
				sub_content: 'No Photos yet',
				content: 'Upload photos of various teams or social clubs in the school for others to take a peep',
			},
			{
				title: 'Identity',
				sub_content: 'No Photos yet',
				content: 'Upload pictures of awards recieved so that others can know the reach of your educational ladder',
			},
		],
		selected = null,
		previous = null;
	$scope.tabs = tabs;
	$scope.selectedIndex = 0;

	$scope.$watch('selectedIndex', function (newValue, oldValue) {
		previous = selected;
		selected = tabs[newValue];
		console.log('Selected Tabs Activities');
		$scope.active_tab = selected;
		$scope.$broadcast('tab_selected.data_changed', newValue);
		//Making another request on data change
		if ($scope.active_tab.title === 'Overview') {
			var r_images = Restangular.all('photos-by-school/all/' + $scope.school.id);
			r_images.getList().then(function (data) {
				var res = data.plain();
				$scope.school_photos = res;
				$scope.l_photos = count($scope.school_photos);
			});
		} else {
			var r_images = Restangular.all('photos-by-school/title/' + $scope.school.id + '/' + $scope.active_tab.title);
			r_images.getList().then(function (data) {
				var res = data.plain();
				$scope.school_photos = res;
				$scope.l_photos = count($scope.school_photos);
			});
		}
	});
	$scope.$on('photo.dataChanged', function (event, data) {
		$scope.school_photos = data;
		console.log('This is on photo change');
		console.dir($scope.school_photos);
	});

	$scope.$on('tab_selected.data_changed', function (event, data) {
		console.log(data);
		console.log(event);
	});

	function count(photo_data) {
		if (photo_data != null && photo_data !== undefined) {
			return photo_data.length;
		}
	}

	//Creating a watcher for the sorting
	$scope.$watch('selectedItem', function (newValue, oldValue) {
		$scope.activeItem = newValue;
		switch ($scope.activeItem) {
			case 'By Latest Date':
				$scope.isProcessing = true;
				if ($scope.active_tab.title === 'Overview') {
					var latest_photos = Restangular.all('photos-by-school/by-latest-date/all/' + $scope.school.id);
					latest_photos.getList().then(function (data) {
						$scope.isProcessing = false;
						var res = data.plain();
						$scope.school_photos = res;
					});
				} else {
					var latest_photos = Restangular.all('photos-by-school/by-latest-date/' + $scope.active_tab.title);
					latest_photos.getList().then(function (data) {
						$scope.isProcessing = false;
						var res = data.plain();
						$scope.school_photos = res;
					});
				}
				break;
			case 'By Oldest Date':
				$scope.isProcessing = true;
				if ($scope.active_tab.title === 'Overview') {
					var latest_photos = Restangular.all('photos-by-school/by-oldest-date/all/' + $scope.school.id);
					latest_photos.getList().then(function (data) {
						$scope.isProcessing = false;
						var res = data.plain();
						$scope.school_photos = res;
					});
				} else {
					var latest_photos = Restangular.all('photos-by-school/by-oldest-date/' + $scope.active_tab.title);
					latest_photos.getList().then(function (data) {
						$scope.isProcessing = false;
						var res = data.plain();
						$scope.school_photos = res;
					});
				}
				break;
		}
	});

	// deleting the image from the backend based on the image id
	$scope.showDeleteDialog = function (school_id, photo_id) {
		lsfactory.setEnv('getSchoolId', school_id);
		lsfactory.setEnv('getPhotoId', photo_id);
		$mdDialog.show({
			clickOutsideToClose: false,
			escapeToClose: true,
			templateUrl: 'views/pages/roles/sa/school_setup/photos/dialogs/delete_photo.html',
			controller: DeleteSchoolPhotoDialogController,
		});
	};

	//Configuring the dialogs for the uploading of the pictures
	$scope.showUploadDialog = function () {
		lsfactory.setEnv('getActiveTab', $scope.active_tab);
		$mdDialog.show({
			clickOutsideToClose: true,
			escapeToClose: true,
			templateUrl: 'views/pages/roles/sa/school_setup/photos/dialogs/upload_school_photos.html',
			controller: UploadSchoolPhotosDialogController,
		});
	};
}

saSchoolSetupPhotosIndex.controller = saSchoolSetupPhotosIndexCmpController;

appModule.component('saSchoolSetupPhotosIndex', saSchoolSetupPhotosIndex);

saSchoolSetupPhotosIndex.selector = 'saSchoolSetupPhotosIndex';

export { saSchoolSetupPhotosIndex }

import template from './index.html';

/** @type {import('@srcAjs/components2/adminRoutes').IComponentOptions2} */
const saHomeAnalysisCmp = {
	template,
	bindings: {},
};

function SA_AnalysisController($scope, /**@type {auth} */ auth) {

	$scope.isReady = true;

	$scope.updateTerm = (term) => {
		if (!term) return;

		$scope.term = term;
	};

	$scope.school = auth.getEngagedUserRole().school
}

saHomeAnalysisCmp.controller = SA_AnalysisController;

appModule.component('saHomeAnalysisCmp', saHomeAnalysisCmp);

saHomeAnalysisCmp.selector = 'saHomeAnalysisCmp';

export { saHomeAnalysisCmp }

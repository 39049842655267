import './GradeColorPillCmp.scss';

import template from './GradeColorPillCmp.tpl.html';

/** @type {ng.IComponentOptions} */
const gradeColorPillCmp = {
	template,
	/** @type {{weight:number,noFloat: boolean,grade:number}} */
	// @ts-ignore
	bindings: {
		weight: '<',
		grade: '<',
		noFloat: '<',
	},
};

class ComponentNameController {
	/** @type {getGradeColorFilter} */ getGradeColorFilter;

	/** @type {typeof gradeColorPillCmp.bindings.noFloat}*/ noFloat;

	/** @type {typeof gradeColorPillCmp.bindings.grade} */ grade;

	constructor(getGradeColorFilter, /** @type {ng.IFilterService}*/ $filter, /** @type {compSetupService} */ compSetupService, /** @type {EffectService} */ EffectService) {
		compSetupService(this, gradeColorPillCmp.bindings);
		this.getGradeColorFilter = getGradeColorFilter;
		this.$filter = $filter;

		EffectService.setup(
			this,
			({ onChanges, onInit }) => {
				if (onChanges || onInit) {
					this.style = this.getStyle(this.noFloat, this.getGradeColorFilter(this.weight));
				}
			},
			() => [this.weight, this.grade, this.noFloat]
		);
	}

	getStyle(noFloat, { ...res }) {
		if (!noFloat) {
			res = { position: 'absolute', top: '-5px', right: '-5px', ...res };
		}

		return res;
	}
}

gradeColorPillCmp.controller = ComponentNameController;

appModule.component('gradeColorPillCmp', gradeColorPillCmp);

import template from './index.html';

/** @type {import('@srcAjs/components2/adminRoutes').IComponentOptions2} */
const saEventsIndex = {
	template,
	bindings: {
		//someInput: '@string,<object,&function'
	},
	transclude: {
		// #slotName: '?optionalPublicName'
	},
};

function saEventsIndexCmpController($scope, $cookies, $mdToast, $state, $mdDialog, $localStorage, $http, $compile, SAFactory, lsfactory, Restangular) {
	SAFactory.initializeRole();
	SAFactory.loadEnvironment();
	var date = new Date();
	var d = date.getDate();
	var m = date.getMonth();
	var y = date.getFullYear();

	var vm = $scope;
	vm.events = [];
	vm.eventsInWeeks = {};
	$scope.eventsLinear = [];
	$scope.isOne = false;
	$scope.isReady = false;

	// UI Calendar JS
	$scope.alertOnEventClick = function (date, jsEvent, view) {
		$scope.tempEventToUpdate = date;
		$scope.updateEvent();
	};

	$scope.addEvent = function (newEvent) {
		$scope.events.push({
			title: newEvent.title,
			start: new Date(newEvent.start_date),
			end: new Date(newEvent.end_date),
			id: newEvent.id,
			stick: true, //force events to remain visible in thier visible after switching months
			className: ['openSesame'],
		});
	};

	$scope.remove = function (index) {
		$scope.events.splice(index, 1);
	};
	$scope.changeView = function (view, calendar) {
		uiCalendarConfig.calendars[calendar].fullCalendar('changeView', view);
	};

	$scope.renderCalender = function (calendar) {
		if (uiCalendarConfig.calendars[calendar]) {
			uiCalendarConfig.calendars[calendar].fullCalendar('render');
		}
	};
	$scope.eventRender = function (event, element, view) {
		element.attr({ tooltip: event.title, 'tooltip-append-to-body': true });
		$compile(element)($scope);
	};

	$scope.uiConfig = {
		calendar: {
			height: 600,
			editable: false,
			header: {
				left: 'title',
				center: '',
				right: 'today prev,next',
			},
			eventClick: $scope.alertOnEventClick,
			eventRender: $scope.eventRender,
		},
	};

	// Custom App JS for Events
	$scope.newEvent = function () {
		$mdDialog.show({
			escapeToClose: false,
			clickOutsideToClose: false,
			templateUrl: 'views/pages/roles/sa/events/dialogs/add_event.html',
			controller: AddEventDialogController,
		});
	};

	$scope.updateEvent = function () {
		$mdDialog.show({
			escapeToClose: false,
			clickOutsideToClose: false,
			templateUrl: 'views/pages/roles/sa/events/dialogs/edit_event.html',
			controller: EditEventDialogController,
			resolve: {
				$newEvent: function () {
					return $scope.tempEventToUpdate;
				},
			},
		});
	};

	$scope.$on('EVENT_UPDATED', function (e, args) {
		console.log('$scope triggered');
		resetEvents();
	});

	function processEventsIntoWeeks(events) {
		vm.eventsInWeeks = {};
		for (var i = 0; i < events.length; i++) {
			addEventToWeek(events[i]); //add each event to eventInWeek
		}
	}

	function addEventToWeek(event) {
		var initial_length = vm.eventsInWeeks.length;
		var week = moment(event.start_date).week() + '_week';
		if (typeof vm.eventsInWeeks[week] == 'undefined') {
			vm.eventsInWeeks[week] = [];
			//set a new week element
			// This causes a few errors on my(@dominic) end
			// var title = `${moment(event.start_date).format('MMMM')} ${moment(event.start_date).startOf('week').format('D')} - ${moment(event.start_date).endOf('week').format('D')}`;
			var title = moment(event.start_date).format('MMMM') + ' ' + moment(event.start_date).startOf('week').format('D') + ' - ' + moment(event.start_date).endOf('week').format('D');
			vm.eventsInWeeks[week] = {
				title: title,
				days: [],
			};
		}

		var eventEntry = {
			date: moment(event.start_date).format('D/M/YYYY'),
			day: moment(event.start_date).format('ddd'),
			date_number: moment(event.start_date).format('D'),
			event: event,
			type: 'event',
		}; //setup eventEntry to be added into days of weeks

		if (vm.eventsInWeeks[week].days.length == 0) {
			eventEntry.showDate = true;
			vm.eventsInWeeks[week].days.push({ number: eventEntry.date_number, entries: [eventEntry] });
		} else {
			for (var i = 0; i < vm.eventsInWeeks[week].days.length; i++) {
				if (vm.eventsInWeeks[week].days[i].number == eventEntry.date_number) {
					vm.eventsInWeeks[week].days[i].entries.push(eventEntry);
				} else {
					vm.eventsInWeeks[week].days.push({ number: eventEntry.date_number, entries: [eventEntry] });
				}
			}
		}
		console.dir(vm.eventsInWeeks);
		if (vm.eventsInWeeks.length > initial_length) return true;
		new Error('Failed to add event to a week');
	}

	function resetEvents() {
		$('#events_is_loading').show();
		$('#events_is_not_loading').hide();
		$scope.events.splice(0, $scope.events.length);
		Restangular.one('school', lsfactory.getEnv('school').id)
			.all('events_latest')
			.getList()
			.then(function (response) {
				var evs = response.plain();
				processEventsIntoWeeks(evs);
				console.dir($scope.eventsLinear);
				for (var k = 0; k < evs.length; k++) {
					$scope.addEvent(evs[k]);
				}
				$('#events_is_loading').hide();
				$('#events_is_not_loading').show();
				$scope.isReady = true;
				if (evs.length > 0) {
					$('#events-items').show();
					$('#events-no-items').hide();
				} else {
					$('#events-items').hide();
					$('#events-no-items').show();
				}
			});
	}

	resetEvents();
	/* event sources array*/
	$scope.eventSources = [$scope.events];
}

saEventsIndex.controller = saEventsIndexCmpController;

appModule.component('saEventsIndex', saEventsIndex);

saEventsIndex.selector = 'saEventsIndex';

export { saEventsIndex };

import template from './index.html';

/** @type {import('@srcAjs/components2/adminRoutes').IComponentOptions2} */
const saTeachersImport = {
	template,
	bindings: {
		//someInput: '@string,<object,&function'
	},
	transclude: {
		// #slotName: '?optionalPublicName'
	},
};

function saTeachersImportCmpController(
	SAFactory,
	$scope,
	$cookies,
	$mdToast,
	$state,
	$mdDialog,
	$rootScope,
	lsfactory,
	Restangular,
	$localStorage,
	editableThemes,
	editableOptions,
	CONST_TEACHER_CSV_URL
) {
	SAFactory.loadEnvironment();
	SAFactory.loadNotifications();
	$scope.isReady = true;

	$scope.downloadTemplate = function () {
		$.fileDownload(CONST_TEACHER_CSV_URL).then(null, function (err) {
			var template = $mdToast.simple().textContent('Unable to start download').action('OK').highlightAction(true).hideDelay(5500);
			$mdToast.show(template);
		});
	};

	$scope.$on('teacher_import_over', function (event, useless) {
		$scope.backHome();
	});

	editableThemes.bs3.inputClass = 'input-sm';
	editableThemes.bs3.buttonsClass = 'btn-sm';
	editableOptions.theme = 'bs3';
	$scope.genders = ['Male', 'Female'];

	function noDummies(list) {
		for (var i = 0; i < list.length; i++) {
			matchString = 'dummy';
			for (var j = 0; j < list[i].length; j++) {
				if (list[i][j].toLowerCase().indexOf(matchString) != -1) {
					return true;
				}
			}
		}
		return false;
	}

	function showConfigDialog() {
		let teachers = $scope.list.map((teacher) => {
			let [salutation, first_name, last_name, gender, email, phone, password, birthdate] = teacher;
			return { salutation, first_name, last_name, gender, email, phone, password, birthdate };
		});
		const COLUMNS = 8;
		let expected_teachers_count = $scope.list.length;
		let actual_teachers_count = teachers.filter((teacher) => {
			let valid_attrs_len = Object.values(teacher).filter((attr) => {
				// TODO add validators here or implement laravel validators
				return attr !== '' && attr !== undefined;
			}).length;
			return valid_attrs_len == COLUMNS;
		}).length;

		// if (expected_teachers_count !== actual_teachers_count) {
		// 	let template = $mdToast.simple().textContent("Some attributes are missing from some teachers, check and try again!").action("OK").highlightAction(true).hideDelay(5500)
		// 	$mdToast.show(template)
		// 	return
		// }

		lsfactory.setEnv('dialogUpTeacherList', teachers);

		$mdDialog.show({
			escapeToClose: true,
			clickOutsideToClose: true,
			templateUrl: 'views/pages/roles/sa/teachers/dialogs/config_import.html',
			controller: ConfigImportTeachersDialogController,
		});
	}

	$scope.init = function () {
		var handleFileSelect = function (evt) {
			var fileTypes = ['csv'];
			var file = evt.currentTarget.files[0];
			var extension = file.name.split('.').pop().toLowerCase(),
				isSuccess = fileTypes.indexOf(extension) > -1;
			if (isSuccess) {
				var reader = new FileReader();
				reader.onload = function (evt) {
					var csv = evt.target.result;
					var data = $.csv.toArrays(csv);
					$scope.$apply(function ($scope) {
						$scope.list = data.map((line) => {
							console.log(line);
							// let result = line[0];
							if (Array.isArray(line) && line.length == 1 && line.length && !!line[0].match('|'))
								//check that line contains |
								return line[0].split('|'); // split line by |
							console.log('no pipe');
							return line;
						});
						$scope.removeRow(0); // remove header
					});
				};
				reader.readAsText(file);
			} else {
				var template = $mdToast.simple().textContent('File format unsupported').action('OK').highlightAction(true).hideDelay(5500);
				$mdToast.show(template);
			}
		};
		angular.element(document.querySelector('#fileInput')).on('change', handleFileSelect);
	};

	$scope.upload = function () {
		if (noDummies($scope.list)) {
			var confirm = $mdDialog
				.confirm()
				.title('Might Contain "dummy" data')
				.textContent('You input file might contain unwanted dummy entries. Do you still want to continue with upload')
				.ariaLabel('Warning')
				.ok('Yes please!')
				.cancel('No, I dont think so');
			$mdDialog.show(confirm).then(
				function () {
					showConfigDialog();
				},
				function () {}
			);
		} else {
			showConfigDialog();
		}
	};

	$scope.saveItem = function (data, id) {
		angular.extend(data, { id: id });
	};
	$scope.backHome = function () {
		$scope.list = undefined;
	};
	$scope.list = undefined;
	$scope.removeRow = function (index) {
		$scope.list.splice(index, 1);
	};
	$scope.addRow = function () {
		$scope.inserted = {
			id: $scope.list.length + 1,
			salutation: '',
			firstname: '',
			lastname: '',
			marital_status: '',
			gender: '',
			birthdate: '',
			phone: '',
			type: '',
			status: null,
			group: null,
		};
		$scope.list.push($scope.inserted);
	};
}

saTeachersImport.controller = saTeachersImportCmpController;

appModule.component('saTeachersImport', saTeachersImport);

saTeachersImport.selector = 'saTeachersImport';

export { saTeachersImport };

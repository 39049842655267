import './StandardInputCmp.scss';

import template from './StandardInputCmp.tpl.html';

const standardInputCmp = {
  template,
  bindings: {
  },
  transclude: {
    'inputLeft': '?sicInputLeft',
    'inputCenter': 'sicInputCenter',
    'inputRight': '?sicInputRight'
  }
};

class StandardInputCmpController {
  constructor() {
  }
}

standardInputCmp.controller = [StandardInputCmpController];

appModule.component('standardInputCmp', standardInputCmp);
// export default component;

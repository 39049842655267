import 'angulartics';
import 'angulartics-google-tag-manager';

import { panelCmp } from '@srcAjs/components/shared/PanelCmp/PanelCmp.component.js';
import { SchoolAdminRoutesConfig } from '@srcAjs/components2/adminRoutes.js';
import { notificationsCmp } from '@srcAjs/components2/NotificationsCmp/NotificationsCmp.component.js';
import { privacyPolicyCmp } from '@srcAjs/components2/PrivacyPolicyCmp/PrivacyPolicyCmp.component.js';
import { saGuardiansToolbar } from '@srcAjs/components2/SaGuardiansToolbar/index.component.js';
import { saOperationsApproveScoresDialogsCheckScoresDialog } from '@srcAjs/components2/SaOperationsApproveScoresDialogsCheckScoresDialog/index.component.js';
import { saSchoolSetupClassesToolbar } from '@srcAjs/components2/SaSchoolSetupClassesToolbar/index.component.js';
import { saSchoolSetupClassesToolbarSection } from '@srcAjs/components2/SaSchoolSetupClassesToolbarSection/index.component.js';
import { saSchoolSetupClassGroupsDialogsAddGroup } from '@srcAjs/components2/SaSchoolSetupClassGroupsDialogsAddGroup/index.component.js';
import { saSchoolSetupClassGroupsDialogsChooseLevel } from '@srcAjs/components2/SaSchoolSetupClassGroupsDialogsChooseLevel/index.component.js';
import { saSchoolSetupClassGroupsDialogsEditGroup } from '@srcAjs/components2/SaSchoolSetupClassGroupsDialogsEditGroup/index.component.js';
import { saSchoolSetupClassGroupsToolbar } from '@srcAjs/components2/SaSchoolSetupClassGroupsToolbar/index.component.js';
import { saSchoolSetupGradingToolbar } from '@srcAjs/components2/SaSchoolSetupGradingToolbar/index.component.js';
import { saSchoolSetupYearsToolbarTerm } from '@srcAjs/components2/SaSchoolSetupYearsToolbarTerm/index.component.js';
import { saStudentsToolbar } from '@srcAjs/components2/SaStudentsToolbar/index.component.js';
import { saTeachersDialogsEditTeacher } from '@srcAjs/components2/SaTeachersDialogsEditTeacher/index.component.js';
import { saTeachersToolbar } from '@srcAjs/components2/SaTeachersToolbar/index.component.js';
import { saTeacherSubjectsAndClasses } from '@srcAjs/components2/saTeacherSubjectsAndClasses/index.component.js';
import { schoolTeacherRoutesConfig } from '@srcAjs/components2/teacherRoutes.js';
import { termsAndConditionsCmp } from '@srcAjs/components2/termsAndConditionsCmp/termsAndConditionsCmp.component.js';
import { toastCmp } from '@srcAjs/components2/ToastCmp/ToastCmp.component.js';

import API from './api.js';
import auth from './auth.js';
import * as EffectService from './EffectService.js';
import * as fcmMessagingService from './messaging.js';
import RouterGuardService from './RouterGuardService.js';
import * as RxService from './rxjs.factory.js';
import * as saTeachersService from './saTeachersService.js';
import * as AppStoreFactory from './store/store.js';
import configService from './configService.js';
import compSetupService from './compSetupService.js';
import * as atomosServiceFactory from './atomosService.js';
import * as dialogUrlService from './dialogUrlService.js';
import * as panelUrlService from './panelUrlService.js';
import { appShellCmp } from '@srcAjs/components2/AppShellCmp/AppShellCmp.component.js';
import { asideLeftCmp } from '@srcAjs/components2/AsideLeftCmp/AsideLeftCmp.component.js';
import { SchrepAdminRoutes } from '@srcAjs/components2/schrepAdminRoutes.js';
import { trSubjectDialogsAddGroupCmp } from '@srcAjs/components2/TrSubjectDialogsAddGroupCmp/index.component.js';
import { trSubjectDialogsAddEntity } from '@srcAjs/components2/TrSubjectDialogsAddEntity/index.component.js';
import { chartAssessmentCmp } from '@srcAjs/components2/ChartAssessmentCmp/ChartAssessmentCmp.component.js';
import { chartBasicCmp } from '@srcAjs/components2/ChartBasicCmp/ChartBasicCmp.component.js';
import { sectionSubMountCharts } from '@srcAjs/components2/SectionSubMountCharts/SectionSubMountCharts.component.js';
import { saGuardiansDialogsEditGuardian } from '@srcAjs/components2/SaGuardiansDialogsEditGuardian/index.component.js';
import { changePasswordCmp } from '@srcAjs/components2/ChangePasswordCmp/index.component.js';
import { ParentRoutesConfig } from '@srcAjs/components2/parentRoutes.js';
import { morphiousDialogCmp } from '../shared/MorphiousDialogCmp/MorphiousDialogCmp.component.js';
import { AboutAndVersionCmp } from '@srcAjs/components2/AboutAndVersionCmp/index.js';
import { guardianNotorizationService } from './guardianNotorizations.js';
import * as navDialogAtomsService from './navDialogsAtoms.js';

const module = {
	declare: [
		appShellCmp,
		asideLeftCmp,
		saTeachersDialogsEditTeacher,
		saTeacherSubjectsAndClasses,
		saTeachersToolbar,
		saStudentsToolbar,
		saSchoolSetupYearsToolbarTerm,
		saSchoolSetupGradingToolbar,
		saSchoolSetupClassGroupsToolbar,
		saSchoolSetupClassesToolbarSection,
		saSchoolSetupClassesToolbar,
		saGuardiansToolbar,
		saOperationsApproveScoresDialogsCheckScoresDialog,
		panelCmp,
		notificationsCmp,
		termsAndConditionsCmp,
		privacyPolicyCmp,
		saSchoolSetupClassGroupsDialogsChooseLevel,
		saSchoolSetupClassGroupsDialogsEditGroup,
		saSchoolSetupClassGroupsDialogsAddGroup,
		toastCmp,
		trSubjectDialogsAddGroupCmp,
		trSubjectDialogsAddEntity,
		chartAssessmentCmp,
		chartBasicCmp,
		sectionSubMountCharts,
		saGuardiansDialogsEditGuardian,
		changePasswordCmp,
		morphiousDialogCmp,
		AboutAndVersionCmp,
	],

	imports: [
		RxService,
		EffectService,
		SchrepAdminRoutes,
		SchoolAdminRoutesConfig,
		schoolTeacherRoutesConfig,
		ParentRoutesConfig,
		AppStoreFactory,
		RouterGuardService,
		fcmMessagingService,
		saTeachersService,
		API,
		auth,
		configService,
		compSetupService,
		atomosServiceFactory,
		dialogUrlService,
		panelUrlService,
		guardianNotorizationService,
		navDialogAtomsService,
	],
};

export { module };

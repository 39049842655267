import './saGenSchoolReportCmp.scss';

import template from './saGenSchoolReportCmp.tpl.html';

const saGenSchoolReportCmp = {
	template,
	bindings: {},
};

function GenerateSchoolReportDialogController(Restangular, $scope, auth, $mdDialog, $mdToast) {
	$scope.school = auth.getEngagedUserRole().school;

	$scope.getReport = function () {
		$scope.isProcessing = true;
		const downloadLink = Restangular.all('report/by_term_id/' + $scope.section_history_id);
		downloadLink.getList().then((data) => {
			$scope.isProcessing = false;
			const list = data.plain();
			console.dir(list);
			$.fileDownload(list[0]).then(null, (err) => {
				const template = $mdToast.simple().textContent('Unable to download report').action('OK').highlightAction(true).hideDelay(5500);
				$mdToast.show(template);
			});
			$scope.hide();
		});
	};

	$scope.hide = function () {
		$mdDialog.hide();
	};

	$scope.cancel = function () {
		$mdDialog.cancel();
	};
}

saGenSchoolReportCmp.controller = GenerateSchoolReportDialogController;

appModule.component('saGenSchoolReportCmp', saGenSchoolReportCmp);
// export default component;

import "./termImportCmp.scss"

import template from "./termImportCmp.tpl.html"

const termInportCmp = {
	template,
	bindings: {
		destinationTerm: "<",
		prevYearTerms: "<",
		school: "<",
	},
}

function ComponentNameController(/** @type {import("@srcAjs/factory/services/class-group").ClassGroupApi} */  ClassGroup, ErrorService, $mdDialog, Restangular, $rootScope, $localStorage, $scope) {
	this.isReady = false
	this.stats = {
		classes: 0,
		sections: 0,
		students: 0,
		subjects: 0,
		subject_teachers: 0,
	}

	this.updateTerm = async (source_term) => {
		try {
			this.isReady = false
			this.source_term = source_term
			this.source_classes = prepare(await ClassGroup.get(this.school, source_term, ["subjects_count", "subjects", "sections"], "1.2"))
		} catch (error) {
			ErrorService.handleError(error)
		} finally {
			this.isReady = true
		}
	}

	this.close = () => $mdDialog.hide()

	this.hasImportedTeachersOrStudents = (section) => section.import_students || section.import_subject_teachers || section.import_info

	this.toggleSectionTeachersAndStudents = (_class, section) => {
		section.import_students = section.import_subject_teachers = section.import_info = !this.hasImportedTeachersOrStudents(section)
		_class.import_subjects = section.import_subject_teachers ? section.import_subject_teachers : _class.import_subjects
	}

	this.hasAllSectionsImported = (sections) => sections.length && sections.length == sections.filter(this.hasImportedTeachersOrStudents).length

	this.hasAnySectionSubjectTeachersImported = (sections) => sections.length && sections.filter((section) => section.import_subject_teachers).length

	this.toggleSectionInfo = (section) => (!section.import_info ? (section.import_students = section.import_subject_teachers = section.import_info) : null)

	this.toggleClassSubjects = (_class) => {
		let import_subject_teachers = !this.hasAnySectionSubjectTeachersImported(_class.sections)

		if (import_subject_teachers) return
		_class.sections = _class.sections.map((section) => ({ ...section, import_subject_teachers }))
	}

	this.toggleClassSubjectsImportAndSectionInfo = (_class, section) => {
		section.import_info = this.hasImportedTeachersOrStudents(section)
		if (!_class.import_subjects && section.import_subject_teachers) _class.import_subjects = section.import_subject_teachers
	}

	this.importWholeClass = (_class) => {
		let hasAllSectionsImported = this.hasAllSectionsImported(_class.sections)
		_class.sections = _class.sections.map((section) => {
			_class.import_subjects = section.import_info = section.import_subject_teachers = section.import_students = !hasAllSectionsImported
			return section
		})
	}

	/**
	 *@typedef {{sections}} class
	 * @param {class []} classes
	 * @returns {[]} array of sections
	 */
	let getSections = (classes) => classes.flatMap((cls) => cls.sections)

	this.hasImportedAllSections = (classes) => (classes ? getSections(classes).length == getSections(classes).filter((section) => section.import_info).length : null)

	this.toggleImportAllSections = (classes) => {
		let current_state = this.hasImportedAllSections(classes)

		getSections(classes).forEach((section) => {
			section.import_info = !current_state
			this.toggleSectionInfo(section)
		})
		// classes = classes.map((_class) => {
		// 	_class.sections = _class.sections.map((section) => ({ ...section, import_info: !current_state }))
		// 	return _class
		// })
	}

	this.hasImportedAllSubjects = (classes) => (classes ? classes.length == classes.filter((cls) => cls.import_subjects).length : null)

	this.toggleImportAllSubjects = (classes) => {
		// classes = classes.map((cls) => ({ ...cls, import_subjects: !current_state }))

		let current_state = this.hasImportedAllSubjects(classes)
		for (let idx in classes) classes[idx].import_subjects = !current_state

		classes.forEach((cls) => {
			cls.import_subject = !current_state
			if (current_state) cls.sections.forEach((section) => (section.import_subject_teachers = !current_state))
		})
	}

	this.hasImportedAllStudents = (classes) => (classes ? getSections(classes).length == getSections(classes).filter((section) => section.import_students).length : null)

	this.toggleImportAllStudents = (classes) => {
		let current_state = this.hasImportedAllStudents(classes)

		getSections(classes).forEach((section) => {
			section.import_students = !current_state
			section.import_info = this.hasImportedTeachersOrStudents(section)
		})
		// classes = classes.map((cls) => {
		// 	cls.sections = cls.sections.map((section) => ({ ...section, import_students: !current_state, import_info: !current_state }))
		// 	return cls
		// })
	}

	this.hasImportedAllSubjectTeachers = (classes) => (classes ? getSections(classes).length == getSections(classes).filter((section) => section.import_subject_teachers).length : null)

	this.toggleImportAllSubjectsTeachers = (classes) => {
		let current_state = this.hasImportedAllSubjectTeachers(classes)

		for (let _class of classes) {
			_class.sections.forEach((section) => {
				section.import_subject_teachers = !current_state
				this.toggleClassSubjectsImportAndSectionInfo(_class, section)
			})
		}
		// classes = classes.map((cls) => {
		// 	cls.sections = cls.sections.map((section) => ({ ...section, import_subject_teachers }))
		// 	return cls
		// })
	}
	this.import = async (__classes) => {
		let _classes = angular.copy(__classes)
		try {
			this.isReady = false
			let _data = {
				classes: _classes
					.map((_class) => {
						_class.import_subjects = Object.keys(_class).includes("import_subjects") ? _class.import_subjects : false
						_class.sections = _class.sections.filter((section) => section.import_info)
						_class.subjects = _class.import_subjects ? _class.subjects : []
						return _class
					})
					.filter((_class) => _class.import_subjects || _class.sections.length),
				source_term_id: this.source_term.id,
				destination_term_id: this.destinationTerm.id,
			}

			// if (!(await showConfirmation(_data.classes))) return

			await Restangular.all("term/import_term_version_1_2")
				.post(_data)
				.then((res) => res.plain())
			$rootScope.$broadcast("system.sa.environmentChanged", $localStorage.environment)

			showSuccess()

			this.close()
		} catch (error) {
			ErrorService.handleError(error)
		} finally {
			this.isReady = true
		}
	}

	let prepare = (class_groups) => {
		this.stats = {
			classes: 0,
			sections: 0,
			students: 0,
			subjects: 0,
			subject_teachers: 0,
		}
		return class_groups.map((cg) => {
			cg.sections = cg.sections.map((section) => ({ ...section, import_info: false, import_subject_teachers: false, import_students: false }))

			return cg
		})
	}

	let showConfirmation = (classes) => {
		let class_count = classes.length
		let section_count = getSections(classes).length

		return $mdDialog.show(
			$mdDialog.confirm({
				title: "Confirm",
				textContet: `I am importing ${class_count} classes and ${section_count} sections`,
				ok: "Yes please!",
				cancel: "Nope",
			})
		)
	}

	let showSuccess = () =>
		$mdDialog.show(
			$mdDialog.alert({
				title: "Great: Operation Complete!",
				textContet: "Click the sections to review the updates",
				ok: "Got it",
			})
		)

	$scope.$watch(
		"$ctrl.source_classes",
		(classes) => {
			if (!classes) return

			let sections = getSections(classes).filter((section) => section.import_info)
			this.stats.subjects = classes.filter((cls) => cls.import_subjects).reduce((acc, cls) => acc + cls.subjects_count, 0)
			this.stats.sections = sections.length
			this.stats.students = sections.filter((section) => section.import_students).reduce((total, section) => total + section.section_histories_count, 0)
			this.stats.subject_teachers = sections
				.filter((section) => section.import_subject_teachers)

				.reduce((total, section) => total + section.subject_mounts_count, 0)

			this.stats.classes = classes.filter((cls) => cls.import_subjects || sections.filter((sc) => sc.s_class_id == cls.id).length).length

			console.log("updating this.stats", this.stats)
		},
		true
	)

	this.disallowedTermFn=( term ) => {
		if ( !term ) return
		
		return term.id == this.destinationTerm.id
	}

	this.next = () => {
		this.promotion_running = true
		this.isReady = true
	}

	this.back = () => {
		this.promotion_running = false
		this.isReady = true
	}
}

termInportCmp.controller = ["ClassGroup", "ErrorService", "$mdDialog", "Restangular", "$rootScope", "$localStorage", "$scope", ComponentNameController]

appModule.component("termImportCmp", termInportCmp)
// export default component;

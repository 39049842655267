import template from './index.html';

/** @type {import('@srcAjs/components2/adminRoutes').IComponentOptions2} */
const saSchoolSetupYearsSubTermsSummary = {
	template,
	bindings: {
		//someInput: '@string,<object,&function'
	},
	transclude: {
		// #slotName: '?optionalPublicName'
	},
};



function saSchoolSetupYearsSubTermsSummaryCmpController(
	SAFactory,
	$scope,
	$cookies,
	$stateParams,
	$state,
	$rootScope,
	$localStorage,
	$mdDialog,
	$mdToast,
	lsfactory,
	Restangular,
	Years
) {
	$scope.next = function (year) {
		var y = parseInt(year);
		return y + 1;
	};
	$scope.sel_year = lsfactory.getEnv('currentYearSelected');
	$scope.sel_term = lsfactory.getEnv('currentTermSelected');

	SAFactory.loadNotifications();
	$scope.$on('system.sa.environmentChanged', function (ev, data) {
		Term.one($stateParams.tid)
			.get()
			.then(
				function (res) {
					$scope.sel_term = res;
					$state.current.data.title =
						$scope.sel_term.term_type.title + ' ( ' + $scope.sel_year.year + ' / ' + $scope.next($scope.sel_year.year) + ' )';
				},
				function (err) {
					if (err.status == 404) {
					}
				}
			);
	});
	$state.current.data.title =
		$scope.sel_term.term_type.title + ' ( ' + $scope.sel_year.year + ' / ' + $scope.next($scope.sel_year.year) + ' )';
}

saSchoolSetupYearsSubTermsSummary.controller = saSchoolSetupYearsSubTermsSummaryCmpController;

appModule.component('saSchoolSetupYearsSubTermsSummary', saSchoolSetupYearsSubTermsSummary);

saSchoolSetupYearsSubTermsSummary.selector = 'saSchoolSetupYearsSubTermsSummary';

export { saSchoolSetupYearsSubTermsSummary }

import './AssessmentEntityScoreCrudCmp.scss';

import template from './AssessmentEntityScoreCrudCmp.tpl.html';

const assessmentEntityScoreCrudCmp = {
	template,
	bindings: {
		enableSetAllSwitch: '<',
		assessmentEntity: '<',
		scoreItem: '<',
		onSubmit: '&',
		panelRef: '<',
		scoreIdx: '<',
		close: '&',
	},
};

class AssessmentEntityScoreCrudCmpController {
	#ErrorService;
	constructor(ErrorService) {
		this.#ErrorService = ErrorService;
		console.log('constructor');
	}

	$onInit() {}

	submit = (score_item, scoreIdx, should_apply_score_to_section_histories = false, event) => {
		try {
			// onSubmit(scooped_item, idx, apply_score_to_all, e)
			let output = { scooped_item: score_item, idx: scoreIdx, apply_score_to_all: should_apply_score_to_section_histories, e: event };
			this.onSubmit(output);
			this.panelRef.destroy(output);
		} catch (error) {
			this.#ErrorService.handleError(error);
		} finally {
		}
	};

	dismiss = (data) => {
		console.log('close called');
		this.panelRef.destroy(data);
	};
}

assessmentEntityScoreCrudCmp.controller = ['ErrorService', AssessmentEntityScoreCrudCmpController];

appModule.component('assessmentEntityScoreCrudCmp', assessmentEntityScoreCrudCmp);

// export default component;

/**
 * Created by dominic on 10/19/17.
 */

import template from './index.html';

/** @type {import('@srcAjs/components2/adminRoutes').IComponentOptions2} */
const saHomePush = {
	template,
	bindings: {},
};

function SaHomePush(
	$scope,
	$cookies,
	$mdToast,
	$mdDialog,
	$state,
	$rootScope,
	$timeout,
	$winNotif,
	Restangular,
	$localStorage,
	lsfactory,
	SAFactory,
	Schools,
	NgMap,
	ngIntroService,
	TourGuide,
	weatherforcaster,
	weatherIcons
) {
	$scope.isReady = true;
	SAFactory.loadEnvironment();
	SAFactory.loadNotifications();
	$scope.env = $localStorage.environment;

	$scope.isReady = true;
}

saHomePush.controller = SaHomePush;

appModule.component('saHomePush', saHomePush);

saHomePush.selector = 'saHomePush';

export { saHomePush }

import template from './index.html';

/** @type {import('@srcAjs/components2/adminRoutes').IComponentOptions2} */
const saSchoolSetupGradingToolbar = {
	template,
	bindings: {
		//someInput: '@string,<object,&function'
	},
	transclude: {
		// #slotName: '?optionalPublicName'
	},
};



function saSchoolSetupGradingToolbarCmpController(
	SAFactory,
	$scope,
	$cookies,
	/** @type {import('@uirouter/angularjs').StateParams} */ $stateParams,
	$mdToast,
	$state,
	$rootScope,
	$localStorage,
	$mdDialog,
	lsfactory,
	Restangular,
	GradingSystem
) {
	$scope.system = {};
	$scope.school = lsfactory.getEnv('school');
	if ($stateParams.id != '') {
		GradingSystem.one($stateParams.id)
			.get()
			.then(
				function (res) {
					$scope.system = res;
				},
				function (err) {
					if (err.status == 404) {
					}
				}
			);
	}

	$scope.$on('grading.dataChanged', function (ev, data) {
		console.log('$scope triggered');
		GradingSystem.one($stateParams.id)
			.get()
			.then(
				function (res) {
					$scope.system = res;
					lsfactory.setEnv('currentGradingSystemSelected', res);
				},
				function (err) {
					if (err.status == 404) {
					}
				}
			);
	});

	$scope.edit = function (system) {
		lsfactory.setEnv('gsEdit', angular.copy(system));
		$mdDialog.show({
			escapeToClose: false,
			clickOutsideToClose: false,

			templateUrl: 'views/pages/roles/sa/school_setup/grading/dialogs/edit_system.html',
			controller: EditGradingSystemController,
		});
	};

	$scope.remove = function (system) {
		var confirm = $mdDialog
			.confirm()
			.title('Would you like to delete the "' + system.title + '" grading system"?')
			.textContent('This operation cannot be undone. All dependants of this grading system will also be removed also.')
			.ariaLabel('Warning')
			.ok('Yes please!')
			.cancel('I dont think so');
		$mdDialog.show(confirm).then(
			function () {
				system.remove().then(function () {
					SAFactory.loadEnvironment();
					$state.go('sa.school_setup_grading');
				});
			},
			function (err) {}
		);
	};
}

saSchoolSetupGradingToolbar.controller = saSchoolSetupGradingToolbarCmpController;

appModule.component('saSchoolSetupGradingToolbar', saSchoolSetupGradingToolbar);

saSchoolSetupGradingToolbar.selector = 'saSchoolSetupGradingToolbar';

export { saSchoolSetupGradingToolbar }

import template from './index.html';

/** @type {import('@srcAjs/components2/adminRoutes').IComponentOptions2} */
const saTeachersDialogsEditTeacher = {
	template,
	bindings: {
		//someInput: '@string,<object,&function'
	},
	transclude: {
		// #slotName: '?optionalPublicName'
	},
};

/** @this {ng.IController} */
function SaTeachersDialogsEditTeacherController(
	Restangular,
	$scope,
	lsfactory,
	/** @type {ng.material.IDialogService} */ $mdDialog,
	$rootScope,
	$mdToast,
	/** @type {ErrorService} */ ErrorService,
	$localStorage,
	Section,
	/** @type {saTeachersService} */ saTeachersService,
	/** @type {ng.IStateParams} */ $stateParams
) {
	const vm = this;

	vm.$onInit = () => {
		$scope.teacher = saTeachersService.teachersStore$.value.teachers?.find((t) => +t.id === +$stateParams.id);
		$scope.user = {
			id: undefined,
			email: undefined,
			phone: undefined,
		};
		$scope.teacher.birthdate = new Date(moment($scope.teacher.birthdate, 'YYYY-MM-DD').format());
		$scope.sals = ['Mr.', 'Mrs.', 'Ms.', 'Dr.', 'Prof.', 'Eld.', 'Rev.'];
		$scope.gends = ['Male', 'Female'];
		$scope.ms = ['Single', 'Married', 'Divorced'];

		$scope.isPhoto = false;
		$scope.image_set = false;
		$scope.isProcessing = false;
		$scope.myCroppedImage = '';
		$scope.cropType = 'circle';

		if ($rootScope.myImage !== undefined) {
			$scope.myImage = $rootScope.myImage;
			$scope.image_set = true;
		} else {
			$scope.image_set = false;
		}
	};

	$scope.changePassword = function () {
		const user = angular.copy($scope.teacher.user);
		$scope.hide();
		$mdDialog.show({
			escapeToClose: false,
			clickOutsideToClose: false,
			locals: {
				user,
			},
			bindToController: true,
			templateUrl: 'views/pages/roles/sa/teachers/dialogs/change_password.html',
			controller: 'TeacherChangePasswordDialogController',
		});
	};

	$scope.changeType = function () {
		if ($scope.teacher.section != undefined) {
			// Do nothing
		} else {
			lsfactory.setEnv('dialogTeacherTypeChange', angular.copy($scope.teacher));
			$scope.hide();
			$mdDialog.show({
				escapeToClose: false,
				clickOutsideToClose: false,
				templateUrl: 'views/pages/roles/sa/teachers/dialogs/change_type.html',
				controller: TeacherChangeTypeDialogController,
			});
		}
	};

	$scope.chooseSkills = function () {
		$scope.hide();
		$mdDialog.show({
			escapeToClose: false,
			clickOutsideToClose: false,
			templateUrl: 'views/pages/roles/sa/teachers/dialogs/choose_skills.html',
			controller: TeacherChooseSkillsDialogController,
		});
	};

	$scope.init = function () {
		console.log('Init the uploader');
		const handleFileSelect = function (evt) {
			const file = evt.currentTarget.files[0];
			const reader = new FileReader();
			reader.onload = function (evt) {
				$scope.$apply(($scope) => {
					$scope.myImage = evt.target.result;
					$rootScope.myImage = evt.target.result;
					$scope.image_set = true;
				});
			};
			reader.readAsDataURL(file);
		};
		angular.element(document.querySelector('#fileInput')).on('change', handleFileSelect);
	};

	$scope.cancelImage = function () {
		$scope.myImage = '';
		$rootScope.myImage = undefined;
		$scope.myCroppedImage = '';
		$scope.image_set = false;
	};

	$scope.save = function () {
		$scope.teacher.isPhoto = $scope.image_set;
		$scope.teacher.myImage = $scope.myCroppedImage;
		$scope.isProcessing = true;

		$scope.user.id = $scope.teacher.user.id; // Custom Code for now
		$scope.user.name = $scope.teacher.user.name; // Custom Code for now
		$scope.user.email = $scope.teacher.user.email; // Custom Code for now
		$scope.user.phone = $scope.teacher.user.phone; // Custom Code for now

		const teacher = {
			isPhoto: $scope.image_set,
			myImage: $scope.myCroppedImage,
			id: $scope.teacher.id,
			salutation: $scope.teacher.salutation,
			firstname: $scope.teacher.firstname,
			lastname: $scope.teacher.lastname,
			marital_status: $scope.teacher.marital_status,
			gender: $scope.teacher.gender,
			birthdate: moment($scope.teacher.birthdate).format('YYYY-MM-DD'),
			country: {
				iso_3166_2: $scope.teacher.country.iso_3166_2,
			},
		};
		const user = {
			id: $scope.teacher.user.id,
			name: $scope.teacher.firstname + ' ' + $scope.teacher.lastname, // Name cache
			phone: $scope.teacher.user.phone,
			email: $scope.teacher.user.email,
		};

		const data_send = {
			teacher,
			user,
		};

		// TODO: Fix this up
		Restangular.all('teacher/update-profile')
			.post(data_send)
			.then(
				(res) => {
					const up_teacher = res.plain();
					saTeachersService.dispatch('updateTeacher', up_teacher);
					$mdDialog.hide().then(() => {
						$scope.cancelImage();
						const template = $mdToast.simple().textContent('Profile Updated').action('OK').highlightAction(true).hideDelay(5500);
						$mdToast.show(template);
					});
				},
				(err) => {
					ErrorService.handleError(err);
				}
			)
			.finally(() => {
				$scope.isProcessing = false;
			});
	};

	$scope.hide = function () {
		$mdDialog.hide();
	};

	$scope.cancel = function () {
		$mdDialog.cancel();
	};

	$scope.updateCountry = (country) => {
		$scope.teacher.country = country;
		$scope.teacher.country_id = country?.id;
		$scope.teacher.code = country?.iso_3166_2;
	};

	$scope.selectCountry = (country) => country?.id === $scope.teacher.country_id;
}

saTeachersDialogsEditTeacher.controller = SaTeachersDialogsEditTeacherController;

appModule.component('saTeachersDialogsEditTeacher', saTeachersDialogsEditTeacher);

saTeachersDialogsEditTeacher.selector = 'saTeachersDialogsEditTeacher';

export { saTeachersDialogsEditTeacher };

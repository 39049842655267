import template from './index.html';

/** @type {import('@srcAjs/components2/adminRoutes').IComponentOptions2} */
const saSchoolSetupYearsIndex = {
	template,
	bindings: {},
};

function SaSchoolSetupYearsIndex(
	SAFactory,
	$scope,
	$state,
	$rootScope,
	/** @type {ng.material.IDialogService} */ $mdDialog,
	lsfactory,
	Restangular,
	ErrorService,
	Years
) {
	SAFactory.loadEnvironment();
	SAFactory.loadNotifications();
	const svm = $scope;

	svm.years = [];
	svm.showOne = false;
	svm.isReady = false;
	$rootScope.loadException = false;
	lsfactory.setEnv('backLog', 'sa.dashboard');

	svm.next = function (year) {
		var y = parseInt(year);
		return y + 1;
	};
	svm.goto = function (year) {
		if (!year) {
			return;
		}
		lsfactory.setEnv('currentYearSelected', year);
		$state.go('sa.school_setup_years_detail.terms', { id: year.id });
	};

	svm.remove = function (year) {
		var confirm = $mdDialog
			.confirm()
			.title('Would you like to delete "' + year.year + '/' + svm.next(year.year) + '" academic year"?')
			.textContent('This operation cannot be undone. All dependants of this academic year will also be removed also.')
			.ariaLabel('Warning')
			.ok('Yes please!')
			.cancel('I dont think so');

		$mdDialog.show(confirm).then(() => {
			Years.one(year.id)
				.get()
				.then((res) => res.remove())
				.then((res) => {
					SAFactory.loadEnvironment();
				})
				.catch(ErrorService.handleError);
		});
	};

	svm.determine_live = (/** @type []*/ terms) => terms.some((term) => term.status === 'active');

	svm.$on('system.sa.environmentChanged', function (ev, data) {
		svm.env = data;
		svm.school = data.school;

		if (!svm.school) {
			return;
		}

		loadYears(svm.school);
	});

	function loadYears(school = svm.school) {
		svm.isReady = false;
		return Restangular.all('year/by-school/' + school.id)
			.getList()
			.then((data) => {
				svm.years = data.plain();
				svm.isReady = true;
				svm.loadRemoved = false;
			})
			.catch(ErrorService.handlerror)
			.finally(() => (svm.isReady = true));
	}

	svm.newYear = () => {
		// lsfactory.setEnv('yearSchool', svm.school);
		// lsfactory.setEnv('addYears', svm.years);
		// lsfactory.setEnv('sub', true);
		$mdDialog.show({
			escapeToClose: false,
			clickOutsideToClose: false,
			templateUrl: 'views/pages/roles/sa/dialogs/add-year-template.html',
			controller: 'AddYearDialogController',
			locals: {
				years: svm.years,
				school: svm.school,
			},
			bindToController: true,
		});
	};
}

saSchoolSetupYearsIndex.controller = SaSchoolSetupYearsIndex;

appModule.component('saSchoolSetupYearsIndex', saSchoolSetupYearsIndex);

saSchoolSetupYearsIndex.selector = 'saSchoolSetupYearsIndex';

export { saSchoolSetupYearsIndex }

import template from './Resetpasstoken.tpl.html';

import './Resetpasstoken.scss';

const resetpasstoken = {
	template,
	bindings: {
		$transition$: '<',
	},
};

function ResetPasswordController(
	$scope,

	$mdToast,
	$state,
	$rootScope,
	/** @type {ErrorService} */ ErrorService,
	/** @type {auth} */ auth
) {
	const vm = this;
	vm.start = true;
	vm.done = false;
	vm.isLoading = true;
	vm.token = null;
	vm.$transition$ = null

	vm.box = {
		password: '',
		password_rec: '',
	};
	vm.user = null;
	$scope.$on('cfpLoadingBar:started', () => {
		vm.isLoading = true;
	});
	/*
	 * vm.$on("cfpLoadingBar:started", function(){
	 *     vm.isLoading = true;
	 * });
	 */

	/*
	 * $scope.$on('cfpLoadingBar:completed', function () {
	 * 	vm.isLoading = false;
	 * });
	 */

	/*
	 * vm.$on("cfpLoadingBar:completed", function(){
	 *     vm.isLoading = false;
	 * });
	 */

	vm.changePassword = function (password, event) {
		event.preventDefault();

		vm.user.password = password;
		vm.user.token = vm.$transition$.params().token;
		vm.isLoading = true;
		auth
			.sendForgottenPassword(vm.user)
			.then(({ status }) => {
				if (status) {
					vm.done = true;
					vm.start = false;
				} else {
					const template = $mdToast.simple().textContent('Error. Cannot change password.').hideDelay(1500);
					$mdToast.show(template);
				}
			}, ErrorService.handleError)
			.finally(() => {
				vm.isLoading = false;
			});
	};

	vm.$onInit = () => {
		if (vm.$transition$.params().token === '') {
			$state.go('access.signin').then(() => {
				const template = $mdToast.simple().textContent('No token provided.').hideDelay(1500);
				$mdToast.show(template);
			});
		}
		$rootScope.allowSub = true;

		auth
			.checkUserVerificationToken({ token: vm.$transition$.params().token })
			.then(({ user }) => {
				if (!user) {
					const template = $mdToast.simple().textContent('Your token is used or invalid.').hideDelay(1500);
					$mdToast.show(template).finally(() => {
						$state.go('access.signin');
					});
				}

				$mdToast.showSimple('Password reset token validated');
				vm.user = user;
				vm.token = vm.$transition$.params().token;

				// var data = res.data;

				/*
				 * $rootScope.allowSub = false;
				 * if (data.status) {
				 * 	vm.user = data.user;
				 * } else {
				 * 	$state.go('access.signin').then(function () {
				 * 		var template = $mdToast.simple().textContent('Your token is used or invalid.').hideDelay(1500);
				 * 		$mdToast.show(template);
				 * 	});
				 * }
				 */
			}, ErrorService.handleError)
			.finally(() => {
				vm.isLoading = false;
			});
	};
}

resetpasstoken.controller = ['$scope', '$mdToast', '$state', '$rootScope', 'ErrorService', 'auth', ResetPasswordController];

appModule.component('resetpasstoken', resetpasstoken);

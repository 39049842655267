import template from './index.html';

/** @type {import('@srcAjs/components2/adminRoutes').IComponentOptions2} */
const saHelpIndex = {
	template,
	bindings: {
		//someInput: '@string,<object,&function'
	},
	transclude: {
		// #slotName: '?optionalPublicName'
	},
};



function saHelpIndexCmpController(
	SAFactory,
	$scope,
	$cookies,
	$mdToast,
	$mdDialog,
	$state,
	$rootScope,
	$localStorage,
	$http,
	$log,
	CONST_GUARDIAN_CSV_URL,
	CONST_MANUAL_URL
) {
	SAFactory.loadEnvironment();
	SAFactory.loadNotifications();

	$scope.options = {
		modalTitle: 'Feedback',
		takeScreenshotButtonText: 'Take screenshot',
		submitButtonText: 'Submit',
		sendFeedbackButtonText: 'Send Feedback',
		cancelScreenshotOptionsButtonText: 'Cancel',
		takeScreenshotOptionsButtonText: 'Take Screenshot',
		takeScreenshotButtonPressed: takeScreenshotButtonPressed,
		submitButtonPressed: submitButtonPressed,
		sendFeedbackButtonPressed: sendFeedbackButtonPressed,
		cancelScreenshotOptionsButtonPressed: cancelScreenshotOptionsButtonPressed,
		takeScreenshotOptionsButtonPressed: takeScreenshotOptionsButtonPressed,
		screenshotTaken: screenshotTaken,
		highlightDrawn: highlightDrawn,
		modalDismissed: modalDismissed,
	};

	function takeScreenshotButtonPressed() {}
	function submitButtonPressed(form) {}
	function sendFeedbackButtonPressed() {}
	function cancelScreenshotOptionsButtonPressed() {}
	function takeScreenshotOptionsButtonPressed() {}
	function screenshotTaken(image, canvas) {}
	function highlightDrawn(element) {}
	function modalDismissed() {}

	$scope.slides = [
		{ src: 'images/weather.jpg', caption: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Enim, maxime.' },
		{ src: 'images/weather.jpg', caption: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Enim, maxime.' },
		{ src: 'images/weather.jpg', caption: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Enim, maxime.' },
		{ src: 'images/weather.jpg', caption: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Enim, maxime.' },
		{ src: 'images/weather.jpg', caption: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Enim, maxime.' },
		{ src: 'images/weather.jpg', caption: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Enim, maxime.' },
		{ src: 'images/weather.jpg', caption: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Enim, maxime.' },
	];

	$scope.questions = [
		{ question: 'What is Schrep', answer: '' },
		{ question: 'What is Schrep', answer: '' },
		{ question: 'What is Schrep', answer: '' },
		{ question: 'What is Schrep', answer: '' },
		{ question: 'What is Schrep', answer: '' },
		{ question: 'What is Schrep', answer: '' },
		{ question: 'What is Schrep', answer: '' },
	];

	$scope.downURL = CONST_MANUAL_URL;

	$scope.options = {
		sourceProp: 'src',
		visible: 3,
		perspective: 25,
		startSlide: 0,
		border: 2,
		dir: 'ltr',
		width: 400,
		height: 250,
		space: 300,
		autoRotationSpeed: 3500,
		controls: false,
	};

	$scope.downloadManual = function () {
		$.fileDownload(CONST_MANUAL_URL).then(null, function (err) {
			var template = $mdToast.simple().textContent('Unable to start download').action('OK').highlightAction(true).hideDelay(5500);
			$mdToast.show(template);
		});
	};

	$scope.showFAQ = function () {
		$mdDialog.show({
			escapeToClose: true,
			clickOutsideToClose: true,
			templateUrl: 'views/pages/roles/sa/help/dialogs/browse_faq.html',
			controller: BrowseFAQController,
		});
	};

	$scope.reportIssue = function () {
		$mdDialog.show({
			escapeToClose: true,
			clickOutsideToClose: true,
			templateUrl: 'views/pages/roles/sa/help/dialogs/report_issue.html',
			controller: ReportIssueController,
		});
	};

	$scope.lastSlide = function (index) {
		$log.log('Last Slide Selected callback triggered. \n == Slide index is: ' + index + ' ==');
	};

	$scope.beforeChange = function (index) {
		$log.log('Before Slide Change callback triggered. \n == Slide index is: ' + index + ' ==');
	};

	$scope.selectedClick = function (index) {
		//TODO: Show dialog with Youtube
		$log.log('Selected Slide Clicked callback triggered. \n == Slide index is: ' + index + ' ==');
	};

	$scope.slideChanged = function (index) {
		$log.log('Slide Changed callback triggered. \n == Slide index is: ' + index + ' ==');
	};

	$scope.addSlide = function (slide, array) {
		array.push(slide);
		vm.slide2 = {};
	};

	$scope.removeSlide = function (index, array) {
		array.splice(array.indexOf(array[index]), 1);
	};
}

saHelpIndex.controller = saHelpIndexCmpController;

appModule.component('saHelpIndex', saHelpIndex);

saHelpIndex.selector = 'saHelpIndex';

export { saHelpIndex }

import template from './index.html';

/** @type {import('@srcAjs/components2/adminRoutes').IComponentOptions2} */
const saGuardiansToolbar = {
	template,
	bindings: {
		//someInput: '@string,<object,&function'
		iguardian: '<',
	},
	transclude: {
		// #slotName: '?optionalPublicName'
	},
};

function saGuardiansToolbarCmpController(SAFactory, $scope, $state, $localStorage, $mdDialog, lsfactory, Restangular, Guardian) {
	this.$onChanges = (changes) => {
		console.log('saGuardianToolbar.changes', changes);
	};

	this.$onInit = () => {
		const IGuardian = this.iguardian;
		$scope.guardian = IGuardian;

		$scope.$on('editGuardian.newEntity', function (event, new_guardian) {
			if (new_guardian !== undefined) {
				$scope.guardian = new_guardian;
			}
		});

		$scope.remove = function (guardian) {
			Guardian.one(guardian.id)
				.get()
				.then(function (res) {
					var guard = res;
					var confirm = $mdDialog
						.confirm()
						.title('Would you like to delete "' + guard.firstname + ' ' + guard.lastname + '"?')
						.textContent('This operation cannot be undone. All dependants of this guardian will also be removed also.')
						.ariaLabel('Warning')
						.ok('Yes please!')
						.cancel('I dont think so');
					$mdDialog.show(confirm).then(
						function () {
							guard.remove().then(function () {
								$scope.$broadcast('guardians.changeFired', $localStorage.environment);
								$state.go('sa.guardians_browse');
							});
						},
						function () {
							// Error Case
						}
					);
				});
		};
	};
}

saGuardiansToolbar.controller = saGuardiansToolbarCmpController;

appModule.component('saGuardiansToolbar', saGuardiansToolbar);

saGuardiansToolbar.selector = 'saGuardiansToolbar';

export { saGuardiansToolbar };

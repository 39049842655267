import template from './index.html';

/** @type {import('@srcAjs/components2/adminRoutes').IComponentOptions2} */
const saGuardiansDialogsEditGuardian = {
	template,
	bindings: {
		//someInput: '@string,<object,&function'
		guardian: '<',
		onOpen: '<',
		onClosse: '<',
	},
	transclude: true,
	selector: 'saGuardiansDialogsEditGuardian',
};

/** @this {ng.IController} */
function saGuardiansDialogsEditGuardianController(
	Restangular,
	$scope,
	lsfactory,
	$mdDialog,
	$rootScope,
	$mdToast,
	/** @type {ErrorService} */ ErrorService,
	/** @type {EffectService} */ EffectService
) {
	$scope.sals = ['Mr.', 'Mrs.', 'Ms.', 'Dr.', 'Prof.'];
	$scope.user = {
		id: undefined,
		email: undefined,
		phone: undefined,
	};
	$scope.gends = ['Male', 'Female'];
	$scope.isPhoto = false;
	$scope.image_set = false;
	$scope.isProcessing = false;
	$scope.myCroppedImage = '';
	$scope.cropType = 'circle';

	this.$onInit = () => {};

	EffectService.setup(
		this,
		({ onInit, onChanges }) => {
			if ((onInit || onChanges) && this.guardian) {
				$scope.guardian = angular.copy(this.guardian);
				$scope.guardian.birthdate = new Date($scope.guardian.birthdate);
				$scope.selectCountry = (country) => country.id === $scope.guardian.country_id;
			}
		},
		() => [this.guardian]
	);

	$scope.updateCountry = (country) => {
		$scope.guardian.country_id = country.id;
		$scope.guardian.country = country;
	};

	if ($rootScope.myImage !== undefined) {
		$scope.myImage = $rootScope.myImage;
		$scope.image_set = true;
	} else {
		$scope.image_set = false;
	}

	$scope.changePassword = function () {
		$scope.hide();

		$mdDialog.show({
			escapeToClose: false,
			clickOutsideToClose: false,
			bindToController: true,
			locals: { guardian: $scope.guardian },
			templateUrl: 'views/pages/roles/sa/guardians/dialogs/change_password.html',
			controller: GuardianChangePasswordDialogController,
		});
	};

	$scope.init = function () {
		console.log('Init the uploader');
		var handleFileSelect = function (evt) {
			var file = evt.currentTarget.files[0];
			var reader = new FileReader();
			reader.onload = function (evt) {
				$scope.$apply(function ($scope) {
					$scope.myImage = evt.target.result;
					$rootScope.myImage = evt.target.result;
					$scope.image_set = true;
				});
			};
			reader.readAsDataURL(file);
		};
		angular.element(document.querySelector('#fileInput')).on('change', handleFileSelect);
	};

	$scope.cancelImage = function () {
		$scope.myImage = '';
		$rootScope.myImage = undefined;
		$scope.myCroppedImage = '';
		$scope.image_set = false;
	};

	$scope.save = function () {
		$scope.guardian.isPhoto = $scope.image_set;
		$scope.guardian.myImage = $scope.myCroppedImage;
		$scope.isProcessing = true;

		$scope.user.id = $scope.guardian.user.id; // Custom Code for now
		$scope.user.name = $scope.guardian.user.name; // Custom Code for now
		$scope.user.email = $scope.guardian.user.email; // Custom Code for now
		$scope.user.phone = $scope.guardian.user.phone; // Custom Code for now

		var data = {
			user: $scope.user,
			guardian: $scope.guardian,
		};

		Restangular.all('guardian/update-profile')
			.post(data)
			.then(function (res) {
				var up_guardian = res.plain();
				console.dir(up_guardian);
				lsfactory.setEnv('currentGuardianSelected', up_guardian);
				$scope.cancelImage();
				var template = $mdToast.simple().textContent('Profile Updated').action('OK').highlightAction(true).hideDelay(5500);
				$mdToast.show(template);
				$rootScope.$broadcast('editGuardian.newEntity', up_guardian);
				$scope.hide;
			}, ErrorService.handleError)
			.finally(() => ($scope.isProcessing = false));
	};

	$scope.hide = (data) => {
		this.onclose(data);
	};

	$scope.cancel = function (data) {
		this.onClose(data);
	};
}

saGuardiansDialogsEditGuardian.controller = saGuardiansDialogsEditGuardianController;

appModule.component('saGuardiansDialogsEditGuardian', saGuardiansDialogsEditGuardian);

export { saGuardiansDialogsEditGuardian };

import template from './index.html';

/** @type {import('@srcAjs/components2/adminRoutes').IComponentOptions2} */
const saSchoolSetupGradingSubEntry = {
	template,
	bindings: {
		//someInput: '@string,<object,&function'
	},
	transclude: {
		// #slotName: '?optionalPublicName'
	},
};



function saSchoolSetupGradingSubEntryCmpController(
	SAFactory,
	$timeout,
	$scope,
	$cookies,
	/** @type {import('@uirouter/angularjs').StateParams} */ $stateParams,
	$state,
	$rootScope,
	$localStorage,
	$mdDialog,
	$mdToast,
	lsfactory,
	Restangular,
	GradingSystem,
	Grading
) {
	SAFactory.loadEnvironment();
	SAFactory.loadNotifications();
	$scope.sel_gs = lsfactory.getEnv('currentGradingSystemSelected');
	$state.current.data.title = $scope.sel_gs.title;

	$scope.entries = [];
	$scope.dots = ['...'];
	$scope.isLoading = true;
	$scope.e_count = undefined;

	$scope.$on('grading.dataChanged', function (ev, data) {
		GradingSystem.one($stateParams.id)
			.get()
			.then(
				function (res) {
					$scope.sel_gs = res;
					$state.current.data.title = $scope.sel_gs.title;
					lsfactory.setEnv('currentGradingSystemSelected', res);
				},
				function (err) {
					if (err.status == 404) {
					}
				}
			);
	});

	$scope.loadItems = function () {
		$scope.isLoading = true;
		if ($scope.sel_gs != null) {
			Restangular.one('grading-system', $scope.sel_gs.id)
				.all('gradings')
				.getList()
				.then((res) => res.plain())
				.then(function (data) {
					$scope.isLoading = false;
					$scope.e_count = data.length;
					$scope.entries = data.map(reStructureGrading);
				});
		}
	};

	/**
	 * @typedef {{start:number, end: number, grade: char, comment: string}} Grading
	 * @param {Grading} grading
	 * @returns
	 */
	const reStructureGrading = (grading) => {
		//some grades have the end as the lower value and the start the higher value
		if (grading.start > grading.end) [grading.start, grading.end] = [grading.end, grading.start];

		return grading;
	};

	$scope.loadItems();

	$scope.$on('grading.entries.dataChanged', function (ev, data) {
		$scope.loadItems();
	});

	$scope.gotoSummary = function () {
		$state.go('sa.school_setup_grading_detail.summary', { id: $scope.sel_gs.id });
	};

	$scope.under_construction = function () {
		$mdDialog.show(
			$mdDialog
				.alert()
				.clickOutsideToClose(false)
				.title('Requested Feature')
				.textContent('This is a requested feature that would be implemented in due time')
				.ariaLabel('Requested feature')
				.ok('Got it!')
		);
	};

	$scope.newEntry = function () {
		lsfactory.setEnv('entrySystem', $scope.sel_gs);
		$mdDialog.show({
			escapeToClose: false,
			clickOutsideToClose: false,
			templateUrl: 'views/pages/roles/sa/school_setup/grading/dialogs/add_entry.html',
			controller: AddGradingEntryController,
		});
	};

	$scope.remove = function (entry) {
		var confirm = $mdDialog
			.confirm()
			.title('Would you like to delete the "' + entry.grade + '" entry now? ')
			.textContent('This operation cannot be undone. Deleting this would disrupt the grading proccess')
			.ariaLabel('Warning')
			.ok('Yes please!')
			.cancel('I dont think so');
		$mdDialog.show(confirm).then(
			function () {
				Grading.one(entry.id)
					.get()
					.then(function (res) {
						var en = res;
						en.remove().then(function () {
							$scope.loadItems();
						});
					});
			},
			function () {}
		);
	};

	$scope.edit = function (entry) {
		lsfactory.setEnv('entryEdit', angular.copy(entry));
		$mdDialog.show({
			escapeToClose: false,
			clickOutsideToClose: false,
			templateUrl: 'views/pages/roles/sa/school_setup/grading/dialogs/edit_entry.html',
			controller: EditGradingEntryController,
		});
	};
}

saSchoolSetupGradingSubEntry.controller = saSchoolSetupGradingSubEntryCmpController;

appModule.component('saSchoolSetupGradingSubEntry', saSchoolSetupGradingSubEntry);

saSchoolSetupGradingSubEntry.selector = 'saSchoolSetupGradingSubEntry';

export { saSchoolSetupGradingSubEntry }

import template from './BasicOrderSelectCmp.tpl.html';

/** @type {import('@srcAjs/components2/adminRoutes').IComponentOptions2} */
const basicOrderSelectCmp = {
	template,
	bindings: {
		// someInput: '@string,<object,&function'
		/** @example onChange=doChange(data) */
		onChange: '&',
		value: '@',
		schemes: '<',
	},
	transclude: {
		// #slotName: '?optionalPublicName'
		labelSlot: '?',
	},
	selector: 'basicOrderSelectCmp',
};

/** @typedef {{ '&': Function, '<': Record<string,any>, '@': string, }} BindScheme */
/** @typedef {'&'|'<'|'@'} BindNote */

/** */

/**
 *
 * @xtemplate {Record<string,keyof BindScheme>} T
 * @template {Record<string,keyof BindNote>} T
 *
 * @xtemplate {Record<string,Record<string,'&'|'<'|'@'|string>>} T
 * @xtypedef { { [P in keyof T]: BindScheme[keyof T[P] ] } } BindingsToType
 * @typedef { { [P in keyof T]:  BindScheme[T[P]] } } BindingsToType
 */

/**
 * 	@typedef {BindingsToType<+readonly basicOrderSelectCmp['bindings']> } CmpBinding
 *  @this {ng.IController & CmpBinding}
 */
function basicOrderSelectCmpController(/**@type {EffectService} */ EffectService) {
	EffectService.setup(this, ({ onInit }) => {
		if (onInit) {
			this._schemes = [...this.schemes].flatMap((field) => [
				{ field: `dsc: ${field}`, sign: `-${field}` },
				{ field: `asc: ${field}`, sign: `+${field}` },
			]);
		}
	});
}

/**
 * @template {Record<string,string>} T
 * @typedef {{ bindings: { -readonly[P in keyof T]: T[P]  } } } PlainBindings
 */

/** @typedef {PlainBindings<basicOrderSelectCmp['bindings']>} PB */
/** @typedef {Omit<typeof basicOrderSelectCmp,'bindings'> & PB} cmpScheme */

basicOrderSelectCmp.controller = basicOrderSelectCmpController;

appModule.component('basicOrderSelectCmp', basicOrderSelectCmp);

export { basicOrderSelectCmp };

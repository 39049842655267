import * as uuid from 'uuid';
import { BehaviorSubject, Observable, distinctUntilChanged, finalize } from 'rxjs';

/** @template T @typedef {import('rxjs').ObservableInput<T>} ObservableInput */
/** @template T @typedef {import('rxjs').ObservedValueOf} ObservedValueOf */

/** @typedef {T|Promise<T>|ObservableInput<T>} AtomSource @template T*/
/** @typedef {ReturnType<typeof atom>} AtomType */

/** @typedef {(args:AtomGetter)=>any} AtomCb */
/**
 * @typedef {T extends Promise ? Awaited<T> : T extends ObservableInput<T> ? ObservedValueOf<T>:never} PlainSource @template {AtomSource} T */

/** @template T @typedef {T extends AtomType ? ObservableInput<T['stream$']>:unknown} AtomValue */

/**
 * @template {AtomSource} T
 * @typedef {{
 * test:(source:T)=>boolean,
 * handle:(source:T,setCb:(source:T,val:PlainSource<T>)=>void,subject:ObservableInput)=>void,
 * type:string
 * }
 * } AtomosHandler
 */

/** @type {Array<AtomosHandler<Promise|ObservableInput|Function|any>>} */
const sourceHandlers = [
	{
		test: (source) => source?.then && typeof source.then === 'function',
		handle: (source, setCb) => source.then((val) => setCb(val)),
		type: 'promise',
	},
	{
		test: (source) => source?.subscribe && typeof source.subscribe === 'function',
		handle: (source, setCb, /** @type {ng.rxjs.Observable} */ subject) =>
			source.pipe(finalize(() => typeof subject?.complete === 'function' && subject.complete())).subscribe({ next: (val) => setCb(val) }),
		type: 'observable',
	},
	{
		test: (source) => typeof source === 'function',
		handle: (source, setCb) => setCb(source),
		type: 'function',
	},
	{
		test: (source = {}) => ['subscribe', 'then'].every((k) => !Object.keys(source).includes(k)) && typeof source !== 'function',
		handle: (source, setCb) => setCb(source),
		type: 'plainValue',
	},
];

const paddRight = (val) =>
	Array.from(8 - ('' + val).length)
		.fill(' ')
		.join('') + val;

const logAtom = ({ id, get, handler }) => ({ id, value: get(), handler });

/** @typedef {typeof atom} I */

/**
 * @template J
 * @template { J extends AtomCb ? J: J extends AtomSource ? J : unknown} T
 * @template {ReturnType<typeof AtomGetter<T>>} TPureSource
 */
export function atom(/**  @type {T} */ valueAtomObsPromise, id = uuid.v1()) {
	// test and set next value
	const handler = sourceHandlers.find((handler) => handler.test(valueAtomObsPromise));

	let value = handler.type === 'plainValue' ? valueAtomObsPromise : null;

	let stream$ = new BehaviorSubject(/** @type {T extends (get:typeof AtomGetter)=>infer W ? W : T extends ObservableInput<infer O> ? O: T} */ (value));

	const getAtomSubscriptionMaps = new Map();

	/**  @template {AtomType} Q*/
	function get(/** @type {Q}*/ _atom) {
		let cv = _atom.get();
		if (!getAtomSubscriptionMaps.has(_atom.id)) {
			const unsub = _atom.stream$.pipe(distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))).subscribe({
				next: (val) => {
					if (cv === val) return;
					cv = val;
					value = val;
					computeValue();
					stream$.next(value);
				},
				complete: () => stream$.complete() && getAtomSubscriptionMaps.forEach((unsub) => unsub()),
			});
			getAtomSubscriptionMaps.set(_atom.id, unsub);
		}
		return cv;
	}

	function computeValue() {
		return (value = handler.type === 'function' ? valueAtomObsPromise(get) : value);
	}

	const setCb = (val) => {
		value = val;
		// // console.log('atom.setCb.fired', { val, value });
		// stream$.next(val);
		value = computeValue();
		stream$.next(value);

		return value;
	};

	handler.handle(valueAtomObsPromise, setCb);

	computeValue();

	return {
		get: () => /** @type {TPureSource} */ (/** @type {any} */ (value)),
		/** @template {Partial<TPureSource>|((c:TPureSource)=>TPureSource)} F */
		set: (/** @type {F}*/ valOrFn) => setCb(typeof valOrFn === 'function' ? valOrFn(/** @type {TPureSource} */ (/** @type {any} */ (value))) : valOrFn),
		stream$,
		handler,
		id,
	};
}

/**@typedef {typeof AtomGetter} AtomGetter<any>*/

/** @template {any|atom|Observable} T */
export const AtomGetter = (/** @type {T} */ t) => /** @type {T extends Observable<infer U>? U: T extends {stream$: Observable<infer Q>} ? Q : T} */ (t);

import template from './index.html';

/** @type {import('@srcAjs/components2/adminRoutes').IComponentOptions2} */
const trClassSubSubjects = {
	template,
	bindings: {
		//someInput: '@string,<object,&function'
	},
	transclude: {
		// #slotName: '?optionalPublicName'
	},
};

function trClassSubSubjectsCmpController(
	$state,
	$rootScope,
	$mdToast,
	$timeout,
	/** @type {ng.ui.StateParams} */ $stateParams,
	/** @type {ng.IScope} */ $scope,
	/** @type {lsfactory}*/ lsfactory,
	/** @type {ErrorService} */ ErrorService,
	/** @type {TRFactory} */ TRFactory,
	/** @type {ng.material.IDialogService} */ $mdDialog,
	/** @type {atomosService} */ atomosService
) {
	TRFactory.loadEnvironment();
	TRFactory.loadNotifications();

	/**
	 * @type { typeof $scope & {
	 * 	subjects:SubjectMount[]
	 *  subjects_loading:boolean
	 *  subjects_meta:Record<string,SyncMeta>
	 *  isReady:boolean
	 *  isCTermDisabled:boolean
	 * 	teacher: Teacher
	 *  [key:string]: any
	 *  }}
	 */
	const svm = /** @type {any} */ ($scope);

	svm.teacher = lsfactory.getEnv('teacher');
	lsfactory.setEnv('backLog', 'tr.home.dashboard');
	svm.subjects = [];
	svm.subjects_loading = false;
	svm.subjects_meta = null;

	svm.isReady = false;
	$rootScope.isCTermDisabled = false;

	svm.open_subject = function (/** @type {SubjectMount}*/ subject) {
		lsfactory.setEnv('backLog', 'tr.class.subjects');
		lsfactory.setEnv('currentSubjectMount', subject);
		lsfactory.setEnv('saveSubjectForTerm', subject.subject);
		$state.go('tr.subject.assessment');
	};

	svm.checkNones = function (subjects) {
		const c = subjects.filter((subj) => subj.teacher_id === svm.teacher.id).length;
		return !!c;
	};

	svm.checkOClass = function (subjects) {
		const c = subjects.filter((subj) => subj.section_id === svm.section.id).length;
		return !!c;
	};

	svm.filterNonTeacherFn = function (subject) {
		if (subject.teacher_id !== svm.teacher.id) {
			return true; // this will be listed in the results
		}
		return false; // otherwise it won't be within the results
	};

	svm.filterOtherClassesFn = function (subject) {
		if (subject.section_id !== svm.section.id) {
			return true; // this will be listed in the results
		}
		return false; // otherwise it won't be within the results
	};

	svm.sync = function (subject_mount) {
		const confirm = $mdDialog.confirm({
			title: `Sync ${subject_mount.title}`,
			textContent: 'Local changes will be uploaded and saved online',
			ok: 'Go ahead',
			cancel: 'I dont think so',
		});

		$mdDialog.show(confirm).then(async () => {
			svm.isReady = false;
			try {
				const { count_ags, count_saes } = await TRFactory.syncSubjectMount(subject_mount, subject_mount.term_id);
				const template = $mdToast.simple({
					textContent: `Synced Assessment Groups:${count_ags}, Assessments: ${count_saes}`,
					hideDelay: 10000,
				});
				$mdToast.show(template);
			} catch (error) {
				console.err('Syncing subject failed', subject_mount.subject.title, subject_mount);
			} finally {
				loadSubjects(subject_mount.term_id);
				svm.isReady = true;
				$timeout(() => {}, 10);
			}
		});
	};

	svm.clear = function (subject_mount, ev) {
		const _alert = $mdDialog.alert({
			textContent: 'Feature in development',
			title: 'Clear changes',
			ariaLabel: 'Feature in development',
			ok: 'Got it',
			targetEvent: ev,
		});

		$mdDialog.show(_alert);
	};

	async function loadSubjects(term_id) {
		console.log('TR_ClassDetailSubjectsController loadSubjects triggered');
		if (svm.subjects_loading) return;

		svm.subjects_loading = true;
		svm.isReady = false;

		if (!term_id) return;

		try {
			const cached_term = await TRFactory.fetchTermDetails(term_id);

			// const has_section_this_term = Object.values(TRFactory.trServiceAtom.get().termsCache[term_id].sections).find(
			// 	({ section }) => section.teacher_id == svm.teacher.id && (TRFactory.trServiceAtom.get().selectedTerm.term_type.version == '1.2' ? section.term_id == term_id : true)
			// );

			const has_section_this_term = Object.values(TRFactory.getCachedSections()).find((s) => s.section.id == $stateParams.section_id);

			svm.section = svm.section ?? has_section_this_term.section;

			TRFactory.trServiceAtom.set((prev) => ({ ...prev, selectedSectionId: svm.section.id }));

			const [cached_subject_mounts, cached_subject_mounts_error] = await (has_section_this_term
				? TRFactory.fetchNonClassTeacherSubjectMounts(cached_term, svm.teacher, term_id)
				: TRFactory.fetchClassTeacherSubjectMounts(cached_term, svm.teacher, term_id)
			).then(
				(res) => [res, null],
				(error) => [null, error]
			);

			if (cached_subject_mounts_error || !cached_subject_mounts) throw new Error(cached_subject_mounts_error);

			const cached_classes = await TRFactory.fetchSchoolClasses(svm.teacher.school_id);

			// $state.current.data.title = cached_classes[svm.section.s_class_id].class_level.title + ' (' + svm.section.title + ')';
			svm.cached_subject_mounts = cached_subject_mounts;
			svm.groups = Object.values(cached_classes);
			svm.subjects_meta = TRFactory.buildSubjectsMeta(cached_subject_mounts);
			svm.subjects = Object.values(cached_subject_mounts).map((nth_val) => nth_val.subject_mount);
		} catch (error) {
			ErrorService.handleError(error);
		} finally {
			svm.isReady = true;
			svm.subjects_loading = false;
			$timeout(() => {}, 10);
		}
	}

	svm.handleClick = (/** @type SubjectMount */ subject_mount) =>
		$state.go('tr.subject.assessment', { subject_mount, subject_mount_id: subject_mount.id }).catch(ErrorService.handleError);

	// svm.$watch(
	// 	() => TRFactory.trServiceAtom.get().selectedTerm,
	// 	(term) => {
	// 		console.log('system.termChanged triggered', term);
	// 		loadSubjects(term.id);
	// 	}
	// );

	atomosService(TRFactory.trServiceAtom.stream$, this).stream$.subscribe((atom) => {
		const data = atom;
		if (!data.selectedTerm) return;
		svm.term = data.selectedTerm;

		loadSubjects(data.selectedTermId);
	});
}

trClassSubSubjects.controller = trClassSubSubjectsCmpController;

appModule.component('trClassSubSubjects', trClassSubSubjects);

trClassSubSubjects.selector = 'trClassSubSubjects';

export { trClassSubSubjects };

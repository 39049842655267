/** @typedef {ng.IComponentOptions & {selector: Readonly<string>,_imports?:Array<any>}} IComponentOptions2*/
import { saAlumniIndex } from '@srcAjs/components2/SaAlumniIndex/index.component';
import { saEventsIndex } from '@srcAjs/components2/SaEventsIndex/index.component';
import { saGuardiansCreate } from '@srcAjs/components2/SaGuardiansCreate/index.component';
import { saGuardiansDetail } from '@srcAjs/components2/SaGuardiansDetail/index.component';
import { saGuardiansImport } from '@srcAjs/components2/SaGuardiansImport/index.component';
import { saGuardiansIndex } from '@srcAjs/components2/SaGuardiansIndex/index.component';
import { saGuardiansSubProfile } from '@srcAjs/components2/SaGuardiansSubProfile/index.component';
import { saGuardiansSubReports } from '@srcAjs/components2/SaGuardiansSubReports/index.component';
import { saHelpIndex } from '@srcAjs/components2/SaHelpIndex/index.component';
import { saHomeAnalysisCmp } from '@srcAjs/components2/SaHomeAnalysis/index.component';
import { saHomeDashboardCmp } from '@srcAjs/components2/SaHomeDashboard/index.component';
import { saHomePush } from '@srcAjs/components2/SaHomePush/index.component';
import { saMobileIndex } from '@srcAjs/components2/SaMobileIndex/index.component';
import { saNoticesIndex } from '@srcAjs/components2/SaNoticesIndex/index.component';
import { saOperationsApproveScoresIndex } from '@srcAjs/components2/SaOperationsApproveScoresIndex/index.component';
import { sSaOperationsCheckAttendanceIndex } from '@srcAjs/components2/SaOperationsCheckAttendanceIndex/index.component';
import { saProfile } from '@srcAjs/components2/SaProfile/index.component';
import { saSchoolSetupAdminsIndex } from '@srcAjs/components2/SaSchoolSetupAdminsIndex/index.component';
import { saSchoolSetupClassGroupsDetail } from '@srcAjs/components2/SaSchoolSetupClassGroupsDetail/index.component';
import { saSchoolSetupClassGroupsIndex } from '@srcAjs/components2/SaSchoolSetupClassGroupsIndex/index.component';
import { saSchoolSetupClassGroupsSubSummary } from '@srcAjs/components2/SaSchoolSetupClassGroupsSubSummary/index.component';
import { saSchoolSetupClassesDetail } from '@srcAjs/components2/SaSchoolSetupClassesDetail/index.component';
import { saSchoolSetupClassesDetailSection } from '@srcAjs/components2/SaSchoolSetupClassesDetailSection/index.component';
import { saSchoolSetupClassesIndex } from '@srcAjs/components2/SaSchoolSetupClassesIndex/index.component';
import { saSchoolSetupClassesSubSections } from '@srcAjs/components2/SaSchoolSetupClassesSubSections/index.component';
import { saSchoolSetupClassesSubSectionsEnrolledStudents } from '@srcAjs/components2/SaSchoolSetupClassesSubSectionsEnrolledStudents/index.component';
import { saSchoolSetupClassesSubSummary } from '@srcAjs/components2/SaSchoolSetupClassesSubSummary/index.component';
import { saSchoolSetupGradingDetail } from '@srcAjs/components2/SaSchoolSetupGradingDetail/index.component';
import { saSchoolSetupGradingIndex } from '@srcAjs/components2/SaSchoolSetupGradingIndex/index.component';
import { saSchoolSetupGradingSubEntry } from '@srcAjs/components2/SaSchoolSetupGradingSubEntry/index.component';
import { saSchoolSetupGradingSubSummary } from '@srcAjs/components2/SaSchoolSetupGradingSubSummary/index.component';
import { saSchoolSetupInfoIndex } from '@srcAjs/components2/SaSchoolSetupInfoIndex/index.component';
import { saSchoolSetupPhotosIndex } from '@srcAjs/components2/SaSchoolSetupPhotosIndex/index.component';
import { saSchoolSetupSubjectsIndex } from '@srcAjs/components2/SaSchoolSetupSubjectsIndex/index.component';
import { saSchoolSetupYearsDetail } from '@srcAjs/components2/SaSchoolSetupYearsDetail/index.component';
import { saSchoolSetupYearsDetailTerm } from '@srcAjs/components2/SaSchoolSetupYearsDetailTerm/index.component';
import { saSchoolSetupYearsIndex } from '@srcAjs/components2/SaSchoolSetupYearsIndex/index.component';
import { saSchoolSetupYearSubSummary } from '@srcAjs/components2/SaSchoolSetupYearsSubSummary/index.component';
import { saSchoolSetupYearsSubTerms } from '@srcAjs/components2/SaSchoolSetupYearsSubTerms/index.component';
import { saSchoolSetupYearsSubTermsEnrolledStudents } from '@srcAjs/components2/SaSchoolSetupYearsSubTermsEnrolledStudents/index.component';
import { saSchoolSetupYearsSubTermsMountedSubjects } from '@srcAjs/components2/SaSchoolSetupYearsSubTermsMountedSubjects/index.component';
import { saSchoolSetupYearsSubTermsNewMountedSubjects } from '@srcAjs/components2/SaSchoolSetupYearsSubTermsNewMountedSubjects/index.component';
import { saSchoolSetupYearsSubTermsSummary } from '@srcAjs/components2/SaSchoolSetupYearsSubTermsSummary/index.component';
import { saStudentsCreate } from '@srcAjs/components2/SaStudentsCreate/index.component';
import { saStudentsDetail } from '@srcAjs/components2/SaStudentsDetail/index.component';
import { saStudentsImport } from '@srcAjs/components2/SaStudentsImport/index.component';
import { saStudentsIndex } from '@srcAjs/components2/SaStudentsIndex/index.component';
import { saStudentsSubReports } from '@srcAjs/components2/SaStudentsSubReports/index.component';
import { saTeachersCreate } from '@srcAjs/components2/SaTeachersCreate/index.component';
import { saTeachersDetail } from '@srcAjs/components2/SaTeachersDetail/index.component';
import { saTeachersImport } from '@srcAjs/components2/SaTeachersImport/index.component';
import { saTeachersIndex } from '@srcAjs/components2/SaTeachersIndex/index.component';
import { saTeachersSubProfile } from '@srcAjs/components2/SaTeachersSubProfile/index.component';
import { saTeachersSubReports } from '@srcAjs/components2/SaTeachersSubReports/index.component';
import { saStudentsSubProfile } from './SaStudentsSubProfile/index.component';
import { appShellCmp } from './AppShellCmp/AppShellCmp.component';

/**
 * @ngdoc function
 * @name app.config:uiRouter
 * @description
 * @author Damoah Dominic
 * # Config
 * Configure router for the School Administrator routes
 */
function SchoolAdminRoutesConfig(/** @type {import ('@uirouter/angularjs').StateProvider}*/ $stateProvider) {
	$stateProvider
		.state('sa', {
			url: '/sa/:user_id/:role_instance_id/:school_id',
			params: { user_id: null, role_instance_id: null, school_id: null },
			// component: appShellCmp.selector,
			views: {
				'': {
					templateUrl: 'views/layout.html',
				},
				'aside@sa': {
					templateUrl: 'views/aside.html',
				},
				'content@sa': {
					templateUrl: 'views/content.html',
				},
			},
		})

		// DASHBOARD
		.state('sa.dashboard', {
			url: '/dashboard/home',
			/*
			 * controller: 'SA_DashboardController',
			 * templateUrl: 'views/pages/roles/sa/home/dashboard.html',
			 * template: '<sa-dashboard-cmp/>',
			 * component: 'saHomeDashboardCmp',
			 */
			component: saHomeDashboardCmp.selector,
			data: { title: 'Home', folded: false, ngDynamicTitle: 'Welcome Home' },
		})
		.state('sa.analysis', {
			url: '/dashboard/analysis',
			/*
			 * controller: 'SA_AnalysisController',
			 * templateUrl: 'views/pages/roles/sa/home/analysis.html',
			 * template: '<sa-home-analysis-cmp/>',
			 */
			component: saHomeAnalysisCmp.selector,
			data: { title: 'Analysis', folded: false, ngDynamicTitle: 'Analysis', child: true },
		})
		.state('sa.push_center', {
			url: '/dashboard/push-center',
			/*
			 * controller: 'SA_PushController',
			 * templateUrl: 'views/pages/roles/sa/home/push.html',
			 */
			component: saHomePush.selector,
			data: { title: 'Push Center', folded: false, ngDynamicTitle: 'Push Center', child: true },
		})
		/*
		 *Academic Years
		 */
		.state('sa.school_setup_years', {
			url: '/school/years',
			/*
			 * rm controller: 'SA_SchoolSetupAcademicYears',
			 * rm templateUrl: 'views/pages/roles/sa/school_setup/years/index.html',
			 */
			component: saSchoolSetupYearsIndex.selector,
			data: { title: 'Academic Years', folded: false, ngDynamicTitle: 'Academic Years', child: true },
		})
		// Academic Years > Detail
		.state('sa.school_setup_years_detail', {
			url: '/school/year/{id}',
			resolve: { ...transitionResolve },
			/*
			 *  views: {
			 *  '': {
			 * rm 	templateUrl: 'views/pages/roles/sa/school_setup/years/detail.html',
			 * rm 	controller: 'SA_SchoolSetupAcademicYearsDetail',
			 *  },
			 *  navbar: {
			 * rm 	templateUrl: 'views/pages/roles/sa/school_setup/years/toolbar.html',
			 * rm 	controller: 'SA_SchoolSetupAcademicYearsDetailToolbarCtrl',
			 *  },
			 */
			'': 'saSchoolSetupYearsDetail',
			/*
			 * navbar: 'saSchoolSetupYearsToolbar',
			 * },
			 */
			component: saSchoolSetupYearsDetail.selector,
			data: { title: 'Loading ...', folded: false, ngDynamicTitle: 'Year Viewer', child: true },
		})
		// Academic Years > Detail > Terms
		.state('sa.school_setup_years_detail.terms', {
			url: '/terms',
			/*
			 * views: {
			 * 	// '': {
			 * 	// 	templateUrl: 'views/pages/roles/sa/school_setup/years/sub/terms.html',
			 * 	// 	controller: 'SA_SchoolSetupAcademicYearsDetailTerms',
			 * 	// },
			 * 	'': 'saSchoolSetupYearsSubTerms',
			 * },
			 */
			component: saSchoolSetupYearsSubTerms.selector,
		})
		// Academic Years > Detail > Summary
		.state('sa.school_setup_years_detail.summary', {
			url: '/summary',
			/*
			 * views: {
			 * 	// '': {
			 * 	// 	templateUrl: 'views/pages/roles/sa/school_setup/years/sub/summary.html',
			 * 	// 	controller: 'SA_SchoolSetupAcademicYearsDetailSummary',
			 * 	// },
			 * 	'': 'saSchoolSetupYearSubSummary',
			 * },
			 */
			component: saSchoolSetupYearSubSummary.selector,
		})
		// Academic Years > Detail > Term > Detail
		.state('sa.school_setup_terms_detail', {
			url: '/school/year/{yid}/term/{tid}',
			/*
			 * views: {
			 * 	// '': {
			 * 	// 	templateUrl: 'views/pages/roles/sa/school_setup/years/detail_term.html',
			 * 	// 	controller: 'SA_SchoolSetupAcademicTermsDetail',
			 * 	// },
			 * 	// navbar: {
			 * 	// 	templateUrl: 'views/pages/roles/sa/school_setup/years/toolbar_term.html',
			 * 	// 	controller: 'SA_SchoolSetupAcademicTermsDetailToolbarCtrl',
			 * 	// },
			 * },
			 */
			component: saSchoolSetupYearsDetailTerm.selector,
			data: { title: 'Loading ...', folded: false, ngDynamicTitle: 'Term Manager', child: true },
		})
		// Academic Years > Detail > Term > Detail > Mounted Subjects
		.state('sa.school_setup_terms_detail.mounted', {
			url: '/subject-assignment',
			/*
			 * views: {
			 * 	'': {
			 * 		templateUrl: 'views/pages/roles/sa/school_setup/years/sub_terms/mounted_subjects.html',
			 * 		controller: 'SA_SchoolSetupAcademicTermsDetailMountedSubjects',
			 * 	},
			 */

			// },
			component: saSchoolSetupYearsSubTermsMountedSubjects.selector,
		})
		.state('sa.school_setup_terms_detail.new_mounted', {
			url: '/term-configuration',
			/*
			 * views: {
			 * 	'': {
			 * 		templateUrl: 'views/pages/roles/sa/school_setup/years/sub_terms/new_mounted_subjects.html',
			 * 		controller: 'SA_SchoolSetupAcademicTermsDetailNewMountedSubjects',
			 * 	},
			 * },
			 */
			component: saSchoolSetupYearsSubTermsNewMountedSubjects.selector,
		})
		// Academic Years > Detail > Term > Detail > Enrolled Students
		.state('sa.school_setup_terms_detail.enrolled', {
			url: '/enrolled-students',
			/*
			 * views: {
			 * 	'': {
			 * 		templateUrl: 'views/pages/roles/sa/school_setup/years/sub_terms/enrolled_students.html',
			 * 		controller: 'SA_SchoolSetupAcademicTermsDetailEnrolledStudents',
			 * 	},
			 * },
			 */
			component: saSchoolSetupYearsSubTermsEnrolledStudents.selector,
		})
		/*
		 * Academic Years > Detail > Term > Detail > Access Codes
		 * .state('sa.school_setup_terms_detail.codes', {
		 * 	url: '/mobile-access',
		 * 	views: {
		 * 		'': {
		 * 			templateUrl: 'views/pages/roles/sa/school_setup/years/sub_terms/access_codes.html',
		 * 			controller: 'SA_SchoolSetupAcademicTermsDetailAccessCodes',
		 * 		},
		 * 	},
		 */

		/*
		 * })
		 * Academic Years > Detail > Term > Detail > Summary
		 */
		.state('sa.school_setup_terms_detail.summary', {
			url: '/summary',
			/*
			 * views: {
			 * 	'': {
			 * 		templateUrl: 'views/pages/roles/sa/school_setup/years/sub_terms/summary.html',
			 * 		controller: 'SA_SchoolSetupAcademicTermsDetailSummary',
			 * 	},
			 * },
			 */
			component: saSchoolSetupYearsSubTermsSummary.selector,
		})

		/*
		 *Grading Systems
		 */
		.state('sa.school_setup_grading', {
			url: '/school/grading-systems',
			/*
			 * controller: 'SA_SchoolSetupGrading',
			 * templateUrl: 'views/pages/roles/sa/school_setup/grading/index.html',
			 */
			component: saSchoolSetupGradingIndex.selector,
			data: { title: 'Grading Systems', folded: false, ngDynamicTitle: 'Grading Systems', child: true },
		})
		// Grading Systems > Detail
		.state('sa.school_setup_grading_detail', {
			url: '/school/grading-system/{id}',
			/*
			 * views: {
			 * 	'': {
			 * 		templateUrl: 'views/pages/roles/sa/school_setup/grading/detail.html',
			 * 		controller: 'SA_SchoolSetupGradingDetail',
			 * 	},
			 * 	navbar: {
			 * 		templateUrl: 'views/pages/roles/sa/school_setup/grading/toolbar.html',
			 * 		controller: 'SA_SchoolSetupGradingDetailToolbarCtrl',
			 * 	},
			 * },
			 */
			component: saSchoolSetupGradingDetail.selector,
			data: { title: 'Loading ...', folded: false, ngDynamicTitle: 'Grading System Viewer', child: true },
		})
		// Grading Systems > Detail > Entry
		.state('sa.school_setup_grading_detail.entry', {
			url: '/entries',
			/*
			 * views: {
			 * 	'': {
			 * 		templateUrl: 'views/pages/roles/sa/school_setup/grading/sub/entry.html',
			 * 		controller: 'SA_SchoolSetupGradingDetailEntry',
			 * 	},
			 * },
			 */
			component: saSchoolSetupGradingSubEntry.selector,
		})
		// Grading Systems > Detail > Summary
		.state('sa.school_setup_grading_detail.summary', {
			url: '/summary',
			/*
			 * views: {
			 * 	'': {
			 * 		templateUrl: 'views/pages/roles/sa/school_setup/grading/sub/summary.html',
			 * 		controller: 'SA_SchoolSetupGradingDetailSummary',
			 * 	},
			 * },
			 */
			component: saSchoolSetupGradingSubSummary.selector,
		})

		/*
		 *Class Groups
		 */
		.state('sa.school_setup_class_groups', {
			url: '/school/departments',
			/*
			 * controller: 'SA_SchoolSetupClassGroups',
			 * templateUrl: 'views/pages/roles/sa/school_setup/class_groups/index.html',
			 */
			component: saSchoolSetupClassGroupsIndex.selector,
			data: { title: 'Departments', folded: false, ngDynamicTitle: 'School Departments', child: true },
		})
		// Class Groups > Detail
		.state('sa.school_setup_class_groups_detail', {
			url: '/school/departments/{id}',
			/*
			 * views: {
			 * 	'': {
			 * 		templateUrl: 'views/pages/roles/sa/school_setup/class_groups/detail.html',
			 * 		controller: 'SA_SchoolSetupClassGroupsDetail',
			 * 	},
			 * 	navbar: {
			 * 		templateUrl: 'views/pages/roles/sa/school_setup/class_groups/toolbar.html',
			 * 		controller: 'SA_SchoolSetupClassGroupsToolbarCtrl',
			 * 	},
			 * },
			 */
			component: saSchoolSetupClassGroupsDetail.selector,
			data: { title: 'Loading ...', folded: false, ngDynamicTitle: 'Division Viewer', child: true },
		})
		// Class Groups > Detail > Summary
		.state('sa.school_setup_class_groups_detail.summary', {
			url: '/summary',
			/*
			 * views: {
			 * 	'': {
			 * 		templateUrl: 'views/pages/roles/sa/school_setup/class_groups/sub/summary.html',
			 * 		controller: 'SA_SchoolSetupClassGroupsDetailSummary',
			 * 	},
			 * },
			 */
			component: saSchoolSetupClassGroupsSubSummary.selector,
		})
		/*
		 *Classes
		 */
		.state('sa.school_setup_classes', {
			url: '/school/class-levels',
			/*
			 * controller: 'SA_SchoolSetupClasses',
			 * templateUrl: 'views/pages/roles/sa/school_setup/classes/index.html',
			 */
			component: saSchoolSetupClassesIndex.selector,
			data: { title: 'Class Levels', folded: false, ngDynamicTitle: 'Class Levels', child: true },
		})
		// Classes > Detail
		.state('sa.school_setup_classes_detail', {
			url: '/school/level/{id}',
			/*
			 * views: {
			 * 	'': {
			 * 		templateUrl: 'views/pages/roles/sa/school_setup/classes/detail.html',
			 * 		controller: 'SA_SchoolSetupClassesDetail',
			 * 	},
			 * 	navbar: {
			 * 		templateUrl: 'views/pages/roles/sa/school_setup/classes/toolbar.html',
			 * 		controller: 'SA_SchoolSetupClassesToolbarCtrl',
			 * 	},
			 * },
			 */
			component: saSchoolSetupClassesDetail.selector,
			data: { title: 'Loading ...', folded: false, ngDynamicTitle: 'Classes', child: true },
		})
		// Classes > Detail > Sections
		.state('sa.school_setup_classes_detail.sections', {
			url: '/sections',
			/*
			 * views: {
			 * 	'': {
			 * 		templateUrl: 'views/pages/roles/sa/school_setup/classes/sub/sections.html',
			 * 		controller: 'SA_SchoolSetupClassesDetailSections',
			 * 	},
			 * },
			 */
			component: saSchoolSetupClassesSubSections.selector,
		})
		// Classes > Detail > Summary
		.state('sa.school_setup_classes_detail.summary', {
			url: '/summary',
			/*
			 * views: {
			 * 	'': {
			 * 		templateUrl: 'views/pages/roles/sa/school_setup/classes/sub/summary.html',
			 * 		controller: 'SA_SchoolSetupClassesDetailSummary',
			 * 	},
			 * },
			 */
			component: saSchoolSetupClassesSubSummary.selector,
		})
		// Classes > Detail > Sections > Detail
		.state('sa.school_setup_sections_detail', {
			url: '/school/level/{cid}/section/{sid}',
			/*
			 * views: {
			 * 	'': {
			 * 		templateUrl: 'views/pages/roles/sa/school_setup/classes/detail_section.html',
			 * 		controller: 'SA_SchoolSetupSectionsDetail',
			 * 	},
			 * 	navbar: {
			 * 		templateUrl: 'views/pages/roles/sa/school_setup/classes/toolbar_section.html',
			 * 		controller: 'SA_SchoolSetupSectionsDetailToolbarCtrl',
			 * 	},
			 * },
			 */
			component: saSchoolSetupClassesDetailSection.selector,
			data: { title: 'Loading ...', folded: false, ngDynamicTitle: 'Class Management', child: true },
		})
		// Classes > Detail > Sections > Detail > Enrolled Students
		.state('sa.school_setup_sections_detail.enrolled', {
			url: '/enrolled-students',
			/*
			 * views: {
			 * 	'': {
			 * 		templateUrl: 'views/pages/roles/sa/school_setup/classes/sub_sections/enrolled_students.html',
			 * 		controller: 'SA_SchoolSetupSectionsDetailEnrolledStudents',
			 * 	},
			 * },
			 */
			component: saSchoolSetupClassesSubSectionsEnrolledStudents.selector,
		})
		// Classes > Detail > Sections > Detail > Summary
		.state('sa.school_setup_sections_detail.summary', {
			url: '/summary',
			/*
			 * views: {
			 * 	'': {
			 * 		templateUrl: 'views/pages/roles/sa/school_setup/classes/sub_sections/summary.html',
			 * 		controller: 'SA_SchoolSetupSectionsDetailSummary',
			 * 	},
			 * },
			 * component: saSchoolSetupClassesSubSectionsSummary.selector,
			 */
		})
		.state('sa.school_setup_info', {
			url: '/school/settings',
			/*
			 * controller: 'SA_SchoolSetupInfo',
			 * templateUrl: 'views/pages/roles/sa/school_setup/info/index.html',
			 */
			component: saSchoolSetupInfoIndex.selector,
			data: { title: 'School Settings', folded: false, ngDynamicTitle: 'School Settings', child: true },
		})
		.state('sa.school_setup_photo', {
			url: '/school/gallery',
			/*
			 * controller: 'SA_SchoolSetupGallery',
			 * templateUrl: 'views/pages/roles/sa/school_setup/photos/index.html',
			 */
			component: saSchoolSetupPhotosIndex.selector,
			data: { title: 'School Gallery', folded: false, ngDynamicTitle: 'School Gallery', child: true },
		})
		.state('sa.school_setup_subjects', {
			url: '/school/subjects',
			/*
			 * controller: 'SA_SchoolSetupSubjects',
			 * templateUrl: 'views/pages/roles/sa/school_setup/subjects/index.html',
			 */
			component: saSchoolSetupSubjectsIndex.selector,
			data: { title: 'Subject Mapping', folded: false, ngDynamicTitle: 'Subject Mapping', child: true },
		})
		.state('sa.school_setup_admins', {
			url: '/school/administrators',
			/*
			 * controller: 'SA_SchoolSetupAdmins',
			 * templateUrl: 'views/pages/roles/sa/school_setup/admins/index.html',
			 */
			component: saSchoolSetupAdminsIndex.selector,
			data: { title: 'Admin Access', folded: false, ngDynamicTitle: 'School Administrators', child: true },
		})
		// Alumni Bucket
		.state('sa.alumni', {
			url: '/alumni/bucket',
			/*
			 * controller: 'SA_AlumniBucket',
			 * templateUrl: 'views/pages/roles/sa/alumni/index.html',
			 */
			component: saAlumniIndex.selector,
			data: { title: 'Alumni Bucket', folded: false, ngDynamicTitle: 'Alumni Buckets', child: true },
		})
		// Operations
		.state('sa.operations_promotion', {
			url: '/operations/promotion',
			abstract: true,
			// controller: 'SA_OperationsPromotion',
			templateUrl: 'views/pages/roles/sa/operations/promotion/index.html',
		})
		// Operations
		.state('sa.operations_promotion.home', {
			url: '/home',
			// controller: 'SA_OperationsPromotion',
			templateUrl: 'views/pages/roles/sa/operations/promotion/home.html',
			data: { title: 'Promote Students', folded: false, ngDynamicTitle: 'Operations Promotion', child: true },
		})
		.state('sa.operations_promotion.promote_term', {
			url: '/term',
			controller: 'SA_OperationsPromotion',
			templateUrl: 'views/pages/roles/sa/operations/promotion/term.html',
			data: { title: 'Promote Students', folded: false, ngDynamicTitle: 'Operations Promotion', child: true },
		})
		// Operations
		.state('sa.operations_promotion.promote_class_section', {
			url: '/class',
			controller: 'SA_OperationsPromotionClassSection',
			templateUrl: 'views/pages/roles/sa/operations/promotion/class_section.html',
			// component: ,
			data: { title: 'Promote Class Section', folded: false, ngDynamicTitle: 'Operations Promotion', child: true },
		})
		// Operations
		.state('sa.operations_promotion.promote_class_list', {
			url: '/class_list',
			// controller: 'SA_OperationsPromotion',
			templateUrl: 'views/pages/505.html',
			data: { title: 'Promote Students', folded: false, ngDynamicTitle: 'Operations Promotion', child: true },
		})
		.state('sa.operations_check_attendance', {
			url: '/operations/check-attendance',
			/*
			 * controller: 'SA_OperationsCheckAttendance',
			 * templateUrl: 'views/pages/roles/sa/operations/check_attendance/index.html',
			 */
			component: sSaOperationsCheckAttendanceIndex.selector,
			data: { title: 'Check Attendance', folded: false, ngDynamicTitle: 'Check Attendance', child: true },
		})
		.state('sa.operations_approve_scores', {
			url: '/operations/approve-scores',
			/*
			 * controller: 'SA_OperationsApproveScores',
			 * templateUrl: 'views/pages/roles/sa/operations/approve_scores/index.html',
			 */
			component: saOperationsApproveScoresIndex.selector,
			data: { title: 'Approve Scores', folded: false, ngDynamicTitle: 'Approve Scores', child: true },
		})
		.state('sa.operations_admissions', {
			url: '/operations/admissions',
			controller: 'SA_OperationsAdmissions',
			templateUrl: 'views/pages/roles/sa/operations/admissions/index.html',
			data: { title: 'Student Admissions', folded: false, ngDynamicTitle: 'Operations Admissions', child: true },
		})

		// Students
		.state('sa.students_create', {
			url: '/student/create',
			/*
			 * controller: 'SA_StudentsCreate',
			 * templateUrl: 'views/pages/roles/sa/students/create.html',
			 */
			component: saStudentsCreate.selector,
			data: {
				title: 'New Student',
				folded: false,
				ngDynamicTitle: 'New Student',
				child: true,
				allowedfn: (_, /** @type {EngagedUserRole}*/ engagedUserRole) => ['tr.', 'sa.'].some((role_parent) => role_parent.includes(engagedUserRole.role.short)),
			},
			params: { section: null, student_intent: null, student_creation_request: null, student_request_index: null },
		})
		.state('sa.students_browse', {
			url: '/students',
			/*
			 * controller: 'SA_StudentsBrowse',
			 * templateUrl: 'views/pages/roles/sa/students/index.html',
			 */
			component: saStudentsIndex.selector,
			data: { title: 'Browse Students', folded: false, ngDynamicTitle: 'Browse Students', child: true },
		})
		.state('sa.students_import', {
			url: '/students/import',
			/*
			 * controller: 'SA_StudentsImport',
			 * templateUrl: 'views/pages/roles/sa/students/import.html',
			 */
			component: saStudentsImport.selector,
			data: { title: 'Import Students', folded: false, ngDynamicTitle: 'Import Students', child: true },
		})
		.state('sa.students_detail', {
			url: '/student/{id}',
			/*
			 * views: {
			 * 	'': {
			 * 		templateUrl: 'views/pages/roles/sa/students/detail.html',
			 * 		controller: 'SA_StudentDetail',
			 * 	},
			 * 	navbar: {
			 * 		templateUrl: 'views/pages/roles/sa/students/toolbar.html',
			 * 		controller: 'SA_StudentDetailToolbarCtrl',
			 * 	},
			 * },
			 */
			component: saStudentsDetail.selector,
			data: { title: 'Loading Student ...', folded: false, ngDynamicTitle: 'Student Profile', child: true },

			resolve: {
				istudent: (
					/** @type {API} */ API,
					/** @type {import('@uirouter/angularjs').StateParams}*/ $stateParams,
					/** @type {ErrorService} */ ErrorService,
					Restangular,
					/** @type {ng.FilterFactory}*/ $filter,
					/** @type {isoCountryList} */ isoCountryList
				) => {
					const /** @type {API_Params} */ params = {
							filters: [{ condition: 'where', column: 'id', value: $stateParams.id }],
							fields: [
								{ condition: 'with', field: 'guardians.user' },
								{ condition: 'with', field: 'student_enrollment_state' },
								{
									condition: 'with',
									field: 'section_histories',
									conditions: [
										{ condition: 'with', column: 'section.sclass.class_level' },
										{ condition: 'with', column: 'term.term_type' },
									],
								},
								{
									condition: 'with',
									field: 'current_section_history',
									conditions: [{ condition: 'with', column: 'section.sclass.class_level' }],
								},
							],
							has_fields: null,
						};

					const attachCountry = (student) =>
						isoCountryList
							.get()
							.then((countries) => {
								student.country = countries.find((country) => country.id === student.country_id);
								return student;
							})
							.catch((error) => {
								ErrorService.handleError(error);
								throw new IError(error);
							});

					return Restangular.all(`student?${API.buildParams(params.filters, params.has_fields, params.fields, null, '1.4')}`)
						.getList(/** { field_section_histories: true }*/)
						.then(([student]) => ({
							...student.plain(),
							// section_histories: student.plain().section_histories.sort((a, b) => b.term_id - a.term_id),
							section_histories: $filter('orderBy')(student.plain().section_histories, ['-term_id', '-created_at']),
						}))
						.then(attachCountry)
						.catch((error) => ErrorService.handleError(error));
				},
			},
		})
		.state('sa.students_detail.profile', {
			url: '/profile',
			component: saStudentsSubProfile.selector,
			bindings: { student: 'istudent' },
		})
		.state('sa.students_detail.reports', {
			url: '/reports',
			/*
			 * templateUrl: 'views/pages/roles/sa/students/sub/reports.html',
			 * controller: 'SA_StudentDetailReports',
			 */
			component: saStudentsSubReports.selector,
		})

		// Guardians
		.state('sa.guardians_create', {
			url: '/guardian/create',
			/*
			 * controller: 'SA_GuardiansCreate',
			 * templateUrl: 'views/pages/roles/sa/guardians/create.html',
			 */
			component: saGuardiansCreate.selector,
			params: { student: null, student_request_index: null, creation_type: null, guardian_intent: null, guardian_request_index: null },
			data: {
				title: 'New Guardian',
				folded: false,
				ngDynamicTitle: 'New Guardian',
				child: true,
				allowedfn: (_, /** @type {EngagedUserRole} */ engagedUserRole) => ['tr.', 'sa.'].some((role_parent) => role_parent.includes(engagedUserRole.role.short)),
			},
		})
		.state('sa.guardians_browse', {
			url: '/guardians',
			/*
			 * controller: 'SA_GuardiansBrowse',
			 * templateUrl: 'views/pages/roles/sa/guardians/index.html',
			 */
			component: saGuardiansIndex.selector,
			data: { title: 'Browse Guardians', folded: false, ngDynamicTitle: 'Browse Guardians', child: true },
		})
		.state('sa.guardians_import', {
			url: '/guardians/import',
			/*
			 * controller: 'SA_GuardiansImport',
			 * templateUrl: 'views/pages/roles/sa/guardians/import.html',
			 */
			component: saGuardiansImport.selector,
			data: { title: 'Import Guardians', folded: false, ngDynamicTitle: 'Import Guardians', child: true },
		})
		.state('sa.guardians_detail', {
			url: '/guardian/{id}',
			/*
			 * views: {
			 * 	'': {
			 * 		templateUrl: 'views/pages/roles/sa/guardians/detail.html',
			 * 		controller: 'SA_GuardiansDetail',
			 * 	},
			 * 	navbar: {
			 * 		templateUrl: 'views/pages/roles/sa/guardians/toolbar.html',
			 * 		controller: 'SA_GuardiansDetailToolbarCtrl',
			 * 	},
			 * },
			 */
			component: saGuardiansDetail.selector,
			data: { title: 'Loading Guardian ...', folded: false, ngDynamicTitle: 'Guardian Viewer', child: true },
			resolve: {
				iguardian: (Restangular, /** @type {import('@uirouter/angularjs').StateParams}*/ $stateParams, ErrorService, API) => {
					const params = {
						filters: [{ condition: 'where', column: 'id', value: $stateParams.id }],
						has_fields: null,
						fields: [
							{
								condition: 'with',
								field: 'students',
								conditions: [{ condition: 'whereDoesntHave', column: 'student_enrollment_states.enrollment_state|where|state', value: 'archived' }],
							},
							{ condition: 'with', field: 'user' },
						],
					};

					try {
						return Restangular.all(`guardian?${API.buildParams(params.filters, params.has_fields, params.fields, null, '1.4')}`)
							.getList()
							.then(([res]) => res.plain());
					} catch (error) {
						ErrorService.handleError(error);
					}
				},
			},
		})
		.state('sa.guardians_detail.reports', {
			url: '/reports',
			/*
			 * templateUrl: 'views/pages/roles/sa/guardians/sub/reports.html',
			 * controller: 'SA_GuardiansDetailReports',
			 */
			component: saGuardiansSubReports.selector,
			data: {
				ngDynamicTitle: 'Guardian Reports',
			},
		})
		.state('sa.guardians_detail.profile', {
			url: '/profile',
			/*
			 * templateUrl: 'views/pages/roles/sa/guardians/sub/profile.html',
			 * controller: 'SA_GuardiansDetailProfile',
			 */

			component: saGuardiansSubProfile.selector,
			data: {
				ngDynamicTitle: 'Guardian Profile',
			},
		})

		// Teachers
		.state('sa.teachers_create', {
			url: '/teachers/create',
			/*
			 * controller: 'SA_TeachersCreate',
			 * templateUrl: 'views/pages/roles/sa/teachers/create.html',
			 */
			component: saTeachersCreate.selector,
			data: { title: 'New Teacher', folded: false, ngDynamicTitle: 'New Teacher', child: true },
		})
		.state('sa.teachers_browse', {
			url: '/teachers',
			/*
			 * controller: 'SA_TeachersBrowse',
			 * templateUrl: 'views/pages/roles/sa/teachers/index.html',
			 */
			component: saTeachersIndex.selector,
			data: { title: 'Browse Teachers', folded: false, ngDynamicTitle: 'Browse Teachers', child: true },
		})
		.state('sa.teachers_import', {
			url: '/teachers/import',
			/*
			 * controller: 'SA_TeachersImport',
			 * templateUrl: 'views/pages/roles/sa/teachers/import.html',
			 */
			component: saTeachersImport.selector,
			data: { title: 'Import Teachers', folded: false, ngDynamicTitle: 'Import Teachers', child: true },
		})
		.state('sa.teachers_detail', {
			url: '/teachers/{id}',
			/*
			 * views: {
			 * 	'': {
			 * 		templateUrl: 'views/pages/roles/sa/teachers/detail.html',
			 * 		controller: 'SA_TeachersDetail',
			 * 	},
			 * 	navbar: {
			 * 		templateUrl: 'views/pages/roles/sa/teachers/toolbar.html',
			 * 		controller: 'SA_TeachersDetailToolbarCtrl',
			 * 	},
			 * },
			 */
			component: saTeachersDetail.selector,
			data: { title: 'Loading Teacher ...', folded: false, ngDynamicTitle: 'Teacher Viewer', child: true },
		})
		.state('sa.teachers_detail.profile', {
			url: '/profile',
			/*
			 * templateUrl: 'views/pages/roles/sa/teachers/sub/profile.html',
			 * controller: 'SA_TeachersDetailProfile',
			 */
			component: saTeachersSubProfile.selector,
			data: {
				ngDynamicTitle: 'Teacher Profile',
			},
		})

		.state('sa.teachers_detail.reports', {
			url: '/reports',
			/*
			 * templateUrl: 'views/pages/roles/sa/teachers/sub/reports.html',
			 * controller: 'SA_TeachersDetailReports',
			 */
			component: saTeachersSubReports.selector,
			data: {
				ngDynamicTitle: 'Teacher Reports',
			},
		})
		// Events
		.state('sa.events', {
			url: '/events',
			/*
			 * controller: 'SA_Events',
			 * templateUrl: 'views/pages/roles/sa/events/index.html',
			 */
			component: saEventsIndex.selector,
			data: { title: 'Events', folded: false, ngDynamicTitle: 'School Events', child: true },
		})
		.state('sa.notices', {
			url: '/notices',
			/*
			 * controller: 'SA_Notices',
			 * templateUrl: 'views/pages/roles/sa/notices/index.html',
			 */
			component: saNoticesIndex.selector,
			data: { title: 'Notices', folded: false, ngDynamicTitle: 'Notice Board', child: true },
		})
		.state('sa.estore', {
			url: '/estore',
			controller: 'SA_EStore',
			templateUrl: 'views/pages/roles/sa/estore/index.html',
			data: { title: 'Plans', folded: false, ngDynamicTitle: 'EStore' },
		})
		// Reports
		.state('sa.reports', {
			url: '/reports',
			component: 'saReportIndexCmp',
			data: { title: 'Choose a Report', folded: false, ngDynamicTitle: 'Choose a Report', child: true },
		})
		// Reports Detail
		.state('sa.report_detail_page', {
			url: '/report/:name',
			resolve: { ...transitionResolve },
			component: 'saReportDetailsCmp',
			params: { report: null },
			data: { title: 'Loading Report', folded: false, ngDynamicTitle: 'Report Viewer', child: true },
		})

		// Help and Support
		.state('sa.help_support', {
			url: '/help-and-support',
			/*
			 * controller: 'SA_HelpSupport',
			 * templateUrl: 'views/pages/roles/sa/help/index.html',
			 */
			component: saHelpIndex.selector,
			data: { title: 'Help & support', folded: false, ngDynamicTitle: 'Help and Support', child: true },
		})

		// Mobile
		.state('sa.mobile', {
			url: '/mobile',
			/*
			 * controller: 'SA_Mobile',
			 * templateUrl: 'views/pages/roles/sa/mobile/index.html',
			 */
			component: saMobileIndex.selector,
			data: { title: 'Mobile', folded: false, ngDynamicTitle: 'Schrep Mobile', child: true },
		})

		// Executables
		.state('sa.downloads', {
			url: '/downloads',
			component: 'saDownloadsCmp',
			data: { title: 'Downloads', folded: false, ngDynamicTitle: 'Downloads', child: true },
		})
		// Profile
		.state('sa.profile', {
			url: '/profile',
			/*
			 * controller: 'SA_ProfileController',
			 * templateUrl: 'views/pages/roles/sa/profile.html',
			 */
			component: saProfile.selector,
			data: { title: 'Profile', folded: false, child: true, ngDynamicTitle: 'My Profile' },
		})

		// DelegationRequests
		.state('sa.delegated_requests', {
			url: '/delegated_requests',
			component: 'delegatedRequestsCmp',
			data: { title: 'Profile', folded: false, child: true, ngDynamicTitle: 'My Profile' },
		})

		// ManageStudentBilling
		.state('sa.manage_billing', {
			url: '/manage-billing',
			component: 'studentBilling',
			// resolve: {
			// 	NotAllowed: (CONST_APP_ENV) => /production/i.test(CONST_APP_ENV),
			// 	AlertChecker: (/** @type {DialogService}*/ DialogService, NotAllowed) =>
			// 		NotAllowed
			// 			? DialogService.dispatch('alert', 'Feature is disabled').then(() => {
			// 					// throw new Error('Access denied');
			// 			  })
			// 			: null,
			// },
			data: {
				title: 'Manage Student Billing',
				folded: false,
				child: false,
				ngDynamicTitle: 'Student Billing',
				// notAllowedfn: () => {
				// 	/production/i.test('__CONST_APP_ENV__');
				// },
			},
		})

		.state('sa.manage_billing.students', {
			url: '/students',
			component: 'manageStudentChargesCmp',
		})
		.state('sa.manage_billing.charge_templates', {
			url: '/charge-templates',
			component: 'manageChargeTemplates',
		});
}

appModule.config(['$stateProvider', SchoolAdminRoutesConfig]);

// export SchoolAdminRoutesConfig
export { SchoolAdminRoutesConfig };

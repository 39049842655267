const schoolReportMap = {
	7: 'itemized subject report card',
};

export const SchoolReportTypePrefService = {
	get: ({ school_id }) => {
		if (!school_id) return;

		return schoolReportMap[school_id] ?? 'detailed report card v2';
	},
};

appModule.factory('SchoolReportTypePrefService', () => SchoolReportTypePrefService);

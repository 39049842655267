import template from './AttendanceAssignMultipleCmp.tpl.html';

import './AttendanceAssignMultipleCmp.scss';

const attendanceAssignMultipleCmp = {
	template,
	/** @type {{scope?,states:[],onClick:({value})=>void}} */
	// @ts-ignore
	bindings: {
		scope: '<',
		states: '<',
		onClick: '&',
	},
};

/** @this {typeof attendanceAssignMultipleCmp.bindings} */
function TRAttendanceOptionsBottomSheetController() {
	this.$onInit = () => {
		console.log({ scope: this.scope, states: this.states, onClick: this.onClick });
	};
	this.doClick = (state) => {
		this.onClick({ value: state });
		const hide = this.hide ?? this.scope?.hide;
		hide && hide(state);
	};
}

attendanceAssignMultipleCmp.controller = TRAttendanceOptionsBottomSheetController;

appModule.component('attendanceAssignMultipleCmp', attendanceAssignMultipleCmp);

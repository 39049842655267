// /** @typedef {<T>(t:T)=> [import("./rxjs.factory").RxService['Observable'],()=>void,takeUntilDestroyCb]} compSetupService */

/** @typedef {<T,B extends Record<string,string|Function|boolean|Object> = {} >(t:T,b?:B)=> asserts t is (T & (ReturnType<ReturnType<typeof compSetupServiceFactory>>) & {[K in keyof B]: B[K] extends `&`|`<`? Function:unknown}) } compSetupService */

function compSetupServiceFactory(/**@type {RxService} */ RxService) {
	return (/** @template {import("angular").IComponentController} T @type {T}*/ _this) => {
		if (_this.componentDestroy$) {
			return;
		}
		// console.log('compSetupService(this).fired', { _this });

		const componentDestroy$ = new RxService.Subject();
		const takeUntilComponentDestroyedCb = (t) => t.pipe(RxService.takeUntil(componentDestroy$));

		// componentDestroy$.subscribe(() => console.log('takeUntilComponentDestroyedCb.fired', _this));

		const fireComponentDestroy = () => {
			console.log(fireComponentDestroy);
			componentDestroy$.next();
			componentDestroy$.complete();
		};

		_this.$onDestroy = () => fireComponentDestroy();

		const temp = { componentDestroy$, takeUntilComponentDestroyedCb, fireComponentDestroy, trap$: takeUntilComponentDestroyedCb };
		Object.assign(_this, temp);
		// console.log(_this,'compSetup(this).successful');
		return temp;
	};
}

appModule.factory('compSetupService', compSetupServiceFactory);

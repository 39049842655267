import _ from 'lodash';
import { BehaviorSubject, Observable, distinctUntilChanged, map } from 'rxjs';

/**
 * @template SInitialState
 * @template SName
 * @template {Record<string, (state: SInitialState, payload: any) => SInitialState>} SReducers
 * @param {{name:SName, initialState:SInitialState , reducers:SReducers}} sliceOptions
 */
export function createSlice(sliceOptions) {
	const actionsArray = Object.entries(sliceOptions.reducers).map(([reducerName, reducer]) => {
		/** @typedef {( Parameters<typeof reducer>)[1]} TPayload */
		const actionCb = (/** @type { TPayload} */ payload) => ({
			sliceName: sliceOptions.name,
			sliceTransformer: (/** @type {SInitialState} */ slice) => reducer(slice, payload),
		});
		return [reducerName, actionCb];
	});

	/**
	 * @type {{
	 * [P in keyof SReducers]:
	 * <M extends Parameters<SReducers[P]>[1]>(payload:M)=> {sliceName:SName , sliceTransformer: (state:SInitialState)=>SInitialState}
	 * }}
	 */
	const actions = Object.fromEntries(actionsArray);
	return { ...sliceOptions, actions };
}

/**
 * @template {Record<string,ReturnType<typeof createSlice>>} T
 */
export function createStore(/** @type {T}*/ slices) {
	// store creator

	if (!Object.entries(slices).every(([name, slice]) => name === slice.name)) {
		throw new Error('Error: keys must match the name field');
	}

	const getInitialStore = (tempSlices = slices) => {
		const initialValues = Object.entries(tempSlices).map(([name, slice]) => [name, slice.initialState]);

		/** @type {{[P in keyof T]: T[P]['initialState']}} */
		const result = Object.fromEntries(initialValues);
		return result;
	};

	/**
	 * @template {{sliceName:keyof T extends string?any:never,sliceTransformer:(state)=>any}} DispatchEvent
	 */
	function _dispatch(/** @type {DispatchEvent|DispatchEvent[]}*/ event) {
		const events = Array.isArray(event) ? event : [event];
		const tempStore = _.cloneDeep(store$);

		const nextStore = events.reduce((accStoreValue, nextEvent) => {
			const { sliceName, sliceTransformer } = nextEvent;
			const tempSlice = sliceTransformer(accStoreValue[sliceName]);
			accStoreValue[sliceName] = tempSlice;
			return accStoreValue;
		}, tempStore.value);

		store$.next(nextStore);
	}

	/** @template {T} Q */
	const makeSliceActionPairs = (/** @type {Q} */ tempSlices) => {
		const sliceActionPairsArr = Object.entries(tempSlices).map(([name, { actions }]) => [name, actions]);

		/** @type {{[P in keyof Q]: Q[P]['actions']}} */
		const result = Object.fromEntries(sliceActionPairsArr);

		return result;
	};

	const store$ = new BehaviorSubject(getInitialStore(slices));

	/**
	 * @template {typeof store$} Store
	 * @template {(q: Store['value'])=>any} CB
	 */
	function selector(/** @type {CB} */ cb, /** @type {Store} */ observableStore) {
		/** @typedef {ReturnType<CB>} U*/
		const tempObservable = observableStore ?? store$;
		const subscription = tempObservable
			.asObservable()
			.pipe(map(cb))
			.pipe(distinctUntilChanged(/** @type {U} */ (prev, /** @type {U} */ curr) => JSON.stringify(prev) === JSON.stringify(curr)));
		Object.defineProperty(subscription, 'value', { get: () => _.cloneDeep(cb(tempObservable.value)) });

		const result = /** @type {Observable<U> & {value:U}} */ (subscription);

		return result;
	}

	const actions = makeSliceActionPairs(slices);

	/**
	 *
	 * @typedef {(_actions:actions)=> any} CB
	 */
	const dispatchWithActionCb = (/** @type {CB} */ cb) => {
		console.log(cb);
		_dispatch(cb(actions));
	};

	/** @param {Parameters< _dispatch | dispatchWithActionCb >[number]} cb*/
	const dispatch = (cb) => (_.isFunction(cb) ? dispatchWithActionCb(cb) : _dispatch(cb));

	return { store$, dispatch, actions, selector };
}

import template from './index.html';

/** @type {import('@srcAjs/components2/adminRoutes').IComponentOptions2} */
const trClassSubAttendance = {
	template,
	bindings: {
		//someInput: '@string,<object,&function'
	},
	transclude: {
		// #slotName: '?optionalPublicName'
	},
};

/** @this {ng.IController} */
function trClassSubAttendanceCmpController(
	/** @type {ng.ITimeoutService} */ $timeout,
	/** @type {angular.IScope} */ $scope,
	$mdToast,
	$state,
	/** @type {lsfactory} */ lsfactory,
	$rootScope,
	/** @type {ng.material.IBottomSheetService} */ $mdBottomSheet,
	$mdDialog,
	$mdpDatePicker,
	/** @type {ErrorService} */ ErrorService,
	/** @type {getDateWithinTermBoundsFilter} */ getDateWithinTermBoundsFilter,
	/** @type {API} */ API,
	/** @type {TRFactory} */ TRFactory,
	/** @type {Restangular} */ Restangular,
	/** @type {atomosService} */ atomosService,
	/** @type {compSetupService} */ compSetupService,
	/** @type {EffectService} */ EffectService,
	/** @type {auth} */ auth,
	/** @type {ng.ui.UrlService} */ $urlService,
	/** @type {ng.ILocationService} */ $location,
	/** @type {ng.IWindowService} */ $window,
	/** @type {$moment} */ $moment,
	/** @type {DialogService} */ DialogService
) {
	/**
	 * @type { typeof $scope & {
	 * attendance_date: Date
	 * [key:string]:any
	 * attendance_updates_map:Map
	 * section_histories:(SectionHistory & {attendance_state: typeof Attendance_States[number] ,attendance_summaries_info:string})[]
	 * }}
	 */
	const svm = /** @type {any} */ ($scope);
	const vm = this;
	compSetupService(this);

	const DEFAULT_STATE = { title: 'N', sub_title: 'Not Available', class: 'attendance-nm' };

	const Attendance_States = [
		{ title: 'P', sub_title: 'Present', class: 'bg-blue text-white', order: 1, reason: 'Present' },
		{ title: 'A', sub_title: 'Absent', class: 'bg-red text-white', order: 2, reason: 'Absent' },
		{ title: 'L', sub_title: 'Late', class: 'bg-green text-black', order: 3, reason: 'Late' },
		{ title: 'H', sub_title: 'Holiday', class: 'bg-yellow', order: 4, reason: 'Holiday' },
		{ title: 'N', sub_title: 'Clear', class: '', order: 5, reason: 'Clear' },
	];
	svm.states = Attendance_States;

	svm.statesMap = Object.fromEntries(svm.states.map((st) => [st.sub_title, st]));

	const EXPECTED_DATE_FORMAT = 'YYYY-MM-DD';
	svm.attendance_date = null;
	svm.page = 'home';
	svm.chosen_date = '';

	svm.isTaken = false;
	svm.icon = 'back';
	svm.date_is_today = null;

	svm.today = null;
	svm.taken_count = 0;
	svm.isReady = false;
	svm.show_attendance_sheet = false;

	$rootScope.isCTermDisabled = false;
	svm.firstStatus = true;
	svm.isCheckerLoading = true;
	svm.goneBack = false;
	svm.isLoading = false;
	svm.attendance_updates_map = new Map();
	svm.attendance_date_term_correspondence = '';

	svm.show_images = false;

	// Set custom color for the calendar heatmap
	svm.color = '#cd2327';

	// Set overview type (choices are year, month and day)
	svm.overview = 'year';

	// Handler function
	svm.print = function (val) {
		console.log(val);
	};

	svm.save = async function () {
		/*
		 * TODO: Do the backend stuff
		 * var marked_students = vm.students.filter((std) => std.attendance_state.title != "N") //get studets with a set attendance
		 */

		/** @type Map */
		const marked_students = svm.attendance_updates_map; // get studets with a set attendance
		if (!marked_students.size) {
			$mdToast.showSimple('Please set atleast once attendance and try again');
			return;
		}
		svm.updateOrSaveLoading = true;

		try {
			const data = {
				date: $moment(svm.attendance_date).format(EXPECTED_DATE_FORMAT),
				teacher_id: svm.teacher.id,
				students: [...marked_students.values()],
			};
			console.log(data);

			await Restangular.all('attendance/affect')
				.post(data)
				.then(() => [
					marked_students.clear(),
					$mdToast.showSimple('Attendance Saved'),
					refreshSectionAttDaily({ revalidateAll: true }),
					// refreshSectionAttDaysInWeek({ revalidateAll: true }),
				])
				.catch(ErrorService.handleError)
				.finally(() => (svm.updateOrSaveLoading = false));
		} catch (error) {
			ErrorService.handleError(error);
		} finally {
			svm.updateOrSaveLoading = false;
			$timeout(() => {}, 10);
		}
	};

	lsfactory.setEnv('backLog', 'tr.home.dashboard');

	const [sectionAttDailyStatsInTerm$, { mutate: querySectionAttDailyStatsInTerm, revalidate: refreshSectionAttDaily }] = TRFactory.attendanceStatsRxq(
		this,
		'sectionAttDailyStatsInTerm'
	);
	const querySectionAttDailyStatsInTermMediator = (term_id, section_id) =>
		querySectionAttDailyStatsInTerm({
			term_ids: [term_id],
			section_ids: [section_id],
			statsCols: ['term'],
			scope: { level: 'term', period: 'daily' },
			filters: [['term_id', term_id]],
		});

	const [sectionAttDaysInWeek$, { mutate: querySsectionAttDaysInWeek, revalidate: refreshSectionAttDaysInWeek }] = TRFactory.attendanceStatsRxq(this, 'sectionAttDaysInWeek');
	const querySectionAttDailyStatsInWeekMediator = (term_id, section_id, /** @type {$moment['Moment']} */ dateM) =>
		querySsectionAttDaysInWeek({
			term_ids: [term_id],
			section_ids: [section_id],
			statsCols: ['term', 'section_history'],
			scope: { level: 'section_history', period: 'daily' },
			filters: [['term_id', term_id]],
			endFilters: [['week', $moment.toYmd_T0(dateM.startOf('week'))]],
		});

	EffectService.setup(this, ({ onInit }) => {
		if (onInit) {
			atomosService(TRFactory.trServiceAtom.stream$, this).stream$.subscribe((atom) => {
				const term = atom.selectedTerm;
				svm.term = term;
				const teacher = auth.getEngagedUserRole();
				svm.teacher = teacher.role_instance;
				const { section = null, section_histories = null } = TRFactory.getCachedTeacherSections(teacher).at(0);
				svm.section = section;
				if (section === null) return;
				$state.current.data.title = svm.section.sclass.class_level.title + ' (' + svm.section.title + ')';

				[svm.term_start_date, svm.term_end_date] = [term.start, term.end].map((d) => $moment(d).toDate());

				const dateSearchParam = $urlService.search()['date-iso']?.replace(/-[a-zA-z]+/, '');
				const dateIsoDate = $moment.isoToMoment($moment.isDate(dateSearchParam) ? dateSearchParam : $moment()).toDate();
				svm.attendance_date = getDateWithinTermBoundsFilter(svm.term, dateIsoDate);
				initializeAttendanceController(term);
				svm.updateAttendanceDate(svm.attendance_date, false);
			});

			atomosService(sectionAttDailyStatsInTerm$, this).stream$.subscribe(({ data, error, loading }) => {
				svm.attDailyStatsInTermLoading = loading;

				if (!data || ErrorService.handleError(error)) return;

				const _data = data.plain();

				const startM = $moment(svm.term_start_date);
				const termDaysMax = Math.abs(startM.diff($moment(svm.term_end_date), 'days'));
				const sectionTermAttDailyMap = Array(termDaysMax)
					.fill(null)
					.map((_, i) => {
						const day = startM.clone().add(i, 'days');
						const key = $moment.toYmd(day);
						const dayT0 = $moment.toYmd_T0(day);
						const attEntry = _data.find((d) => d.day === dayT0) ?? {};
						return [key, attEntry];
					});

				vm.attendanceDaysList = Object.fromEntries(sectionTermAttDailyMap);
				svm.isTaken = /** @type {Attendance} */ (vm.attendanceDayList?.[$moment.toYmd(svm.attendance_date)])?.term_section_histories_daily;
				console.log({ sectionTermDays: sectionTermAttDailyMap });
			});

			atomosService(sectionAttDaysInWeek$, this).stream$.subscribe(({ data, error, loading }) => {
				svm.attDailyStatsInWeekLoading = loading;
				//get the days in the week

				if (!data || ErrorService.handleError(error)) return;

				const att_stats_daily_this_week = data.plain();

				_getSectionHistories().then((section_histories) => {
					svm.section_histories = section_histories.map((sh) => {
						sh.attendance_state =
							att_stats_daily_this_week.find((atdw) => atdw.section_history_id === sh.id && atdw.day === $moment.toYmd_T0(svm.attendance_date)) ?? DEFAULT_STATE;
						sh.attendance_state.title = sh.attendance_state.title ?? sh.attendance_state.status[0];
						const stats = att_stats_daily_this_week.find((atdw) => atdw.section_history_id === sh.id);
						sh.attendance_summaries_info = ['present', 'absent', 'late'].map((st) => `${st.toUpperCase()[0]}:${stats?.[`section_history_${st}_termly`] ?? 'N/A'}`).join(' | ');
						return sh;
					});
				});
			});
		}
	});

	function focusDateInList(delay = 500) {
		$timeout(() => {
			const dateItemHtmlRefQuery = '#date-listitem-' + $moment.toYmd(svm.attendance_dateM);
			const foundDateHtmlRef = $window.document.querySelector(dateItemHtmlRefQuery);
			console.log({ dateItemHtmlRefQuery, foundDateHtmlRef });
			foundDateHtmlRef?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
		}, delay);
	}

	svm.setStudentsReason = (students, reason) => {
		svm.section_histories = students.map((stu) => {
			stu.attendance_state.reason = reason;
			return stu;
		});
	};

	svm.$on('attendance.setall', (event, state) => {
		process_students_for_attendance(svm.section_histories, { ...state, reason: svm.reason });

		/**
		 * @type {SectionHistory[]}
		 */
		const section_histories = svm.section_histories;
		section_histories.forEach((sh) => svm.attendance_updates_map.set(sh.id, sh));
	});

	/**
	 * @descript attendance_count is an index used to retrieve the attendance to place on a student
	 * @description attendance_state is the attendance object of a student
	 * @param {{section_histories: (SectionHistory & {attendance_state: typeof svm.states, attendance_count})[], state}} param0
	 * @param {event} ev
	 */
	svm.setAttendance = function ({ section_histories = [...svm.section_histories], state }, ev) {
		ev?.stopPropagation();

		for (const section_history of section_histories) {
			section_history.attendance_state = { ...state };

			svm.attendance_updates_map.set(section_history.id, section_history);
		}

		svm.section_histories = svm.section_histories.map((student) => svm.attendance_updates_map.get(student.id) ?? student);
	};

	svm.findState = (state) => {
		console.log(state);
		return svm.states.find(({ order }) => state.order === order);
	};

	svm.findNextState = (current_state) => {
		console.log(current_state);
		return svm.findState({ order: ((current_state?.order ?? 0) % svm.states.length) + 1 });
	};

	function process_individual_students_for_attendance(/** @type {SectionHistory} */ sh, /** @type {AttendanceState} */ obj) {
		sh.attendance_state = { ...obj };
		switch (obj.title) {
			case 'P':
				sh.attendance_count = 0;
				break;
			case 'A':
				sh.attendance_count = 1;
				break;
			case 'T':
				sh.attendance_count = -1;
				break;
			case 'L':
				sh.attendance_count = -1;
				break;
			case 'N':
				sh.attendance_count = -1;
				break;
			default: {
				noop();
			}
		}
	}

	function process_students_for_attendance(students, obj) {
		for (const student of students) {
			process_individual_students_for_attendance(student, obj);
		}
	}

	svm.closeAttendanceSheet = () => {
		Promise.resolve(svm.attendance_updates_map)
			.then((attendance_updates_map) => {
				return !attendance_updates_map.size
					? true
					: DialogService.dispatch('confirm', 'Discard attendance updates and exit', 'Unsaved attendance updates').then(() => {
							attendance_updates_map.clear();
							svm.page = 'home';

							svm.goneBack = true;
							svm.show_attendance_sheet = false;
					  });
			})
			.then((res) => {
				if (!res) return;
				svm.isReady = true;
				svm.show_attendance_sheet = false;
				svm.page = 'home';
				focusDateInList();
			});
	};

	function showAttendanceSheet(date = $moment(svm.attendance_date).format(EXPECTED_DATE_FORMAT)) {
		svm.show_attendance_sheet = true;

		svm.attendance_updates_map.clear();

		focusDateInList();

		return;
	}

	svm.showAttendanceSheet = showAttendanceSheet;

	/**
	 * @typedef {{id:string}} SectionHistory
	 * @returns {Promise<SectionHistory[]>} alias to vm.students
	 */
	const _getSectionHistories = async () =>
		Promise.resolve(
			svm.section_histories ?? TRFactory.fetchTermDetails(svm.term.id).then((ct) => ct.sections[svm.section.id]?.section_histories.filter((sh) => sh.term_id === svm.term.id))
		);
	svm.auto_cap = function () {
		$mdDialog.show({
			clickOutsideToClose: true,
			escapeToClose: true,
			templateUrl: 'views/pages/roles/tr/class/dialogs/auto_capture.html',
			controller: AutoCaptureAttendanceDialogController,
		});
	};

	svm.showBottomSheet = function () {
		$mdBottomSheet.show({
			template: '<attendance-assign-multiple-cmp states="$ctrl.states" on-click="$ctrl.onClick(value)" scope="$ctrl"></attendance-assign-multiple-cmp>',
			bindToController: true,
			controllerAs: '$ctrl',
			controller: 'DumbBottomSheetController',
			locals: {
				states: svm.states,
				onClick: (value) => svm.setAttendance({ state: value }),
			},
		});
	};

	/**
	 *
	 * @param {Term} term - is a term_id
	 */
	function initializeAttendanceController(term) {
		if (!term) return;

		try {
			svm.attendance_date = getDateWithinTermBoundsFilter(term, svm.attendance_date);

			/**
			 * @type SectionHistory
			 * @param SectionHistory students
			 */
			_getSectionHistories().then((section_histories) => {
				if (!section_histories || !section_histories.length) {
					$mdToast.showSimple('No students enrolled, exiting...');
					return $state.go('tr.home.dashboard');
				}

				return section_histories;
			});
		} catch (error) {
			console.log('attendance setup failed', error);
			ErrorService.handleError(error);
		}
	}

	svm.toggleHeatMap = (show_heatmap) => {
		if (show_heatmap && !svm.term_heatmaps) svm.refreshHeatMap(svm.section_histories);
	};

	/**
	 * @description month_group|month_collection [date_group|date_collection [attendance,,,,]]
	 * @param {SectionHistory[]} section_histories
	 */
	svm.refreshHeatMap = async (section_histories) => {
		svm.loadingHeatmap = true;
		svm.isProcessing = true;
		const term_info = svm.term.id;

		if (!term_info) {
			$mdToast.showSimple('No term selected');
			return;
		}

		const attendances_list = await Restangular.all('attendance/get')
			.post({ shids: section_histories.map(({ id }) => ({ id })) })
			.then((res) => res.plain())
			.finally(() => (svm.isProcessing = false));

		// group by date
		const date_groups = new Map();

		attendances_list.forEach((attendance) => {
			/** @type Set  */
			const date_group = date_groups.get(attendance.date) ?? new Set();
			date_group.add(attendance);
			date_groups.set(attendance.date, date_group);
		});

		// group dates into months
		const month_groups = new Map();

		date_groups.forEach((date_group) => {
			const month_label = $moment([...date_group.values()][0].date, EXPECTED_DATE_FORMAT).format('YYYY-MM');

			/** @type Set*/
			const month_group = month_groups.get(month_label) ?? new Set();

			const _data_collection = [...date_group.values()];

			const _regex = new RegExp('present', 'i');
			month_group.add({
				date: _data_collection[0].date,
				total: _data_collection.filter((attendance) => (attendance.status + '').match(_regex)).length,
				details: _data_collection,
			});

			month_groups.set(month_label, month_group);
		});

		$timeout(() => {
			svm.term_heatmaps = [...month_groups.values()].flatMap((month_set) => [...month_set.values()]);

			svm.loadingHeatmap = false;

			console.log('month_groups is ready', svm.term_heatmaps);
		});
	};

	svm.getDateItemClasses = (idxDate_str, chosen_attendance_str) => {
		const _dateM = $moment.isoToMoment(idxDate_str);
		const res = [_dateM.isAfter($moment(), 'date') && 'date-in-future text-muted', _dateM.isSame(svm.attendance_dateM, 'date') && 'bg-cyan date-selected'];
		return res;
	};

	svm.isWeekend = (date_str) => /sat|sun/i.test($moment(date_str).format('-ddd').toLocaleLowerCase());

	svm.updateAttendanceDate = (/** @type {Date}*/ date, sub = false) => {
		console.log('updateAttendanceDate', { date, sub });
		try {
			svm.attendance_date = date;
			svm.attendance_dateM = $moment.isoToMoment(date);
			svm.date_is_today = svm.attendance_dateM.isSame($moment(), 'day');

			$location.search('date-iso', $moment.toYmd_ddd($moment(date))).replace();

			if (sub) {
				svm.showAttendanceSheet();
			}

			Promise.allSettled([
				querySectionAttDailyStatsInWeekMediator(svm.term.id, svm.section.id, $moment(date).startOf('week')),
				querySectionAttDailyStatsInTermMediator(svm.term.id, svm.section.id),
			])

				.then(() => {
					focusDateInList();
				})
				.catch(ErrorService.handleError);
		} catch (error) {
			ErrorService.handleError(error);
		}
	};

	svm.isDateInFuture = (dateStr) => $moment(dateStr).isAfter($moment('today'));

	svm.toMoment = (date) => $moment(date);
}

trClassSubAttendance.controller = trClassSubAttendanceCmpController;

appModule.component('trClassSubAttendance', trClassSubAttendance);

trClassSubAttendance.selector = 'trClassSubAttendance';

export { trClassSubAttendance };

import template from './index.html';

/** @type {import('@srcAjs/components2/adminRoutes').IComponentOptions2} */
const saGuardiansImport = {
	template,
	bindings: {
		//someInput: '@string,<object,&function'
	},
	transclude: {
		// #slotName: '?optionalPublicName'
	},
};

function saGuardiansImportCmpController(
	SAFactory,
	Restangular,
	$scope,
	$cookies,
	$mdToast,
	$state,
	$mdDialog,
	lsfactory,
	$rootScope,
	$localStorage,
	editableThemes,
	editableOptions,
	CONST_GUARDIAN_CSV_URL
) {
	SAFactory.loadEnvironment();
	SAFactory.loadNotifications();
	$scope.downloadTemplate = function () {
		$.fileDownload(CONST_GUARDIAN_CSV_URL).then(null, function (err) {
			var template = $mdToast.simple().textContent('Unable to start download').action('OK').highlightAction(true).hideDelay(5500);
			$mdToast.show(template);
		});
	};
	editableThemes.bs3.inputClass = 'input-sm';
	editableThemes.bs3.buttonsClass = 'btn-sm';
	editableOptions.theme = 'bs3';

	$scope.$on('guardian_import_over', function (event, useless) {
		$scope.backHome();
	});

	$scope.init = function () {
		$scope.isReady = true;
		var handleFileSelect = function (evt) {
			var fileTypes = ['csv'];
			var file = evt.currentTarget.files[0];
			var extension = file.name.split('.').pop().toLowerCase(),
				isSuccess = fileTypes.indexOf(extension) > -1;
			console.dir(isSuccess);
			if (isSuccess) {
				var reader = new FileReader();
				reader.onload = function (evt) {
					var csv = evt.target.result;
					var data = $.csv.toArrays(csv);
					$scope.$apply(function ($scope) {
						$scope.list = data.map((line) => {
							console.log(line);
							// let result = line[0];
							if (Array.isArray(line) && line.length == 1 && line.length && !!line[0].match('|'))
								//check that line contains |
								return line[0].split('|'); // split line by |
							console.log('no pipe');
							return line;
						});
						$scope.removeRow(0); // remove header
					});
				};
				reader.readAsText(file);
			} else {
				var template = $mdToast.simple().textContent('File format unsupported').action('OK').highlightAction(true).hideDelay(5500);
				$mdToast.show(template);
			}
		};
		angular.element(document.querySelector('#fileInput')).on('change', handleFileSelect);
	};

	function showConfigDialog() {
		// Title	First Name	Last Name	Gender	Email	Phone	Password	Profession

		let data = $scope.list.map(([salutation, firstname, lastname, gender, email, phone, password, profession]) =>
			Object.assign({}, { salutation, firstname, lastname, gender, email, phone, password, profession })
		);

		lsfactory.setEnv('dialogUpGuardianList', data);
		$mdDialog.show({
			escapeToClose: true,
			clickOutsideToClose: true,
			templateUrl: 'views/pages/roles/sa/guardians/dialogs/config_import.html',
			controller: ConfigImportGuardiansDialogController,
		});
	}

	function noDummies(list) {
		for (var i = 0; i < list.length; i++) {
			matchString = 'dummy';
			for (var j = 0; j < list[i].length; j++) {
				if (list[i][j].toLowerCase().indexOf(matchString) != -1) {
					return true;
				}
			}
		}
		return false;
	}

	$scope.upload = function () {
		if (noDummies($scope.list)) {
			var confirm = $mdDialog
				.confirm()
				.title('Might Contain "dummy" data')
				.textContent('You input file might contain unwanted dummy entries. Do you still want to continue with upload')
				.ariaLabel('Warning')
				.ok('Yes please!')
				.cancel('No, I dont think so');
			$mdDialog.show(confirm).then(
				function () {
					showConfigDialog();
				},
				function () {}
			);
		} else {
			showConfigDialog();
		}
	};

	$scope.saveItem = function (data, id) {
		angular.extend(data, { id: id });
	};
	$scope.backHome = function () {
		$scope.list = undefined;
	};
	$scope.list = undefined;
	$scope.removeRow = function (index) {
		$scope.list.splice(index, 1);
	};
	$scope.addRow = function () {
		$scope.inserted = {
			id: $scope.list.length + 1,
			salutation: '',
			firstname: '',
			lastname: '',
			profession: '',
			gender: '',
			phone: '',
			status: null,
			group: null,
		};
		$scope.list.push($scope.inserted);
	};
}

saGuardiansImport.controller = saGuardiansImportCmpController;

appModule.component('saGuardiansImport', saGuardiansImport);

saGuardiansImport.selector = 'saGuardiansImport';

export { saGuardiansImport };

import template from './ChartAssessmentCmp.tpl.html';

/** @type {import('@srcAjs/components2/adminRoutes').IComponentOptions2} */
const chartAssessmentCmp = {
	template,
	bindings: {
		//someInput: '@string,<object,&function'
		subjectMount: '<',
	},
	transclude: {
		// #slotName: '?optionalPublicName'
	},
	selector: 'chartAssessmentCmp',
};

/** @this {ng.IController} */
function chartAssessmentCmpController(
	/** @type {EffectService} */ EffectService,
	/** @type {compSetupService} */ compSetupService,
	/** @type {TRFactory} */ TRFactory,
	/** @type {atomosService} */ atomosService,
	/** @type {ErrorService} */ ErrorService
) {
	compSetupService(this, chartAssessmentCmp.bindings);
	const vm = this;
	vm.itemHals = [];
	const [gb$, { mutate }] = TRFactory.gradebookRxQ(this);

	EffectService.setup(this, ({ onInit, onChanges }) => {
		// fetch class gradebook
		if (onInit) {
			if (this.subjectMount?.subject_assessment_groups) {
				this.itemHals = getSubjectMountEntityHals(this.subjectMount);
				return;
			}

			atomosService(gb$, this).stream$.subscribe(({ data, error, loading }) => {
				this.loading = loading;
				if (error) return ErrorService.handleError(error);

				if (!data) return;

				const assessmentHals = getSubjectMountEntityHals(data.assessment_data);

				// computeAssessmentHAL

				this.itemHals = assessmentHals;
				console.log('halsReady', { assessmentsHal: assessmentHals });
			});
		}

		if (onChanges) {
			if (this.subMount?.subject_assessment_groups) return;
			mutate(this.subjectMount);
		}
	});
}

chartAssessmentCmp.controller = chartAssessmentCmpController;

appModule.component('chartAssessmentCmp', chartAssessmentCmp);

export { chartAssessmentCmp };

function getSubjectMountEntityHals(/** @type {Partial<SubjectMount> & {subject_assessment_groups: SubjectAssessmentGroup[]}} */ data) {
	return data.subject_assessment_groups.flatMap((ag) =>
		ag.subject_assessment_entities.map((ae) => {
			const a_scores = ae.subject_assessment_scores;
			const values = a_scores.map((s) => (Number.isFinite(+s.fractional_score) ? s.fractional_score : 0) * 100 ?? 0);
			const sum = Number(values.reduce((acc, s) => acc + s, 0)).toFixed(2);

			const chartData = {
				values,
				sum,
				max: 100,
				high: Number('' + (Math.max(...values) ?? 0)).toFixed(2),
				low: Number('' + (Math.min(...values) ?? 0)).toFixed(2),
				avg: Number('' + sum / (values.length > 0 ? values.length : -1)).toFixed(2),
				label: `${ae.title}/${ag.title}`,
			};

			ae['chartData'] = chartData;

			return ae;
		})
	);
}

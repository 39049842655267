import template from './index.html';

/** @type {import('@srcAjs/components2/adminRoutes').IComponentOptions2} */
const saSchoolSetupYearsSubTerms = {
	bindings: {},
	template,
};



function saSchoolSetupYearsSubTermsCmpController(SAFactory, $scope, $state, ErrorService, $mdDialog, $mdToast, lsfactory, Restangular, auth, Term, TermType) {
	SAFactory.loadEnvironment();
	SAFactory.loadNotifications();
	$scope.sel_year = lsfactory.getEnv('currentYearSelected');
	$state.current.data.title = $scope.sel_year.title;
	$scope.terms = [];
	$scope.dots = ['...'];
	$scope.types = [];
	$scope.forget_list = [];
	$scope.isLoading = true;
	$scope.t_count = undefined;
	$scope.no_need_to_add_terms = true;
	$scope.isReady = false;

	TermType.getList().then(function (data) {
		$scope.types = data.plain();
	});

	$scope.$on('system.sa.environmentChanged', function (ev, data) {
		$scope.isLoading = true;
		if ($scope.sel_year !== null) {
			Restangular.one('year', $scope.sel_year.id)
				.all('terms')
				.getList()
				.then(function (data) {
					console.dir(data);
					$scope.isLoading = false;
					var list = data.plain(); //cant explain why [0] is needed, check the api
					$scope.t_count = list.length;
					$scope.terms = list;
					$scope.forget_list = [];
					TermType.getList().then(function (data) {
						$scope.types = data.plain();

						let previous_term_types = list.map((term) => (term.term_type.title + '').toLowerCase());
						$scope.types = $scope.types.map((term_type) => {
							term_type.disable = previous_term_types.includes((term_type.title + '').toLowerCase());
							return term_type;
						});

						$scope.no_need_to_add_terms = $scope.terms.length == $scope.types.length;

						$scope.isReady = true;
					});
				});
		}
	});

	$scope.newTerm = function () {
		$mdDialog.show({
			escapeToClose: true,
			clickOutsideToClose: true,
			locals: {
				termYear: $scope.sel_year,
				termTypes: $scope.types,
				sub: true,
				sadmin_id: auth.getEngagedUserRole().role_instance_id,
			},
			bindToController: true,
			controllerAs: '$ctrl',
			autoWrap: false,
			controller: 'DumbController',
			template: `<create-term-cmp layout layout-align="center"
						flex="95" flex-gt-md="45" sadmin-id="$ctrl.sadmin_id" 
						term-types="$ctrl.termTypes" 
						term-year="$ctrl.termYear" sub="$ctrl.sub">
					</create-term-cmp>`,
		});
	};

	// $scope.cloneOldAcademicYearConfig = function(new_term){
	//   var confirm = $mdDialog.confirm()
	//           .title('Would you like to setup this academic year with data from the previous academic yearr "'+term.academic_year.title+'" now?')
	//           .textContent('Please ensure the last term from previous year is marked as active')
	//           .ariaLabel('Warning')
	//           .ok('Yes please!')
	//           .cancel('I dont think so');
	//         $mdDialog.show(confirm).then(function() {
	//         Restangular.all('years').customPUT({'new_term_id':term.id,'new_academic_year_id':term.academic_year.id}).then(function(){
	//           SAFactory.loadEnvironment();
	//
	//         });
	//       }, function() {
	//
	//       });
	//
	// };

	$scope.remove = function (term, $event) {
		$event.stopPropagation();
		var confirm = $mdDialog
			.confirm()
			.title('Would you like to delete "' + term.term_type.title + '" now?')
			.textContent('This operation cannot be undone. All dependants of this academic term will also be removed also.')
			.ariaLabel('Warning')
			.ok('Yes please!')
			.cancel('I dont think so');
		$mdDialog.show(confirm).then(function () {
			Term.one(term.id)
				.remove()
				.then(function () {
					SAFactory.loadEnvironment();
					$mdToast.showSimple('Term deleted');
				}, ErrorService.handleError);
		});
	};

	$scope.view = function (term) {
		if (term !== undefined) {
			lsfactory.setEnv('currentTermSelected', term);
			$state.go('sa.school_setup_terms_detail.new_mounted', { yid: $scope.sel_year.id, tid: term.id, term });
		}
	};

	$scope.edit = function (term) {
		lsfactory.setEnv('termYear', $scope.sel_year);
		lsfactory.setEnv('termEdit', angular.copy(term));
		$mdDialog.show({
			escapeToClose: false,
			clickOutsideToClose: false,
			templateUrl: 'views/pages/roles/sa/dialogs/edit-term-template.html',
			controller: EditTermDialogController,
		});
	};
}
saSchoolSetupYearsSubTerms.controller = saSchoolSetupYearsSubTermsCmpController;

appModule.component('saSchoolSetupYearsSubTerms', saSchoolSetupYearsSubTerms);

saSchoolSetupYearsSubTerms.selector = 'saSchoolSetupYearsSubTerms';

export { saSchoolSetupYearsSubTerms }

import { createSlice } from './src/store.mjs';

/** @typedef {typeof initialState} AuthState */

/** @type {{token: undefined|string, user:undefined|UserWithMeta,engagedUserRole:EngagedUserRole|undefined,locked:undefined|boolean}} */
const initialState = {
	token: undefined,
	user: undefined,
	engagedUserRole: undefined,
	locked: undefined,
};

export default createSlice({
	name: /** @type {const} */ ('auth'),
	initialState,
	reducers: {
		setToken(state, /** @type {string} */ token) {
			return { ...state, token };
		},
		setUser(state, /** @type {UserWithMeta} */ user) {
			return { ...state, user };
		},
		setEngagedUserRole(state, /** @type {EngagedUserRole} */ engagedUserRole) {
			return { ...state, engagedUserRole };
		},
		setLock(state, /** @type {boolean|undefined} */ locked = false) {
			return { ...state, locked };
		},
		replace(_state, /** @type {initialState} */ newState) {
			return { ...newState };
		},
	},
});

import * as rxjs from 'rxjs';

/** @template O @typedef {O extends rxjs.Observable<infer T>?T:unknown} RxObservedValue] */

/** @typedef {typeof rxjs} RxService */
function _RxService() {
	return rxjs;
}

appModule.factory('RxService', _RxService);

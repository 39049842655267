import template from './index.html';

/** @type {import('@srcAjs/components2/adminRoutes').IComponentOptions2} */
const saSchoolSetupYearsToolbarTerm = {
	template,
	bindings: {
		//someInput: '@string,<object,&function'
	},
	transclude: {
		// #slotName: '?optionalPublicName'
	},
};

/** @this {ng.IController} */
function saSchoolSetupYearsToolbarTermController(SAFactory, $scope, $rootScope, $mdDialog, lsfactory, Restangular, Term) {
	$scope.term = lsfactory.getEnv('currentTermSelected');
	$scope.years = [];
	$scope.school = lsfactory.getEnv('school');
	$scope.$on('system.sa.environmentChanged', function (ev, data) {
		$scope.term = lsfactory.getEnv('currentTermSelected');
	});

	var r_years = Restangular.all('year/by-school/' + $scope.school.id);
	r_years.getList().then(function (data) {
		var list = data.plain();
		$scope.years = list;
		_.each($scope.years, function (y) {
			_.each(y.terms, function (term) {
				term.disabled = term.id === $scope.term.id;
			});
		});
	});

	$scope.$on('system.selected_term_status_changed', function (event, data) {
		$scope.term.status = data;
	});

	$scope.edit = function (term) {
		lsfactory.setEnv('termYear', lsfactory.getEnv('currentYearSelected'));
		lsfactory.setEnv('termEdit', angular.copy(term));
		$mdDialog.show({
			escapeToClose: false,
			clickOutsideToClose: false,
			templateUrl: 'views/pages/roles/sa/dialogs/edit-term-template.html',
			controller: EditTermDialogController,
		});
	};

	$scope.$on('dialog_events.show_copy_term', function (event, data) {
		$scope.copy_term($scope.term);
	});

	$scope.copy_term = function (term) {
		// $mdDialog.show(
		//     $mdDialog.alert()
		//         .clickOutsideToClose(true)
		//         .title('Feature Pending')
		//         .textContent('This feature is still under development')
		//         .ariaLabel('Feature Pending')
		//         .ok('Got it!')
		// );
		lsfactory.setEnv('termYear', lsfactory.getEnv('currentYearSelected'));
		lsfactory.setEnv('cmbTerms', lsfactory.getEnv('currentYearSelected').terms);
		lsfactory.setEnv('cmbYearsList', $scope.years);
		lsfactory.setEnv('termToCopy', angular.copy(term));
		$mdDialog.show({
			escapeToClose: false,
			clickOutsideToClose: false,
			templateUrl: 'views/pages/roles/sa/school_setup/years/dialogs/copy_term_source_target_dialog.html',
			controller: CopyTermSourceTargetDialogController,
		});
	};

	$scope.import_term = function (term) {
		if ($scope.term.section_histories_count > 0) {
			var confirm = $mdDialog
				.confirm()
				.title('Term already has some data. Are you sure?')
				.textContent('This term has ' + $scope.term.section_histories_count + ' enrolled students. This may cause a problem with import ... Do you want to proceed.')
				.ariaLabel('Warning')
				.ok('Yes please!')
				.cancel('I dont think so');
			$mdDialog.show(confirm).then(
				function () {
					lsfactory.setEnv('termYear', lsfactory.getEnv('currentYearSelected'));
					lsfactory.setEnv('cmbTerms', lsfactory.getEnv('currentYearSelected').terms);
					lsfactory.setEnv('termToUse', angular.copy(term));
					$mdDialog.show({
						escapeToClose: false,
						clickOutsideToClose: false,
						templateUrl: 'views/pages/roles/sa/school_setup/years/dialogs/import_term_config_dialog.html',
						controller: ImportTermConfigDialogController,
					});
				},
				function (err) {}
			);
		} else {
			lsfactory.setEnv('termYear', lsfactory.getEnv('currentYearSelected'));
			lsfactory.setEnv('cmbTerms', lsfactory.getEnv('currentYearSelected').terms);
			lsfactory.setEnv('termToUse', angular.copy(term));
			$mdDialog.show({
				escapeToClose: false,
				clickOutsideToClose: false,
				templateUrl: 'views/pages/roles/sa/school_setup/years/dialogs/import_term_config_dialog.html',
				controller: ImportTermConfigDialogController,
			});
		}
	};

	$scope.remove = function (term) {
		var confirm = $mdDialog
			.confirm()
			.title('Would you like to delete "' + term.term_type.title + '"?')
			.textContent('This operation cannot be undone. All dependants of this academic term will also be removed also.')
			.ariaLabel('Warning')
			.ok('Yes please!')
			.cancel('I dont think so');
		$mdDialog.show(confirm).then(
			function () {
				Restangular.one('term', term.id)
					.remove()
					.then(function () {
						SAFactory.loadEnvironment();
						$rootScope.goBack();
					});
			},
			function (err) {}
		);
	};

	$scope.importTermV2 = (term) => {
		if (term.term_type.version != '1.2') {
			let _alert = $mdDialog.alert({
				title: 'Illegal Operation on Version 1 term',
				textContent: `Import is only supported a term that is version 1.2, this term's version is ${term.term_type.version}. Create a version 1.2 term and try again or contact support`,
				ok: 'Got it',
			});

			$mdDialog.show(_alert);
			return;
		}

		$mdDialog.show({
			locals: {
				term,
				school: $scope.school,
			},
			autoWrap: false,
			bindToController: true,
			controllerAs: '$ctrl',
			controller: 'DumbController',
			template: '<term-import-cmp destination-term="$ctrl.term" school="$ctrl.school" layout-fill></term-import-cmp>',
		});
	};
}

saSchoolSetupYearsToolbarTerm.controller = saSchoolSetupYearsToolbarTermController;

appModule.component('saSchoolSetupYearsToolbarTerm', saSchoolSetupYearsToolbarTerm);

saSchoolSetupYearsToolbarTerm.selector = 'saSchoolSetupYearsToolbarTerm';

export { saSchoolSetupYearsToolbarTerm };

import template from './index.html';

/** @type {import('@srcAjs/components2/adminRoutes').IComponentOptions2} */
const saTeachersCreate = {
	template,
	bindings: {
		//someInput: '@string,<object,&function'
	},
	transclude: {
		// #slotName: '?optionalPublicName'
	},
};



function saTeachersCreateCmpController(
	SAFactory,
	$scope,
	$mdToast,
	$mdDialog,
	$cookies,
	Restangular,
	$state,
	$rootScope,
	$localStorage,
	$timeout,
	/** @type {import("restangular").IService} */ Teacher,
	/** @type {lsfactory} */ lsfactory,
	SubjectType,
	/** @type {auth} */ auth,
	/** @type {ErrorService} */ ErrorService
) {
	//   SAFactory.loadEnvironment();
	const vm = $scope;
	SAFactory.loadNotifications();
	vm.done = false;
	vm.env = $localStorage.environment;

	vm.isReady = true;

	vm.selectedItem = null;
	vm.searchText = null;
	vm.subjects = [];
	vm.new_teacher = {}; // this is the newly created teacher
	vm.selectedSubjects = [];
	vm.autocompleteDemoRequireMatch = true;
	vm.clearForm = clearForm;

	clearForm(); // call clearForm on component load

	vm.transformChip = function (chip) {
		if (angular.isObject(chip)) {
			return chip;
		}
		return { title: chip };
	};

	vm.querySearch = function (query, subjects = vm.subjects) {
		const results = query ? subjects.filter(createFilterFor(query)) : [];
		return results;
	};

	function createFilterFor(/** @type {string} */ query) {
		const lowercaseQueryRegEx = new RegExp(query, 'i');

		return (/** @type {Subject} */ { title }) => lowercaseQueryRegEx.test(title);
	}

	SubjectType.getList().then((data) => {
		const list = data.plain();
		vm.subjects = list;
	}, ErrorService.handleError);

	vm.checkboxVal = {
		variable: false,
		unique: true,
	};

	vm.viewTeacher = function () {
		lsfactory.setEnv('currentTeacherSelected', vm.new_teacher);

		$state.go('sa.teachers_detail.assigned_subjects', { id: vm.new_teacher.id });
	};

	vm.check = function () {
		$timeout(() => {});
	};

	vm.sals = ['Mr.', 'Mrs.', 'Ms.', 'Dr.', 'Prof.', 'Eld.', 'Rev.'];
	vm.gends = ['Male', 'Female'];
	vm.ms = ['Single', 'Married', 'Divorced'];

	vm.myCroppedImage = '';
	vm.cropType = 'circle';
	vm.image_set = false;

	function clearForm() {
		vm.box = {
			salutation: 'Mr.',
			gender: 'Male',
			marital_status: 'Single',
			school_id: auth.getEngagedUserRole().school_id,
			sadmin_id: auth.getEngagedUserRole().role_instance_id,
		};

		vm.user = {
			send_sms: true,
			phone: null,
		};
		vm.selectedSubjects = [];
		cancelImage();
		vm.new_teacher = null;
		vm.done = true;
	}

	function cancelImage() {
		vm.myImage = '';
		$rootScope.myImage = undefined;
		vm.myCroppedImage = '';
		vm.image_set = false;
	}

	vm.cancelImage = cancelImage;

	vm.init = function () {
		const handleFileSelect = function (/** @type {Event} */ evt) {
			const [file] = evt.currentTarget.files;
			const reader = new FileReader();
			reader.onload = function (evt) {
				vm.$apply(($scope) => {
					vm.myImage = evt.target.result;
					vm.image_set = true;
				});
			};
			reader.readAsDataURL(file);
		};
		angular.element(document.querySelector('#fileInput')).on('change', handleFileSelect);
	};

	vm.browseTeachers = function () {
		$state.go('sa.teachers_browse');
	};

	vm.createTeacher = function () {
		vm.done = false;
		vm.user.image = vm.myCroppedImage;
		vm.user.is_set = vm.image_set;
		const birthdate = moment(vm.box.birthdate).format('YYYY-MM-DD');
		const data = {
			user: vm.user,
			teacher: { ...vm.box, birthdate },
			subjects: vm.selectedSubjects,
		};

		Teacher.post(data).then(
			(res) => {
				const data = res.plain();
				vm.new_teacher = data.teacher;
				if (data.status == 'success') {
					vm.done = true;
				}
			},
			(err) => {
				vm.done = true;

				vm.selected = 1;
				vm.step1.disabled = false;
				vm.step1.completed = false;
				vm.step2.disabled = false;
				vm.step2.completed = false;

				ErrorService.handleError(err);
			}
		);
	};

	vm.updateCountry = (country) => {
		vm.box.code = country?.iso_3166_2;
		vm.box.country_id = country?.id;
		vm.box.country = country;
	};
}

saTeachersCreate.controller = saTeachersCreateCmpController;

appModule.component('saTeachersCreate', saTeachersCreate);

saTeachersCreate.selector = 'saTeachersCreate';

export { saTeachersCreate }

import template from './index.html';

/** @type {import('@srcAjs/components2/adminRoutes').IComponentOptions2} */
const saTeacherSubjectsAndClasses = {
	template,
	bindings: {
		// someInput: '@string,<object,&function'
		teacher: '<',
		term: '<',
	},
	transclude: {
		// #slotName: '?optionalPublicName'
	},
	selector: '',
};

/**
 * @this { ng.IController & {subMounts: (Section&{section:Section})[],  classes: Partial<SClass>[],departments:{title}[]} }
 * @prop {} classes
 * @prop {} departments
 * @prop {} studentsCount
 * @prop {} subMounts
 * @prop {Teacher} teacher
 */
function saTeacherSubjectsAndClassesController(
	/** @type {API}*/ API,
	/** @type {ErrorService} */ ErrorService,
	/** @type {EffectService} */ EffectService,
	/** @type {compSetupService} */ compSetupService,
	/** @type {atomosService} */ atomosService
) {
	compSetupService(this);
	const vm = this;
	vm.subMounts = undefined;

	const fields = /**  @type {const} @satisfies {API_Params['fields']} */ ([
		{ field: 'section.sclass.class_level' },
		{ field: 'section.sclass.class_group.class_group_type' },
		{ field: 'section_histories' },
		{ field: 'section.teacher' },
		{ field: 'subject' },
		{ field: 'term.term_type' },
		{ field: 'term.year' },
	]);

	const schoolsAtom = atomosService(API.rxQueryUtils(JSON.stringify(['schools', null]))?.stream$, this);

	const [teacherStream$, { mutate, revalidate }] = API.rxQuery(
		['rxq.subject-mounts.teacher'],
		(r, /** @type {{teacher:Teacher,term:Term}} */ { teacher, term, loadAll = false }) =>
			/** @type {Restangular.ICollectionPromise<( SubjectMount &ApiFieldsToPath<fields>[] ) >} */ r
				.all(
					'subject-mount?' +
						API.buildParams_v2({
							fields,
							filters: [{ condition: 'where', column: 'teacher_id', value: teacher.id }, ...(!loadAll ? [{ condition: 'where', column: 'term_id', value: term.id }] : [])],
						})
				)
				.getList(),
		{
			enabled: false,
			qInput$: undefined,
			destroy$: this.componentDestroy$,
			onError: ({ error }) => ErrorService.handleError(error),
			onSuccess: ({ data }) => data && vm.onResponse(data),
		}
	);

	vm.revalidate = revalidate;
	vm.doSubjectSchemeChange = (data) => (vm.orderSubjectScheme = data);

	const atomTrStream$ = atomosService(teacherStream$, this);
	atomosService((get) => {
		const { loading = null } = get(atomTrStream$) ?? {};
		vm.loading = loading;
	}, this);

	EffectService.setup(
		vm,
		({ onChanges }) => {
			if (onChanges) {
				vm.school = this.teacher.school ?? schoolsAtom?.get()?.data?.find((sch) => sch.id === this.teacher.school_id) ?? { id: this.teacher.school_id };
				mutate({ teacher: this.teacher, term: this.term });
			}
		},
		() => [this.teacher, this.term]
	);

	vm.onResponse = (res) => {
		vm.subMounts = res;

		vm.classes = [
			...new Map(vm.subMounts.map((s) => ({ ...s.section.sclass.class_level, teacher: s.section.teacher, term: s.term, section: s.section })).map((s) => [s.id, s])).values(),
		];
		vm.sections = [...new Set(vm.subMounts.map(({ term, section }) => ({ ...section, term, term_id: section.term_id ?? term.id })))];
		vm.departments = [...new Map(vm.subMounts.map((c) => c.section.sclass.class_group.class_group_type).map((d) => [d.id, d])).values()];
		vm.studentsCount = [...new Map(vm.subMounts.map((sm) => [sm.id, sm.section_histories.length])).values()].reduce((acc, item) => acc + item, 0);
		vm.terms = [...new Map(vm.subMounts.map(({ term }) => [term.id, term]))];
	};

	vm.onLoadAllSubMounts = (loadAll) => {
		mutate({ teacher: this.teacher, term: this.term, loadAll });
	};
}

saTeacherSubjectsAndClasses.controller = saTeacherSubjectsAndClassesController;

appModule.component('saTeacherSubjectsAndClasses', saTeacherSubjectsAndClasses);

saTeacherSubjectsAndClasses.selector = 'saTeacherSubjectsAndClasses';

export { saTeacherSubjectsAndClasses };

import './ManageAccountCmp.scss';

import template from './ManageAccountCmp.tpl.html';

const manageAccountCmp = {
	template,
	/** @type {{close:()=>void,user: User,onUpdate:(value:User)=>void}|{}} */
	bindings: {
		user: '<',
		onUpdate: '&',
		onClose: '&',
	},
};

/** @implements {import('@srcAjs/components2/adminRoutes').IComponentOptions2} */

class ComponentNameController {
	/** @type {ErrorService} */ ErrorService;

	/** @type {auth} */ auth;

	/** @type {API} */ API;

	isReady = true;

	EffectService;

	constructor(
		auth,
		ErrorService,
		API,
		$localStorage,
		$rootScope,
		$mdToast,
		/** @type {EffectService} */ EffectService,
		/** @type {compSetupService} */ compSetupService,
		/** @type {DialogService} */ DialogService,
		/** @type {atomosService} */ atomosService
	) {
		compSetupService(this, manageAccountCmp.bindings);

		this.auth = auth;
		this.ErrorService = ErrorService;
		this.API = API;
		this.$localStorage = $localStorage;
		this.$rootScope = $rootScope;
		this.$mdToast = $mdToast;

		this.EffectService = EffectService;
		this.EffectService.setup(
			this,
			({ onInit, onChanges }) => {
				if (onInit || onChanges) {
					console.log('user', this.user);
				}
			},
			() => [this.user]
		);
	}

	changePhoneNumber() {
		this.isReady = false;

		this.auth
			.changePhone({ phone: this.newPhoneNumber, user_id: this.user.id })
			.then(() => {
				this.$mdToast.showSimple('Phone updated');
				this.refreshUser(this.user);
			})
			.catch(this.ErrorService.handleError)
			.finally(() => (this.isReady = true));
	}

	changePassword() {
		this.isReady = false;

		this.auth
			.changePassword({ user_id: this.user.id, password: this.newPassword, password_confirmation: this.passwordConfirmation })
			.then(() => {
				this.$mdToast.showSimple('Password updated');
				this.refreshUser();
			}, this.ErrorService.handleError)
			.finally(() => (this.isReady = true));
	}

	changeEmail() {
		this.isReady = false;
		this.auth
			.changeEmail({ user_id: this.user.id, email: this.newEmail })
			.then(() => {
				this.$mdToast.showSimple('Email updated');
				this.refreshUser(this.user);
			})
			.catch(this.ErrorService.handleError)
			.finally(() => (this.isReady = true));
	}

	changeThumbnail() {
		this.isReady = false;
		this.auth
			.changeAccountImage({ user_id: this.user.id, image: this.newThumbnail, scope: 'thumbnail' })
			.then(() => {
				this.$mdToast.showSimple('Thumbnail updated');
				this.refreshUser(this.user);
			})
			.catch(this.ErrorService.handleError)
			.finally(() => (this.isReady = true));
	}

	changeSignature() {}

	updateUser({ newProps, user = this.user }) {}

	stageSignature(value) {
		this.newSignature = value;
	}

	stageThumbnail(value) {
		this.newThumbnail = value;
	}

	refreshUser(/** @type {User} */ user) {
		if (!user) {
			return this.resetForms();
		}

		// this.auth
		// 	.refreshAuthToken()
		Promise.resolve()
			.then(() => this.auth.getUser(user))

			.then(({ user_instance }) => {
				// this.user = user_instance;

				if (user.id === this.auth.getEngagedUserRole().user_id) {
					this.auth
						.fetchEngagedUserRole({
							...this.auth.getEngagedUserRole(),
							API: this.API,
						})
						.then(this.auth.setEngagedUserRole)
						// .then(() => {
						// 	if (this.auth.authData$.value.user.user_instance.id !== user_instance.id) {
						// 		return;
						// 	}
						// 	// this.$localStorage.user = user_instance;
						// 	this.$rootScope.user = user_instance;
						// })
						.catch(this.ErrorService.handleError);
				}

				return this.onUpdate({ user: user_instance });
			})
			.finally(() => this.resetForms());
	}

	resetForms() {
		this.newPhoneNumber = null;
		this.newPassword = null;
		this.newPasswordConfirmation = null;
		this.newEmail = null;
		this.newSignature = null;
		this.newThumbnail = null;
		this.imgInputCtrl && this.imgInputCtrl.clear();
	}
}

manageAccountCmp.controller = ComponentNameController;

appModule.component('manageAccountCmp', manageAccountCmp);

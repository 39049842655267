import template from './index.html';
import { saTeachersToolbar } from '../SaTeachersToolbar/index.component';

/** @type {import('@srcAjs/components2/adminRoutes').IComponentOptions2} */
const saTeachersDetail = {
	template,
	bindings: {
		//someInput: '@string,<object,&function'
	},
	transclude: {
		// #slotName: '?optionalPublicName'
	},
};

function saTeachersDetailCmpController(
	SAFactory,
	$scope,
	$cookies,
	$stateParams,
	$mdToast,
	$rootScope,
	$localStorage,
	$mdDialog,
	lsfactory,
	Restangular,
	Teacher,
	/** @type {saTeachersService} */ saTeachersService,
	/** @type {auth}*/ auth,
	/** @type {ErrorService} */ ErrorService,
	/** @type {ng.IStateService} */ $state
) {
	// $scope.teacher = lsfactory.getEnv('currentTeacherSelected');
	// $scope.school = lsfactory.getEnv('school');
	const vm = this;
	const subs = [];

	vm.$onInit = () => {
		const date = Date.now();
		subs[subs.length] = saTeachersService.teachersStore$.subscribe((data) => {
			console.log({ observable_saTeacherDetailsComp: date, id: $stateParams.id });

			$scope.teacher = data.teachers?.find((t) => +t.id === +$stateParams.id);
			// $scope.teacher = data.selectedTeacher;
		}).unsubscribe;
		$scope.school = auth.authData$.value.engagedUserRole.school;
		$scope.years = [];
		const years = Restangular.all('year/by-school/' + $scope.school.id);
		years.getList().then((data) => {
			const list = data.plain();
			$scope.years = list;
		});

		vm.$onDestroy = () => {
			console.log('clear sub');
			subs.forEach((sub) => sub());
		};
	};

	$scope.getSelectedYear = function (term) {
		return term.term_typ;
	};

	$scope.getClass = function (code) {
		if (code !== undefined && code != null) {
			return 'flag-icon flag-icon-' + code.toLowerCase();
		}
	};

	// TODO: Implementing the mdDialog logic for the showing of the qr code dialog box
	$scope.view = function (teacherQR) {
		// lsfactory.setEnv("currentStudentQrcode",studentQR);
		$mdDialog.show({
			escapeToClose: false,
			clickOutsideToClose: true,
			templateUrl: 'views/pages/roles/sa/teachers/dialogs/qr_code_view.html',
			controller: ViewQrcodeDialogTeacherController,
		});
	};
}

saTeachersDetail.controller = saTeachersDetailCmpController;

appModule.component('saTeachersDetail', saTeachersDetail);

saTeachersDetail.selector = 'saTeachersDetail';

export { saTeachersDetail };

/** @typedef {{amount:number, description:string, meta:JSON, title:string,charge_template_id, currency: string, charge_type_id}} Charge*/
/** @typedef {{charges:Charge[],term_id,id?,meta,title:string}} ChargeTemplate */

import { BehaviorSubject } from 'rxjs';

/** @typedef {chargeTemplateService} ChargeTemplateService */

export function chargeTemplateService(/** @type {API}*/ API, /** @type {Restangular.IService} */ Restangular, /** @type {ng.IQService} */ $q) {
	this.chargeTemplates$ = new BehaviorSubject([]);

	/** @type {API_Params} */
	const params = {};

	const reload = API.wrapWithCache({
		restangularElement: Restangular.all(`charge-templates?${API.buildParams_v2(params)}`),
		callable: true,
	});

	const fetchChargeTemplates = ({ last = 6, refresh = false }) => {
		// make api call to fetch templates
		if (typeof reload !== 'function') {
			return;
		}

		reload(refresh)
			.getList()
			.then((res) => res?.plain())
			.then((data) => this.chargeTemplates$.next(data));
	};

	const refresh = (refresh = false) => {
		fetchChargeTemplates({ refresh });
	};

	/** update a charge on a template that is not in use else throw exception */
	const upsertCharge = async (/** @type {Charge}*/ charge, /** @type {ChargeTemplate}*/ chargeTemplate) => {
		// add a charge to the template and refresh
		const deferred = $q.defer();

		charge.id = charge?.id ?? crypto.randomUUID();

		const chargesMap = new Map((chargeTemplate.charges ?? []).map((ch) => [ch.id, ch]));

		chargesMap.set(charge.id, charge);

		chargeTemplate.charges = [...chargesMap.values()];

		const newChargeTemplate = { ...chargeTemplate };

		const shouldUpdate = ![undefined, null, ''].includes(chargeTemplate.id);

		// newChargeTemplate.charges = shouldUpdate
		// 	? newChargeTemplate.charges.map((ch) => (ch.id === charge.id ? charge : ch))
		// 	: [...newChargeTemplate.charges, charge];

		if (shouldUpdate) {
			upsertChargeTemplate(newChargeTemplate)
				.then(() => deferred.resolve(charge))
				.catch(deferred.reject);
		} else {
			deferred.resolve(charge);
		}

		// return charge;
		return deferred.promise;
	};

	/** remove a charge from the template and refresh else throw exception */
	const deleteCharge = async (/** @type {Charge}*/ charge) => {
		let newChargeTemplate = this.chargeTemplates$.value.find((ct) => ct.id === charge.charge_template_id);
		newChargeTemplate = { ...newChargeTemplate, charges: newChargeTemplate.charges.filter((qCharge) => qCharge.id !== charge.id) };

		upsertChargeTemplate(newChargeTemplate);

		// return Restangular.customPOST(newChargeTemplate, 'charge-templates').then(refresh);
	};

	const deleteChargeTemplate = async (/** @type {number} */ id) => {
		return Restangular.all('charge-templates')
			.customDELETE(id + '')
			.then(() => refresh())
			.then(() => {
				this.chargeTemplates$.next(this.chargeTemplates$.value.filter((ct) => ct.id !== id));
			});
	};

	const upsertChargeTemplate = async (/** @type {ChargeTemplate}  */ chargeTemplate) => {
		// make a request to send the chargeTemplate
		const deffered = $q.defer();

		Restangular.all('charge-templates')
			.post(chargeTemplate)
			.then((res) => res.plain())
			.then((ct) => {
				chargeTemplate.charges = chargeTemplate?.charges ?? [];
				const shouldUpdate = this.chargeTemplates$.value.find((ct) => ct.id === chargeTemplate.id);
				const newChargeTemplates = shouldUpdate
					? this.chargeTemplates$.value.map((ct) => (ct.id === chargeTemplate.id ? chargeTemplate : ct))
					: [...this.chargeTemplates$.value, chargeTemplate];

				this.chargeTemplates$.next(angular.copy(newChargeTemplates));
				deffered.resolve(ct);
			})
			.catch(deffered.reject);

		return deffered.promise;
	};

	const handlers = { upsertChargeTemplate, deleteChargeTemplate, upsertCharge, deleteCharge, refresh };

	/** @template {keyof handlers}	 T*/
	const dispatch = (/** @type {T}*/ action, /** @type {Parameters<typeof handlers[T]>} */ ...payload) => {
		return typeof payload !== 'never' ? handlers[action](...angular.copy(payload)) : null;
	};

	this.dispatch = dispatch;
}

appModule.service('chargeTemplateService', chargeTemplateService);

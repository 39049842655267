import template from './index.html';

/** @type {import('@srcAjs/components2/adminRoutes').IComponentOptions2} */
const saSchoolSetupYearsSubTermsEnrolledStudents = {
	template,
	bindings: {
		//someInput: '@string,<object,&function'
	},
	transclude: {
		// #slotName: '?optionalPublicName'
	},
};



function saSchoolSetupYearsSubTermsEnrolledStudentsCmpController(SAFactory,$scope,$cookies,$stateParams,$mdToast,$state,$rootScope,$localStorage,$mdDialog,lsfactory,Restangular,Years,Term) {
    $scope.next = function(year){
        var y = parseInt(year);
        return y+1;
    };

    SAFactory.loadNotifications();

    $scope.sel_year = lsfactory.getEnv('currentYearSelected');
    $scope.sel_term = lsfactory.getEnv('currentTermSelected');
    $state.current.data.title = $scope.sel_term.term_type.title+" ( "+$scope.sel_year.year+" / "+$scope.next($scope.sel_year.year)+" )";
    var r_years = Restangular.all("year/by-school/"+$scope.school.id);
    r_years.getList().then(function(data) {
        var list = data.plain();
        $scope.years = list;
    });

    $scope.next = function(year){
        var y = parseInt(year);
        return y+1;
    };

    $scope.$on("system.sa.environmentChanged", function(ev, data){
        Term.one($stateParams.tid).get().then(function(res){
            $scope.sel_term = res;
            $state.current.data.title = $scope.sel_term.term_type.title+" ( "+$scope.sel_year.year+" / "+$scope.next($scope.sel_year.year)+" )";
        },function(err){
            if(err.status == 404){
            }
        });
    });

    $state.current.data.title = $scope.sel_term.term_type.title+" ( "+$scope.sel_year.year+" / "+$scope.next($scope.sel_year.year)+" )";
    $scope.students = [];
    var stds = Restangular.all("term/"+$scope.sel_term.id+"/students");
    stds.getList().then(function(data){
         var list = data.plain();
        $scope.students = list;
        console.dir(list);
    });

    $scope.showStudentDialog = function(student){
        lsfactory.setEnv("dialogCurrentSelectedStudent",student);
        $mdDialog.show({
            clickOutsideToClose:true,
            escapeToClose:true,
            templateUrl:"views/pages/roles/sa/school_setup/years/dialogs/show_student_enroll.html",
            controller:ShowStudentEnrollDialogController
        });
    };

    var tree, treedata_schrep;

    $scope.my_tree_handler = function(branch) {
        var _ref;
        $scope.output = "You selected: " + branch.label;
        if ((_ref = branch.data) != null ? _ref.description : void 0) {
            return $scope.output += '(' + branch.data.description + ')';
        }
    };

    treedata_schrep = [
        {
            label: 'Primary',
            data: {

            },
            children: [
                {
                    label: 'Class 1',
                    children: [
                        'Section A', 'Section B'
                    ],
                    data: {}
                }, {
                    label: 'Class 2',
                    children: ['Section A', 'Section B'],
                    data: {}
                }, {
                    label: 'Class 3',
                    children: ['Section A', 'Section B'],
                    data: {}
                }
            ]
        }
    ];

    $scope.my_data = treedata_schrep;
    $scope.my_tree = tree = {};
    
}


saSchoolSetupYearsSubTermsEnrolledStudents.controller = saSchoolSetupYearsSubTermsEnrolledStudentsCmpController;

appModule.component('saSchoolSetupYearsSubTermsEnrolledStudents', saSchoolSetupYearsSubTermsEnrolledStudents);

saSchoolSetupYearsSubTermsEnrolledStudents.selector = 'saSchoolSetupYearsSubTermsEnrolledStudents';

export { saSchoolSetupYearsSubTermsEnrolledStudents }

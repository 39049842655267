import 'pwaupdate';
import './tr-subjectlist-item.scss';

import template from './tr-subjectlist-item.tpl.html';

const trSubjectListItem = {
	template,
	/** @type {{subject_mount:SubjectMount,agBuckets:CachedAssessmentGroupMap}} */
	// @ts-ignore
	bindings: {
		subject_mount: '<subjectMount',
		isExternalSubject: '<',
		agBuckets: '<',
		isSubjectAgCached: '<',
		can_sync: '<canSync',
		is_mine: '<isMine',
		onClick: '&',
		clear: '&',
		sync: '&',
	},
};

class TrSubjectListItemCmpController {
	#ErrorService;

	#$state;

	/** @type {typeof trSubjectListItem.bindings.agBuckets}*/ agBuckets;

	constructor(ErrorService, /** @type $state */ $state) {
		this.#ErrorService = ErrorService;
		this.isExternalSubject = false;
		this.#$state = $state;
	}

	click(/** @type Function*/ onClick, /** @type SubjectMount */ subject_mount) {
		try {
			if (!onClick) throw new Error('No external handler defined');
			onClick({ subject_mount });
		} catch (error) {
			this.#ErrorService.handleError(error);
		}
	}

	syncHandler(/** @type SubjectMount*/ subject_mount) {
		console.log('trSucjectListItem.sync', subject_mount);
		this.sync({ subject_mount });
	}

	/**
	 * @todo call subjects controller clear function
	 * @param {*} subject_mount
	 */
	clearHandler(subject_mount) {
		this.clear({ subject_mount });
	}

	get agBucketsArr() {
		return Object.values(this.agBuckets ?? {});
	}

	get assessmentEntities() {
		return this.agBucketsArr?.flatMap((cag) => Object.values(cag?.subject_assessment_entities)) ?? [];
	}
}

trSubjectListItem.controller = ['ErrorService', '$state', TrSubjectListItemCmpController];

appModule.component('trSubjectListItem', trSubjectListItem);

import './EditStudentProfileCmp.scss';

import template from './EditStudentProfileCmp.tpl.html';

const editStudentProfileCmp = {
	template,
	bindings: {
		student: '<',
	},
};

function EditStudentDialogController(
	/** @type {import('restangular').IService}*/ Restangular,
	$scope,
	ErrorService,
	$mdDialog,
	$rootScope,
	$mdToast,
	/** @type {$moment} */ moment
	// /** @type {auth}*/ auth
) {
	/**
	 * @type {
	 *  typeof this &
	 *  {
	 * [key:string]:any
	 *  student: Student
	 * gends: string[]
	 * outputImage: string
	 * selectCountry: (country:any)=>void
	 * myImage: string
	 * }
	 * }
	 */
	const vm = this;

	vm.$onInit = () => {
		try {
			$scope.student = { myImage: '', ...vm.student };
			$scope.student.birthdate = moment($scope.student.birthdate).isValid() ? new Date(moment($scope.student.birthdate)) : undefined;
			$scope.gends = ['Male', 'Female'];
			$scope.isPersonal = true;
			$scope.isPhoto = false;
			$scope.image_set = false;
			$scope.isProcessing = false;
			$scope.outputImage = '';
			$scope.cropType = 'square';
			$scope.selectCountry = (country) => country.id === $scope.student.country_id;
		} catch (error) {
			ErrorService.handleError(error);
		} finally {
			$scope.isReady = true;
		}
	};

	$scope.cancelImage = function () {
		$scope.srcImage = '';
		$scope.outputImage = '';
		$scope.image_set = false;
	};

	$scope.save = function (student) {
		student.isPhoto = $scope.image_set;
		$scope.isProcessing = true;

		Restangular.all('student/update-profile')
			.post(student)
			.then((res) => res.plain())
			.then((res) => {
				$scope.cancelImage();
				const template = $mdToast.showSimple('Profile Updated');
				$mdToast.show(template);
				vm.student = { ...vm.student, ...res };
				$rootScope.$broadcast('editStudent.newEntity', res);
				$mdDialog.hide(res);
			}, ErrorService.handleError)
			.finally(() => ($scope.isProcessing = false));
	};

	$scope.cancel = function () {
		$scope.cancelImage();
		$mdDialog.cancel();
	};

	$scope.hide = function () {
		$mdDialog.hide();
	};

	$scope.updateCountry = (country) => {
		$scope.student.country_id = country.id;
		$scope.student.country = country;
	};

	$scope.updateImage = (image) => ($scope.student.myImage = image);
}

editStudentProfileCmp.controller = EditStudentDialogController;

appModule.component('editStudentProfileCmp', editStudentProfileCmp);

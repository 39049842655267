'use strict';

import { appShellCmp } from './AppShellCmp/AppShellCmp.component';
import { paChildSectionHistoryDetails } from './PaChildSectionHistoryDetails';
import { paDashboard } from './PaDashboard';

function ParentRoutesConfig(/** @type {ng.ui.StateProvider} */ $stateProvider) {
	$stateProvider
		// Parent Routes
		.state('pa', {
			url: '/pa/:user_id/:role_instance_id/:school_id',
			params: { user_id: null, role_instance_id: null, school_id: null },
			views: {
				'': appShellCmp.selector,
			},
		})
		.state('pa.dashboard', {
			url: '/dashboard',
			component: paDashboard.selector,
			data: { ngDynamicTitle: 'Guardian App - Schrep.info' },
		})
		.state('pa.child', {
			url: '/child/{id}',
			component: paChildSectionHistoryDetails.selector,
			data: { title: 'Choose an Item', ngDynamicTitle: 'Guardian App - Schrep.info', child: true },
			params: { section_history: null, student: null },
		});
}

/**
 * @ngdoc function
 * @name app.config:uiRouter
 * @description
 * # Config
 * Configure router for the Parents routes
 */
appModule.config(['$stateProvider', '$urlRouterProvider', 'MODULE_CONFIG', 'CONST_APP_NAME', ParentRoutesConfig]);

export { ParentRoutesConfig };

import userSlice from './auth.slice.js';
import saTeachersSlice from './saTeachers.slice.js';
import { fcmSlice } from './fcm.slice.js';
import { createStore } from './src/store.mjs';
import { errorSlice } from './errors.slice.js';

/** @typedef {ReturnType<AppStoreFactory>} AppStore */
function AppStoreFactory() {
	console.log('AppStore.fired');
	return createStore({ auth: userSlice, saTeachers: saTeachersSlice, fcmSlice, errorSlice });
}

appModule.factory('AppStore', AppStoreFactory);

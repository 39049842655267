import template from './index.html';

/** @type {import('@srcAjs/components2/adminRoutes').IComponentOptions2} */
const saSchoolSetupClassGroupsDialogsEditGroup = {
	template,
	bindings: {
		//someInput: '@string,<object,&function'
		onClose: '&',
		systems: '<',
		group: '<',
	},
	transclude: {
		// #slotName: '?optionalPublicName'
	},
	selector: 'saSchoolSetupClassGroupsDialogsEditGroup',
};

/** @this {ng.IController} */
function saSchoolSetupClassGroupsDialogsEditGroupController(Restangular, $scope, $rootScope, $localStorage, ErrorService) {
	$scope.isProcessing = false;
	// $scope.systems = lsfactory.getEnv('dialogGradingSystemList');
	// this.group = lsfactory.getEnv('groupEdit');

	$scope.updateGroup = (group = this.group) => {
		$scope.isProcessing = true;
		Restangular.all('class-group/' + group.id)
			.customPUT(this.group)
			.then((data) => {
				$rootScope.$broadcast('class_groups.dataChanged', $localStorage.environment);
				this.onClose({ data });
			})
			.catch(ErrorService.handleError)
			.finally(() => {
				$scope.isProcessing = false;
			});
	};

	$scope.hide = () => {
		this.onClose({});
	};

	$scope.cancel = () => {
		this.onClose({});
	};

	$scope.answer = (answer) => {
		this.onClose({});
	};
}

saSchoolSetupClassGroupsDialogsEditGroup.controller = saSchoolSetupClassGroupsDialogsEditGroupController;

appModule.component('saSchoolSetupClassGroupsDialogsEditGroup', saSchoolSetupClassGroupsDialogsEditGroup);

export { saSchoolSetupClassGroupsDialogsEditGroup };

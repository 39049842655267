import './ManageStudentChargesCmp.scss';

import template from './ManageStudentChargesCmp.tpl.html';

/** @typedef {import('restangular').IService} IService*/

const manageStudentChargesCmp = {
	template,
	bindings: {},
};

class manageStudentChargesCmpController {
	/** @type {Section[]} */
	sections;

	/** @type {Section |null} */
	selected_section = null;

	/** @type {Term}*/
	term;

	paper = {
		selected_sections: /** @type {Section[]} */ (undefined),
		selected_shistories: /** @type {SectionHistory[]} */ (undefined),
	};

	/** @type {import('../ChargeTemplateService').ChargeTemplate[]} */
	chargeTemplates;

	refresh;

	/** @type {API}*/ #API;

	constructor(
		/** @type {ErrorService} */ ErrorService,
		API,
		/** @type {IService} */ Restangular,
		/** @type {auth}*/ auth,
		/** @type {import('../ChargeTemplateService').ChargeTemplateService} */ chargeTemplateService,
		/** @type {DialogService} */ DialogService
	) {
		this.#API = API;
		this.Restangular = Restangular;
		this.chargeTemplateService = chargeTemplateService;
		this.auth = auth;
		this.ErrorService = ErrorService;
	}

	$onInit() {
		this.auth.authData$.subscribe((authData) => {
			this.engagedUserRole = authData.engagedUserRole;

			// this.loadClassSectionWithStudents();
		});
		this.chargeTemplateService.chargeTemplates$.subscribe((data) => {
			this.chargeTemplates = data;
		});

		this.refreshAll();
	}

	updateTerm(/** @type {Term}*/ term) {
		this.term = term;

		this.loadClassSectionWithStudents(this.term);
	}

	refreshAll() {
		this.loadChargeTemplates();
		this.loadClassSectionWithStudents();
	}

	loadClassSectionWithStudents(_term = this.term) {
		if (!_term) {
			return;
		}

		const /** @type {API_Params} */ params = {
				fields: [
					{
						field: 'section_histories',
						conditions: [
							{ condition: 'where', column: 'term_id', value: _term.id },
							{ condition: 'with', column: 'student' },
							{ condition: 'with', column: 'student_billing_charges' },
						],
					},
					{ field: 'sclass.class_level' },
				],
				filters: [{ condition: 'where', column: 'term_id', value: _term.id }],
			};

		const refresh = this.#API.wrapWithCache({
			restangularElement: this.Restangular.all(`section?${this.#API.buildParams_v2(params)}`),
			callable: true,
		});

		if (typeof refresh !== 'function') return;

		/** @type {import('restangular').ICollectionPromise<Section>}*/ (refresh(true).getList())
			.then((data) => data.plain())
			.then((/** @type {Section[]}*/ data) => {
				this.sections = data;

				this.selected_section = this.selected_section ? this.sections.find((s) => s.id === this.selected_section.id) : null;
			})
			.catch(this.ErrorService.handleError);
	}

	loadChargeTemplates(school = this.engagedUserRole.school) {
		this.chargeTemplateService.dispatch('refresh');
	}

	updateTerm(term) {
		this.term = term;
		this.loadClassSectionWithStudents(this.term);
	}

	applyCharges(/** @type {import('../ChargeTemplateService').Charge[]} */ charges, /** @type {SectionHistory[]} */ section_histories) {
		if (!section_histories?.length || !charges?.length) {
			// return DialogService.dispatch('alert',)
		}

		this.isApplyChargesLoading = true;

		const data = section_histories.flatMap(({ id, term_id, student_id }) =>
			charges.map((ch) => ({ ...ch, term_id, student_id, section_history_id: id, meta: { data: { currency: ch.currency, reason: '' } } }))
		);
		return this.Restangular.allUrl('student-billing-charges')
			.post({ charges: data })
			.then(() => (this.showApplyChargeConfirmationDialog = false))
			.then(() => this.loadClassSectionWithStudents())
			.catch(this.ErrorService.handleError)
			.finally(() => (this.isApplyChargesLoading = false));
	}

	mapCharges = (/** @type {import('../ChargeTemplateService').ChargeTemplate} */ item) => item.charges;

	toggleShistoryCheck = (/** @type {(SectionHistory &{checked?:boolean})[]}}*/ s_hiss, val = null) => {
		const temp_s_his = s_hiss.map((s_his) => ({ ...s_his, checked: val === null ? !s_hiss?.checked : val }));

		this.selected_section.section_histories = this.selected_section.section_histories.map((s) => temp_s_his.find((ts) => ts.id === s.id) ?? s);
	};

	noneSHisSelected = (/** @type {(SectionHistory&{checked?:boolean})[]} */ s_hiss) => {
		return s_hiss.some((s) => !s?.checked);
	};

	isChecked = (item) => item?.checked;
	isNotChedked = (item) => item?.checked;
	toggleCheckCharges = (charges, $event) => {
		for (let i = 0; i < charges.length; i++) {
			charges[i].checked = [true, false].includes($event) ? $event : charges[i]?.checked;
		}
	};

	mapChargeAmount = (/**@type {import('../ChargeTemplateService').Charge} */ charge) => charge.amount;

	mapTitle = (/** @type {StudentBillingCharge} */ sbc) => sbc.title;

	setSelectedSection = (/** @type {Section} */ section) => {
		const prevSectionHistories = this.selected_section?.section_histories ?? [];
		// clear any checked section_histories
		if (this.selected_section) {
			this.toggleShistoryCheck([...prevSectionHistories, ...(section?.section_histories ?? [])], false);
		}
		// clear any checked charges
		this.toggleCheckCharges(this.chargeTemplates.flatMap(this.mapCharges), false);
		this.selected_section = section;
	};

	sumCharges = (/** @type {import('../ChargeTemplateService').Charge[]} */ charges) => charges?.reduce((acc, c) => acc + c.amount, 0);
}

manageStudentChargesCmp.controller = manageStudentChargesCmpController;

appModule.component('manageStudentChargesCmp', manageStudentChargesCmp);

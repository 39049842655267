import './ImageInputCmp.scss';

import template from './ImageInputCmp.tpl.html';

/** @type {ng.IComponentOptions & any} */
const imageInputCmp = {
	template,
	/** @type {{onUpdate:({value})=>void,isRequired?:boolean = true,name:string}} */
	// @ts-ignore
	bindings: {
		onUpdate: '&',
		isRequired: '<',
		name: '@',
	},
};

class ComponentNameController {
	/** @type {ng.IScope}*/ $scope;

	/** @type {ng.IDocumentService} */ $document;

	cropType = 'square';

	outputImage = '';

	isReady = false;

	/** @type {typeof imageInputCmp.bindings.onUpdate}*/ onUpdate;

	constructor($scope, $document) {
		this.$scope = $scope;
		this.$document = $document;
	}

	$onInit() {
		this.$scope.$watch('$ctrl.outputImage', (result) => {
			if (!this.image_set) {
				return;
			}

			this.update(result);
		});
		this.isReady = true;
	}

	initializeImageCrop() {
		console.log('Init the uploader');

		const handleFileSelect = (evt) => {
			const [file] = evt.currentTarget.files;
			const reader = new FileReader();
			reader.onload = (evt) => {
				this.$scope.$apply(($scope) => {
					this.srcImage = evt.target.result;
					this.image_set = true;
				});
			};
			reader.readAsDataURL(file);
		};

		const [input_field] = this.$document.find('#fileInput');
		angular.element(input_field).on('change', handleFileSelect);
	}

	update(value, image_set = this.image_set, srcImage = this.srcImage) {
		console.log({ image_set, value });
		if (!image_set) {
			return;
		}

		this.onUpdate({ value: !this.srcImage?.length ? '' : value });
	}

	clear() {
		angular.element(this.$document.find('#fileInput')).val('');
		this.srcImage = '';
		this.outputImage = '';
	}
}

imageInputCmp.controller = ComponentNameController;

appModule.component('imageInputCmp', imageInputCmp);

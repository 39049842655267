import template from './index.html';

/** @type {import('@srcAjs/components2/adminRoutes').IComponentOptions2} */
const saSchoolSetupYearsSubTermsMountedSubjects = {
	template,
	bindings: {
		//someInput: '@string,<object,&function'
	},
	transclude: {
		// #slotName: '?optionalPublicName'
	},
};



function saSchoolSetupYearsSubTermsMountedSubjectsCmpController(
	SAFactory,
	$scope,
	$cookies,
	$stateParams,
	$state,
	$rootScope,
	$localStorage,
	$mdDialog,
	$mdToast,
	lsfactory,
	Restangular,
	Years,
	Term,
	TourGuide
) {
	$scope.sel_year = lsfactory.getEnv('currentYearSelected');
	$scope.sel_term = lsfactory.getEnv('currentTermSelected');
	$scope.school = lsfactory.getEnv('school');
	$scope.obj = { selectedItem: null, selectedClassLevel: null };

	$scope.next = function (year) {
		var y = parseInt(year);
		return y + 1;
	};

	$state.current.data.title =
		$scope.sel_term.term_type.title + ' ( ' + $scope.sel_year.year + ' / ' + $scope.next($scope.sel_year.year) + ' )';
	$scope.my_data = [];
	$scope.teachers = [];
	$scope.selected_class_level = [];
	$scope.diff_idxs = [];
	$scope.env = $localStorage.environment;
	$scope.school = $scope.env.school;
	$scope.level_count = -1;
	$('#when_default_selected').hide();
	$('#when_loading').show();
	$('#when_nothing_selected').hide();
	$('#when_class_selected').hide();

	var tour = {
		steps: [
			{
				element: 'class-0',
				text: 'Choose a class level to get started.',
			},
		],
	};

	$scope.showSubjectDetail = function (subm) {
		var remainderStudents = resolveRemainder(subm.section_histories, subm.class_students);
		var registeredStudents = resolveRegistered(subm.section_histories, subm.class_students);
		lsfactory.setEnv('dialogSubjectMountDetails', subm);
		lsfactory.setEnv('dialogTeachersList', $scope.teachers);
		lsfactory.setEnv('dialogSubjectMountEnrollment', subm.class_students);
		lsfactory.setEnv('dialogSubjectMountStudentRemainder', remainderStudents);
		lsfactory.setEnv('dialogSubjectMountRegisteredStudents', registeredStudents);
		$mdDialog.show({
			escapeToClose: true,
			clickOutsideToClose: true,
			templateUrl: 'views/pages/roles/sa/school_setup/years/dialogs/show_subject_detail.html',
			controller: ShowSubjectDetailDialogController,
		});
	};

	$scope.chooseTeacher = function (sub) {
		lsfactory.setEnv('dialogChosenSubject', sub);
		lsfactory.setEnv('classSection', sub.section_data);
		lsfactory.setEnv('dialogTeachersList', $scope.teachers);

		$mdDialog.show({
			escapeToClose: true,
			clickOutsideToClose: true,
			templateUrl: 'views/pages/roles/sa/school_setup/years/dialogs/choose_teacher.html',
			controller: ChooseTeacherDialogController,
		});
	};
	$scope.gotoClass = function () {
		lsfactory.setEnv('backLog', 'sa.dashboard');
		$state.go('sa.school_setup_classes');
	};
	$scope.takeTour = function () {
		TourGuide.startTour(tour);
	};
	$scope.trigger = function (subject) {
		if (angular.equals({}, subject.mounts)) {
			$scope.chooseTeacher(subject);
		} else {
			console.log('Right HERE!!!!!');
			console.dir(subject.mounts.class_students);
			if (subject.mounts.class_students.length == 0) {
				var template = $mdToast.simple().textContent('Class has no enrollment').action('OK').highlightAction(true).hideDelay(5500);
				$mdToast.show(template);
			} else {
				$scope.showSubjectDetail(subject.mounts);
			}
		}
	};

	$scope.class_handler = function (sclass) {
		$scope.selected_class_level = sclass;
		if ($scope.selected_class_level.sections.length > 0) {
			$('#when_default_selected').hide();
			$('#when_nothing_selected').hide();
			$('#when_loading').hide();
			$('#when_class_selected').show();
			var data_array = [];
			for (var i = 0; i < $scope.selected_class_level.sections.length; i++) {
				data_array.push($scope.selected_class_level.sections[i].id);
			}
			$scope.isDataLoading = true;
			var poster = Restangular.all('subject-mount/by-section-term/bulk');
			poster.post({ sections: data_array, term_id: $scope.sel_term.id, sclass_id: sclass.id }).then(
				function (res) {
					var data = res.plain();
					$scope.isDataLoading = false;
					apply_subjects_and_process_selected_class_level_with_mounts($scope.selected_class_level, data);
				},
				function (err) {
					console.log('There was an error saving');
				}
			);
		} else {
			$('#when_default_selected').hide();
			$('#when_nothing_selected').show();
			$('#when_loading').hide();
			$('#when_class_selected').hide();
		}
	};

	function fix_the_mounts_on_the_subjects(section) {
		var id_of_section = section.id;
		var mounts_for_section = section.mounts;
		var size = mounts_for_section.length;
		_.each(section.subjects, function (subject) {
			subject.section_data = angular.copy(section);
		});
		for (var k = 0; k < size; k++) {
			for (var i = 0; i < section.subjects.length; i++) {
				if (mounts_for_section[k].subject_id == section.subjects[i].id) {
					if (mounts_for_section[k].section_id == section.id) {
						mounts_for_section[k].class_students = section.students;
						section.subjects[i].mounts = mounts_for_section[k];
						break;
					}
				}
			}
		}
	}
	function resolveRemainder(section_histories, class_students) {
		var remmy = [];
		var remmy_idx = [];
		var class_idx = [];
		var term_idx = [];

		for (var i = 0; i < class_students.length; i++) {
			class_idx.push(class_students[i].id);
		}

		for (var j = 0; j < section_histories.length; j++) {
			term_idx.push(section_histories[j].id);
		}
		// Calc diff into remmy_idx
		remmy_idx = _.difference(class_idx, term_idx);

		for (var k = 0; k < remmy_idx.length; k++) {
			for (var l = 0; l < class_students.length; l++) {
				if (class_students[l].id == remmy_idx[k]) {
					remmy.push(class_students[l]);
				}
			}
		}
		return remmy; // 1738
	}
	function resolveRegistered(section_histories, class_students) {
		var registered = [];
		for (var i = 0; i < class_students.length; i++) {
			for (var j = 0; j < section_histories.length; j++) {
				if (class_students[i].id == section_histories[j].id) {
					registered.push(class_students[i]);
				}
			}
		}
		return registered; // 1738
	}
	function find_section_and_alter(selected_class_level, section_payload) {
		console.dir(section_payload);
		for (var i = 0; i < selected_class_level.sections.length; i++) {
			if (selected_class_level.sections[i].id == section_payload.section_id) {
				selected_class_level.sections[i].students = section_payload.students; // So we can tell how many students have been enrolled at the time of making the request
				selected_class_level.sections[i].mounts = section_payload.mounts; // So we can tell how many students have been enrolled at the time of making the request
				fix_the_mounts_on_the_subjects(selected_class_level.sections[i]);
				break;
			}
		}
	}
	function process_sections_with_mounts(selected_class_level, results) {
		for (var i = 0; i < results.length; i++) {
			var section = results[i];
			find_section_and_alter(selected_class_level, section);
		}
	}
	function apply_subjects_and_process_selected_class_level_with_mounts(selected_class_level, data) {
		//Applyng Subjects
		_.each(data.class_subjects, function (subject) {
			subject.mounts = {}; // Create the empty object for the DOM's sake
		});
		for (var i = 0; i < selected_class_level.sections.length; i++) {
			selected_class_level.sections[i].subjects = angular.copy(data.class_subjects);
		}
		process_sections_with_mounts(selected_class_level, data.results);
	}

	var process_groups_into_linear_data = function (groups) {
		$scope.level_count = 0;
		var linear = [];
		for (var i = 0; i < groups.length; i++) {
			var gobj = {
				label: ' ' + groups[i].class_group_type.title,
			};
			linear.push({
				title: gobj.label,
				data: {
					id: null,
					type: 'group',
				},
			});
			var pushed_sections = 0;
			for (var j = 0; j < groups[i].classes.length; j++) {
				var cobj = {
					id: groups[i].classes[j].id,
					label: groups[i].classes[j].class_level.title,
				};
				linear.push({
					title: cobj.label,
					data: {
						id: cobj.id,
						itself: groups[i].classes[j],
						sections: groups[i].classes[j].sections,
						type: 'item',
					},
				});
				$scope.level_count++;
			}
		}
		console.dir(linear);
		return linear;
	};

	$scope.$on('term_man_subject_mount_added', function (event, data) {
		_.each($scope.selected_class_level.sections, function (section) {
			_.each(section.subjects, function (subject) {
				if (subject.id == data.mount.subject_id && section.id == data.mount.section_id) {
					subject.mounts = data.mount;
					subject.mounts.class_students = data.class_students; // For the existing structure
				}
			});
		});
	});
	$scope.$on('subm.teacher_changed', function (event, data) {
		console.dir(data);
		console.dir($scope.selected_class_level);
	});
	$scope.$on('subject_detail.students_added', function (event, data) {
		_.each($scope.selected_class_level.sections, function (section) {
			_.each(section.subjects, function (subject) {
				if (subject.id == data.mount.subject_id && section.id == data.mount.section_id) {
					_.each(data.students, function (student) {
						subject.mounts.section_histories.push(student);
					});
				}
			});
		});
	});

	$scope.$on('subm.teacher_changed', function (event, data) {
		_.each($scope.selected_class_level.sections, function (section) {
			_.each(section.subjects, function (subject) {
				var mount = subject.mounts;
				if (mount.id !== undefined) {
					if (mount.id == data.mount.id) {
						mount.teacher = data.mount.teacher;
					}
				}
			});
		});
	});

	$scope.$on('show_subject_detail.student_unregistered', function (event, data) {
		_.each($scope.selected_class_level.sections, function (section) {
			_.each(section.subjects, function (subject) {
				if (subject.id == data.subject_mount_id && section.id == data.section_id) {
					var $index;
					for (var j = 0; j < subject.mounts.section_histories.length; j++) {
						if (subject.mounts.section_histories[j].id == data.section_history_id) {
							$index = j;
							break;
						}
					}
					subject.mounts.section_histories.splice($index, 1); // Removing specific student from the UI
				}
			});
		});
	});

	$scope.$on('system.sa.environmentChanged', function (ev, data) {
		Term.one($stateParams.tid)
			.get()
			.then(
				function (res) {
					$scope.sel_term = res;
					$state.current.data.title =
						$scope.sel_term.term_type.title + ' ( ' + $scope.sel_year.year + ' / ' + $scope.next($scope.sel_year.year) + ' )';
				},
				function (err) {
					if (err.status == 404) {
					}
				}
			);
	});

	var r_teachers = Restangular.all('teacher/by-school/' + $scope.school.id);
	r_teachers.getList().then(function (data) {
		var list = data.plain();
		$scope.teachers = list;
	});

	var r_levels = Restangular.all('class/by-school/' + $scope.school.id);
	r_levels.getList().then(function (data) {
		$scope.groups = data.plain();
		console.log('This is the groups object');
		console.dir($scope.levels);
		$('#when_default_selected').show();
		$('#when_nothing_selected').hide();
		$('#when_loading').hide();
		$('#when_class_selected').hide();
	});
}

saSchoolSetupYearsSubTermsMountedSubjects.controller = saSchoolSetupYearsSubTermsMountedSubjectsCmpController;

appModule.component('saSchoolSetupYearsSubTermsMountedSubjects', saSchoolSetupYearsSubTermsMountedSubjects);

saSchoolSetupYearsSubTermsMountedSubjects.selector = 'saSchoolSetupYearsSubTermsMountedSubjects';

export { saSchoolSetupYearsSubTermsMountedSubjects }

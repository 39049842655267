/** @typedef {{id:number,student_guardian_id:number,subject_assessment_entity_id:number,notorized_at:string, notorized_at_date:$moment['Moment']}} AssessmentNotorization */

/**
 *
 * @class
 * */

function guardianNotorizationService(
	/** @type {EffectService} */ EffectService,
	/** @type {API} */ API,
	/** @type {compSetupService} */ compSetupService,
	/** @type {atomosService} */ atomosService,
	/** @type {ErrorService} */ ErrorService
) {
	compSetupService(this);

	this.gaNotorizationRxm = API.rxQuery(
		['rxm.keygaNotorization'],
		(r, /** @type {Pick<AssessmentNotorization,'id'|'notorized_at'>}*/ data) => /** @type {ng.restangular.IPromise<any>} */ (r.all('assessment-notorizations').post(data)),
		{ /** @type {any} */ qInput$: undefined, enabled: false, destroy$: this.componentDestroy$ }
	);

	this.gaNotorizationsRxq = API.rxQuery(
		['rxq.gaNotorizations'],
		(r, /**@type {{student_guardian_ids:Number[]}} */ data) =>
			/** @type {ng.restangular.IPromise<AssessmentNotorization[]>} */ (
				r
					.all(
						'assessment-notorizations?' +
							API.buildParams_v2({
								filters: [{ condition: 'whereIn', column: 'student_guardian_id', value: data.student_guardian_ids }],
								fields: [
									{ condition: 'with', field: 'subject_assessment_entity.subject_assessment_group' },
									{ condition: 'with', field: 'student_term_subject.section_history.section' },
									{ condition: 'with', field: 'student_term_subject.section_history.student' },
								],
							})
					)
					.getList()
			),
		{
			/** @type {any} */ qInput$: undefined,
			enabled: false,
			destroy$: this.componentDestroy$,
		}
	);

	EffectService.setup(this, ({ onInit }) => {
		if (onInit) {
			atomosService(this.gaNotorizationRxm[0], this).stream$.subscribe(({ data, error }) => {
				if (ErrorService.handleError(error, true) || !data) return;

				this.gaNotorizationsRxq[1].revalidate();
			});
		}
	});

	return this;
}

appModule.service('GuardianNotorizationService', guardianNotorizationService);

export { guardianNotorizationService };

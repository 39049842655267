import template from './index.html';
import { saStudentsToolbar } from '../SaStudentsToolbar/index.component';

/** @type {import('@srcAjs/components2/adminRoutes').IComponentOptions2} */
const saStudentsDetail = {
	template,
	bindings: {
		//someInput: '@string,<object,&function'
		istudent: '<',
	},
	transclude: {
		// #slotName: '?optionalPublicName'
	},
};



function saStudentsDetailCmpController(
	$mdToast,
	$timeout,
	SAFactory,
	$scope,
	$stateParams,
	$state,
	// istudent,
	$mdDialog,
	lsfactory,
	Restangular,
	ErrorService
) {
	SAFactory.loadEnvironment();

	this.$onInit = () => {
		const istudent = this.istudent
		$scope.student = istudent;
		$scope.focused_sec_his = null;

		/*
		 * console.log('student detail load:',$scope.student);
		 * $(".gallery").lightGallery()
		 * $("#lightgallery").lightGallery()
		 */

		$scope.$on('editStudent.newEntity', (event, new_student) => {
			fetchStudentDetails(istudent);
			/*
			 * .then(attachGuardians)
			 * .then((student) => ($scope.student = student))
			 * .catch(ErrorService.handleError)
			 */
		});

		$scope.getClass = function (code) {
			if (code !== undefined && code != null) {
				return 'flag-icon flag-icon-' + code.toLowerCase();
			}
		};

		/**
		 * @typedef {{event, student}} ShowEnrollmentEvent
		 * @param {ShowEnrollmentEvent} data
		 */
		$scope.$on('show_enrollment_state_editor', (ev, data) => {
			$scope.toggleEnrollmentStateEditor(data.event);
		});

		$scope.showStudentDialog = function (student) {
			console.dir(student);
			lsfactory.setEnv('dialogCurrentSelectedStudent', student);
			$mdDialog.show({
				escapeToClose: true,
				clickOutsideToClose: true,
				templateUrl: 'views/pages/roles/sa/school_setup/years/dialogs/show_student_enroll.html',
				controller: ShowStudentEnrollDialogController,
			});
		};

		$scope.view = function (studentQR) {
			// lsfactory.setEnv("currentStudentQrcode",studentQR);
			$mdDialog.show({
				escapeToClose: false,
				clickOutsideToClose: true,
				templateUrl: 'views/pages/roles/sa/students/dialogs/qr_code_view.html',
				controller: ViewQrcodeDialogStudentController,
			});
		};

		function attachGuardians(student) {
			if (!student) throw new Error('Invalid student paramerter');

			$scope.isLoading = true;

			return Promise.all(student.guardians.map(fetchGuardianDetails))
				.then((_guardians) => {
					student.guardians = _guardians;
					return student;
				})
				.catch(ErrorService.handleError)
				.finally(() => ($scope.isLoading = false));
		}

		function fetchStudentDetails({ id: student_id }) {
			return $state.reload();
		}

		/**
		 *
		 * @param {*} param0
		 * @returns {Promise<Guardian[]>}
		 */
		function fetchGuardianDetails({ id }) {
			$scope.isLoading = true;
			return Restangular.one('guardian', id)
				.get()
				.then((response) => response.plain());
		}

		$scope.toggleEnrollmentStateEditor = (ev) => {
			console.log('toggleEnrollmentStateEditor');
			const enrollmentDialogController = function ($scope, $mdDialog, student) {
				$scope.close = (data = null) => $mdDialog.hide(data);
				$scope.student = student;
			};

			$mdDialog
				.show({
					controller: enrollmentDialogController,
					bindToController: true,
					clickOutsideToClose: true,
					locals: {
						student: $scope.student,
					},
					controllerAs: '$ctrl',
					autoWrap: false,
					targetEvent: ev,
					template: `
			<student-enrollment-state-editor layout layout-align="center"
				student="$ctrl.student"
				section-history="$ctrl.student.section_histories[0]"
				on-toggle-editor="close(data)"
				student-enrollment-state='$ctrl.student.student_enrollment_state'
				flex="95" flex-gt-md="45">
			</student-enrollment-state-editor>
		`,
				})
				.then((response) => {
					if (!response) return;

					fetchStudentDetails({ id: $stateParams.id });
				});
		};

		$scope.$watch(
			() => istudent,
			(student) => {
				try {
					if (!student) throw new Error('Invalid student parameter');

					$state.current.data.title = student.firstname + ' ' + student.lastname + ' ( Student )';
					attachGuardians(istudent).then((_student) => ($scope.student = _student));
				} catch (error) {
					ErrorService.handleError(error);
				}
			}
		);
	};
}

saStudentsDetail.controller = saStudentsDetailCmpController;

appModule.component('saStudentsDetail', saStudentsDetail);

saStudentsDetail.selector = 'saStudentsDetail';

export { saStudentsDetail };

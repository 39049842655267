import template from './index.html';

/** @type {import('@srcAjs/components2/adminRoutes').IComponentOptions2} */
const saSchoolSetupClassesSubSections = {
	template,
	bindings: {
		//someInput: '@string,<object,&function'
	},
	transclude: {
		// #slotName: '?optionalPublicName'
	},
};



function saSchoolSetupClassesSubSectionsCmpController(
	SAFactory,
	$scope,
	SadminSetupClassLevelStore,
	ErrorService,
	$state,
	/** @type {import("@srcAjs/factory/services/class-group").ClassGroupApi} */  ClassGroup,
	$timeout,
	$mdDialog,
	$mdToast,

	lsfactory,
	Restangular,
	SClass,
	Section
) {
	const svm = $scope;

	SAFactory.loadNotifications();
	svm.school = lsfactory.getEnv('school');
	svm.class = SadminSetupClassLevelStore.get('selected_sclass');
	svm.selected_term = SadminSetupClassLevelStore.get('selected_term');
	$state.current.data.title = svm.class.class_level.title;

	svm.sections = [];
	svm.teachers = [];
	svm.s_count = undefined;
	svm.isReady = false;
	svm.isLoading = false;

	if (svm.school == null) {
		$state.go('sa.dashboard');
	}

	svm.loadItems = function (reload_changes) {
		svm.isLoading = true;

		if (reload_changes) {
			const cache = SadminSetupClassLevelStore.getCache();

			return ClassGroup.get(svm.school, svm.selected_term, ['sections'], '1.2', cache, 'refresh')
				.then((sclasses) => {
					const new_class = sclasses.find((sclass) => sclass.id == SadminSetupClassLevelStore.get('selected_sclass').id);
					console.log('loadItems.refresh', new_class);

					if (!new_class) throw new Error(['problem reloading', new_class]);

					svm.class = new_class;
					SadminSetupClassLevelStore.set('selected_sclass', new_class);
				})
				.catch(ErrorService.handleError)
				.finally(() => (svm.isLoading = false));
		}

		console.log('received class', svm.class);
		svm.class = SadminSetupClassLevelStore.get('selected_sclass');

		svm.isLoading = false;
		getTeachersOldApi();
	};

	function getTeachersOldApi() {
		return Restangular.all('teacher?filters[school_id]=' + svm.school.id)

			.getList()
			.then((data) => {
				const list = data.plain();
				svm.teachers = list; // All teachers
				svm.isLoading = false;
			})
			.catch(ErrorService.handleError)
			.finally(() => (svm.isLoading = false));
	}

	svm.$on('classes.sections.dataChanged', () => {
		svm.isLoading = true;
		svm.loadItems(true);
	});

	svm.remove = function (section) {
		Section.one(section.id)
			.get()
			.then((res) => {
				const section_var = res;
				const confirm = $mdDialog
					.confirm()
					.title('Would you like to delete "' + section.title + '"?')
					.textContent('This operation cannot be undone.All dependants of this section will also be removed alsoo.')
					.ariaLabel('Warning')
					.ok('Yes please!')
					.cancel('I dont think so');
				$mdDialog.show(confirm).then(() => {
					section_var
						.remove()
						.then((data) => {
							console.log('delete', data.plain());
							svm.loadItems(true);
						})
						.catch(ErrorService.handleError);
				});
			});
	};

	svm.view = function (section) {
		if (!section) {
			return;
		}
		SadminSetupClassLevelStore.set('selected_section', section);
		$state.go('sa.school_setup_sections_detail.enrolled', { cid: svm.class.id, sid: section.id });
	};

	svm.change = async function (section) {
		svm.isLoading = false;

		const url = `teacher?filters[school_id]=${svm.school.id}&filters[term_id]=${svm.selected_term.id}`;
		const teachers = await Restangular.all(url)
			.getList()
			.then((res) => res.plain())
			.catch(ErrorService.handleError)
			.finally(() => (svm.isLoading = false));

		$mdDialog.show({
			escapeToClose: false,
			clickOutsideToClose: false,
			bindToController: true,
			locals: {
				teachers,
				section,
				term: svm.selected_term,
			},
			templateUrl: 'views/pages/roles/sa/school_setup/classes/dialogs/edit_section.html',
			controller: EditSectionDialogController,
		});
	};

	svm.newSection = async () => {
		$mdDialog.show({
			escapeToClose: false,
			clickOutsideToClose: false,
			locals: {
				term: svm.selected_term,
				sclass: svm.class,
			},
			bindToController: true,
			templateUrl: 'views/pages/roles/sa/school_setup/classes/dialogs/add_section.html',
			controller: 'AddSectionController',
		});
	};
}

saSchoolSetupClassesSubSections.controller = saSchoolSetupClassesSubSectionsCmpController;

appModule.component('saSchoolSetupClassesSubSections', saSchoolSetupClassesSubSections);

saSchoolSetupClassesSubSections.selector = 'saSchoolSetupClassesSubSections';

export { saSchoolSetupClassesSubSections }

import './QuickActionsMenuCmp.scss';

import template from './QuickActionsMenuCmp.tpl.html';

const quckAcitonsMenuCmp = {
	template,
	/** @type {{floatOff:boolean}} */
	bindings: {
		floatOff: '<',
	},
};

class ComponentNameController {
	/** @type {auth}*/ auth;

	/** @type {typeof quckAcitonsMenuCmp.bindings.floatOff}*/ floatOff;

	/** @type {string} */ hash;

	constructor(auth, $window) {
		this.auth = auth;
		this.hash = $window.location.hash;
	}

	$onInit() {
		this.engaged_user_role = this.auth.getEngagedUserRole();
	}
}

quckAcitonsMenuCmp.controller = ComponentNameController;

appModule.component('quickActionsMenuCmp', quckAcitonsMenuCmp);

import template from './index.html';
/** @typedef {{title,description,school_id,location,start_date,end_date,color,type,}} SchoolEvent */

/** @type {import('@srcAjs/components2/adminRoutes').IComponentOptions2} */
const saHomeDashboardCmp = {
	bindings: {},
	template,
};

function SA_DashboardController(
	/** @type {ng.IScope} */ $scope,
	$mdDialog,
	$state,
	/** @type {ng.IRootScopeService} */ $rootScope,
	$winNotif,
	Restangular,
	$localStorage,
	lsfactory,
	SAFactory,
	TourGuide,
	weatherforcaster,
	weatherIcons,
	/** @type {DialogService} */ DialogService,
	CONST_APP_ENV,
	/** @type {atomosService} */ atomosService,
	/** @type {compSetupService} */ compSetupService,
	/** @type {ErrorService} */ ErrorService,
	/** @type {EffectService} */ EffectService,
	/** @type {API} */ API,
	/** @type {auth} */ auth
) {
	/**
	 * @type {typeof $scope & {
	 *  isReady:boolean,
	 *  user:{},
	 *  isProcessing:boolean,
	 *  myCroppedImage:string,
	 *  boolT:boolean,
	 *  eventsLinear:[],
	 *  isLoading:boolean,
	 *  weatherCity:string,
	 *  rootStopLoading:boolean,
	 *  env:{},
	 *  weather_obj:{},
	 *  events:[],
	 *  eventsInWeeks:[],
	 *  sel_year:Year,
	 *  sel_term:Term,
	 *  next,
	 *  t_count:number,
	 *  cancelSchool:Function,
	 *  showSchoolPage:Function,
	 *  isTermLoading:boolean,
	 *  school:School,ars:Year[],
	 *  terms:Term[],
	 *  showWizard:Function,
	 *  new_student:Function,
	 *  new_guardian:Function,
	 *  new_teacher:Function,
	 *  groups:[],
	 *  display:Function,
	 *  viewNotices:Function,
	 *  forecast,
	 * }}
	 */
	compSetupService(this);

	const vm = $scope;
	vm.isReady = false;
	vm.showSchoolPage = false;
	vm.user = $localStorage.user;
	vm.isProcessing = false;
	vm.isTermLoading = true;
	vm.myCroppedImage = '';
	vm.boolT = true;
	vm.eventsLinear = [];
	vm.isLoading = true;
	vm.weatherCity = {};
	vm.rootStopLoading = false;
	SAFactory.loadEnvironment();
	SAFactory.loadNotifications();
	vm.env = $localStorage.environment;
	lsfactory.setEnv('isFromDash', false);

	// SadminSetupClassLevelStore.clear()

	vm.weather_obj = null;
	lsfactory.setEnv('isFromDash', false);

	vm.events = [];
	vm.eventsInWeeks = {};

	vm.sel_year = {};
	vm.sel_term = {};

	vm.modules = [
		{ img: 'student.svg', text: 'Students', state: 'sa.students_browse' },
		{ img: 'guardian.svg', text: 'Guardians', state: 'sa.guardians_browse' },
		{ img: 'teacher.svg', text: 'Teachers', state: 'sa.teachers_browse' },
		{ img: 'real-estate.svg', text: 'Departments', state: 'sa.school_setup_class_groups' },
		{ img: 'board.svg', text: 'Class Levels', state: 'sa.school_setup_classes' },
		{ img: 'books.svg', text: 'Subjects', state: 'sa.school_setup_subjects' },
		{ img: 'test.svg', text: 'Grading', state: 'sa.school_setup_grading' },
		{ img: 'year.svg', text: 'Years & Terms', state: 'sa.school_setup_years' },
		{ img: 'university.svg', text: 'Settings', state: 'sa.school_setup_info' },
		{ img: 'calendar.svg', text: 'Events', state: 'sa.events' },
		{ img: 'daily.svg', text: 'Notices', state: 'sa.notices' },
		{ img: 'statistics.svg', text: 'Analytics', state: 'sa.analysis' },
		{ img: 'diploma.svg', text: 'Reports', state: 'sa.reports' },
		{ img: 'settings.svg', text: 'Admins', state: 'sa.school_setup_admins' },
		{ img: 'photo-camera.svg', text: 'Gallery', state: 'sa.school_setup_photo' },
		{ img: 'gift.svg', text: 'Downloads', state: 'sa.downloads' },
		{ img: 'lifesaver.svg', text: 'Help', state: 'sa.help_support' },
		{ img: 'medal.svg', text: 'Promotions', state: 'sa.operations_promotion.home' },
		{ img: 'grad.svg', text: 'Alumni', state: 'sa.alumni' },
		{ img: 'checked.svg', text: 'Check Attendance', state: 'sa.operations_check_attendance' },
		{ img: 'approved.svg', text: 'Approve Scores', state: 'sa.operations_approve_scores' },
		{ img: 'envelope-9.svg', text: 'Delegated Requests', state: 'sa.delegated_requests' },
		{
			img: 'money.svg',
			text: 'Student Billing',
			state: 'sa.manage_billing.students',
		},
		// { img: 'smartphone-1.svg', text: 'Mobile', state: 'sa.mobile' },
	];

	const tour = {
		steps: [
			{
				element: 'sc-sa-aside-nav',
				text: "System's navigation panel which is usable to navigate to all places the administrator may wish to see",
				direction: 'bottom',
			},
			{
				element: 'sc-sa-dashboard-shortcuts',
				text: 'Get work done quickly by using shortcuts. You could enter a shortcut name in field above to show it',
			},
			{
				element: 'sc-sa-dashboard-term-card',
				text: 'Displays term information you may appriciate and includes some quick links for term operations',
			},
			{
				element: 'sc-sa-dashboard-forecaster',
				text: 'See what the weather will be where you are, always be ready but we love sunshine',
			},
			{
				element: 'sc-sa-dashboard-calendar-events',
				text: 'Schrep is launching soon, see and add your events through extra controls',
			},
		],
	};

	EffectService.setup(this, ({ onInit }) => {
		if (onInit) {
			vm.isTermLoading = true;
			atomosService(auth.authData$, this).stream$.subscribe((data) => {
				if (!data.engagedUserRole?.school) return;

				vm.school = data.engagedUserRole.school;
				loadClassGroups(vm.school);
				loadSchoolEventsRxQ(vm.school).then((res) => {
					processEventsIntoWeeks(res.data?.plain());
				}, ErrorService.handleError);
			});

			const watchers = [
				vm.$on('system.startupHook', () => {
					SAFactory.initializeRole();
				}),
				$rootScope.$watch('rootStopLoading', (newValue) => {
					if (newValue !== undefined) {
						vm.rootStopLoading = newValue;
					}
				}),
				vm.$on('system.sa.environmentChanged', (ev, data) => {
					vm.env = data;
					vm.school = vm.school;

					vm.isReady = true;
					vm.isProcessing = false;

					if (!vm.school) {
						return;
					}

					revalidateClassGroups();

					loadSchoolEvents();

					weatherforcaster.getWeatherForcast(vm.env); // Should start happening right here
				}),

				vm.$on('system.firsttime', (ev) => {
					$mdDialog
						.show({
							clickOutsideToClose: false,
							scope: $scope,
							templateUrl: 'views/pages/roles/sa/dialogs/welcome-dialog-template.html',
							controller: WelcomeDialogController,
						})
						.then(() => {
							$winNotif.show({
								title: 'Welome to Schrep',
								msg: 'You are welcome to the schrep platform. Take a tour to begin',
								acceptText: 'Take Tour',
								declineText: 'Skip',
								imgUrl: 'images/logo.png',
								timeout: 9000,
								hasButtons: true,
								accept() {
									// What if accepted
									TourGuide.startTour(tour);
									$winNotif.hide();
								},
								decline() {
									// What if declined

									$winNotif.hide();
								},
							});
						});
				}),

				vm.$on('weather_forcast_read', (event, data) => {
					vm.weatherCity = data.city;
					vm.forecast = prepareWeatherIcons(data.list);
					console.dir(vm.forecast);
				}),
			];

			return () => watchers.forEach((e) => e());
		}
	});

	vm.tmanager = function () {
		lsfactory.setEnv('isFromDash', true);
		lsfactory.setEnv('backLog', $state.current.name);
		$state.go('sa.school_setup_terms_detail.new_mounted', { yid: vm.sel_term.year_id, tid: vm.sel_term.id });
	};

	vm.cancelSchool = function () {
		vm.showSchoolPage = false;
	};

	vm.updateTerm = (term) => {
		vm.sel_term = term;
		vm.isTermLoading = false;
	};

	vm.showWizard = function (ev) {
		$rootScope.rootStopLoading = true;
		// vm.showSchoolPage = true;
		$mdDialog.show({
			clickOutsideToClose: true,
			scope: $scope,
			templateUrl: 'views/pages/roles/sa/dialogs/school-wizard-template.html',
			controller: SchoolWizardDialogController,
		});
	};

	vm.new_student = function () {
		$state.go('sa.students_create');
	};

	vm.new_guardian = function () {
		$state.go('sa.guardians_create');
	};

	vm.new_teacher = function () {
		$state.go('sa.teachers_create');
	};

	const [, { mutate: loadSchoolEventsRxQ }] = API.rxQuery(
		['rxq.events.school'],
		(r, /** @type {Partial<School>} */ school) => /** @type {import('restangular').ICollectionPromise<SchoolEvent>} */ (r.one('school', school.id).all('events_latest').getList()),
		{
			destroy$: this.componentDestroy$,
			enabled: false,
			qInput$: undefined,
		}
	);

	function loadSchoolEvents(school = vm.school) {
		if (!school) return;

		loadSchoolEventsRxQ(school);
	}

	const [, { mutate: getClassGroups, revalidate: revalidateClassGroups }] = API.rxQuery(
		['rxq.class_groups.school'],
		(r, /** @type {School} */ school) =>
			/** @type {ng.restangular.ICollectionPromise<ClassGroup>} */ (
				/**@type {ng.restangular.ICollectionPromise<ClassGroup&{classes:SClass[],grading_system,class_group_type}>} */ (
					r
						.all(
							'class-group?' +
								API.buildParams_v2({
									filters: [{ condition: 'where', column: 'school_id', value: school.id }],
									// 'grading_system', 'class_group_type', 'classes.class_level'
									fields: [
										{ condition: 'with', field: 'grading_system' },
										{ condition: 'with', field: 'class_group_type' },
										{ condition: 'with', field: 'classes.class_level' },
									],
								})
						)
						.getList()
				)
			),
		{
			destroy$: this.componentDestroy$,
			enabled: false,
			qInput$: undefined,
			expire: 60 * 1000 * 5,
			onSuccess: ({ data, loading, error }) => {
				vm.isReady = !loading;
				vm.groups = undefined;
				if (error) return ErrorService.handleError(error);
				if (!data) return;
				vm.groups = data.plain();
				lsfactory.setEnv('school_groups', vm.groups);
			},
		}
	);

	this.revalidateClassGroups = revalidateClassGroups;

	function loadClassGroups(school = vm.school) {
		if (!school) {
			return;
		}

		// getClassGroups(school);
	}

	function processEventsIntoWeeks(events) {
		vm.eventsInWeeks = {};
		events.forEach((event) => {
			addEventToWeek(event); // add each event to eventInWeek
		});
	}

	function addEventToWeek(event) {
		const initial_length = vm.eventsInWeeks.length;
		const week = moment(event.start_date).week() + '_week';
		if (typeof vm.eventsInWeeks[week] === 'undefined') {
			vm.eventsInWeeks[week] = [];

			const title =
				moment(event.start_date).format('MMMM') + ' ' + moment(event.start_date).startOf('week').format('D') + ' - ' + moment(event.start_date).endOf('week').format('D');
			vm.eventsInWeeks[week] = {
				title,
				days: [],
			};
		}

		const eventEntry = {
			date: moment(event.start_date).format('D/M/YYYY'),
			day: moment(event.start_date).format('ddd'),
			date_number: moment(event.start_date).format('D'),
			event,
			type: 'event',
		}; // setup eventEntry to be added into days of weeks

		if (vm.eventsInWeeks[week].days.length == 0) {
			eventEntry.showDate = true;
			vm.eventsInWeeks[week].days.push({ number: eventEntry.date_number, entries: [eventEntry] });
		} else {
			for (let i = 0; i < vm.eventsInWeeks[week].days.length; i++) {
				if (vm.eventsInWeeks[week].days[i].number == eventEntry.date_number) {
					vm.eventsInWeeks[week].days[i].entries.push(eventEntry);
				} else {
					vm.eventsInWeeks[week].days.push({ number: eventEntry.date_number, entries: [eventEntry] });
				}
			}
		}
		if (vm.eventsInWeeks.length > initial_length) return true;
		new Error('Failed to add event to a week');
	}

	vm.display = function (event) {
		lsfactory.setEnv('viewEvents', event);
		$mdDialog.show({
			escapeToClose: true,
			clickOutsideToClose: true,
			templateUrl: 'views/pages/roles/sa/events/dialogs/show_event.html',
			controller: ShowEventDialogController,
		});
	};

	// Defining the scope controller for the view notices dialog
	vm.viewNotices = function (itemNotice) {
		// TODO: get notices from here on out.
		lsfactory.setEnv('dialogNoticeSelected', angular.copy(itemNotice));
		$mdDialog.show({
			clickOutsideToClose: false,
			escapeToClose: false,
			templateUrl: 'views/pages/roles/sa/notices/dialogs/view_notice.html',
			controller: ViewNoticeDialogController,
		});
	};

	function prepareWeatherIcons(responseList) {
		const list = [];
		for (let i = 0; i < responseList.length; i++) {
			const weather = responseList[i].weather[0];
			weather.icon = weatherIcons.getIcon(weather);
			weather.temp = responseList[i].main.temp;
			weather.day = responseList[i].day;
			list.push(weather);
		}
		return list;
	}
}

saHomeDashboardCmp.controller = SA_DashboardController;

appModule.component('saHomeDashboardCmp', saHomeDashboardCmp);

saHomeDashboardCmp.selector = 'saHomeDashboardCmp';

export { saHomeDashboardCmp };

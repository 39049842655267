import template from './SubjectGradebookCmp.tpl.html';

import './SubjectGradebookCmp.scss';

/** @type {import('@srcAjs/components2/adminRoutes').IComponentOptions2} */
const subjectGradebookCmp = {
	template,
	bindings: {},
	selector: 'subjectGradebookCmp',
};

function ComponentNameController(
	/** @type {ng.IScope & Object.<string,any>} */ $scope,
	/** @type {ErrorService} */ ErrorService,
	/** @type {import('angular-ui-router').IStateService} */ $state,
	/** @type {lsfactory} */ lsfactory,
	$rootScope,
	/** @type {ng.material.IDialogService} */ $mdDialog,
	/** @type {TRFactory} */ TRFactory,
	/** @type {API} */ API,
	/** @type {ng.IFilterService} */ $filter,
	/** @type {ng.material.IDialogService}*/ $mdToast,
	/** @type {getGradeColorFilter} */ getGradeColorFilter,
	/** @type {EffectService} */ EffectService,
	/** @type {atomosService} */ atomosService,
	/** @type {compSetupService} */ compSetupService,
	/** @type {ng.IStateParams} */ $stateParams,
	/** @type {RxService} */ RxService
) {
	compSetupService(this);

	const vm = this;
	const PERCENT = 100;
	/** @type {Term} */ vm.term = null;
	/** @type {StudentTermSubject[]} */ vm.term_students = null;
	/** @type {Array<AssessmentScore[]>} */ vm.score_data_groups = null;
	/** @type {SubjectMount} */ vm.subjectMount = null;
	/** @type {Section} */ vm.section = lsfactory.getEnv('teacher').section;
	/** @type {SubjectAssessmentEntity[]} */ vm.subject_assessment_entities = null;
	vm.$transition$ = null;
	vm.openRemarksDialog = null;
	vm.sortedStudentTermSubjects = null;

	TRFactory.loadEnvironment();
	vm.school = lsfactory.getEnv('school');
	$rootScope.isCTermDisabled = true;
	vm.isReady = false;
	vm.myData = {};
	// vm.loadStudentAnalytics = loadStudentAnalytics;
	const apiCache = TRFactory.getApiCache();
	vm.sortChoice = 'score';

	$('#when_assessments_are_available').hide();
	$('#when_assessments_are_not_available').hide();

	vm.gotoAssessments = () => {
		$state.go('tr.subject.assessment');
	};

	function sortStudentTermSubjects(/** @type {StudentTermSubject[]} */ studentTermSubjects, /** @type {"avg"|"name"} */ sortOption, score_data_groups = vm.score_data_groups) {
		const sortKey = sortOption === 'name' ? '+section_history.student.firstname' : '-avg_per';
		return $filter('orderBy')(studentTermSubjects, sortKey).map((term_student) => ({
			...term_student,
			sae_scores: getStudentTermSubjectScoreGroup(term_student.id, score_data_groups),
		}));
	}

	function getStudentTermSubjectScoreGroup(student_term_subject_id, /** @type {AssessmentScore[][]} */ score_groups) {
		// find score_group by comparing its student_term_subject_id
		return score_groups.find((scores) => scores.some((score) => score?.student_term_subject_id === student_term_subject_id));
	}

	vm.getStudentTermSubjectScoreGroup = getStudentTermSubjectScoreGroup;

	vm.sortStudentTermSubjects = sortStudentTermSubjects;

	function mapGroupsToEntitiesWithTotal(/** @type {SubjectAssessmentGroup[]} */ assessment_groups) {
		return assessment_groups
			.flatMap((ag) => ag.subject_assessment_entities)
			.map((entity) => {
				entity.subject_assessment_scores = entity.subject_assessment_scores.map((score) => ({ ...score, total: entity.total }));
				return entity;
			});
	}

	function gen_scores(/** @type {SubjectAssessmentEntity[]} */ sa_entities, /** @type {StudentTermSubject[]} */ term_students) {
		const blankScore = /** @type {AssessmentScore} */ { score: null, comment: null, total: null, fractional_score: 0 };
		// subject_assessment_entities
		const /** @type {Array<AssessmentScore[]>} */ scores = _.map(term_students, (student) => {
				const /** @type {AssessmentScore[]} */ std_scores = _.map(sa_entities, (assessment) => {
						const foundStsEntScore = assessment.subject_assessment_scores.find((score) => student.id === score.student_term_subject_id);

						return foundStsEntScore ?? angular.copy({ ...blankScore, id: crypto?.randomUUID() ?? new Date().toISOString() });
					});

				return std_scores.map((score) => ({ ...score }));
			});
		return scores;
	}

	function getScoreFromLot(student_term_subject_id, /** @type AssessmentScore[] */ scores) {
		const score = scores.find((st_score) => st_score?.student_term_subject_id === student_term_subject_id);
		return score?.score;
	}

	function addin_caculated_weights(/** @type {StudentTermSubject[]} */ term_students, /** @type {CachedAssessmentGroup[]} */ assessment_groups) {
		// This is a verified calculation [Server Side Compliant]
		return angular.copy(term_students).map((term_student) => {
			let total_groups = 0;
			const student_term_subject_id = term_student.id;
			for (const assessment_group of assessment_groups) {
				let sum_totals = 0;
				let sum_scores = 0;
				const group_weight = assessment_group.subject_assessment_group.weight;
				for (const entity of Object.values(assessment_group.subject_assessment_entities)) {
					if (entity.subject_assessment_entity.gradable) {
						// Factor in the grading

						let score = getScoreFromLot(student_term_subject_id, Object.values(entity.subject_assessment_scores));
						score = parseFloat(score ?? 0);
						const total = parseFloat(entity.subject_assessment_entity.total);
						sum_totals += total;
						sum_scores += score;
					}
				}
				sum_totals = sum_totals == 0 ? 1 : sum_totals;
				const total_percent = Math.round((sum_scores / sum_totals) * PERCENT);
				const real_weighted_percent = (total_percent / PERCENT) * group_weight;
				total_groups += real_weighted_percent;
			}
			term_student['avg_per'] = total_groups;
			return term_student;
		});
	}

	vm.goto_score_sheet = function (obj) {
		const confirm = $mdDialog
			.confirm()
			.title('Do you really want to open "' + obj.title + '"?')
			.textContent('You are moving to the scores of "' + obj.title + '". Are you sure?')
			.ariaLabel('Warning')
			.ok('Yes please!')
			.cancel('I dont think so');

		$mdDialog.show(confirm).then(() => {
			lsfactory.setEnv('checkAssessmentBefore', obj);
			$state.go('tr.subject.assessment');
		});
	};

	const [asGroups$, { revalidate: refreshGradebook, mutate }] = TRFactory.gradebookRxQ(this);

	vm.refreshGradebook = refreshGradebook;

	EffectService.setup(this, ({ onInit }) => {
		if (onInit) {
			vm.sortedStudentTermSubjects = [];
			atomosService(TRFactory.trServiceAtom.stream$, this).stream$.subscribe((atom) => {
				const data = atom;
				if (!data.selectedTerm) return;
				vm.term = data.selectedTerm;
				vm.subjectMount = TRFactory.trServiceAtom.get().selectCachedSubjectMount($stateParams.subject_mount_id).subject_mount;

				mutate(vm.subjectMount);
				loadStudentAnalytics(vm.term, false, vm.subjectMount);
			});

			asGroups$.subscribe(({ data: res, error, loading }) => {
				if (error) return ErrorService.handleError(error);

				vm.isReady = !loading;

				if (!res) return;
				vm.myData = res.plain();

				if (vm.myData.assessment_data.subject_assessment_groups.length == 0) {
					$('#when_assessments_are_available').hide();
					$('#when_assessments_are_not_available').show();
				} else {
					$('#when_assessments_are_not_available').hide();
					$('#when_assessments_are_available').show();
				}

				const { subject_assessment_groups, subject_mount } = TRFactory.trServiceAtom.get().selectCachedSubjectMount($stateParams.subject_mount_id);

				let term_students = angular.copy(subject_mount.term_students);

				vm.subject_assessment_entities = mapGroupsToEntitiesWithTotal(vm.myData.assessment_data.subject_assessment_groups);

				vm.score_data_groups = gen_scores(vm.subject_assessment_entities, term_students);

				term_students = addin_caculated_weights(term_students, Object.values(subject_assessment_groups));

				vm.sortedStudentTermSubjects = vm.sortStudentTermSubjects(term_students, 'avg', vm.score_data_groups);

				vm.term_students = term_students;
			});
		}
	});

	vm.loadStudentAnalytics = loadStudentAnalytics;

	const [studentMutation$, { mutate: mutateStudent }] = TRFactory.updateTermStudentRxM(vm);

	atomosService(studentMutation$, this).stream$.subscribe(({ data, error, loading }) => {
		// vm.isReady = !loading;
		if (error) return ErrorService.handleError(error);
		if (!data) return;

		const response = data.plain();

		const term_students = vm.term_students.map((ts) => {
			if (ts.id === response.id) {
				const { remarks, strengths } = response;
				ts = { ...ts, remarks, strengths };
			}

			return ts;
		});

		TRFactory.updateAtom((atom) => {
			atom.termsCache[atom.selectedTermId].subject_mounts[response.subject_mount_id].subject_mount.term_students = term_students;
			return { ...atom };
		});
	});

	function loadStudentAnalytics(/** @type {Term} */ term, refresh = true, /** @type {SubjectMount} */ newSubjectMount) {
		$state.current.data.title = vm.subjectMount.subject.title + ' - ' + vm.subjectMount.section.title;

		const update = async (/** @type {StudentTermSubject} */ subject_term_student) => mutateStudent(subject_term_student);

		vm.openRemarksDialog = (/** @type {StudentTermSubject} */ studentTermSubject, /** @type {SubjectMount} */ subjectMount, ev) => {
			$mdDialog
				.show({
					template: `
						<student-term-subject-edit-cmp student-term-subject='$ctrl.studentTermSubject' 
							subject-mount="$ctrl.subjectMount" on-update='$ctrl.onUpdate(studentTermSubject)' 
							on-hide="$ctrl.onHide(response)"
							layout
							layout-align="center start"
							flex="95" flex-gt-sm="40"
							>
							<dialog-debug flex="100">
								<span>{{$ctrl.subjectMount}}</span>
							</dialog-debug>
						</student-term-subject-edit-cmp>`,
					targetEvent: ev.currentTarget,
					clickOutsideToClose: false,
					locals: {
						studentTermSubject: angular.copy(studentTermSubject),
						subjectMount,
						onUpdate: (response) => update(response),
						childCmpName: 'studentTermSubjectEditCmp',
						onHide: (response) => $mdDialog.hide(response),
					},
					controller: 'DumbController',
					bindToController: true,
					controllerAs: '$ctrl',
					autoWrap: false,
				})
				.then((newSubjectMount) => {
					if (!newSubjectMount) {
						return;
					}
					loadStudentAnalytics(vm.term, true, newSubjectMount);
				}, ErrorService.handleError);
		};
	}
}

subjectGradebookCmp.controller = ComponentNameController;

appModule.component('subjectGradebookCmp', subjectGradebookCmp);

export { subjectGradebookCmp as gradebookCmp };

import template from './index.html';

/** @type {import('@srcAjs/components2/adminRoutes').IComponentOptions2} */
const saSchoolSetupYearsDetailTerm = {
	bindings: {},
	template,
	selector: '',
};

function saSchoolSetupYearsDetailTermCmpController(
	SAFactory,
	$scope,
	$cookies,
	/** @type {import('@uirouter/angularjs').StateParams} */ $stateParams,
	$state,
	$rootScope,
	$localStorage,
	$mdDialog,
	$mdToast,
	lsfactory,
	Restangular,
	Years,
	Term
) {
	SAFactory.loadEnvironment();
	SAFactory.loadNotifications();
	if (lsfactory.getEnv('isFromDash')) {
		// No Nothing Cuz Backlog has been set from there in addition
	} else {
		lsfactory.setEnv('backLog', 'sa.school_setup_years_detail.terms');
		lsfactory.setEnv('backLogData', { id: lsfactory.getEnv('currentYearSelected').id });
	}

	$scope.term = lsfactory.getEnv('currentTermSelected');
	$scope.isReady = false;
	$scope.prev = null;
	$scope.count = 0;
	$scope.status = undefined;

	$scope.update_percentage = function () {
		var now = moment();
		const startDate = moment($scope.term.start);
		const endDate = moment($scope.term.end);
		var percentage_complete = ((now - startDate) / (endDate - startDate)) * 100;
		var percentage_rounded = Math.round(percentage_complete * 100) / 100;
		$scope.percy = percentage_rounded;
	};

	$scope.statuses = [
		{ display: 'Active', code: 'active' },
		{ display: 'Inactive', code: 'inactive' },
		{ display: 'Concluded', code: 'ended' },
	];

	if ($stateParams.yid != '') {
		Years.one($stateParams.yid)
			.get()
			.then(
				function (res) {
					if ($stateParams.tid != '') {
						Term.one($stateParams.tid)
							.get()
							.then(
								function (res) {
									var obj_sub = res.plain();
									$scope.term = res;
									$scope.status = $scope.term.status;
								},
								function (err) {
									if (err.status == 404) {
										$state.go('sa.school_setup_years_detail.terms', { id: $stateParams.yid });
									}
								}
							);
					}
				},
				function (err) {
					if (err.status == 404) {
						$state.go('sa.school_setup_years');
					}
				}
			);
	}
	$rootScope.$watch('isReady', function (newValue, oldValue) {
		if (newValue !== undefined) {
			$scope.isReady = newValue;
		}
	});

	$scope.$watch('status', function (newValue, oldValue) {
		var title = '';
		var message = '';

		switch (newValue) {
			case $scope.statuses[0].code:
				title = 'Term status to "Active"';
				message =
					'This operation is going to set this term as the current term of the current academic year. This will cause other current terms to be switched to default. Do you want to continue?';
				break;
			case $scope.statuses[1].code:
				title = 'Term status to "Inactive"';
				message = 'This operation will place the term in an inactive state. Do you want to continue?';
				break;
			case $scope.statuses[2].code:
				title = 'Term status to "Concluded"';
				message = 'This operation is going set this term as concluded. This status indicates that the academic term is over. Do you want to continue?';
				break;
		}

		if (newValue != null && newValue !== undefined && newValue != $scope.prev) {
			if ($scope.count > 0) {
				var confirm = $mdDialog.confirm().title(title).textContent(message).ariaLabel('Warning').ok('Yes please!').cancel('I dont think so');
				$mdDialog.show(confirm).then(
					function () {
						if (newValue) {
							Restangular.all('term/set-status')
								.post({
									term_id: $scope.term.id,
									year_id: lsfactory.getEnv('currentYearSelected').id,
									status: newValue,
									school_id: $scope.school.id,
								})
								.then(function (res) {
									$rootScope.$broadcast('system.selected_term_status_changed', newValue);
									SAFactory.loadEnvironment();
									var template = $mdToast.simple().textContent('Term Status Changed').action('OK').highlightAction(true).hideDelay(5500);
									$mdToast.show(template);
									$scope.prev = newValue;
								});
						}
					},
					function () {
						$scope.status = oldValue;
						$scope.prev = oldValue;
					}
				);
			}
			$scope.count++;
		}
	});

	$scope.$on('system.selected_term_status_changed', function (event, data) {
		$scope.term.status = data;
	});

	$scope.remove = function (year) {
		Years.one(year.id)
			.get()
			.then(function (res) {
				var yr = res;
				var confirm = $mdDialog
					.confirm()
					.title('Would you like to delete "' + year.year + '/' + $scope.next(year.year) + '" academic year"?')
					.textContent('This operation cannot be undone. All dependants of this academic year will also be removed also.')
					.ariaLabel('Warning')
					.ok('Yes please!')
					.cancel('I dont think so');
				$mdDialog.show(confirm).then(
					function () {
						yr.remove().then(function () {
							SAFactory.loadEnvironment();
						});
					},
					function () {}
				);
			});
	};

	$scope.$on('system.sa.environmentChanged', function (ev, data) {
		if (data !== undefined) {
			$scope.env = data;
			$scope.school = data.school;
			if ($stateParams.yid != '') {
				Years.one($stateParams.yid)
					.get()
					.then(
						function (res) {
							if ($stateParams.tid != '') {
								Term.one($stateParams.tid)
									.get()
									.then(
										function (res) {
											var obj_sub = res.plain();
											$scope.term = res;
											$scope.status = $scope.term.status;
											$scope.update_percentage();
										},
										function (err) {
											if (err.status == 404) {
												$state.go('sa.school_setup_years_detail.Term', { id: $stateParams.yid });
											}
										}
									);
							}
						},
						function (err) {
							if (err.status == 404) {
								$state.go('sa.school_setup_years');
							}
						}
					);
			}
		}
	});
}

saSchoolSetupYearsDetailTerm.controller = saSchoolSetupYearsDetailTermCmpController;

appModule.component('saSchoolSetupYearsDetailTerm', saSchoolSetupYearsDetailTerm);

saSchoolSetupYearsDetailTerm.selector = 'saSchoolSetupYearsDetailTerm';

export { saSchoolSetupYearsDetailTerm };

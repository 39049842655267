import './DelegatedRequests.scss';

import template from './DelegatedRequests.tpl.html';

const delegatedRequestsCmp = {
	template,
	bindings: {},
};

class ComponentNameController {
	#Restangular;

	#ErrorService;

	#API;

	#$mdToast;

	#auth;

	gc_requests;

	sc_requests;

	constructor($scope, Restangular, ErrorService, API, $mdToast, auth) {
		this.$scope = $scope;
		this.#Restangular = Restangular;
		this.#ErrorService = ErrorService;
		this.#API = API;
		this.#$mdToast = $mdToast;
		this.#auth = auth;
	}

	$onInit = () => {
		this.#fetchStudentCreationRequests();
	};

	#fetchStudentCreationRequests = () => {
		const params = {
			filters: [
				{
					condition: 'whereIn',
					column: 'type',
					value: [
						encodeURI('App\\Notifications\\StudentCreationRequested'),
						encodeURI('App\\Notifications\\ExistingStudentGuardianCreationRequested'),
					],
				},
				{ condition: 'where', column: 'data->status', value: 'pending' },
				{ condition: 'where', column: 'data->user_role->school_id', value: this.#auth.getEngagedUserRole().school_id },
			],
			has_fields: null,
			fields: null,
		};

		this.#Restangular
			.all(`notifications?${this.#API.buildParams(params.filters, params.has_fields, params.fields, null, '1.4')}`)
			.getList()
			.then((c_requests) => {
				this.sc_requests = c_requests.plain().filter(({ type }) => type == 'App\\Notifications\\StudentCreationRequested');
				this.gc_requests = c_requests.plain().filter(({ type }) => type == 'App\\Notifications\\ExistingStudentGuardianCreationRequested');
			}, this.#ErrorService.handleError);
	};

	#fetchGuardianMakeAttachmentRequests = () => {};

	approveAndMarkRead = (sc_request_entry) => {
		const request_data = angular.copy(sc_request_entry);
		request_data.data.status = 'approved';

		this.#Restangular
			.all('student/approve_student_creation_request')
			.post(request_data)
			.then(() => {
				this.#$mdToast.showSimple('Request Approved');
				this.#fetchStudentCreationRequests();
			})
			.catch(this.#ErrorService.handleError);
	};

	denyAndMarkRead = (sc_request_entry) => {
		const request_data = angular.copy(sc_request_entry);
		request_data.data.status = 'denied';

		this.#Restangular
			.all('student/approve_student_creation_request')
			.post(request_data)
			.then(() => {
				this.#$mdToast.showSimple('Request Denied');
				this.#fetchStudentCreationRequests();
			})
			.catch(this.#ErrorService.handleError);
	};
}

delegatedRequestsCmp.controller = ['$scope', 'Restangular', 'ErrorService', 'API', '$mdToast', 'auth', ComponentNameController];

appModule.component('delegatedRequestsCmp', delegatedRequestsCmp);

// export default component

import template from './index.html';

/** @type {import('@srcAjs/components2/adminRoutes').IComponentOptions2} */
const saSchoolSetupYearsSubTermsNewMountedSubjects = {
	template,
	bindings: {
		//someInput: '@string,<object,&function'
	},
	transclude: {
		// #slotName: '?optionalPublicName'
	},
};

function saSchoolSetupYearsSubTermsNewMountedSubjectsCmpController(
	SadminSetupClassLevelStore,
	$scope,
	$state,
	$mdDialog,
	$mdToast,
	lsfactory,
	Restangular,
	ErrorService,
	TourGuide,
	$winNotif,
	$timeout,
	$rootScope
) {
	$scope.sel_year = lsfactory.getEnv('currentYearSelected');
	$scope.sel_term = lsfactory.getEnv('currentTermSelected');
	$scope.term = $scope.sel_term;
	// $scope.sel_term = $stateParams.term;
	$scope.school = lsfactory.getEnv('school');
	$scope.obj = { selectedItem: null, selectedClassLevel: null };
	$scope.next = function (year) {
		var y = parseInt(year);
		return y + 1;
	};
	// $state.current.data.title = $scope.sel_term.term_type.title + " ( " + $scope.sel_year.year + " / " + $scope.next($scope.sel_year.year) + " )"
	$state.current.data.title = `${$scope.sel_term.term_type.title} ${$scope.sel_year.title} version ${$scope.sel_term.term_type.version}`;
	$scope.my_data = [];
	$scope.teachers = [];
	$scope.current_section = null;
	$scope.context_subjects = [];
	$scope.diff_idxs = [];
	$scope.school = lsfactory.getEnv('school');
	$scope.level_count = -1;
	$('#when_default_selected').hide();
	$('#when_loading').show();
	$('#when_nothing_selected').hide();
	$('#when_class_selected').hide();
	$('#when_import_errors').hide();
	$('#when_loading_import').hide();
	$('#when_done_with_import').hide();
	var tour = {
		steps: [
			{
				element: 'class-0',
				text: 'Choose a class level to get started.',
			},
		],
	};

	$scope.$on('system.selected_term_status_changed', function (event, data) {
		$scope.sel_term.status = data;
	});

	$scope.showSubjectDetail = function (subm) {
		var remainderStudents = resolveRemainder(subm.section_histories, subm.class_students);
		var registeredStudents = resolveRegistered(subm.section_histories, subm.class_students);
		lsfactory.setEnv('dialogSubjectMountDetails', subm);
		lsfactory.setEnv('dialogTeachersList', $scope.teachers);
		lsfactory.setEnv('dialogSubjectMountEnrollment', subm.class_students);
		lsfactory.setEnv('dialogSubjectMountStudentRemainder', remainderStudents);
		lsfactory.setEnv('dialogSubjectMountRegisteredStudents', registeredStudents);
		$mdDialog.show({
			escapeToClose: true,
			clickOutsideToClose: true,
			templateUrl: 'views/pages/roles/sa/school_setup/years/dialogs/show_subject_detail.html',
			controller: ShowSubjectDetailDialogController,
		});
	};

	$scope.chooseTeacher = function (sub) {
		lsfactory.setEnv('dialogChosenSubject', sub);
		lsfactory.setEnv('classSection', $scope.current_section);
		lsfactory.setEnv('dialogTeachersList', $scope.teachers);

		$mdDialog.show({
			escapeToClose: true,
			clickOutsideToClose: true,
			templateUrl: 'views/pages/roles/sa/school_setup/years/dialogs/choose_teacher.html',
			controller: ChooseTeacherDialogController,
		});
	};
	$scope.back = function () {
		$('#when_showing_sections').show();
		$('#when_clicked_section').hide();
	};

	$scope.takeTour = function () {
		TourGuide.startTour(tour);
	};
	$scope.trigger = function (subject) {
		if ($scope.sel_term.status === 'ended') {
			$scope.term_concluded();
		} else {
			if (angular.equals(null, subject.mount)) {
				$scope.chooseTeacher(subject);
			} else {
				if (subject.mount.class_students.length == 0) {
					var template = $mdToast.simple().textContent('Class has no enrollment').action('OK').highlightAction(true).hideDelay(5500);
					$mdToast.show(template);
				} else {
					$scope.showSubjectDetail(subject.mount);
				}
			}
		}
	};
	$scope.section_clicked = function (section) {
		$('#when_showing_sections').hide();
		$('#when_clicked_section').show();
		$('#l_subjects_are_loading').show();
		$('#l_subjects_not_loading').hide();
		$('#when_import_errors').hide();
		$('#when_loading_import').hide();
		$('#when_done_with_import').hide();
		$scope.current_section = section;
		var poster = Restangular.all('term/get-subject-mount-by-term');
		poster
			.post({
				section_id: section.id,
				term_id: $scope.sel_term.id,
				s_class_id: section.s_class_id,
			})
			.then(
				function (res) {
					var data = res.plain();
					$scope.context_subjects = data;
					$('#l_subjects_are_loading').hide();
					$('#l_subjects_not_loading').show();
				},
				function (err) {
					console.log('There was an error saving');
				}
			);
	};
	$scope.class_handler = function (sclass) {
		$scope.selected_class_level = sclass;
		$scope.context_subjects = []; // Reset the value
		if ($scope.selected_class_level.sections.length > 0) {
			$('#when_default_selected').hide();
			$('#when_nothing_selected').hide();
			$('#when_loading').hide();
			$('#when_class_selected').show();
			$('#when_showing_sections').show();
			$('#when_clicked_section').hide();
			$('#when_import_errors').hide();
			$('#when_loading_import').hide();
			$('#when_done_with_import').hide();
		} else {
			$('#when_default_selected').hide();
			$('#when_nothing_selected').show();
			$('#when_loading').hide();
			$('#when_class_selected').hide();
			$('#when_showing_sections').hide();
			$('#when_clicked_section').hide();
			$('#when_import_errors').hide();
			$('#when_loading_import').hide();
			$('#when_done_with_import').hide();
		}
	};
	function resolveRemainder(section_histories, class_students) {
		var remmy = [];
		var remmy_idx = [];
		var class_idx = [];
		var term_idx = [];

		for (var i = 0; i < class_students.length; i++) {
			class_idx.push(class_students[i].id);
		}

		for (var j = 0; j < section_histories.length; j++) {
			term_idx.push(section_histories[j].id);
		}
		// Calc diff into remmy_idx
		remmy_idx = _.difference(class_idx, term_idx);

		for (var k = 0; k < remmy_idx.length; k++) {
			for (var l = 0; l < class_students.length; l++) {
				if (class_students[l].id == remmy_idx[k]) {
					remmy.push(class_students[l]);
				}
			}
		}
		return remmy; // 1738
	}

	function resolveRegistered(section_histories, class_students) {
		var registered = [];
		for (var i = 0; i < class_students.length; i++) {
			for (var j = 0; j < section_histories.length; j++) {
				if (class_students[i].id == section_histories[j].id) {
					registered.push(class_students[i]);
				}
			}
		}
		return registered; // 1738
	}

	$scope.$on('term_man_subject_mount_added', function (event, data) {
		_.each($scope.context_subjects, function (subject) {
			if (subject.id == data.mount.subject_id) {
				subject.mount = data.mount;
				subject.mount.class_students = data.class_students; // For the existing structure
			}
		});
	});

	$scope.$on('subject_detail.students_added', function (event, data) {
		_.each($scope.context_subjects, function (subject) {
			if (subject.id == data.mount.subject_id) {
				_.each(data.students, function (student) {
					subject.mount.section_histories.push(student);
				});
			}
		});
	});

	$scope.$on('subm.teacher_changed', function (event, data) {
		_.each($scope.context_subjects, function (subject) {
			var mount = subject.mount;
			if (mount.id !== undefined) {
				if (mount.id == data.mount.id) {
					mount.teacher = data.mount.teacher;
				}
			}
		});
	});

	$scope.term_concluded = function () {
		$mdDialog.show(
			$mdDialog
				.alert()
				.clickOutsideToClose(false)
				.title('This is term has ended')
				.textContent('This term has ended. You cannot make any more changes to the term unless its status is modified.')
				.ariaLabel('Term Concluded')
				.ok('Got it!')
		);
	};

	$scope.$on('show_subject_detail.student_unregistered', function (event, data) {
		_.each($scope.context_subjects, function (subject) {
			if (subject.mount.id == data.subject_mount_id) {
				var $index;
				for (var j = 0; j < subject.mount.section_histories.length; j++) {
					if (subject.mount.section_histories[j].id == data.section_history_id) {
						$index = j;
						break;
					}
				}
				subject.mount.section_histories.splice($index, 1); // Removing specific student from the UI
			}
		});
	});

	$scope.$on('import_term.loading', function (event, data) {
		$('#when_default_selected').hide();
		$('#when_loading').hide();
		$('#when_nothing_selected').hide();
		$('#when_class_selected').hide();
		$('#when_import_errors').hide();
		$('#when_loading_import').show();
		$('#when_done_with_import').hide();
	});

	$scope.$on('import_term.done_success', function (event, data) {
		$('#when_default_selected').hide();
		$('#when_loading').hide();
		$('#when_nothing_selected').hide();
		$('#when_class_selected').hide();
		$('#when_import_errors').hide();
		$('#when_loading_import').hide();
		$('#when_done_with_import').show();
	});

	$scope.$on('import_term.done_error', function (event, data) {
		$('#when_default_selected').hide();
		$('#when_loading').hide();
		$('#when_nothing_selected').hide();
		$('#when_class_selected').hide();
		$('#when_import_errors').show();
		$('#when_loading_import').hide();
		$('#when_done_with_import').hide();
	});

	var r_teachers = Restangular.all('teacher/by-school/' + $scope.school.id);
	r_teachers.getList().then(function (data) {
		var list = data.plain();
		$scope.teachers = list;
	});

	function loadClassGroups(term, _sclass) {
		let url = term.term_type.version == '1.2' ? `class?school_id=${$scope.school.id}&term_id=${term.id}&fields[]=sections` : 'class/by-school/' + $scope.school.id;
		var r_levels = Restangular.all(url);
		r_levels
			.getList()
			.then(function (data) {
				$scope.groups = data.plain();
				console.log('This is the groups object');
				console.dir($scope.groups);
				if (_sclass) {
					$scope.obj.selectedItem = _sclass.id;
				}
				$('#when_default_selected').show();
				$('#when_nothing_selected').hide();

				$('#when_class_selected').hide();
			}, ErrorService.handleError)
			.finally(() => $('#when_loading').hide());
	}

	loadClassGroups($scope.term);

	$scope.$on('system.sa.environmentChanged', () => {
		loadClassGroups($scope.term);
	});

	$scope.createNewSection = async (term, _sclass) => {
		$scope.isLoading = true;

		SadminSetupClassLevelStore.set('selected_sclass', _sclass);
		SadminSetupClassLevelStore.set('selected_term', term);

		let url = `teacher?filters[school_id]=${$scope.school.id}&filters[term_id]=${$scope.sel_term.id}&filters[relations][]=has_no_term_section`;
		let teachers = await Restangular.all(url)
			.getList()
			.then((res) => res.plain())
			.catch(ErrorService.handleError)
			.finally(() => ($scope.isLoading = false));

		if (!teachers.length) {
			$winNotif.show({
				title: 'No more teacher to assign',
				msg: 'To add a new Class, you must either unassign a teacher or create a new teacher.',
				acceptText: 'New Teacher',
				declineText: 'Dismiss',
				imgUrl: 'images/logo.png',
				timeout: 10000,
				hasButtons: true,
				accept: function () {
					$state.go('sa.teachers_create');
					$winNotif.hide();
				},
				decline: function () {
					$winNotif.hide();
				},
			});
			return;
		}

		$timeout(() => {}, 10);
		$mdDialog
			.show({
				escapeToClose: false,
				clickOutsideToClose: false,
				locals: {
					store: SadminSetupClassLevelStore,
					teachers,
					term,
					sclass: _sclass,
				},
				multiple: true,
				bindToController: true,
				templateUrl: 'views/pages/roles/sa/school_setup/classes/dialogs/add_section.html',
				controller: AddSectionController,
			})
			.then(() => {
				loadClassGroups($scope.term, _sclass);
			});
	};

	/**
	 *
	 * @param {string} state
	 * @param {Event} event
	 */
	$scope.goToClassLevelSections = (state, event) => {
		event.stopPropagation();

		if (!state || !state.length) ErrorService.handleError(new Error('Invalid valid state parameter'));

		$state.go(state);
	};
}

saSchoolSetupYearsSubTermsNewMountedSubjects.controller = saSchoolSetupYearsSubTermsNewMountedSubjectsCmpController;

appModule.component('saSchoolSetupYearsSubTermsNewMountedSubjects', saSchoolSetupYearsSubTermsNewMountedSubjects);

saSchoolSetupYearsSubTermsNewMountedSubjects.selector = 'saSchoolSetupYearsSubTermsNewMountedSubjects';

export { saSchoolSetupYearsSubTermsNewMountedSubjects };

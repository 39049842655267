import * as atomos from './atomos/atomos.mjs';

/** @typedef {ReturnType<atomosServiceFactory>} atomosService*/
function atomosServiceFactory(/** @type {RxService} */ RxService, $injector) {
	/**
	 * @template J
	 * @template {J extends atomos.AtomCb ? J : J extends atomos.AtomSource ? J : J} T
	 */
	const _atomos = (/** @type {T} */ initVal, /** @type {ng.IComponentController|typeof RxService.Observable} */ _this) => {
		const atom = atomos.atom(initVal);

		if (RxService.isObservable(_this)) {
			_this.pipe(RxService.take(1)).subscribe({ complete: () => atom.stream$.complete() });
		}

		if (!RxService.isObservable(_this) && typeof _this === 'object') {
			const compSetupService = /** @type {compSetupService}*/ ($injector.get('compSetupService'));
			compSetupService(/** @type {ng.IComponentController} */ _this);
			_this.componentDestroy$.subscribe({ complete: () => atom.stream$.complete() });
		}

		return atom;
	};

	_atomos.AtomGetter = atomos.AtomGetter;

	return _atomos;
}

appModule.factory('atomosService', atomosServiceFactory);

import template from './index.html';

/** @type {import('@srcAjs/components2/adminRoutes').IComponentOptions2} */
const saSchoolSetupGradingIndex = {
	template,
	bindings: {
		//someInput: '@string,<object,&function'
	},
	transclude: {
		// #slotName: '?optionalPublicName'
	},
};



function saSchoolSetupGradingIndexCmpController(
	SAFactory,
	$scope,
	$cookies,
	$mdToast,
	$state,
	$mdDialog,
	$rootScope,
	$winNotif,
	$localStorage,
	GradingSystem,
	Restangular,
	lsfactory
) {
	SAFactory.loadEnvironment();
	SAFactory.loadNotifications();

	$scope.school = lsfactory.getEnv('school');
	$scope.systems = [];
	$scope.showOne = false;
	$scope.isReady = false;

	// Initial Requests
	lsfactory.setEnv('backLog', 'sa.dashboard');

	var r_grading = Restangular.all('grading-system/by-school/' + $scope.school.id);
	r_grading.getList().then(function (data) {
		$scope.systems = data.plain();
		if ($scope.systems.length == 0) {
			$scope.showOne = true;
		} else {
			$scope.showOne = false;
		}
	});

	$scope.$watch(
		() => $rootScope.isReady,
		function (newValue, oldValue) {
			console.log('$scope.$watch triggered');
			if (newValue !== undefined) {
				$scope.isReady = newValue;
			}
		}
	);

	$scope.goto = function (system) {
		if (system !== undefined) {
			lsfactory.setEnv('currentGradingSystemSelected', system);
			lsfactory.setEnv('backLog', 'sa.school_setup_grading');
			$state.go('sa.school_setup_grading_detail.entry', { id: system.id });
		}
	};

	$scope.loadItems = function () {
		var r_grading = Restangular.all('grading-system/by-school/' + $scope.school.id);
		r_grading.getList().then(function (data) {
			$scope.systems = data.plain();
			if ($scope.systems.length == 0) {
				$scope.showOne = true;
			} else {
				$scope.showOne = false;
			}
		});
	};

	$scope.edit = function (system) {
		lsfactory.setEnv('gsEdit', angular.copy(system));
		$mdDialog.show({
			escapeToClose: false,
			clickOutsideToClose: false,

			templateUrl: 'views/pages/roles/sa/school_setup/grading/dialogs/edit_system.html',
			controller: EditGradingSystemController,
		});
	};

	$scope.remove = function (system) {
		if (system.class_groups.length > 0) {
			$winNotif.show({
				title: 'Cannot Remove',
				msg: 'You cannot remove this grading system because it is currently being used by your departments',
				acceptText: 'Go To Departments',
				declineText: 'Cancel',
				imgUrl: 'images/logo.png',
				timeout: 9000,
				hasButtons: true,
				accept: function () {
					// What if accepted
					$state.go('sa.school_setup_class_groups');
					$winNotif.hide();
				},
				decline: function () {
					// What if declined

					$winNotif.hide();
				},
			});
		} else {
			var confirm = $mdDialog
				.confirm()
				.title('Would you like to delete the "' + system.title + '" grading system."?')
				.textContent('This operation cannot be undone. All entries of this grading system will also be cleared also.')
				.ariaLabel('Warning')
				.ok('Yes please!')
				.cancel('I dont think so');
			$mdDialog.show(confirm).then(
				function () {
					GradingSystem.one(system.id)
						.get()
						.then(function (res) {
							var gs = res;
							gs.remove().then(function () {
								$scope.loadItems();
							});
						});
				},
				function () {}
			);
		}
	};

	$scope.$on('grading.dataChanged', function (ev, data) {
		console.log('$scope triggered');
		$scope.loadItems();
	});

	$scope.newGradingSystem = function () {
		$mdDialog.show({
			escapeToClose: false,
			clickOutsideToClose: false,

			templateUrl: 'views/pages/roles/sa/school_setup/grading/dialogs/add_system.html',
			controller: AddGradingSystemController,
		});
	};
}

// function controller(){		}
saSchoolSetupGradingIndex.controller = saSchoolSetupGradingIndexCmpController;

appModule.component('saSchoolSetupGradingIndex', saSchoolSetupGradingIndex);

saSchoolSetupGradingIndex.selector = 'saSchoolSetupGradingIndex';

export { saSchoolSetupGradingIndex }

import template from './termsAndConditionsCmp.tpl.html';

/** @type {import('@srcAjs/components2/adminRoutes').IComponentOptions2} */
const termsAndConditionsCmp = {
	template,
	bindings: {
		//someInput: '@string,<object,&function'
		onClose: '&',
	},
	transclude: {
		// #slotName: '?optionalPublicName'
	},
};

/** @this {ng.IController} */
function termsAndConditionsCmpController($scope, $mdDialog) {
	$scope.hide = function () {
		$mdDialog.hide();
	};

	$scope.cancel = function () {
		$mdDialog.cancel();
	};

	$scope.answer = function (answer) {
		$mdDialog.hide(answer);
	};
}

termsAndConditionsCmp.controller = termsAndConditionsCmpController;

appModule.component('termsAndConditionsCmp', termsAndConditionsCmp);

termsAndConditionsCmp.selector = 'termsAndConditionsCmp';

export { termsAndConditionsCmp };

import template from './AccessReviewschoolCmp.tpl.html';

import './AccessReviewschoolCmp.scss';

const accessReviewschoolCmp = {
	template,
	bindings: {},
};

function ReviewSchoolController(Restangular, $window, $scope, $transition$, $mdToast, $state, $rootScope, $localStorage, auth) {
	const vm = this;
	vm.start = true;
	vm.approved = false;
	vm.declined = false;
	vm.isLoading = true;
	vm.isPending = false;
	vm.box = {
		sap_id: -1,
		answer: null,
	};

	vm.admin = null;
	vm.school = null;

	vm.goto = function (lat, lng) {
		var win = window.open('https://www.google.com/maps/@' + lat + ',' + lng + ',11z?hl=en', '_blank');
		if (win) {
			//Browser has allowed it to be opened
			win.focus();
		} else {
			//Browser has blocked it
			alert('Please allow popups for this website');
		}
	};

	$scope.$on('cfpLoadingBar:started', function () {
		vm.isLoading = true;
	});

	$scope.$on('cfpLoadingBar:completed', function () {
		vm.isLoading = false;
	});

	vm.answer = function (answer) {
		vm.box.answer = answer;
		vm.isPending = true;
		Restangular.all('school/approval/perform')
			.post(vm.box)
			.then(function (res) {
				vm.isPending = false;
				if (res.status == 'approved') {
					vm.approved = true;
					vm.declined = false;
					vm.start = false;
				} else if (res.status == 'declined') {
					vm.approved = false;
					vm.declined = true;
					vm.start = false;
				}
			});
	};

	if ($transition$.params().token != '') {
		$rootScope.allowSub = true;
		Restangular.all('school/approval/check-token')
			.post({ token: $transition$.params().token })
			.then(function (res) {
				$rootScope.allowSub = false;
				if (res.status) {
					var data = res.plain();
					vm.box.sap_id = data.sap_id;
					vm.school = data.school;
					vm.admin = data.school.school_admin.user;
				} else {
					$state.go('access.signin').then(function () {
						var template = $mdToast.simple().textContent('Token Invalid.').hideDelay(1500);
						$mdToast.show(template);
					});
				}
			});
	} else {
		$state.go('access.signin').then(function () {
			var template = $mdToast.simple().textContent('No token provided.').hideDelay(1500);
			$mdToast.show(template);
		});
	}
}

accessReviewschoolCmp.controller = ['Restangular', '$window', '$scope', '$transition$', '$mdToast', '$state', '$rootScope', '$localStorage', 'auth', ReviewSchoolController];

appModule.component('accessReviewschoolCmp', accessReviewschoolCmp);

import "./studentCreationRequestList.scss"

import template from "./studentCreationRequestList.tpl.html"

const studentsCreationRequestListCmp = {
	template,
	bindings: {},
}

class ComponentNameController {
	section
	teacher

	/**
	 * @type []
	 */
	$scope
	pending_students

	/**@type{TRFactory()} */
	#TRFactory
	#ErrorService
	#$mdDialog
	#$mdToast
	#auth

	constructor($scope, $rootScope, lsfactory, $state, TRFactory, ErrorService, $mdDialog, $mdToast, auth) {
		console.log("locked and loaded")
		this.$scope = $scope
		this.$rootScope = $rootScope
		this.lsfactory = lsfactory
		this.$state = $state
		this.#TRFactory = TRFactory
		this.#ErrorService = ErrorService
		this.#$mdDialog = $mdDialog
		this.#$mdToast = $mdToast
		this.#auth = auth
	}

	$onInit = () => {
		this.$rootScope.isCTermDisabled = true
		this.teacher = this.lsfactory.getEnv("teacher")

		if (!this.teacher || !this.teacher?.section) {
			this.$state.go("tr.home.dashboard", {}, { location: "replace" })
		}

		this.section = this.teacher.section

		this.$scope.$watch(
			() => this.#TRFactory?.studentCreationRequests?.getStudentCreationRequests(),
			(student_creation_requests) => {
				if (!student_creation_requests) return

				this.pending_students = student_creation_requests
			}
		)
	}

	removeGuardian = (student_request_index, guardian_index, guardian_entry) => {
		let { firstname, othername, lastname } = guardian_entry.guardian
		//prompt
		this.#$mdDialog
			.show(
				this.#$mdDialog.confirm({
					textContent: `Do you want to deleted guardian: (${firstname} ${othername??''} ${lastname})`,
					ok: "Yes please!",
					cancel: "I dont think so",
				})
			)
			.catch((canceled) => this.#$mdToast.showSimple("Canceled"))
			.then(() => {
				this.#TRFactory.studentCreationRequests.removeGuardian(student_request_index, guardian_index)
				this.#$mdToast.showSimple("Guardian deleted")
			})
			.catch(this.#ErrorService.handleError)
	}

	requestCreation = (student, student_request_index) => {
		this.isLoading = true

		this.#TRFactory.studentCreationRequests
			.sendStudentCreationRequestNotification([[student, student_request_index]], this.#auth.getEngagedUserRole())
			.then((res) => this.#$mdToast.showSimple("Request sent: check notification for updates"))
			.catch(this.#ErrorService.handleError)
			.finally(() => (this.isLoading = false))
	}

	removeStudentCreationRequest = (/**@type StudentCreationRequest*/ student_cr, student_cr_index) => {
		this.#$mdDialog
			.show(
				this.#$mdDialog.confirm({
					textContent: "Are you sure you want to delete this student creation request?",
					cancel: "Nope",
					ok: "Yes Please!",
				})
			)
			.then(() => {
				this.#TRFactory.studentCreationRequests.removeStudent(student_cr_index)
				this.#$mdToast.showSimple("Student cration request removed")
			}, this.#ErrorService.handleError)
	}
}

studentsCreationRequestListCmp.controller = ["$scope", "$rootScope", "lsfactory", "$state", "TRFactory", "ErrorService", "$mdDialog", "$mdToast", "auth", ComponentNameController]

appModule.component("studentsCreationRequestListCmp", studentsCreationRequestListCmp)
// export default component;

import template from './MorphiousDialogCmp.tpl.html';

/** @type {import('@srcAjs/components2/adminRoutes').IComponentOptions2} */
const morphiousDialogCmp = {
	template,
	bindings: {
		//someInput: '@string,<object,&function'
		asDialog: '<',
		ariaLabel: '@'
	},
	transclude: {
		// #slotName: '?optionalPublicName'
		trigger: 'morphiousTrigger',
		content: 'morphiousContent',
	},
	selector: 'morphiousDialogCmp',
};

/** @this {ng.IController} */
function morphiousDialogCmpController() {
	this.doClose = () => {
		this.dialogOpen = false;
	};
}

morphiousDialogCmp.controller = morphiousDialogCmpController;

appModule.component('morphiousDialogCmp', morphiousDialogCmp);

export { morphiousDialogCmp };

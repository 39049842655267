import template from './index.html';

/** @type {import('@srcAjs/components2/adminRoutes').IComponentOptions2} */
const saMobileIndex = {
	template,
	bindings: {
		//someInput: '@string,<object,&function'
	},
	transclude: {
		// #slotName: '?optionalPublicName'
	},
};



function saMobileIndexCmpController($scope, $cookies, $mdToast, $state, $rootScope, $localStorage, $http, SAFactory) {
	SAFactory.loadEnvironment();
	SAFactory.loadNotifications();
}

saMobileIndex.controller = saMobileIndexCmpController;

appModule.component('saMobileIndex', saMobileIndex);

saMobileIndex.selector = 'saMobileIndex';

export { saMobileIndex }

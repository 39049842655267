import template from './index.html';

/** @type {import('@srcAjs/components2/adminRoutes').IComponentOptions2} */
const trSubjectDetail = {
	template,
	bindings: {
		//someInput: '@string,<object,&function'
	},
	transclude: {
		// #slotName: '?optionalPublicName'
	},
};



function trSubjectDetailCmpController($scope, $cookies, $mdToast, $state, $rootScope, $localStorage, $http) {}

trSubjectDetail.controller = trSubjectDetailCmpController;

appModule.component('trSubjectDetail', trSubjectDetail);

trSubjectDetail.selector = 'trSubjectDetail';

export { trSubjectDetail }

import template from './index.html';

/** @type {import('@srcAjs/components2/adminRoutes').IComponentOptions2} */
const saStudentsToolbar = {
	template,
	bindings: {
		//someInput: '@string,<object,&function'
		istudent: '<',
	},
	transclude: {
		// #slotName: '?optionalPublicName'
	},
};

function saStudentsToolbarCmpController(
	SAFactory,
	$scope,
	// istudent,
	$state,
	$rootScope,
	/** @type {ng.material.IDialogService} */ $mdDialog,
	ErrorService,
	Student
) {
	this.$onInit = () => {
		const svm = $scope;

		svm.student = this.istudent;

		svm.$on('editStudent.newEntity', (event, new_student) => {
			if (new_student !== undefined) {
				svm.student = new_student;
			}
		});

		svm.update = function (student) {
			$mdDialog.show({
				escapeToClose: true,
				clickOutsideToClose: false,
				bindToController: true,
				autoWrap: false,
				controllerAs: '$ctrl',
				locals: {
					student: angular.copy(student),
				},
				controller: 'DumbDialogController',
				template: "<edit-student-profile-cmp student='$ctrl.student' layout layout-align='center cnter' flex></edit-student-profile-cmp>",
			});
		};

		svm.showEnrollmentStateEditor = (ev, _student) => {
			$rootScope.$broadcast('show_enrollment_state_editor', { event: ev, student: _student });
		};

		svm.remove = function (student) {
			Student.one(student.id)
				.get()
				.then((res) => {
					const stu = res;
					const confirm = $mdDialog
						.confirm()
						.title('Would you like to delete "' + stu.firstname + ' ' + stu.lastname + '"?')
						.textContent('This operation cannot be undone. All dependants of this student will also be removed also.')
						.ariaLabel('Warning')
						.ok('Yes please!')
						.cancel('I dont think so');
					$mdDialog.show(confirm).then(() => {
						stu.remove().then(() => {
							$state.go('sa.students_browse');
						}, ErrorService.handleError);
					});
				});
		};
	};
}

saStudentsToolbar.controller = saStudentsToolbarCmpController;

appModule.component('saStudentsToolbar', saStudentsToolbar);

saStudentsToolbar.selector = 'saStudentsToolbar';

export { saStudentsToolbar };

import template from './index.html';

/** @type {import('@srcAjs/components2/adminRoutes').IComponentOptions2} */
const trClassSubParents = {
	template,
	bindings: {
		//someInput: '@string,<object,&function'
	},
	transclude: {
		// #slotName: '?optionalPublicName'
	},
};

function trClassSubParentsCmpController(
	/** @type {ng.IScope} */ $scope,
	$cookies,
	$state,
	$rootScope,
	/** @type {API} */ API,
	/** @type {import('restangular').IService} */ Restangular,
	/** @type {ng.material.IDialogService} */ $mdDialog,
	/** @type {lsfactory} */ lsfactory,
	/** @type {TRFactory} */ TRFactory,
	/** @type {ErrorService} */ ErrorService,
	/** @type {EffectService} */ EffectService,
	/** @type {atomosService} */ atomosService,
	/** @type {auth} */ auth,
	/** @type {compSetupService} */ compSetupService
) {
	compSetupService(this);
	/**
	 * @type {typeof $scope & {
	 * 	teacher: Teacher
	 *  section: Section
	 *  g_tid
	 *  isReady
	 *  school: School
	 * term: Term
	 *  parents: RoleInstance[]
	 * }}
	 */
	const svm = $scope;

	EffectService.setup(this, ({ onInit }) => {
		if (onInit) {
			svm.teacher = lsfactory.getEnv('teacher');
			svm.section = svm.teacher?.section;
			lsfactory.setEnv('backLog', 'tr.home.dashboard');
			svm.g_tid = lsfactory.getEnv('g_selected_term_id');
			svm.isReady = false;
			svm.school = auth.getEngagedUserRole().school;
			svm.parents = [];

			atomosService(TRFactory.trServiceAtom.stream$).stream$.subscribe((atom) => {
				const data = atom;

				const term = data.selectedTerm;

				if (!term) return;

				svm.term = term;

				svm.section = lsfactory.getCache()[term.id].sections[svm.teacher.section.id];

				fetchParents(term);
			});
		}
	});

	// TODO: defining the scope for the parents at the teachers portal
	svm.showParent = function (parent) {
		$mdDialog.show({
			clickOutsideToClose: true,
			escapeToClose: true,
			locals: {
				parent,
			},
			controllerAs: '$ctrl',
			bindToController: true,
			templateUrl: 'views/pages/roles/tr/class/dialogs/view_parents.html',
			controller: 'StudentReportDialogController',
		});
	};

	function fetchParents(term, refresh = false) {
		svm.isReady = false;

		const /** @type {API_Params} */ params = {
				filters: null,
				has_fields: [
					{
						field: 'students.section_histories',
						conditions: [{ condition: 'whereIn', column: 'id', value: svm.section.section_histories.map(({ id }) => id) }],
					},
				],
				fields: [
					{
						field: 'students',
						condition: 'with',
						conditions: [
							{
								condition: 'whereHas',
								column: 'section_histories|whereIn|id',
								value: svm.section.section_histories.map(({ id }) => id),
							},
						],
					},
					{
						condition: 'with',
						field: 'user',
					},
				],
				param_resolve_version: '1.4',
			};

		return API.wrapWithCache({
			restangularElement: Restangular.all(`guardian?${API.buildParams_v2(params)}`),
			refresh,
		})
			.getList()
			.then((res) => {
				svm.parents = res.plain();
			}, ErrorService.handleError)
			.finally(() => (svm.isReady = true));
	}

	svm.refresh = () => {
		svm.parents = [];

		fetchParents(svm.term, true);
	};
}

trClassSubParents.controller = trClassSubParentsCmpController;

appModule.component('trClassSubParents', trClassSubParents);

trClassSubParents.selector = 'trClassSubParents';

export { trClassSubParents };

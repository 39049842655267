import template from './index.html';

/** @type {import('@srcAjs/components2/adminRoutes').IComponentOptions2} */
const saSchoolSetupClassesToolbar = {
	template,
	bindings: {
		//someInput: '@string,<object,&function'
	},
	transclude: {
		// #slotName: '?optionalPublicName'
	},
};



function saSchoolSetupClassesToolbarCmpController(
	SAFactory,
	$scope,
	$cookies,
	$stateParams,
	$state,
	$rootScope,
	$localStorage,
	$mdDialog,
	$mdToast,
	lsfactory,
	Restangular,
	SClass
) {
	$scope.class = {};
	$scope.school = lsfactory.getEnv('school');
	if ($stateParams.id != '') {
		SClass.one($stateParams.id)
			.get()
			.then(
				function (res) {
					$scope.class = res;
				},
				function (err) {
					if (err.status == 404) {
					}
				}
			);
	}

	$scope.remove = function (sclass) {
		var confirm = $mdDialog
			.confirm()
			.title('Would you like to delete "' + sclass.class_level.title + '"?')
			.textContent('This operation cannot be undone. All dependants of this class will also be removed also.')
			.ariaLabel('Warning')
			.ok('Yes please!')
			.cancel('I dont think so');
		$mdDialog.show(confirm).then(
			function () {
				sclass.remove().then(function () {
					SAFactory.loadEnvironment();
					$state.go('sa.school_setup_classes');
				});
			},
			function (err) {}
		);
	};
}

saSchoolSetupClassesToolbar.controller = saSchoolSetupClassesToolbarCmpController;

appModule.component('saSchoolSetupClassesToolbar', saSchoolSetupClassesToolbar);

saSchoolSetupClassesToolbar.selector = 'saSchoolSetupClassesToolbar';

export { saSchoolSetupClassesToolbar }

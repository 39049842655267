import template from './index.html';

/** @type {import('@srcAjs/components2/adminRoutes').IComponentOptions2} */
const saSchoolSetupYearsDetail = {
	template,
	bindings: {},
};

function SA_SchoolSetupAcademicYearsDetail(
	SAFactory,
	$scope,
	$cookies,
	$state,
	$rootScope,
	$localStorage,
	$mdDialog,
	$mdToast,
	lsfactory,
	Restangular,
	Years,
	/** @type {import('@uirouter/angularjs').StateParams} */ $stateParams
) {
	SAFactory.loadEnvironment();
	SAFactory.loadNotifications();
	lsfactory.setEnv('backLog', 'sa.school_setup_years');
	$scope.year = lsfactory.getEnv('currentYearSelected');
	$scope.isReady = false;
	$scope.prev = null;
	$scope.count = 0;

	this.$onInit = () => {
		$rootScope.$watch('isReady', function (newValue, oldValue) {
			if (newValue !== undefined) {
				$scope.isReady = newValue;
			}
		});

		$scope.next = function (year) {
			var y = parseInt(year);
			return y + 1;
		};
		$scope.remove = function (year) {
			Years.one(year.id)
				.get()
				.then(function (res) {
					var yr = res;
					var confirm = $mdDialog
						.confirm()
						.title('Would you like to delete "' + year.year + '/' + $scope.next(year.year) + '" academic year"?')
						.textContent('This operation cannot be undone. All dependants of this academic year will also be removed also.')
						.ariaLabel('Warning')
						.ok('Yes please!')
						.cancel('I dont think so');
					$mdDialog.show(confirm).then(
						function () {
							yr.remove().then(function () {
								SAFactory.loadEnvironment();
							});
						},
						function () {}
					);
				});
		};

		$scope.resolveTerm = function (terms) {
			var l = terms.length;
			if (l > 0) {
				if (l === 1) {
					return l + ' term available';
				} else {
					return l + ' terms available';
				}
			} else {
				return 'No terms available';
			}
		};

		$scope.$on('system.sa.environmentChanged', function (ev, data) {
			if (data !== undefined) {
				$scope.env = data;
				$scope.school = data.school;
				Years.one($stateParams.id)
					.get()
					.then(function (res) {
						var obj_sub = res.plain();
						$scope.year = res;
					});
			}
		});
	};
}

saSchoolSetupYearsDetail.controller = SA_SchoolSetupAcademicYearsDetail;

appModule.component('saSchoolSetupYearsDetail', saSchoolSetupYearsDetail);

saSchoolSetupYearsDetail.selector = 'saSchoolSetupYearsDetail';

export { saSchoolSetupYearsDetail }

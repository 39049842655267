import template from './index.html';

/** @type {import('@srcAjs/components2/adminRoutes').IComponentOptions2} */
const saSchoolSetupClassGroupsToolbar = {
	template,
	bindings: {
		//someInput: '@string,<object,&function'
	},
	transclude: {
		// #slotName: '?optionalPublicName'
	},
};



function saSchoolSetupClassGroupsToolbarCmpController(
	SAFactory,
	$scope,
	$cookies,
	$stateParams,
	$mdToast,
	$state,
	$rootScope,
	$localStorage,
	$mdDialog,
	lsfactory,
	Restangular,
	GradingSystem,
	ClassGroup
) {
	$scope.system = {};
	$scope.school = lsfactory.getEnv('school');
	$scope.group = lsfactory.getEnv('currentClassGroupSelected');

	$scope.$on('class_groups.dataChanged', function (ev, data) {
		console.log('$scope triggered');
		ClassGroup.one($stateParams.id)
			.get()
			.then(
				function (res) {
					$scope.group = res;
					lsfactory.setEnv('currentClassGroupSelected', res);
				},
				function (err) {
					if (err.status == 404) {
					}
				}
			);
	});

	$scope.edit = function (group) {
		lsfactory.setEnv('groupEdit', angular.copy(group));
		$mdDialog.show({
			escapeToClose: false,
			clickOutsideToClose: false,

			templateUrl: 'views/pages/roles/sa/school_setup/class_groups/dialogs/edit_group.html',
			controller: EditClassGroupController,
		});
	};

	$scope.remove = function (group) {
		var confirm = $mdDialog
			.confirm()
			.title('Would you like to delete the "' + group.class_group_type.title + '" class group?')
			.textContent('This operation cannot be undone. All dependants of this class group will also be removed also.')
			.ariaLabel('Warning')
			.ok('Yes please!')
			.cancel('I dont think so');
		$mdDialog.show(confirm).then(
			function () {
				ClassGroup.one($scope.group.id)
					.get()
					.then(function (res) {
						var cg = res;
						cg.remove().then(function () {
							$rootScope.$broadcast('class_groups.dataChanged', $localStorage.environment);
							$state.go('sa.school_setup_class_groups');
						});
					});
			},
			function (err) {}
		);
	};
}

saSchoolSetupClassGroupsToolbar.controller = saSchoolSetupClassGroupsToolbarCmpController;

// appModule.component('saSchoolSetupClassGroupsToolbar', saSchoolSetupClassGroupsToolbar);

saSchoolSetupClassGroupsToolbar.selector = 'saSchoolSetupClassGroupsToolbar';

export { saSchoolSetupClassGroupsToolbar }

import template from './index.html';

/** @type {import('@srcAjs/components2/adminRoutes').IComponentOptions2} */
const saSchoolSetupYearSubSummary = {
	template,
	bindings: {},
};



function saSchoolSetupYearSubSummaryCmpController(
	SAFactory,
	$scope,
	$cookies,
	/** @type {import('@uirouter/angularjs').StateParams} */ $stateParams,
	$state,
	$rootScope,
	$localStorage,
	$mdDialog,
	$mdToast,
	lsfactory,
	Restangular,
	Years
) {
	SAFactory.loadEnvironment();
	SAFactory.loadNotifications();
	$scope.$on('system.sa.environmentChanged', function (ev, data) {});
	$scope.sel_year = lsfactory.getEnv('currentYearSelected');
	$state.current.data.title = $scope.sel_year.title;
}

saSchoolSetupYearSubSummary.controller = saSchoolSetupYearSubSummaryCmpController;

appModule.component('saSchoolSetupYearSubSummary', saSchoolSetupYearSubSummary);

saSchoolSetupYearSubSummary.selector = 'saSchoolSetupYearSubSummary';

export { saSchoolSetupYearSubSummary }

import template from './saTermReportCmp.tpl.html';

import './saTermReportCmp.scss';

const saTermReportCmp = {
	template,
	bindings: {},
};

function SA_Report_Term_Report($scope, Restangular, $localStorage, $mdDialog, lsfactory, ErrorService) {
	const svm = $scope;

	svm.env = $localStorage.environment;
	svm.school = svm.env.school;
	svm.classes = [];
	svm.years = [];
	svm.students = [];
	svm.lastFile = null;
	svm.isClassbuttonReady = false;
	svm.isStudentbuttonReady = false;
	svm.subject_assessment_types = [];
	svm.selected_student = null;
	svm.selected_section_histories = null;
	// loadAssessmentTypes()

	this.$onInit = () => {
		svm.isReady = false;

		Promise.all([
			Restangular.all('class/by-school/' + svm.school.id)
				.getList()
				.then((data) => {
					const list = data.plain();
					svm.classes = list;
					svm.isClassbuttonReady = true;
				}),
			// Restangular.all('year/by-school/' + svm.school.id)
			// 	.getList()
			// 	.then((data) => {
			// 		const list = data.plain();
			// 		svm.years = list;
			// 	}),
			Restangular.all('student/by-school/' + svm.school.id)
				.getList()
				.then((data) => {
					const list = data.plain();
					svm.students = [...list];
					svm.isStudentbuttonReady = true;
				}),
		])
			.catch(ErrorService.handleError)
			.finally(() => {
				svm.isReady = true;
			});
	};

	svm.downloadFile = function () {
		if (svm.lastFile !== null) {
			window.open(svm.lastFile, 'Download');
		}
	};

	function loadAssessmentTypes() {
		if (svm.subject_assessment_types.length) return;

		Restangular.all('subject-assessment-type')

			.getList()
			.then((res) => {
				console.log('term types', res.plain());
				svm.subject_assessment_types = res.plain();
			});
	}

	/**
	 *
	 * @param {[string]} fields
	 * @param {{title:string}[]} assessment_types
	 * @param {'in'|'not_in'} condition
	 * @returns {'id'[]}
	 */
	const pullAssessmentType_ids = (fields, assessment_types, condition) => {
		const lower_fields = fields.map((fld) => fld.toLocaleLowerCase());

		let result = assessment_types.filter((as_type) => lower_fields.includes(as_type.title.toLocaleLowerCase())).map(({ id }) => id);

		if (!condition) result = assessment_types.map(({ id }) => id).filter((id) => !result.includes(id));

		return result;
	};

	const sumCompact = (buckets, fields, assessment_types, condition) => {
		let _class_totals = 0;
		let _exam_totals = 0;
		for (const bucket of buckets) {
			if (pullAssessmentType_ids(fields, assessment_types, condition).includes(bucket.assessment_group_type_id))
				_class_totals += bucket.fractional_score;
			else _exam_totals += bucket.fractional_score;
		}

		return { _class_totals, _exam_totals };
	};
}

saTermReportCmp.controller = SA_Report_Term_Report;

appModule.component('saTermReportCmp', saTermReportCmp);
// export default component;

import './AppLogCmp.scss';

/**
 * @typedef {{ env,
 * level,
 * version,
 * meta: {
 *		data: {
 *			log:string,
 *			occured_at:string,
 *			page_path:string,
 *			href:string,
 *			browserInfo:string,
 *			user:string,
 *			engagedUserRole: {
 *				school_id: string
 *				role_instance_id: string
 *				role_instance_type_id: string
 *				role_instance_type: string
 *				user_id: string
 *			},
 *		},
 *	},
 * }} IAppLog
 */

import template from './AppLogCmp.tpl.html';
const appLogCmp = {
	template,
	bindings: {},
	selector: 'appLogCmp',
};

class AppLogCmp {
	appLogs = [];

	lastHours = 2;

	isLoading = false;

	timeMarker$;

	constructor(
		/** @type {API} */ API,
		/** @type {ErrorService} */ ErrorService,
		/** @type {$moment} */ $moment,
		/** @type {EffectService} */ EffectService,
		/** @type {atomosService} */ atomosService,
		/** @type {compSetupService} */ compSetupService
	) {
		compSetupService(this);
		this.hoursMap = new Map();
		console.log('AppLogCmp');
		this.timeMarker$ = atomosService(this.lastHours, this);
		this.bootTime = $moment();

		EffectService.setup(this, ({ onInit, destroy$ }) => {
			if (onInit) {
				const filter = (hours) =>
					!hours
						? ''
						: API.buildParams_v2({
								filters: [{ condition: 'where', comparator: '>=', column: 'created_at', value: $moment().startOf('hour').subtract(hours, 'hours').toISOString() }],
						  });

				const [query$, { revalidate }] = API.rxQuery(
					['rxq.app.logs'],
					(r, data) => /** @type {import('restangular').ICollectionPromise<{created_at,title,meta}>} */ (r.all(`logs?${filter(data)}`).getList()),
					{
						destroy$,
						qInput$: this.timeMarker$.stream$,
						onSuccess: ({ data }) => (this.appLogs = data?.plain()),
						onError: ({ error }) => ErrorService.handleError(error),
					}
				);

				this.reload = revalidate;

				query$.subscribe({
					next: (logs$) => {
						this.isLoading = logs$.loading || logs$.pending;
						ErrorService.handleError(logs$.error);
					},
					complete: () => console.log('appLog.query$.completed'),
				});
			}
		});
	}

	updateApi(hours) {
		this.timeMarker$.set(hours);
	}

	trapClick(/** @type {Event}*/ event) {
		event.stopPropagation();
		event.preventDefault();
	}
}

appLogCmp.controller = AppLogCmp;

appModule.component('appLogCmp', appLogCmp);

export { appLogCmp };

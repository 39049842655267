import template from './index.html';

/** @type {import('@srcAjs/components2/adminRoutes').IComponentOptions2} */
const saSchoolSetupGradingDetail = {
	template,
	bindings: {
		//someInput: '@string,<object,&function'
	},
	transclude: {
		// #slotName: '?optionalPublicName'
	},
};



function saSchoolSetupGradingDetailCmpController(
	SAFactory,
	$scope,
	$cookies,
	/** @type {import('@uirouter/angularjs').StateParams} */ $stateParams,
	$state,
	$rootScope,
	$localStorage,
	$mdDialog,
	$mdToast,
	lsfactory,
	Restangular,
	GradingSystem
) {
	SAFactory.loadEnvironment();
	SAFactory.loadNotifications();
	$scope.system = lsfactory.getEnv('currentGradingSystemSelected');
	$scope.isReady = false;

	if ($stateParams.id != '') {
		GradingSystem.one($stateParams.id)
			.get()
			.then(
				function (res) {
					var obj_sub = res.plain();
					$scope.system = res;
				},
				function (err) {
					if (err.status == 404) {
						$state.go('sa.school_setup_grading');
					}
				}
			);
	} else {
		$state.go('sa.school_setup_grading');
	}

	$rootScope.$watch('isReady', function (newValue, oldValue) {
		if (newValue !== undefined) {
			$scope.isReady = newValue;
		}
	});

	$scope.$on('', function (ev, data) {});
}

saSchoolSetupGradingDetail.controller = saSchoolSetupGradingDetailCmpController;

appModule.component('saSchoolSetupGradingDetail', saSchoolSetupGradingDetail);

saSchoolSetupGradingDetail.selector = 'saSchoolSetupGradingDetail';

export { saSchoolSetupGradingDetail }

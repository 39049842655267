import template from './index.html';

/** @type {import('@srcAjs/components2/adminRoutes').IComponentOptions2} */
const saGuardiansSubProfile = {
	template,
	bindings: {
		//someInput: '@string,<object,&function'
		IGuardian: '<iguardian',
	},
	transclude: {
		// #slotName: '?optionalPublicName'
	},
};



function saGuardiansSubProfileCmpController(SAFactory, $scope, $state, ErrorService, lsfactory, Guardian) {
	this.$onInit = () => {
		const IGuardian = this.IGuardian;
		$scope.guardian = IGuardian;
		$scope.selectCountry = (country) => country.id === $scope.guardian.country_id;

		$state.current.data.title =
			$scope.guardian.salutation + ' ' + $scope.guardian.firstname + ' ' + $scope.guardian.lastname + ' ( Guardian )';

		$scope.$on('editGuardian.newEntity', function (event, new_guardian) {
			Guardian.one(IGuardian.id)
				.get()
				.then((guardian) => {
					$scope.guardian = guardian;
					$state.current.data.title =
						$scope.guardian.salutation + ' ' + $scope.guardian.firstname + ' ' + $scope.guardian.lastname + ' ( Guardian )';
				});
		});

		$scope.gotoStudent = function (student) {
			if (!student) return;
			$state.go('sa.students_detail.profile', { id: student.id });
		};
	};
}

saGuardiansSubProfile.controller = saGuardiansSubProfileCmpController;

appModule.component('saGuardiansSubProfile', saGuardiansSubProfile);

saGuardiansSubProfile.selector = 'saGuardiansSubProfile';

export { saGuardiansSubProfile };

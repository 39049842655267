import template from './index.html';

/** @type {import('@srcAjs/components2/adminRoutes').IComponentOptions2} */
const trClassDialogsStudentReport = {
	template,
	bindings: {
		//someInput: '@string,<object,&function'
		sectionHistories: '<',
		section: '<',
		term: '<',
	},
	transclude: {
		// #slotName: '?optionalPublicName'
	},
};

function trClassDialogsStudentReportCmpController(
	Restangular,
	/** @type {ng.IScope}*/ $scope,
	$mdToast,
	ErrorService,
	$timeout,
	CONST_REPORT_TYPES,
	fullnameFilter,
	/** @type {auth} */ auth,
	/** @type {SchoolReportTypePrefService} */ SchoolReportTypePrefService,
	/** @type {EffectService} */ EffectService,
	/** @type {compSetupService} */ compSetupService
) {
	compSetupService(this, trClassDialogsStudentReport.bindings);

	/**
	 * @type { typeof $scope &  {
	 * [key:string]: any|{}
	 * }}
	 */
	const svm = $scope;
	EffectService.setup(
		this,
		({ onInit, onChanges }) => {
			if (onChanges || onInit) {
				svm.levels = [];
				svm.isProcessing = false;
				svm.box = {};
				svm.term_id = this.term?.id;
				svm.section_histories = this.sectionHistories;
				svm.section = this.section;
				svm.report_type = 'single report';
				svm.selectedItem = undefined;
				svm.CONST_REPORT_TYPES = [...CONST_REPORT_TYPES];
				svm.report_option = SchoolReportTypePrefService.get(auth.getEngagedUserRole());
				console.dir(svm.section_histories);

				this.startWatch();
			}
		},
		() => [this.sectionHistories, this.term, this.section]
	);

	this.startWatch = () => {
		/**
		 * [description]
		 * @method
		 * @param  {[type]} newValue [holds a section_history of selected student]
		 * @param  {[type]} oldValue [description]
		 * @return {[type]}          [description]
		 */
		svm.$watch('selectedItem', (newValue) => {
			if (newValue !== undefined) {
				if (newValue !== null) {
					console.dir(newValue);
					svm.box.student_id = newValue.student.id; // use student Id
					svm.box.section_history_id = newValue.id; // get id from newValue which is section_history and assign to box for getting reports
					console.log('new value for report building params', newValue);
				}
			}
		});
	};

	svm.getReport = function () {
		try {
			svm.isProcessing = true;

			// generate term report for student using section_history_id
			let download_url;
			const params = {
				section_id: svm.section.id,
				term_id: svm.term_id,
				report_option: svm.report_option.replaceAll(' ', '_'),
				v: '1.2',
				response_as: 'pdf',
			};
			let param_fields = Object.entries(params)
				.map(([key, val]) => `${key}=${val}`)
				.join('&');
			if (svm.next_term) param_fields += `&next_term_id=${svm.next_term.id}`;

			switch (svm.report_type.replaceAll(' ', '_')) {
				case 'single_report':
					download_url = Restangular.all(`report/by_section_history_id/${svm.box.section_history_id}?${param_fields}&shid=${svm.box.section_history_id}`).getRestangularUrl();

					break;

				case 'all_reports':
					download_url = Restangular.all(`bulk_report_download?${param_fields}`).getRestangularUrl();
					break;
				default:
					break;
			}

			$mdToast.showSimple('The download is readyyyooo!. Abinooo!');

			console.dir(download_url);

			window.open(download_url, 'Download');
		} catch (error) {
			ErrorService.handleError(error, true);
		} finally {
			svm.isProcessing = false;
			$timeout(() => {}, 10);
		}
	};

	svm.getFullname = function (/** @type {SectionHistory}*/ sh) {
		return fullnameFilter(sh.student);
	};

	svm.updateTerm = (term) => {
		if (!term) return;
		console.log('term');
		svm.next_term = term;
	};
}

trClassDialogsStudentReport.controller = trClassDialogsStudentReportCmpController;

appModule.component('trClassDialogsStudentReport', trClassDialogsStudentReport);

trClassDialogsStudentReport.selector = 'trClassDialogsStudentReport';

export { trClassDialogsStudentReport };

import template from './index.html';

/** @type {import('@srcAjs/components2/adminRoutes').IComponentOptions2} */
const saSchoolSetupClassGroupsSubSummary = {
	template,
	bindings: {
		//someInput: '@string,<object,&function'
	},
	transclude: {
		// #slotName: '?optionalPublicName'
	},
};



function saSchoolSetupClassGroupsSubSummaryCmpController(
	SAFactory,
	$scope,
	$cookies,
	/** @type {import('@uirouter/angularjs').StateParams} */ $stateParams,
	$mdToast,
	$state,
	$rootScope,
	$localStorage,
	$mdDialog,
	lsfactory,
	Restangular,
	GradingSystem,
	ClassGroup
) {
	SAFactory.loadEnvironment();
	SAFactory.loadNotifications();
	$scope.class_group = lsfactory.getEnv('currentClassGroupSelected');
	$state.current.data.title = $scope.class_group.class_group_type.title;

	$scope.$on('class_groups.dataChanged', function (ev, data) {
		ClassGroup.one($stateParams.id)
			.get()
			.then(
				function (res) {
					$scope.class_group = res;
					$state.current.data.title = $scope.class_group.class_group_type.title;
					lsfactory.setEnv('currentClassGroupSelected', res);
				},
				function (err) {
					if (err.status == 404) {
					}
				}
			);
	});
}

saSchoolSetupClassGroupsSubSummary.controller = saSchoolSetupClassGroupsSubSummaryCmpController;

appModule.component('saSchoolSetupClassGroupsSubSummary', saSchoolSetupClassGroupsSubSummary);

saSchoolSetupClassGroupsSubSummary.selector = 'saSchoolSetupClassGroupsSubSummary';

export { saSchoolSetupClassGroupsSubSummary }

import template from './AccessSignupCmp.tpl.html';

import './AccessSignupCmp.scss';

const accessSignupCmp = {
	template,
	bindings: {
		$transition$: '<',
	},
};

function SignupController(
	$scope,
	$mdToast,
	$state,
	$mdDialog,
	$localStorage,
	lsfactory,
	auth,
	NgMap,
	Schools,
	/** @type {import('restangular').IService} */ Restangular,
	/** @type {ErrorService} */ ErrorService,
	CONST_APP_ENV
) {
	const vm = this;

	vm.isLoading = false;
	vm.code = undefined;
	vm.map_pos = { lat: 5.555886, lng: -0.196588 }; // Default Ghana
	vm.map_zoom = 6;

	// function findCountryByID(country, index, arr) {
	// 	return this == country.id; //* this* will be value of object submitted as thisArg in find
	// }

	// function findName(country_id) {
	// 	const found_country = isoCountries.find(findCountryByID, country_id); /** county will become thisArg in function */
	// 	return found_country ? found_country.text : found_country;
	// }

	vm.$onInit = () => {
		const code = $transition$.param().code;
		if (!code) {
			$state.go('access.choose_country', { origin: 'signup' });
		}

		vm.code = code;
	};

	// const name = findName(vm.code);
	const geocoder = new google.maps.Geocoder();

	if (geocoder) {
		geocoder.geocode(
			{
				address: name,
			},
			(results, status) => {
				if (status == google.maps.GeocoderStatus.OK) {
					vm.$apply(() => {
						vm.map_pos = { lat: results[0].geometry.location.lat(), lng: results[0].geometry.location.lng() };
					});
				}
			}
		);
	}

	vm.box = {
		title: '',
		description: '',
		lat: CONST_APP_ENV === 'production' ? null : 3,
		lng: CONST_APP_ENV === 'production' ? null : 3,
	};

	vm.removeAllmarkers = function () {
		angular.forEach(vm.myMarkers, (marker) => {
			marker.setMap(null);
		});
	};

	vm.addMarker = function (position, address) {
		angular.forEach(vm.myMarkers, (marker) => {
			marker.setMap(null);
		});
		vm.myMarkers.push(new google.maps.Marker({ position: { lat: position.lat, lng: position.lng }, map: vm.map }));
		vm.box.lat = position.lat;
		vm.box.lng = position.lng;
	};

	/*
	 * $scope.$on("places-autocomplete:select", function(event, res) {
	 *     var format = res.formatted_address;
	 *     var loc = res.location;
	 *     vm.map_pos = loc;
	 *     vm.addMarker(loc,format);
	 * });
	 */
	$scope.$on('places-autocomplete:select', (event, res) => {
		const format = res.formatted_address;
		const loc = res.location;
		vm.map_pos = loc;
		vm.addMarker(loc, format);
	});

	vm.map = null;
	vm.removeAllmarkers();

	NgMap.getMap().then((map) => {
		vm.map = map;
		vm.removeAllmarkers();
		vm.myMarkers = [];
	});

	vm.myMarkers = [];

	vm.sendData = function () {
		vm.isLoading = true;
		vm.box.code = vm.code;
		Restangular.all('school/register')
			.post(vm.box)
			.then((res) => {
				const data = res.plain();
				if (data.status == 'success') {
					$localStorage.attempted_user = data.user;
					$state.go('access.sms-verify', { user: data.user });
				}
			}, ErrorService.handleError)
			.finally(() => (vm.isLoading = false));
	};

	vm.gotoSignin = function () {
		vm.removeAllmarkers();
		$state.go('access.signin');
	};

	vm.placeMarker = function (e) {
		angular.forEach(vm.myMarkers, (marker) => {
			marker.setMap(null);
		});
		vm.myMarkers.push(new google.maps.Marker({ position: e.latLng, map: vm.map }));
		vm.box.lat = e.latLng.lat();
		vm.box.lng = e.latLng.lng();
	};

	vm.isProcessing = false;
	vm.doRegister = function () {
		vm.isProcessing = true;
		auth.sendCode(vm.box).then((data) => {
			$state.go('access.sms-verify', { user: data.user });
		});
	};

	vm.$onInit = () => {
		vm.step1 = {
			disabled: false,
			completed: false,
		};
		vm.step2 = {
			disabled: true,
			completed: false,
		};

		vm.selected = 0;
	};

	vm.completeStep = ({ currentStep, lastStep = 2 }) => {
		switch (currentStep + '') {
			case 0 + '': {
				// complete step1 select 1
				this.step1.completed = true;
				this.step2.disabled = false;
				this.selected = currentStep < lastStep ? currentStep + 1 : currentStep;
				break;
			}
			case 1 + '': {
				this.step2.completed = true;
				// this.step3.disabled = false;
				this.selected = currentStep < lastStep ? currentStep + 1 : currentStep;
				break;
			}
		}
	};
}

accessSignupCmp.controller = [
	'$scope',
	'$mdToast',
	'$state',
	'$mdDialog',
	'$localStorage',
	'lsfactory',
	'auth',
	'NgMap',
	'Schools',
	'Restangular',
	'ErrorService',
	'CONST_APP_ENV',
	SignupController,
];

appModule.component('accessSignupCmp', accessSignupCmp);

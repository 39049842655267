import template from './index.html';

/** @type {import('@srcAjs/components2/adminRoutes').IComponentOptions2} */
const saSchoolSetupClassGroupsDialogsAddGroup = {
	template,
	bindings: {
		//someInput: '@string,<object,&function'
		onClose: '&',
		systems: '<',
		types: '<',
	},
	transclude: {
		// #slotName: '?optionalPublicName'
	},
	selector: 'saSchoolSetupClassGroupsDialogsAddGroup',
};

/** @this {ng.IController} */
function saSchoolSetupClassGroupsDialogsAddGroupController(
	$scope,
	$rootScope,
	$localStorage,
	ClassGroup,
	/**@type {import('@srcAjs/components/services.components.js/EffectService').EffectService} */ EffectService,
	/** @type {auth} */ auth
) {
	$scope.isProcessing = false;
	$scope.box = {};

	EffectService.setup(this, ({ changes }) => {
		if (changes) {
			return;
		}

		$scope.school = auth.getEngagedUserRole().school;
		$scope.systems = this.systems;
		$scope.types = this.types;
	});

	$scope.addGroup = () => {
		$scope.isProcessing = true;
		var data = {
			grading_system_id: $scope.box.grading_system_id,
			school_id: $scope.school.id,
			class_group_type_id: $scope.tt.id,
			order: $scope.tt.order,
		};

		ClassGroup.post(data).then(function (data) {
			$scope.hide(data);
			$rootScope.$broadcast('class_groups.dataChanged', $localStorage.environment);
		});
	};

	$scope.hide = (data) => {
		this.onClose({ data });
	};

	$scope.cancel = (data) => {
		this.onClose({ data });
	};

	$scope.answer = function (data) {
		this.onClose({ data });
	};
}

saSchoolSetupClassGroupsDialogsAddGroup.controller = saSchoolSetupClassGroupsDialogsAddGroupController;

appModule.component('saSchoolSetupClassGroupsDialogsAddGroup', saSchoolSetupClassGroupsDialogsAddGroup);

export { saSchoolSetupClassGroupsDialogsAddGroup };

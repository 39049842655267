import template from './AccessSmsveryfyCmp.tpl.html';

import './AccessSmsveryfyCmp.scss';

const accessSuccessCmp = {
	template,
	bindings: {
		$transition$: '<',
	},
	selector: 'accessSmsVerifyCmp',
};

function SMSVerifyController(
	$scope,
	/** @type {ErrorService} */ ErrorService,
	/** @type {ng.material.IToastService} */ $mdToast,
	/** @type {import('angular-ui-router').IStateService} */ $state,
	/** @type {TRFactory} */ TRFactory,
	/** @type {SAFactory} */ SAFactory,
	/** @type {ReturnType<makeAAFactory>} */ AAFactory,
	/** @type {ReturnType<makePAFactory>} */ PAFactory,
	$rootScope,
	/** @type {auth} */ auth,
	CONST_ENTRY_STATES,
	/** @type {atomosService} */ atomosService
) {
	const vm = this;
	vm.user = null;
	vm.isChecking = false;

	vm.box = {
		code: '',
	};

	vm.resendCode = resendCode;
	vm.checkCode = checkCode;

	const roleViewInitializer = {
		sa: SAFactory.initializeRole,
		tr: TRFactory.loadEnvironment,
		aa: AAFactory.initializeRole,
		pa: PAFactory.initializeRole,
	};

	atomosService(auth.authData$, this).stream$.subscribe((authData) => {
		auth.getUser().then(({ user_instance: user }) => {
			// const user = this.$transition$.params().user ?? $localStorage.attempted_user ?? null;
			if (!user || !auth?.authData$?.value?.user) {
				return $state.go('access.signin');
			}

			vm.user = user;

			if (this.$transition$.params().resend_code && this.$transition$.params().resend_code) resendCode();
		});
	});

	function checkCode(code) {
		if (code.length !== 6 || vm.isChecking) return;

		vm.isChecking = true;

		auth
			.verifyCode(code)
			.then(({ data: { status: success } }) => {
				if (!success) throw Error('Error: code verification failed');
			})

			.then(() => {
				auth.loadUserModule({ /** @type {user} */ user_instance: vm.user, is_verified: true, status: true }, $rootScope, $state, CONST_ENTRY_STATES, roleViewInitializer);
			})
			.catch(ErrorService.handleError)
			.finally(() => (vm.isChecking = false));
	}

	function resendCode() {
		$rootScope.allowSub = true;
		vm.isChecking = true;
		auth
			.resendUserVerificationToken(vm.user)
			.then((data) => {
				vm.isChecking = false;
				if (data.status) {
					$rootScope.allowSub = false;
					const template = $mdToast.simple().textContent('Your activiation code has been resent.').hideDelay(1500);
					$mdToast.show(template);
				}
			}, ErrorService.handleError)

			.finally(() => {
				vm.isChecking = false;
			});
	}

	vm.logout = () => {
		auth.logout().then(() => $state.go('access.signin'));
	};
}

accessSuccessCmp.controller = SMSVerifyController;

appModule.component('accessSmsverifyCmp', accessSuccessCmp);

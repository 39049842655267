function DialogService(/** @type {ng.material.IDialogService} */ $mdDialog, /** @type {AppStore} */ AppStore) {
	const handlers = {
		async confirm(/** @type {string}*/ textContent, title = 'Confirmation', yes = 'Yes', cancel = 'I dont think so!') {
			return $mdDialog.show($mdDialog.confirm().title(title).textContent(textContent).ok(yes).cancel(cancel));
		},
		async prompt(nothing = undefined) {},
		async modal(/** @type {ng.material.IDialogOptions} */ modalOptions) {},
		async alert(/** @type {string}*/ textContent, title = 'Alert!') {
			return $mdDialog.show($mdDialog.alert().title(title).textContent(textContent).ok('ok'));
		},
		simpleToast(/** @type {string}*/ message, title = 'Info') {
			return this.toast({ messages: [message], type: 'toast', title });
		},
		toast(/** @type {ParsedError}*/ data) {
			return AppStore.dispatch((store) => store.errorSlice.add({ type: 'toast', ...data }));
		},
	};

	/** @template {keyof handlers} T*/
	const dispatch = (/** @type {T} */ action, /** @type {Parameters<handlers[T]>} */ ...payload) => /** @type {ReturnType<typeof handlers[T]>} */(handlers[action](...angular.copy(payload)));

	this.dispatch = dispatch;
}

appModule.service('DialogService', DialogService);

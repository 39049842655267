import _ from 'angular-material';
import template from './index.html';

/** @type {import('@srcAjs/components2/adminRoutes').IComponentOptions2} */
const paDashboard = {
	template,
	bindings: {
		//someInput: '@string,<object,&function'
	},
	transclude: {
		// #slotName: '?optionalPublicName'
	},
	selector: 'paDashboard',
};

/** @this {ng.IController} */
function paDashboardController(
	$scope,
	$cookies,
	$mdToast,
	$state,
	$rootScope,
	$localStorage,
	/** @type {PAFactory} */ PAFactory,
	lsfactory,
	$mdDialog,
	/** @type {import('@components/services.components.js/guardianNotorizations').guardianNotorizationService} */ GuardianNotorizationService,
	/** @type {EffectService} */ EffectService,
	/** @type {auth} */ auth,
	/** @type {atomosService} */ atomosService,
	/** @type {RxService} */ RxService,
	/** @type {compSetupService} */ compSetupService,
	/** @type {ErrorService} */ ErrorService,
	/** @type {$moment} */ $moment
) {
	compSetupService(this);

	PAFactory.loadEnvironment();
	PAFactory.loadNotifications();
	$scope.env = $localStorage.environment;
	$scope.wards = [];

	const [gaNotorizations$, { mutate: queryGaNotorizations }] = GuardianNotorizationService.gaNotorizationsRxq;
	const [guardian$, guardianRxHandlers] = PAFactory.guardianRxq;

	const guardiansAtom = atomosService(guardian$, this);
	const authAtom = atomosService(auth.authData$, this);
	const notorizationAtom = atomosService(gaNotorizations$, this);

	EffectService.setup(this, ({ onInit }) => {
		if (onInit) {
			atomosService((get) => ({ _guardians: get(guardiansAtom), _authData: get(authAtom) }), this).stream$.subscribe(({ _authData, _guardians }) => {
				if (!/pa/i.test(_authData?.engagedUserRole?.role?.short) || ErrorService.handleError(_guardians?.error) || !_guardians?.data) return;

				const [guardian] = _guardians.data.plain();
				$scope.wards = guardian.students;
				$scope.parent = guardian;

				$scope.school = auth.getEngagedUserRole().school;

				queryGaNotorizations({ student_guardian_ids: guardian.students.flatMap((st) => st.pivot.id) });
			});

			atomosService(gaNotorizations$, this).stream$.subscribe(({ data, loading, error }) => {
				if (ErrorService.handleError(error) || !data) return;

				this.notorizations = data.plain()?.map((nt) => ({
					...nt,
					get notorized_at_date() {
						return this.notorized_at ? $moment(this.notorized_at) : this.notorized_at;
					},
				}));

				this.newNotorizations = this.notorizations.filter((nt) => !nt.notorized_at);
			});

			atomosService((get) => [get(guardiansAtom), get(notorizationAtom)]).stream$.subscribe(([guardiansQ, notorizationsQ]) => {
				this.isLoading = guardiansQ?.loading || notorizationsQ?.loading;
			});
		}
	});

	this.notorize = (/** @type {import('@components/services.components.js/guardianNotorizations').AssessmentNotorization} */ { id, notorized_at }) =>
		GuardianNotorizationService.gaNotorizationRxm[1]
			.mutate({ id, notorized_at: $moment.toYmd_T0(notorized_at ?? $moment()) })
			.catch(ErrorService.handleError)
			.then(() => GuardianNotorizationService.gaNotorizationsRxq[1].revalidate());

	$scope.next = function (year) {
		var y = parseInt(year);
		return y + 1;
	};

	$scope.getFull = function (ward) {
		return ward.firstname + ' ' + ward.lastname;
	};

	$scope.$on('system.pa.environmentChanged', function (ev, data) {
		$scope.env = data;

		// var years = Restangular.all("year/by-school/"+$scope.school.id);
		// years.getList().then(function(data){
		// 	var list = data.plain();
		// 	$scope.years = list;
		// });
		// $state.current.data.title = $scope.parent.salutation + ' ' + $scope.parent.firstname + ' ' + $scope.parent.lastname;
		$state.current.data.title = $scope.parent.name;
	});

	$scope.showTermDialog = function (student) {
		if (student.section_histories.length == 0) {
			var template = $mdToast.simple().textContent('No Academic History').action('OK').highlightAction(true).hideDelay(5500);
			$mdToast.show(template);
		} else {
			$mdDialog.show({
				escapeToClose: true,
				clickOutsideToClose: true,
				bindToController: true,
				locals: {
					school: $scope.school,
					student,
				},
				templateUrl: 'views/pages/roles/pa/dialogs/show_term.html',
				controller: ShowTermDialogController,
			});
		}
	};
}

paDashboard.controller = paDashboardController;

appModule.component('paDashboard', paDashboard);

export { paDashboard };

import template from './index.html';

/** @type {import('@srcAjs/components2/adminRoutes').IComponentOptions2} */
const saProfile = {
	template,
	bindings: {
		//someInput: '@string,<object,&function'
	},
	transclude: {
		// #slotName: '?optionalPublicName'
	},
};



function saProfileCmpController(
	SAFactory,
	$scope,
	$cookies,
	$mdToast,
	$state,
	$rootScope,
	$localStorage,
	$http,
	$location,
	$mdDialog,
	lsfactory,
	CONST_DEFAULT_PROFILE
) {
	SAFactory.loadEnvironment();
	SAFactory.loadNotifications();

	$scope.user = $localStorage.user;
	$scope.env = $localStorage.environment;
	$scope.$on('system.sa.environmentChanged', function (ev, data) {
		$scope.env = data;
	});

	$rootScope.$watch(
		'user',
		function (newValue, oldValue) {
			if (newValue !== undefined) {
				$scope.user = newValue;
			}
		},
		true
	);

	$scope.info = function () {
		$mdDialog.show(
			$mdDialog
				.alert()
				.clickOutsideToClose(false)
				.title('Requested Feature')
				.textContent('This is a requested feature that would be implemented in due time')
				.ariaLabel('Requested feature')
				.ok('Got it!')
		);
	};

	$scope.resolveProfile = function (thumbnail) {
		if (thumbnail == '') {
			return 'http://www.gravatar.com/avatar/' + md5($scope.user.email) + '.jpg?s=800&d=' + CONST_DEFAULT_PROFILE;
		} else {
			return thumbnail;
		}
	};

	$scope.update = function () {
		var user = angular.copy($localStorage.user);
		lsfactory.setEnv('editUser', user);

		$mdDialog.show({
			escapeToClose: true,
			clickOutsideToClose: true,
			templateUrl: 'views/pages/roles/sa/dialogs/profile-edit-template.html',
			controller: ProfileEditDialogController,
		});
	};
}

saProfile.controller = saProfileCmpController;

appModule.component('saProfile', saProfile);

saProfile.selector = 'saProfile';

export { saProfile }

import template from './index.html';

/** @type {import('@srcAjs/components2/adminRoutes').IComponentOptions2} */
const saSchoolSetupClassesDetailSection = {
	template,
	bindings: {
		//someInput: '@string,<object,&function'
	},
	transclude: {
		// #slotName: '?optionalPublicName'
	},
};



function saSchoolSetupClassesDetailSectionCmpController(
	SAFactory,
	$scope,
	SadminSetupClassLevelStore,
	$stateParams,
	$state,
	$rootScope,
	$localStorage,
	$mdDialog,
	$mdToast,
	lsfactory,
	Restangular,
	SClass,
	Section
) {
	lsfactory.setEnv('backLog', 'sa.school_setup_classes_detail.sections');
	lsfactory.setEnv('backLogData', { id: SadminSetupClassLevelStore.get('selected_sclass').id });
	SAFactory.loadEnvironment(SadminSetupClassLevelStore.getCache());
	SAFactory.loadNotifications();

	$scope.isReady = false;

	// if($stateParams.cid != ""){
	//       SClass.one($stateParams.cid).get().then(function(res){
	//         $scope.class = res;
	//       },function(err){
	//         if(err.status == 404){
	//         }
	//       });
	// }
}

saSchoolSetupClassesDetailSection.controller = saSchoolSetupClassesDetailSectionCmpController;

appModule.component('saSchoolSetupClassesDetailSection', saSchoolSetupClassesDetailSection);

saSchoolSetupClassesDetailSection.selector = 'saSchoolSetupClassesDetailSection';

export { saSchoolSetupClassesDetailSection }

import template from './index.html';

/** @type {import('@srcAjs/components2/adminRoutes').IComponentOptions2} */
const aaHomeDashboard = {
	template,
	bindings: {
		//someInput: '@string,<object,&function'
	},
	transclude: {
		// #slotName: '?optionalPublicName'
	},
	selector: 'aaHomeDashboard',
};

/** @this {ng.IController} */
function aaHomeDashboardController(
	$scope,
	$mdToast,
	$mdDialog,
	$state,
	$localStorage,
	AAFactory,
	Restangular,
	Schools,
	$cacheFactory,
	/** @type {ErrorService} */ ErrorService,
	/** @type {EffectService} */ EffectService,
	/** @type {compSetupService} */ compSetupService,
	/** @type {atomosService} */ atomosService,
	/** @type {API} */ API
) {
	compSetupService(this);

	const [schoolsRxQ$, { revalidate }] = API.rxQuery(
		['rxq.schools'],
		(r) =>
			r
				.all(
					'school?' +
						API.buildParams_v2({
							fields: [
								{ condition: 'with', field: 'school_admins.user' },
								{ condition: 'with', field: 'school_approvals' },
							],
						})
				)
				.getList(),
		{
			destroy$: this.componentDestroy$,
		}
	);

	const refreshSchoolsRxQ = () => revalidate({ revalidateAll: true });

	EffectService.setup(this, ({ onInit }) => {
		if (onInit) {
			atomosService(schoolsRxQ$, this).stream$.subscribe(({ data, loading, error }) => {
				$scope.isLoading = loading;
				if (error) return ErrorService.handleError(error);

				if (!data) return;

				const schools = data.plain().map((sch) => ({ ...sch, approval_status: sch.school_approvals[0]?.status }));
				$scope.approved_schools = schools.filter(({ approval_status = 'pending' }) => approval_status.toLowerCase().includes('approved'));
				$scope.pending_schools = schools.filter((school) => !$scope.approved_schools.includes(school));
				$scope.schools = schools;
			});
		}
	});

	AAFactory.initializeRole();
	AAFactory.loadEnvironment();
	$scope.schools = [];
	$scope.pending_count = 0;

	let promtWhenSchoolHasApproval = (school) => () => {
		let result = true;

		if (school.approval_status !== 'approved') {
			return result;
		}
		return $mdDialog.show($mdDialog.prompt().textContent('Type `PROCEED` to continue with the delete operation').ok('Go Ahead').cancel('I change my mind')).then((response) => {
			if (response !== 'PROCEED') throw new Error('Invalid input');

			return result;
		});
	};

	$scope.decline = function (school) {
		var confirm = $mdDialog
			.confirm()
			.title('Decline Confirmation for "' + school.title + '"?')
			.textContent('Decline school.')
			.ariaLabel('Warning')
			.ok('Yes please!')
			.cancel('I dont think so');

		$mdDialog.show(confirm).then(() => {
			Restangular.all('school/approval/perform')
				.post({ sap_id: school.school_approvals[0].id, answer: 'declined' })
				.then((res) => {
					$mdToast.showSimple('Decline successful');
					return refreshSchoolsRxQ();
				}, ErrorService.handleError);
		});
	};
	$scope.remove = (school) => {
		let confirm = $mdDialog
			.confirm()
			.title('Would you like to delete "' + school.title + '"?')
			.textContent('This operation cannot be undone. All dependants of this school will also be removed.')
			.ariaLabel('Warning')
			.ok('Yes please!')
			.cancel('I dont think so');

		$mdDialog
			.show(confirm)
			.then(promtWhenSchoolHasApproval(school))
			.then(() =>
				Schools.one(school.id)
					.remove()
					.then(function (sch) {
						$mdToast.showSimple(`School: ${sch.title} removed`);
						delete $localStorage.environment.isWaiting;
						return refreshSchoolsRxQ();
					})
			)
			.catch(ErrorService.handleError);
	};

	$scope.approve = function (school, response = true) {
		var confirm = $mdDialog
			.confirm()
			.title('Approval confirmation for "' + school.title + '"?')
			.textContent('Approve school.')
			.ariaLabel('Warning')
			.ok('Yes please!')
			.cancel('I dont think so');

		$mdDialog.show(confirm).then(function () {
			return Restangular.all('school/approval/perform')
				.post({ school_id: school.school_approvals[0].id, status: response ? 'approved' : 'pending' })
				.then(function (res) {
					$mdToast.showSimple('School approval updated');
					refreshSchoolsRxQ();
				});
		}, ErrorService.handleError);
	};
}

aaHomeDashboard.controller = aaHomeDashboardController;

appModule.component('aaHomeDashboard', aaHomeDashboard);

export { aaHomeDashboard };

import template from './index.html';

/** @type {import('@srcAjs/components2/adminRoutes').IComponentOptions2} */
const trClassSubAnalytics = {
	template,
	bindings: {
		//someInput: '@string,<object,&function'
	},
	transclude: {
		// #slotName: '?optionalPublicName'
	},
};

function trClassSubAnalyticsCmpController(
	$scope,
	$timeout,
	/** @type {ErrorService} */ ErrorService,
	$mdToast,
	$state,
	lsfactory,
	$rootScope,
	capitalizeInitialFilter,
	/** @type {TRFactory} */ TRFactory,
	/** @type {Restangular} */ Restangular,
	/** @type {atomosService} */ atomosService,
	/** @type {compSetupService} */ compSetupService,
	/** @type {EffectService} */ EffectService,
	/** @type {auth} */ auth,
	$stateParams,
	/** @type {API} */ API
) {
	compSetupService(this, trClassSubAnalytics.bindings);

	$scope.isReady = false;
	// $rootScope.isCTermLoading = true
	$rootScope.isCTermDisabled = false;
	$scope.term = null;

	const [sectionStudentsAnalytics$, { mutate, revalidate: revalidateSectionStudentsAnalytics }] = TRFactory.sectionStudentsAnalyticsRxQ(this);

	$scope.revalidateSectionStudentsAnalytics = () => {
		revalidateSectionStudentsAnalytics();
		revalidateSubMounts();
	};

	EffectService.setup(this, ({ onInit }) => {
		if (onInit) {
			atomosService(TRFactory.trServiceAtom.stream$).stream$.subscribe((atom) => {
				const data = atom;

				$scope.school = auth.getEngagedUserRole().school;
				const teacher = auth.getEngagedUserRole();
				$scope.teacher = teacher;
				const { section, section_histories } = TRFactory.getCachedTeacherSections(teacher).at(0);
				section.term = section.term ?? data.selectedTerm;
				$scope.section = section;

				lsfactory.setEnv('backLog', 'tr.home.dashboard');
				$state.current.data.title = $scope.section.sclass.class_level.title + ' (' + $scope.section.title + ')';

				mutate($scope.section);
			});

			sectionStudentsAnalytics$.subscribe(({ data, error, loading }) => {
				$scope.isReady = !loading;
				if (error) return ErrorService.handleError(error);
				renderUi(data);
			});
		}
	});

	function colores_google(n) {
		var colores_g = [
			'#3a5de8',
			'#dc3912',
			'#ff9900',
			'#109618',
			'#990099',
			'#0099c6',
			'#dd4477',
			'#66aa00',
			'#b82e2e',
			'#316395',
			'#994499',
			'#22aa99',
			'#aaaa11',
			'#6633cc',
			'#e67300',
			'#8b0707',
			'#651067',
			'#329262',
			'#5574a6',
			'#3b3eac',
		];
		return colores_g[n % colores_g.length];
	}

	$scope.options_subjects = {
		chart: {
			type: 'multiBarChart',
			height: 320,
			margin: {
				top: 50,
				right: 20,
				bottom: 45,
				left: 45,
			},
			color: function (d, i) {
				var key = i === undefined ? d : i;
				return d.color || colores_google(key);
			},
			clipEdge: true,
			duration: 500,
			stacked: true,
			xAxis: {
				axisLabel: 'Subject',
				showMaxMin: false,
				tickFormat: function (d) {
					return d;
				},
			},
			yAxis: {
				axisLabel: 'Subject Performance (%)',
				axisLabelDistance: -20,
				tickFormat: function (d) {
					return d3.format(',.1f')(d);
				},
			},
			forceY: [0, 100],
			zoom: {
				enable: true,
			},
			staggerLabels: true,
		},
	};

	$scope.options_students = {
		chart: {
			type: 'multiBarChart',
			height: 320,
			margin: {
				top: 50,
				right: 20,
				bottom: 45,
				left: 45,
			},
			color: function (d, i) {
				var key = i === undefined ? d : i;
				return d.color || colores_google(key);
			},
			clipEdge: true,
			duration: 500,
			stacked: true,
			staggerLabels: true,
			xAxis: {
				axisLabel: 'Student Name',
				showMaxMin: false,
				tickFormat: function (d) {
					return d;
				},
			},
			yAxis: {
				axisLabel: 'Performance',
				axisLabelDistance: -20,
				tickFormat: function (d) {
					return d3.format(',.1f')(d);
				},
			},
			forceY: [0, 100],
			zooming: {
				enable: true,
			},
		},
	};

	function generateChartDataSubjects(term_subjects) {
		var ret_data = [
			{
				key: 'Subject Grade Average (%)',
				values: [],
			},
		];

		var sr = [];
		_.each(term_subjects, function (subject) {
			var tx = subject.average;
			if (tx < 0) {
				tx = 0;
			}
			var p = { x: subject.subject.title + ' (' + subject.subject.type + ')', y: tx, orderNum: tx, subm_id: subject.id };
			sr.push(p);
		});

		sr.sort(function (a, b) {
			if ((typeof b.orderNum === 'undefined' && typeof a.orderNum !== 'undefined') || a.orderNum > b.orderNum) {
				return -1;
			}
			if ((typeof a.orderNum === 'undefined' && typeof b.orderNum !== 'undefined') || a.orderNum < b.orderNum) {
				return 1;
			}
			return 0;
		});

		_.each(sr, function (sx) {
			ret_data[0].values.push(sx);
		});

		return ret_data;
	}

	function generateChartDataStudents(term_students) {
		var ret_data = [
			{
				key: 'Class Average Percentage (%)',
				values: [],
			},
		];

		var sr = [];
		_.each(term_students, function (student) {
			var tx = student.term_grades.CLASS_AVG;
			if (tx < 0) {
				tx = 0;
			}
			var p = { x: capitalizeInitialFilter(student.student.lastname) + '. ' + student.student.firstname, y: tx, orderNum: tx };
			sr.push(p);
		});

		_.each(sr, function (sx) {
			ret_data[0].values.push(sx);
		});

		return ret_data;
	}

	const renderUi = (res) => {
		if (!res) return;
		var data = res.plain();
		$scope.student_perf_list = data.students_load;
		$scope.data_students = generateChartDataStudents(data.students_load);
		$scope.data_subjects = generateChartDataSubjects(data.subjects_load);

		// $rootScope.isCTermLoading = false
		$timeout(function () {
			window.dispatchEvent(new Event('resize'));
		}, 1000);
	};
}

trClassSubAnalytics.controller = trClassSubAnalyticsCmpController;

appModule.component('trClassSubAnalytics', trClassSubAnalytics);

trClassSubAnalytics.selector = 'trClassSubAnalytics';

export { trClassSubAnalytics };

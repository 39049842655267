import template from './index.html';

/** @type {import('@srcAjs/components2/adminRoutes').IComponentOptions2} */
const saSchoolSetupSubjectsIndex = {
	template,
	bindings: {
		//someInput: '@string,<object,&function'
	},
	transclude: {
		// #slotName: '?optionalPublicName'
	},
};

function saSchoolSetupSubjectsIndexCmpController(
	$scope,
	/** @type {import("@srcAjs/factory/services/class-group").ClassGroupApi} */ ClassGroup,
	$timeout,
	$state,
	ErrorService,
	SAFactory,
	Subject,
	$mdDialog,
	lsfactory,
	SubjectType,
	$filter
) {
	SAFactory.initializeRole();
	SAFactory.loadEnvironment();
	// $scope.env = $localStorage.environment
	// $scope.school = $scope.env.school
	$scope.school = lsfactory.getEnv('school');
	$scope.classes = [];
	$scope.types = [];
	$scope.sections = [];
	$scope.class_group_title = null;
	$scope.is_in_detail_mode = false;
	$scope.term;

	$scope.reloadData = function () {
		$scope.isReady = false; //only show when view is being initalized

		ClassGroup.get($scope.school, $scope.term, ['subjects', 'class_group'])
			.then(function (data) {
				$scope.sections = [];
				console.log('Check this data out');
				console.dir(data);

				let ordered_groups = $filter('orderBy')(data, '+class_group.order');
				$scope.class_groups = $filter('groupBy')(ordered_groups, 'class_group.class_group_type.title');

				// if ($scope?.class_group) $scope.sections = $filter("filter")(data, filter($scope.class_group_title))
				if ($scope.class_group_title) $scope.class_group = $scope.class_groups[$scope.class_group_title];
			})
			.catch((error) => ErrorService.handleError(error))
			.finally(() => ($scope.isReady = true));
	};
	function filter(title) {
		return (value) => value.class_group.class_group_type.title == title;
	}

	$scope.set_department = function (class_group, title) {
		$scope.is_in_detail_mode = true;
		$scope.class_group_title = title;
		// Fix in the scooped classes into a sub array for processing to reduce burden
		$scope.class_group = class_group;
	};

	$scope.go_back = function () {
		$scope.is_in_detail_mode = false;
		$scope.sections = null;
	};

	$scope.newClass = function () {
		$state.go('sa.school_setup_classes');
	};

	$scope.$on('subject.dataChanged', function () {
		$scope.reloadData();
	});

	$scope.addDeptSubject = function (id) {
		lsfactory.setEnv('dialog_class_group', id); // Send something to the dialog
		$mdDialog.show({
			escapeToClose: false,
			clickOutsideToClose: false,
			locals: {
				_term: $scope.term,
			},
			bindToController: true,
			templateUrl: 'views/pages/roles/sa/school_setup/subjects/dialogs/add_dept_subject.html',
			controller: AddDeptSubjectDialogController,
		});
	};

	$scope.edit = function (sub) {
		var cedit = angular.copy(sub);
		lsfactory.setEnv('dialogEditSubject', cedit);
		lsfactory.setEnv('dialogSubjectTypes', $scope.types);
		$mdDialog.show({
			escapeToClose: false,
			clickOutsideToClose: false,
			templateUrl: 'views/pages/roles/sa/school_setup/subjects/dialogs/edit_subject.html',
			controller: EditSubjectDialogController,
		});
	};

	$scope.remove = function (subject) {
		Subject.one(subject.id)
			.get()
			.then(function (res) {
				var sub1 = res;
				var confirm = $mdDialog
					.confirm()
					.title('Would you like to delete "' + subject.title + '"?')
					.textContent('This operation cannot be undone.All dependants of this subject will also be removed alsoo.')
					.ariaLabel('Warning')
					.ok('Yes please!')
					.cancel('I dont think so');
				$mdDialog.show(confirm).then(
					function () {
						sub1.remove().then(function () {
							$scope.$broadcast('subject.dataChanged', res.plain());
						});
					},
					function () {
						// Error Case
					}
				);
			});
	};

	SubjectType.getList().then(
		function (data) {
			var list = data.plain();
			$scope.types = list;
		},
		function (err) {
			ErrorService.handleError(err);
		}
	);

	$scope.addSubject = function (sclass) {
		lsfactory.setEnv('dialog_sclass', sclass); // Send something to the dialog
		lsfactory.setEnv('dialogSubjectTypes', $scope.types);
		$mdDialog.show({
			escapeToClose: false,
			clickOutsideToClose: false,
			locals: {
				_term: $scope.term,
			},
			bindToController: true,
			controllerAs: '$ctrl',
			templateUrl: 'views/pages/roles/sa/school_setup/subjects/dialogs/add_subject.html',
			controller: AddSubjectDialogController,
		});
	};

	//TODO: displaying of the summary dialog for each subject
	//Also will be setting an environment for each of the details here
	$scope.subjectSummary = function (subject) {
		lsfactory.setEnv('dialogSubjectSummary', subject);
		$mdDialog.show({
			clickOutsideToClose: true,
			escapeToClose: false,
			templateUrl: 'views/pages/roles/sa/school_setup/subjects/dialogs/subject_summary.html',
			controller: SubjectSummary,
		});
	};

	$scope.updateTerm = (term) => {
		if (term !== $scope.term) {
			$scope.term = term;

			$scope.year = term.year;

			$scope.reloadData();
		}
	};
}

saSchoolSetupSubjectsIndex.controller = saSchoolSetupSubjectsIndexCmpController;

appModule.component('saSchoolSetupSubjectsIndex', saSchoolSetupSubjectsIndex);

saSchoolSetupSubjectsIndex.selector = 'saSchoolSetupSubjectsIndex';

export { saSchoolSetupSubjectsIndex };

/** @typedef {ReturnType<typeof PanelUrlServiceFactory>} PanelUrlService*/

function PanelUrlServiceFactory(/** @type {atomosService} */ atomosService) {
	this._atom = undefined;

	this.init = (/** @type {ng.Controller} */ _this) => {
		this._atom = atomosService(/** @type {[]|undefined}*/undefined, _this);
	};

	this.dispatch = (/** @type {'push'|'pop'} */ action, /** @type {string} */ data) => {
		this._atom.set((prev) => {
			let temp;
			if (action === 'pop') {
				temp = (prev ?? []).filter((i) => i !== data && i !== encodeURI(data));
			}

			if (action === 'push') {
				temp = [...new Set([...(prev ?? []), data]).values()];
			}
			return temp.length ? temp : undefined;
		});
	};

	return this;
}

appModule.factory('PanelUrlService', PanelUrlServiceFactory);

import template from './index.html';

/** @type {import('@srcAjs/components2/adminRoutes').IComponentOptions2} */
const trSubjectSubAssessment = {
	template,
	bindings: {
		//someInput: '@string,<object,&function'
	},
	transclude: {
		// #slotName: '?optionalPublicName'
	},
};

function trSubjectSubAssessmentCmpController(
	/** @type {ng.IScope} */ $scope,
	$timeout,
	$mdToast,
	/** @type {ng.material.IDialogService} */ $mdDialog,
	$state,
	$rootScope,
	$localStorage,
	/** @type {lsfactory}*/ lsfactory,
	/** @type {TRFactory}*/ TRFactory,
	SubjectAssessmentType,
	SubjectAssessmentGroup,
	SubjectAssessmentEntity,
	/** @type {import('restangular').IService} */ Restangular,
	/** @type {canSyncAssessmentEntityFilter}*/ canSyncAssessmentEntityFilter,
	/** @type {canSyncAssessmentGroupFilter}*/ canSyncAssessmentGroupFilter,
	amDateFormatFilter,
	/** @type {ErrorService} */ ErrorService,
	$stateParams,
	/** @type {ng.material.IPanelService} */ $mdPanel,
	/** @type {getGradeColorFilter} */ getGradeColorFilter,
	/** @type {EffectService} */ EffectService,
	/** @type {atomosService} */ atomosService,
	/** @type {compSetupService} */ compSetupService,
	/** @type {ng.IWindowService} */ $window
) {
	compSetupService(this, trSubjectSubAssessment.bindings);

	$rootScope.isCTermDisabled = true;

	/**
	 * @type {typeof $scope &
	 * {teacher: RoleInstance,
	 *  subject_mount: SubjectMount,
	 *  this_term: Term,
	 *  current_term:Term,
	 *  isReady:boolean,
	 *  isProcessing:boolean,
	 * scoop_students: SectionHistory[],
	 * agroups: SubjectAssessmentGroup[],
	 * school: School
	 *  current_assessment: SubjectAssessmentEntity
	 * subjectMount: SubjectMount
	 * chosen_assessment:SubjectAssessmentEntity
	 * chosen_assessment_group:SubjectAssessmentGroup
	 * [key: string]:any
	 *
	 * }}
	 */
	const svm = $scope;

	svm.school = lsfactory.getEnv('school');
	svm.assessment_groups = [];
	svm.types = [];
	svm.search_students = '';
	svm.this_term = undefined;
	svm.current_sum = 0.0;
	svm.forget_list = [];
	svm.isSaved = false;
	svm.isProcessing = true;
	svm.isReady = false;
	svm.current_assessment = undefined;
	svm.scoop_students = [];
	svm.assessments_stats = null;
	// TODO: Soon this is gonna have to come from the Backend or something. Thats why its a data structure
	svm.color_standard = [
		{ fg_color: 'rgb(65, 113, 15)', bg_color: 'rgb(198, 230, 135)', min: 80, max: 100 }, // green zone
		{ fg_color: 'rgb(125, 91, 0)', bg_color: 'rgb(238, 202, 108)', min: 50, max: 79 }, // warning zone
		{ fg_color: 'rgb(255, 255, 255)', bg_color: 'rgb(234, 72, 67)', min: 0, max: 49 }, // red zone
	];

	svm.subject_mount = null;
	svm.agroups = null;

	svm.under_construction = function () {
		$mdDialog.show(
			$mdDialog
				.alert()
				.clickOutsideToClose(false)
				.title('Requested Feature')
				.textContent('This is a requested feature that would be implemented in due time')
				.ariaLabel('Requested feature')
				.ok('Got it!')
		);
	};
	svm.myData = {
		labels: null,
		datasets: [
			{
				data: null,
			},
		],
	};

	function findAndRemove(groupe) {
		var $index = null;
		for (var i = 0; i < svm.agroups.length; i++) {
			if (svm.agroups[i].id == groupe.id) {
				$index = i;
				break;
			}
		}
		svm.agroups.splice($index, 1);
	}

	svm.review = function (ae) {
		ae.in_review = !ae.in_review;
	};

	function showAssessmentEditBlockAlert() {
		$mdDialog.show(
			$mdDialog.alert().textContent('Assessment has pending updates, sync or refresh to cancel changes and try again').clickOutsideToClose(false).title('Not permitted').ok('Close')
		);
	}

	function getorUpdateCachedAE(
		/** @type {{ae:SubjectAssessmentEntity,sh_cache?: ScCache,ag: SubjectAssessmentGroup, update?:boolean}} */ {
			ae,
			ag = svm.agroups.find(({ id: ag_id }) => ae.subject_assessment_group_id === ag_id),
			sh_cache = TRFactory.trServiceAtom.get().termsCache,
			update = false,
		}
	) {
		const cae = sh_cache[svm.this_term.id].subject_mounts[ag.subject_mount_id].subject_assessment_groups[ae.subject_assessment_group_id].subject_assessment_entities[ae.id];

		cae.subject_assessment_entity = update ? ae : cae.subject_assessment_entity;

		return { cached_assessment_entity: cae, cache: sh_cache };
	}
	svm.put_review = function (
		/** @type {SubjectAssessmentEntity} */ ae,
		/** @type {SubjectAssessmentGroup} */ ag = svm.agroups.find((ag) => ag.id === ae.subject_assessment_group_id),
		/** @type {Event} */ event = null
	) {
		event?.preventDefault();

		const sh_cache = lsfactory.getCache(true);
		const { cached_assessment_entity: cae } = getorUpdateCachedAE({ ae, sh_cache, ag });
		const { in_review_toggle, in_review, gradable, id } = ae;

		if (canSyncAssessmentEntityFilter(cae)) {
			ae.in_review_toggle = in_review;
			showAssessmentEditBlockAlert();
			return;
		}

		if (!ae?.subject_assessment_scores?.length || ae.subject_assessment_scores.length < svm.subjectMount.term_students.length) {
			ae.in_review_toggle = in_review;
			$mdDialog.show($mdDialog.alert().title('Review blocked').textContent('Reason: provided scores for all students and try again!').ok('Ok'));
			return;
		}

		if (ae.approved) {
			ae.in_review_toggle = ae.in_review;
			$mdToast.showSimple('Assessment has passed review already');
			return;
		}

		$mdDialog
			.show($mdDialog.confirm().title('Confirm action').textContent(`Request review: ${ae.title}`).ok('proceed').cancel('cancel'))
			.then((result) => {
				if (!result) {
					return;
				}

				svm.isProcessing = true;
				console.log({ in_review_toggle, in_review });

				Restangular.all('subject/subject-assessment-entity')
					.post({ in_review: in_review_toggle, gradable, id })
					.then((res) => {
						const msg = in_review_toggle ? 'Successful: Assessment pending review' : 'Successful: Assessment reviewd from review';
						ae.in_review = in_review_toggle;

						$mdToast.showSimple(msg);
						refreshAEntity(ae, ag, {
							title: `Review status updated: ${ae.title}`,
							textContent: `Click refresh to update your local copy now`,
						});
					})
					.catch((error) => {
						ae.in_review_toggle = ae.in_review;
						ErrorService.handleError(error);
					})
					.finally(() => {
						svm.isProcessing = false;
					});
			})
			.catch((result) => {
				ae.in_review_toggle = ae.in_review;
			});
	};

	svm.saved = function () {
		$mdDialog.show(
			$mdDialog
				.alert()
				.clickOutsideToClose(false)
				.title('All your "Scores" have been Saved Successfully')
				.textContent('Your changes have been saved successfully. You can click the "Close" button')
				.ariaLabel('Saved Successfully')
				.ok('Got it!')
		);
	};

	svm.warning = function () {
		$mdDialog.show(
			$mdDialog
				.alert()
				.clickOutsideToClose(false)
				.title('Changes NOT Saved')
				.textContent('Your changes have not yet been saved. Trying to establish connection with server ...')
				.ariaLabel('Changes NOT Saved')
				.ok('Got it!')
		);
	};

	svm.swapGradable = function (ae, ag, /** @type {Event}*/ event) {
		event?.preventDefault();

		const sh_cache = lsfactory.getCache(true);

		const { cached_assessment_entity: cae } = getorUpdateCachedAE({ ae, ag, sh_cache });

		if (canSyncAssessmentEntityFilter(cae)) {
			showAssessmentEditBlockAlert();
			return;
		}
		var orig = ae.gradable;
		var op = !orig;
		var msg = op ? 'Add "' + ae.title + '" to Gradebook ?' : 'Remove "' + ae.title + '" from the Gradebook?';
		var desc = op
			? 'Choosing "yes" will make this assessment a part of your Gradebook thereby, this item would count towards your students\' final score'
			: 'Choosing "yes" will remove this assessment from your gradebook. Hence, this item would not count towards your students\' final score';
		var confirm = $mdDialog.confirm().title(msg).textContent(desc).ariaLabel('Warning').ok('Yes please!').cancel('I dont think so');

		$mdDialog
			.show(confirm)
			.then(() => {
				ae.gradable = op;
				return Restangular.all('subject/subject-assessment-entity')
					.post({ gradable: op, in_review: ae.in_review, id: ae.id })
					.then(() => {
						getorUpdateCachedAE({ ae, ag, sh_cache, update: true });
						TRFactory.updateAtomCache(sh_cache);
						$mdToast.showSimple('Assessment Updated');
					});
			})
			.catch(ErrorService.handleError)
			.finally(() => {
				svm.isReady = true;
			});
	};

	async function findAndRemoveEntity(group_id, ae) {
		try {
			svm.isReady = false;
			let [ag_item] = Object.values(await TRFactory.fetchSubjectMountAssessmentGroups(svm.subjectMount)).filter((ag_item) => ag_item.subject_assessment_group.id == group_id);

			//remote ae from ag
			delete ag_item.subject_assessment_entities[ae.id];

			//update case
			let sh_cache = TRFactory.trServiceAtom.get().termsCache;
			sh_cache[svm.this_term.id].subject_mounts[ag_item.subject_assessment_group.subject_mount_id].subject_assessment_groups[ag_item.subject_assessment_group.id] = ag_item;
			TRFactory.updateAtomCache(sh_cache);
		} catch (error) {
			ErrorService.handleError(error);
		} finally {
			setAssessmentGroups(await TRFactory.fetchSubjectMountAssessmentGroups(svm.subjectMount));
			svm.isReady = true;
		}
	}

	function findAndUpdate(ae) {
		for (var i = 0; i < svm.agroups.length; i++) {
			for (var j = 0; j < svm.agroups[i].subject_assessment_entities.length; j++) {
				if (svm.agroups[i].subject_assessment_entities[j].id == ae.id) {
					svm.agroups[i].subject_assessment_entities[j] = ae;
					break;
				}
			}
		}
	}

	function findAndUpdateGroup(/**@type {SubjectAssessmentGroup}*/ ag) {
		TRFactory.updateAtom((atom) => {
			const { subject_assessment_entities, ...ag_props } = ag;
			const temp_ag = atom.termsCache[atom.selectedTermId].subject_mounts[atom.selectedCachedSubjectMountId].subject_assessment_groups[ag_props.id];
			temp_ag.subject_assessment_group = { ...temp_ag.subject_assessment_group, ...ag_props };
			return atom;
		});
	}

	svm.removeGroup = async function (group) {
		if (canSyncAssessmentGroupFilter((await TRFactory.fetchSubjectMountAssessmentGroups(svm.subjectMount))[group.id])) {
			showAssessmentGroupEditBlockAlert();
			return;
		}

		svm.isReady = false;

		var confirm = $mdDialog
			.confirm()
			.title('Would you like to delete "' + group.title + '"?')
			.textContent('This operation cannot be undone. All the assessments under this bucket will be removed in addition. You will lose all your scores. Are you sure?')
			.ariaLabel('Warning')
			.ok('Yes please!')
			.cancel('I dont think so');

		$mdDialog
			.show(confirm)
			.then(function () {
				return SubjectAssessmentGroup.one(group.id)
					.remove()
					.then(function (res) {
						if (!res) return;

						// findAndRemove(group);
						let sh_cache = TRFactory.trServiceAtom.get().termsCache;
						delete sh_cache[svm.this_term.id].subject_mounts[svm.subjectMount.id].subject_assessment_groups[group.id];
						TRFactory.updateAtomCache(sh_cache);

						$mdToast.showSimple('Assessment Group removed');
					});
			})
			.catch(ErrorService.handleError)
			.finally(() => {
				// TODO: REMOVE
				// updateContext();
				svm.isReady = true;
			});
	};

	// TODO: REMOVE
	function updateContext() {
		var list = svm.agroups;

		svm.current_sum = 0;
		svm.forget_list = [];
		_.each(svm.types, function (item) {
			item.disable = false;
		});

		for (var i = 0; i < list.length; i++) {
			svm.current_sum += parseFloat(svm.agroups[i].weight);
			for (var j = 0; j < svm.types.length; j++) {
				if (svm.types[j].id == list[i].subject_assessment_type_id) {
					svm.forget_list.push(j);
				}
			}
		}

		for (var i = svm.forget_list.length - 1; i >= 0; i--) {
			svm.types[svm.forget_list[i]].disable = true;
		}
	}

	svm.$on('Xentity.edited', function (event, entity) {
		console.log('entity.edited fired');
		svm.chosen_assessment = entity;
		attachGradeStyles(svm.scoop_students);
		findAndUpdate(entity);
	});

	svm.$on('Xassessments.entity_added', function () {
		try {
			TRFactory.fetchSubjectMountAssessmentGroups(svm.subjectMount).then(setAssessmentGroups);
		} catch (error) {
			ErrorService.handleError(error);
		}
	});
	function initUI() {
		// $("#assessment-buckets").show()
		// $("#assessment-scores").hide()
		svm.chosen_assessment_group = null;
		svm.chosen_assessment = null;
	}

	svm.view_registered_students = function () {
		lsfactory.setEnv('dialogRegisteredStudents', svm.subjectMount.section_histories);
		$mdDialog.show({
			escapeToClose: true,
			clickOutsideToClose: true,
			templateUrl: 'views/pages/roles/tr/subject/dialogs/view_registered_students.html',
			controller: ViewRegisteredStudentsDialogController,
		});
	};

	// todo: remove these (hideAllItems, hideEmAll)
	function hideAllItems(s, e) {
		_.each(svm.scoop_students, function (std) {
			if (s.id != std.id) {
				$('#score-' + std.id).hide();
				e.stopPropagation();
			}
		});
	}

	svm.hideEmAll = function (e) {
		_.each(svm.scoop_students, function (std) {
			$('#score-' + std.id).hide(300);
			e.stopPropagation();
		});
	};

	function getBG(score) {
		var color = 'rgb(236, 239, 241)'; // Default once again
		var total = svm.chosen_assessment.total;
		var centage = (score.score / total) * 100; // Get the percentage;
		console.log('For this score ' + score.score + ' ---> ' + centage + '%');
		centage = Math.round(centage);
		for (var i = 0; i < svm.color_standard.length; i++) {
			if (centage >= svm.color_standard[i].min && centage <= svm.color_standard[i].max) {
				color = svm.color_standard[i].bg_color;
			}
		}
		return color;
	}

	svm.getBGColorCode = function (sh) {
		if (!!sh.score && !!sh.score.score) {
			return getBG(sh.score);
		} else {
			return 'rgb(236, 239, 241)'; // Defaults for NM
		}
	};

	function getFG(score) {
		var color = 'black'; // Default once again
		var total = svm.chosen_assessment.total;
		var centage = (score.score / total) * 100; // Get the percentage;
		centage = Math.round(centage);
		for (var i = 0; i < svm.color_standard.length; i++) {
			if (centage >= svm.color_standard[i].min && centage <= svm.color_standard[i].max) {
				color = svm.color_standard[i].fg_color;
			}
		}
		return color;
	}

	svm.getFGColorCode = function (sh) {
		if (!!sh.score && !!sh.score.score) {
			return getFG(sh.score);
		} else {
			return 'black'; // Defaults for NM
		}
	};

	svm.submitScore = async function (/**@type ScoreModel*/ scooped_item, /**@type number */ idx, /**@type boolean */ apply_score_to_all, /**@type Event */ e) {
		try {
			console.log('apply all', apply_score_to_all);

			if (!_.isFinite(scooped_item?.score?.score) || !_.isBoolean(apply_score_to_all) || !_.isFinite(idx) || !e) {
				throw new IError('Invalid parameters on submitScore', { scooped_item, apply_score_to_all, idx });
			}

			const local_created_at = new Date().toISOString();
			// let counter_instances = { new_scores: 0, updated_scores: 0 };
			let score_mods = new Map();

			// get all sh and apply score to them
			console.log('applying all for students: ', svm.scoop_students.length);

			const score_changes = angular
				.copy(svm.scoop_students)
				// .filter((student_term_subject) => student_term_subject.id == scooped_item.id || apply_score_to_all)
				.map((student_term_subject, key) => {
					if (student_term_subject.id != scooped_item.id && !apply_score_to_all) {
						//return old score
						return [student_term_subject.id, student_term_subject.score];
					}

					/**@interface Score */
					let data = {
						id: student_term_subject?.score?.id,
						score: scooped_item.score.score,
						comment: scooped_item.score.comment,
						student_term_subject_id: student_term_subject.id,
						subject_assessment_entity_id: svm.chosen_assessment.id,
						sync_status: 'updated_unsynced',
						local_created_at,
					};

					// let new_or_update_array = update_scores

					if (!student_term_subject?.score?.id) {
						console.log('sh no score id', student_term_subject.score.id);
						// Creating a new score. Will have to update UI
						// vm.isProcessing = true
						data.id = `new_${Date.now()}_:_${student_term_subject.id}`;
						data.sync_status = 'created_unsynced';
					}

					return [student_term_subject.id, data];
				});

			const counter_instances = score_changes.reduce(
				(acc, current) => {
					if (current['local_created_at'] === local_created_at) {
						acc[current.sync_status] += 1;
					}

					return acc;
				},
				{ created_unsynced: 0, updated_unsynced: 0 }
			);

			score_mods = new Map(score_changes);
			// vm.isProcessing = true

			let sh_cache = angular.copy(TRFactory.trServiceAtom.get().termsCache);

			let { subject_assessment_scores: cached_assessment_scores } = angular.copy(
				(await TRFactory.fetchSubjectMountAssessmentGroups(svm.subjectMount))[svm.chosen_assessment_group.id].subject_assessment_entities[svm.chosen_assessment.id]
			);

			let student_term_subject_ids = Object.values(angular.copy(cached_assessment_scores)).map((cas) => cas.student_term_subject_id);

			// [...score_mods.values()].forEach((score) => {
			for (const score of [...score_mods.values()]) {
				//insert into cached term subjectmount assessment_groups subject_assessment_entity subject_assessment_scores

				if (score?.sync_status === 'created_unsynced' && !student_term_subject_ids.includes(score?.student_term_subject_id)) {
					//if students score is not cached
					cached_assessment_scores[score.id] = score; //cache new score
				} else if (score?.sync_status === 'updated_unsynced' && student_term_subject_ids.includes(score?.student_term_subject_id)) {
					let cached_score = Object.values(cached_assessment_scores).find((cs) => cs.student_term_subject_id == score.student_term_subject_id);
					// cached_score.score = score.score
					cached_assessment_scores[cached_score.id] = Object.assign(cached_score, score);
				}
			}

			TRFactory.updateAtom((atom) => {
				const entity =
					atom.termsCache[svm.this_term.id].subject_mounts[svm.chosen_assessment_group.subject_mount_id].subject_assessment_groups[svm.chosen_assessment_group.id]
						.subject_assessment_entities[svm.chosen_assessment.id];

				entity.subject_assessment_entity.sync_status = 'updated_unsynced';
				entity.subject_assessment_scores = cached_assessment_scores;

				return atom;
			});

			showScoreUpdateToast(counter_instances);

			apply_score_to_all = false; //disable apply on all
		} catch (error) {
			ErrorService.handleError(error);
		}
	};

	svm.showHistogram = function () {};

	svm.setFocus = function (sh, $event) {
		// TODO find a way to prevent :space from going back to the score
		$('#comment-' + sh.id)
			.first()
			.focus();
		$event.stopPropagation();
	};

	svm.showAssessmentScoreCrudCmp = (assessment_entity, _scooped_item, enable_set_all_switch, idx, $event) => {
		console.log('showAssessmentScoreCrudCmp');
		new Promise((res, rej) => {
			if (!assessment_entity.approved) return res(true);

			if (svm.chosen_assessment.in_review) {
				$mdDialog.show(
					$mdDialog
						.alert()
						.clickOutsideToClose(false)
						.title('You cannot modify score in Review MODE')
						.textContent('This assessment has been marked for review by school administrators. Please toggle the review state to modify scores')
						.ariaLabel('REVIEW MODE Constraint dialogs')
						.ok('Got it!')
				);
				return rej(false);
			}

			let msg = 'You are about to edit an Approved Assessment Score';
			let desc = 'This assessment has been approved ... Modifying the scores would make it unavailable to parents requiring a subsequent approval from a school administrator';

			let confirm = $mdDialog.confirm().title(msg).textContent(desc).ariaLabel('Warning').ok('Yes please!').cancel('I dont think so');
			$mdDialog.show(confirm).then(
				() => res(true),
				() => rej(false)
			);
		}).then((response) => {
			if (!response) return;

			$event.target.scrollIntoView(true);

			console.log('assessment score panel');
			let panelPosition = $mdPanel.newPanelPosition().absolute($event.target).center();
			let panelAnimation = $mdPanel.newPanelAnimation().openFrom($event).duration(200).withAnimation($mdPanel.animation.SCALE);

			let panel_response;
			// debugger;
			let panel_config = {
				attachTo: angular.element(document.body),
				controller: 'DumbController',
				controllerAs: '$ctrl',
				position: panelPosition,
				animation: panelAnimation,
				locals: {
					scoreItem: angular.copy(_scooped_item),
					enable_set_all_switch,
					onSubmit: svm.submitScore,
					'score-idx': idx,
					assessment_entity,
				},
				targetEvent: $event,
				template: `<assessment-entity-score-crud-cmp flex='95' flex-gt-md='50' 
						panel-ref="$ctrl.mdPanelRef" 
						score-idx="$ctrl.score-idx" 
						enable-set-all-switch='$ctrl.enable_set_all_switch' 
						assessment-entity='$ctrl.assessment_entity' score-item='$ctrl.scoreItem'
						on-submit="$ctrl.onSubmit(scooped_item, idx, apply_score_to_all, e)" 
					></assessment-entity-score-crud-cmp>`,
				clickOutsideToClose: true,
				bindToControoller: true,
				escapeToClose: true,
				focusOnOpen: true,
				disableParentScroll: true,
			};

			$mdPanel.open(panel_config);
		});
	};

	svm.hideGradeDialog = function (scooped_item, e) {
		console.log(e);
		$('#score-' + scooped_item.id).fadeOut(200);
		e.stopPropagation();
	};

	function showScoreUpdateToast(counter_instances) {
		return $mdToast.showSimple(`New scores: ${counter_instances.created_unsynced}  Updated scores: ${counter_instances.updated_unsynced}. Remember to sync changes regularly`);
	}

	function merge_the_two(scoop_students, scores) {
		scoop_students = scoop_students.map((sts) => {
			[sts.score] = Object.values(scores ?? []).filter((score) => score.student_term_subject_id == sts.id);
			sts.score = sts.score ?? { score: null, comment: null };
			return { ...sts };
		});
	}

	function attachGradeStyles(/** @type {TermSubjectStudent &{score:Score}}*/ scoop_students) {
		return angular.copy(scoop_students).map((sts) => ({ ...sts, score: { ...sts.score, gradeColorStyle: getGradeColorFilter((sts.score.fractional_score ?? 0) * 100) } }));
	}

	svm.open_gradebook = function () {
		$state.go('tr.subject.gradebook');
	};

	function attachScoresToStudents(scores, ae = svm.chosen_assessment) {
		// merge_the_two(svm.scoop_students, scores);
		svm.scoop_students = angular.copy(svm.scoop_students).map((sts) => {
			[sts.score] = Object.values(scores ?? []).filter((score) => score.student_term_subject_id == sts.id);
			sts.score = sts.score ?? { score: null, comment: null };
			const fractional_score = !ae ? null : (sts.score.score ?? 0) / ae.total;
			return { ...sts, score: { ...sts.score, fractional_score, gradeColorStyle: getGradeColorFilter((fractional_score ?? 0) * 100) } };
		});

		svm.isSaved = true;
		svm.isProcessing = false;
	}

	svm.showScores = async function (ae, agroup) {
		if (!ae || !agroup) throw new IError('Invalid ae or agroup parameter', { ae, agroup });

		$('#assessment-is-not-ready').show();
		$('#assessment-is-ready').hide();
		// vm.isReady = false
		svm.chosen_assessment = ae;
		svm.chosen_assessment_group = agroup;
		let _students = TRFactory.trServiceAtom.get().termsCache[svm.subjectMount.term_id].subject_mounts[agroup.subject_mount_id].subject_mount.term_students;
		svm.scoop_students = [..._students];
		svm.isLoadingScores = true;
		let student_term_subject_ids = _students.map((term_stu) => term_stu.id);

		let scores = (await TRFactory.fetchSubjectMountAssessmentGroups(svm.subjectMount))[agroup.id].subject_assessment_entities[ae.id].subject_assessment_scores ?? [];
		attachScoresToStudents(scores, ae);
		$('#assessment-is-not-ready').hide();
		$('#assessment-is-ready').show();
		// vm.isReady = true
		$timeout(() => {}, 10);
		$('#page-home').hide();
		$('#page-list').show();

		$('#assessment-buckets').hide();
		$('#assessment-scores').show();
	};

	svm.refreshAEntity = refreshAEntity;

	function refreshAEntity(/** @type {SubjectAssessmentEntity} */ ae, /** @type {SubjectAssessmentGroup} */ ag, /** @type {{title:string,textContent:string}} */ dialogConfig) {
		var confirm = $mdDialog
			.confirm()
			.title(dialogConfig?.title ?? `Refresh assessment?: ${ae.title}`)
			.textContent(dialogConfig?.textContent ?? 'Update your local changes to reflect your last synced changes. Changes that have not been synced will be lost.')
			.ariaLabel('Refresh assessment entity')
			.ok('Refresh!')
			.cancel('Cancel');

		$mdDialog.show(confirm).then(async function () {
			try {
				svm.isReady = false;
				let ag_item = (await TRFactory.fetchSubjectMountAssessmentGroups(svm.subjectMount))[ag.id];
				let sh_cache = TRFactory.trServiceAtom.get().termsCache;

				ae.id += '';

				if (ae.id.includes('new_')) {
					//remove from cache
					delete ag_item.subject_assessment_entities[ae.id];
				} else {
					//fetch assessment
					let sae = await TRFactory.fetchAssessmentEntity(ae.id);
					let scores = sae.subject_assessment_scores.reduce((acc, score) => {
						acc[score.id] = score;
						return acc;
					}, {});

					delete sae.subject_assessment_scores;
					ag_item.subject_assessment_entities[sae.id] = { subject_assessment_entity: sae, subject_assessment_scores: scores };
				}

				//update cache
				sh_cache[svm.this_term.id].subject_mounts[ag.subject_mount_id].subject_assessment_groups[ag.id] = ag_item;
				TRFactory.updateAtomCache(sh_cache);
			} catch (error) {
				showErrorToast(error, 'Failed to refresh assessment');
			} finally {
				setAssessmentGroups(await TRFactory.fetchSubjectMountAssessmentGroups(svm.subjectMount));
				svm.isReady = true;
				$timeout(() => {}, 10);
			}
		});
	}

	svm.syncAssessmentAndScore = async (/** @type {SubjectAssessmentEntity} */ ae, /** @type {SubjectAssessmentGroup} */ ag) => {
		let confirm = $mdDialog
			.confirm()
			.title('Confirm sync?')
			.textContent('This operation requires internet access. Would you like to proceed.')
			.ariaLabel('Confirm sync')
			.ok('Yes please!')
			.cancel('Cancel');

		$mdDialog.show(confirm).then(async function () {
			try {
				svm.isReady = false;
				await TRFactory.syncAssessmentAndScore(ae, ag, svm.subjectMount, svm.this_term.id);

				$mdToast.show($mdToast.simple().textContent(`Synced assessment: ${ae.title}`).hideDelay(5500).action('OK').highlightAction(true));
			} catch (err) {
				ErrorService.handleError(err);
			} finally {
				setAssessmentGroups(await TRFactory.fetchSubjectMountAssessmentGroups(svm.subjectMount));
				svm.isReady = true;
				$timeout(() => {}, 10);
			}
		});
	};

	svm.refreshAGroup = async (/** @type {SubjectAssessmentGroup} */ ag, skip_confirmation = false) => {
		var confirm = $mdDialog
			.confirm()
			.title('Refresh assessment group?')
			.textContent('Delete local changes and replace with online data for assessment group if one exists. All local changes will be lost.')
			.ariaLabel('Refresh assessment group')
			.ok('Please do it!')
			.cancel('Cancel');

		if (!skip_confirmation) await $mdDialog.show(confirm);

		try {
			svm.isReady = false;
			let sh_cache = TRFactory.trServiceAtom.get().termsCache;
			sh_cache[svm.this_term.id].subject_mounts[ag.subject_mount_id].subject_assessment_groups[ag.id] = await TRFactory.fetchAssessmentGroup(ag.id);
			TRFactory.updateAtomCache(sh_cache);
			setAssessmentGroups(await TRFactory.fetchSubjectMountAssessmentGroups(svm.subjectMount));
		} catch (err) {
			showErrorToast(err, 'Failed to refresh assessment group');
		} finally {
			svm.isReady = true;
			$timeout(() => {}, 10);
		}
	};

	svm.syncAssessmentGroup = async (/** @type {SubjectAssessmentGroup} */ ag) => {
		var confirm = $mdDialog
			.confirm()
			.title('Sync assessment group?')
			.textContent('Local changes made on this group will be synced your schools account.')
			.ariaLabel('Sync assessment group')
			.ok('Please do it!')
			.cancel('Cancel');

		$mdDialog.show(confirm).then(async function () {
			try {
				svm.isReady = false;
				await TRFactory.syncAssessmentGroup(ag, svm.subjectMount, svm.this_term.id);
				$mdToast.showSimple('Sync completed');
			} catch (err) {
				showErrorToast(err, 'Failed to refresh assessment group');
			} finally {
				setAssessmentGroups(await TRFactory.fetchSubjectMountAssessmentGroups(svm.subjectMount));
				svm.isReady = true;
				$timeout(() => {}, 10);
			}
		});
	};
	function showAssessmentGroupEditBlockAlert() {
		$mdDialog.show(
			$mdDialog
				.alert()
				.textContent('Assessment group (bucket) has pending updates, sync or refresh to cancel changes and try again')
				.clickOutsideToClose(false)
				.title('Not permitted')
				.ok('Close')
		);
	}

	svm.editGroup = async function (/** @type {SubjectAssessmentGroup} */ ag) {
		try {
			if (canSyncAssessmentGroupFilter((await TRFactory.fetchSubjectMountAssessmentGroups(svm.subjectMount))[ag.id])) {
				showAssessmentGroupEditBlockAlert();
				return;
			}

			$mdDialog
				.show({
					escapeToClose: true,
					clickOutsideToClose: false,
					bindToController: true,
					locals: {
						dialogAssessmentEditGroup: angular.copy(ag),
						dialogAssessmentCurrentWeight: svm.current_sum,
					},
					templateUrl: 'views/pages/roles/tr/subject/dialogs/edit_group.html',
					controller: EditAssessmentGroupDialogController,
				})
				.then((group) => {
					findAndUpdateGroup(group);
				}, ErrorService.handleError);
		} catch (error) {
			ErrorService.handleError(error);
		}
	};

	svm.editEntity = function (/** @type {SubjectAssessmentEntity} */ ae, /** @type {SubjectAssessmentGroup} */ ag) {
		try {
			if (
				canSyncAssessmentEntityFilter(
					TRFactory.trServiceAtom.get().termsCache[svm.this_term?.id].subject_mounts[ag.subject_mount_id].subject_assessment_groups[ag.id].subject_assessment_entities[ae.id]
				)
			) {
				showAssessmentEditBlockAlert();
				return;
			}

			$mdDialog
				.show({
					escapeToClose: true,
					locals: {
						_ASSESSMENTGROUP: ag,
						_ACTION: 'update',
						_TERM: svm.this_term,
						_ASSESSMENTENTITY: ae,
					},
					bindToController: true,
					controllerAs: '$ctrl',
					clickOutsideToClose: false,
					templateUrl: 'views/pages/roles/tr/subject/dialogs/add_entity.html',
					controller: AddAssessmentEntityDialogController,
				})
				.then((entity) => {
					TRFactory.updateAtomCache(TRFactory.updateAssessmentEntity('model', entity, ae, svm.this_term.id, ag.subject_mount_id));
					return TRFactory.fetchSubjectMountAssessmentGroups(svm.subjectMount).then(angular.copy);
				})
				.then(setAssessmentGroups);
		} catch (error) {
			ErrorService.handleError(error);
		}
	};

	svm.remove_ae = function (group_id, ae) {
		var confirm = $mdDialog
			.confirm()
			.title('Would you like to delete "' + ae.title + '"?')
			.textContent('This operation cannot be undone. All the scores under this assessment entity will be removed in addition. You will lose all your scores. Are you sure?')
			.ariaLabel('Warning')
			.ok('Yes please!')
			.cancel('I dont think so');

		$mdDialog.show(confirm).then(
			function () {
				SubjectAssessmentEntity.one(ae.id)
					.get()
					.then(function (res) {
						var groupe = res;
						svm.isSaved = false;
						groupe.remove().then(
							function () {
								findAndRemoveEntity(group_id, ae);
								var template = $mdToast.simple().textContent('Assessment Removed').action('OK').highlightAction(true).hideDelay(5500);
								svm.isProcessing = false;
								$mdToast.show(template);
							},
							(err) => {
								showErrorToast(err, ' attempting deleting assessment entity');
							}
						);
					});
			},
			function () {
				// Error Case
			}
		);
	};

	function showErrorToast(err, cause_msg = null) {
		console.log(cause_msg);
		/** @type {Promise} */ (ErrorService.handleError(err)).then(() => {
			if (!cause_msg) return;
		});
	}

	svm.addGroup = (/**@type {SubjectAssessmentGroup}*/ group) => {
		this.openAGCrud = false;
		if (!group) return;

		TRFactory.updateAtom((atom) => {
			const sh_cache = atom.termsCache;
			let temp_group = angular.copy(group);

			if (temp_group.subject_assessment_entities) delete temp_group.subject_assessment_entities;

			let cached_assessment_group = {
				subject_assessment_entities: {},
				...sh_cache[svm.this_term.id].subject_mounts[svm.subjectMount.id].subject_assessment_groups[group.id],
				subject_assessment_group: temp_group,
			};

			sh_cache[svm.this_term.id].subject_mounts[svm.subjectMount.id].subject_assessment_groups[group.id] = cached_assessment_group;
			return atom;
		});
		return;
	};

	svm.goBack = function (/** @type {SubjectAssessmentEntity} */ chosen_assessment) {
		initUI();
		setTimeout(() => {
			const selector = `#assessment_item_id_${chosen_assessment.id}`;
			const assessmentRef = $window.document.querySelector(selector);
			assessmentRef?.scrollIntoView({ inline: 'nearest', block: 'nearest', behavior: 'smooth' });
		}, 50);
	};

	initUI();

	SubjectAssessmentType.getList().then((data) => {
		svm.types = data.plain();
	});

	EffectService.setup(this, ({ onInit }) => {
		if (onInit) {
			svm.TRFactory = TRFactory;
			atomosService(TRFactory.trServiceAtom.stream$, this).stream$.subscribe((atom) => {
				const data = atom;
				const new_term = data.selectedTerm;
				if (!new_term) return;

				svm.isReady = false;
				svm.this_term = new_term;

				try {
					const newSubMount = TRFactory.trServiceAtom.get().selectCachedSubjectMount($stateParams.subject_mount_id).subject_mount;
					const shouldRefreshSubMount = newSubMount?.term_id !== svm.subjectMount?.term_id || newSubMount?.id !== svm.subjectMount?.id;
					svm.subjectMount = newSubMount;
					svm.sectionTitle = TRFactory.sectionTitle();

					// vm.shadowSubject = lsfactory.getEnv("saveSubjectForTerm")

					if (!svm.subjectMount) {
						throw new IError('Invalid subject mount');
					}

					$state.current.data.title =
						svm.subjectMount.subject.title +
						' - ' +
						(svm.subjectMount.section.title ?? TRFactory.trServiceAtom.get().termsCache.cached_classes[svm.subjectMount.section.s_class_id].class_level.title);

					if (svm.subjectMount) {
						TRFactory.fetchSubjectMountAssessmentGroups(svm.subjectMount)
							.then((ag_map) => setAssessmentGroups(angular.copy(ag_map)))
							.then(() => {
								updateContext();
							})
							.catch(ErrorService.handleError)
							.finally(() => {
								svm.isReady = true;
								svm.isProcessing = false;
								$timeout(() => {}, 10);
							});
					} else {
						svm.isReady = true;
					}
				} catch (err) {
					ErrorService.handleError(err);

					$state.go('tr.home.dashboard');
					svm.isReady = true;
				}
			});
		}
	});

	const addSyncMetaToAssessmentGroup = (/**@type {CachedAssessmentGroup}*/ cag) => {
		cag.subject_assessment_group.subject_assessment_entities = Object.values(cag.subject_assessment_entities).map((cache_ae) => {
			cache_ae.subject_assessment_entity.can_sync = canSyncAssessmentEntityFilter(cache_ae);
			// cache_ae.subject_assessment_entity.subject_assessment_scores = cache_ae.subject_assessment_scores;

			return { ...cache_ae.subject_assessment_entity, subject_assessment_scores: [...Object.values(cache_ae.subject_assessment_scores)] };
		});
		return cag.subject_assessment_group;
	};

	function setAssessmentGroups(/**@type {CachedAssessmentGroupMap}*/ cached_assessment_groups) {
		try {
			svm.assessments_stats = [];
			svm.agroups = Object.values(angular.copy(cached_assessment_groups))
				.map(addSyncMetaToAssessmentGroup)
				.map((ag) => {
					ag.subject_assessment_entities = ag.subject_assessment_entities.map((sae) => ({
						...sae,
						gradable_toggle: sae.gradable,
						in_review_toggle: sae.in_review,
					}));
					return ag;
				});

			svm.assessments_stats = Object.fromEntries(
				svm.agroups
					.flatMap(({ subject_assessment_entities: aes }) => aes)
					.map((sae) => [
						sae.id,
						{
							can_sync: sae.can_sync,
							is_in_gradebook: sae.gradable,
							date_stat: `${amDateFormatFilter(sae.date_issued, 'MMM Do')} — ${amDateFormatFilter(sae.date_due, 'MMM Do YYYY')}`,
						},
					])
			);

			svm.collectiveAssessments = svm.agroups.flatMap((g) => Object.values(g.subject_assessment_entities));
			if (!svm.chosen_assessment_group || !svm.chosen_assessment) {
				return;
			}
			svm.chosen_assessment_group = svm.agroups.find((agroup) => svm.chosen_assessment_group.id == agroup.id);
			svm.chosen_assessment = svm.chosen_assessment_group.subject_assessment_entities.find((sae) => sae.id == svm.chosen_assessment.id);
			svm.showScores(svm.chosen_assessment, svm.chosen_assessment_group);
		} catch (error) {
			ErrorService.handleError(error);
		}
	}
}

trSubjectSubAssessment.controller = trSubjectSubAssessmentCmpController;

appModule.component('trSubjectSubAssessment', trSubjectSubAssessment);

trSubjectSubAssessment.selector = 'trSubjectSubAssessment';

export { trSubjectSubAssessment };

import './SwitchRoleCmp.scss';

import template from './SwitchRoleCmp.tpl.html';

const switchRoleCmp = /** @type {const} */ ({
	template,
	bindings: {
		onClose: '&',
	},
	selector: 'switchRoleCmp',
});

class switchRoleCmpController {
	ghostable_user_roles;

	assumable_user_roles;

	isLoading;

	/** @type {import('restangular').IService} */ #Restangular;

	/** @type {auth} */ #auth;

	/** @type {API} */ #API;

	/** @type {ErrorService} */ #ErrorService;

	#CONST_ENTRY_STATES;

	#$state;

	#$mdDialog;

	/** @type {lsfactory} */
	#lsfactory;

	#$localStorage;

	constructor(
		/** @type {auth} */ auth,
		Restangular,
		/** @type {API} */ API,
		$scope,
		/** @type {ErrorService} */ ErrorService,
		$state,
		CONST_ENTRY_STATES,
		/** @type {ng.material.IDialogService} */ $mdDialog,
		lsfactory,
		$localStorage,
		/** @type {EffectService} */ EffectService,
		/** @type {compSetupService} */ compSetupService,
		/** @type {atomosService} */ atomosService
	) {
		compSetupService(this, switchRoleCmp.bindings);

		this.#Restangular = Restangular;
		this.#auth = auth;
		this.#API = API;
		this.$scope = $scope;
		this.#ErrorService = ErrorService;
		this.#$state = $state;
		this.#CONST_ENTRY_STATES = CONST_ENTRY_STATES;
		this.#$mdDialog = $mdDialog;
		this.#lsfactory = lsfactory;
		this.#$localStorage = $localStorage;

		EffectService.setup(this, ({ onInit, takeUntilDestroyCb }) => {
			if (onInit) {
				const [schools$, { revalidate: refreshSchools, mutate: getSchoolsRx }] = API.rxQuery(
					['rxq.schools'],
					(r, /**@type {any} */ someData) =>
						/** @type {ng.restangular.ICollectionPromise<School>} */ (
							r
								.all(
									'school?' +
										API.buildParams_v2({
											has_fields: [{ field: 'school_approvals', conditions: [{ condition: 'where', column: 'status', value: 'approved' }] }],
										})
								)
								.getList()
						),
					{
						/** @type {any} */ qInput$: undefined,
						enabled: true,
						destroy$: this.componentDestroy$,
					}
				);

				this.refreshSchools = () => {
					// refreshSchools();
					getSchoolsRx().then((data) => {
						this.schools = data?.data?.plain();
					});
				};

				atomosService(schools$, this).stream$.subscribe(({ data, error, loading }) => {
					this.loadingSchools = loading;
					if (!data || ErrorService.handleError(error)) return;

					this.schools = data.plain();
				});

				atomosService(auth.authData$, this).stream$.subscribe((authData) => {
					this.engaged_user_role = authData.engagedUserRole;
					this.can_assume_ghost_role = /^Administrator$/i.test(authData.engagedUserRole.role.title); // this.fetchGhostableRoles(engaged_user_role)

					this.engagebleUserRoles = this.#auth.getEngagebleUser(authData.user.user_instance);
				});
			}
		});
	}

	fetchGhostableRoles(can_assume_ghost_role, current_e_user_role, school, ghostable_filters) {
		if (!can_assume_ghost_role || !school || !ghostable_filters.length) return;

		this.isLoading = true;

		const /** @type {API_Params} */ params = {
				filters: [
					{ condition: 'whereNotIn', column: 'role_instance_type', value: ['Admin'] },
					{
						condition: 'where',
						column: 'school_id',
						value: school.id,
					},
					{ condition: 'whereIn', column: 'role_instance_type', value: ghostable_filters },
				],
				has_fields: null,
				fields: [
					{ condition: 'with', field: 'role' },
					{ condition: 'with', field: 'role_instance' },
					{ condition: 'with', field: 'user' },
					{ condition: 'with', field: 'school' },
				],
			};

		return this.#Restangular
			.all(`user_role?${this.#API.buildParams(params.filters, params.has_fields, params.fields, null, '1.4')}&role_instance_id=${current_e_user_role.role_instance_id}`)
			.getList()
			.then((/** @type {import('restangular').ICollection} */ ghostable_user_roles) => {
				this.ghostable_user_roles = ghostable_user_roles.plain();
			})
			.catch(this.#ErrorService.handleError)
			.finally(() => (this.isLoading = false));
	}

	async assumeUserRole(/** @type {EngagedUserRole} */ user_role) {
		try {
			this.onClose(null);
			this.#$mdDialog.destroy();

			await this.cleanUpEngagedUserRoleCache(this.#auth.getEngagedUserRole());

			this.#auth.setEngagedUserRole(user_role);
			this.#$state.go(this.#CONST_ENTRY_STATES[user_role.role.short], user_role).then(() => {});
		} catch (error) {
			this.#ErrorService.handleError(error);
		}
	}

	async cleanUpEngagedUserRoleCache(/** @type {EngagedUserRole}*/ engaged_user_role) {
		switch (engaged_user_role.role.title.toLowerCase()) {
			case 'teacher':
				await this.#$mdDialog.show(
					this.#$mdDialog.confirm({
						textContent: 'Any unsynced data will be deleted. Do you want to proceed',
						ok: 'Yes please!',
						cancel: 'I dont think so',
					})
				);

				break;

			default:
				break;
		}

		this.#lsfactory.clearUserRoleCachedKeys(this.#auth.getEngagedUserRole());
	}
}

switchRoleCmp.controller = switchRoleCmpController;

appModule.component('switchRoleCmp', switchRoleCmp);

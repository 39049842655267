import './ShowImageToggleCmp.scss';

import template from './ShowImageToggleCmp.tpl.html';

const showImageToggleCmp = {
	template,
	bindings: {},
};

class ComponentNameController {
	constructor() {}
}

showImageToggleCmp.controller = ComponentNameController;

appModule.component('showImageToggleCmp', showImageToggleCmp);

function configService(/** @type {ng.IWindowService} */ $window) {
	const vm = this;
	// const base_url = (/dev-training-client.localhost/.test($window.location.href) ? 'http://dev-training-api.localhost:8000' : '__CONST_APP_ENV__').trim();
	const isLocalTraining = /dev-training-client.localhost/.test($window.location.href);
	const BASE_API_URL = (isLocalTraining ? 'http://dev-training-api.localhost:8000' : process.env.CONST_REST_API).trim();

	const CONST_APP_API_VERSION_STRING_URL = '/api/v1';

	const _config = /** @type {const} */ ({
		BASE_API_URL,
		API_URL: BASE_API_URL + CONST_APP_API_VERSION_STRING_URL,
		CONST_APP_ENV: process.env.CONST_APP_ENV,
		CONST_APP_BUILD_TIME: process.env.CONST_APP_BUILD_TIME,
		CONST_COMPACT_CACHE: process.env.CONST_COMPACT_CACHE,
		CONST_REST_API: process.env.CONST_REST_API,
		CONST_APP_API_VERSION_STRING_URL,
		CONST_APP_NAME: 'School Reporting',
		CONST_DEFAULT_PROFILE: 'http://pichoster.net/images/2016/11/29/avatar2-and.jpg',
		CONST_APP_VERSION: process.env.CONST_APP_VERSION,
		CONST_APP_BUILD_NUMBER: process.env.CONST_APP_VERSION,
		CONST_STUDENT_CSV_URL: 'https://static.schrep.info/csv/tmpl.students.csv',
		CONST_TEACHER_CSV_URL: 'https://static.schrep.info/csv/tmpl.teachers.csv',
		CONST_GUARDIAN_CSV_URL: 'https://static.schrep.info/csv/tmpl.parents.csv',
		CONST_MANUAL_URL: 'https://static.schrep.info/pdf/manual.pdf',
		CONST_API_DATE_FORMAT: 'Y-MM-DD',
		CONST_REPORT_TYPES: ['compact report card', 'detailed report card', 'compact report card v2', 'detailed report card v2', 'itemized subject report card'],
		CONST_ENTRY_STATES: { sa: 'sa.dashboard', tr: 'tr.home.dashboard', ga: 'ga.dashboard', aa: 'aa.dashboard', pa: 'pa.dashboard' },
		host: BASE_API_URL.replace('http://', ''),
		pusherConfig: {
			broadcaster: process.env.BROADCAST_DRIVER ?? 'pusher',
			key: process.env.PUSHER_APP_KEY ?? 'your-pusher-key',
			wsHost: (process.env.LARAVEL_WEBSOCKETS_HOST ?? BASE_API_URL)
				.replace('http://', '')
				.replace('https://', '')
				.replace(/[:]\d+/, ''),
			// .replace(/.*training-api./, ''),
			wsPort: process.env.LARAVEL_WEBSOCKETS_PORT_WS ?? 8000,
			wssPort: process.env.LARAVEL_WEBSOCKETS_PORT_WSS ?? 443,
			forceTLS: process.env.PUSHER_FORCE_TLS === 'true' ?? true,
			disableStats: true,
			cluster: process.env.PUSHER_CLUSTER ?? 'mt1',
			authEndpoint: BASE_API_URL + CONST_APP_API_VERSION_STRING_URL + '/broadcasting/auth',
			enabledTransports: ['ws', 'wss'],
		},
	});

	for (const key in _config) {
		vm[key] = _config[key];
	}

	this.config = _config;

	// return /** @type {_config & typeof this} */ (vm);
	return this;
}

appModule.factory('configService', configService);

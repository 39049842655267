import template from './index.html';

/** @type {import('@srcAjs/components2/adminRoutes').IComponentOptions2} */
const saSchoolSetupClassesIndex = {
	template,
	bindings: {
		//someInput: '@string,<object,&function'
	},
	transclude: {
		// #slotName: '?optionalPublicName'
	},
};

function saSchoolSetupClassesIndexCmpController(
	$cacheFactory,
	$scope,
	ClassGroup,
	$state,
	SadminSetupClassLevelStore,
	/** @type {ErrorService} */ ErrorService,
	$mdDialog,
	SAFactory,
	lsfactory,
	SClass,
	ClassLevel,
	$winNotif,
	Restangular,
	$timeout,
	/**@type {API}*/ API,
	/** @type {EffectService} */ EffectService,
	/** @type {compSetupService} */ compSetupService
) {
	compSetupService(this);
	let cache = SadminSetupClassLevelStore.getCache();

	SAFactory.loadEnvironment(cache);
	SAFactory.loadNotifications();
	$scope.school = lsfactory.getEnv('school');
	$scope.obj = { selectedItem: null };
	$scope.classes = [];
	$scope.groups = [];
	$scope.levels = [];
	$scope.showOne = false;
	$scope.isReady = false;

	// Initial Requests
	lsfactory.setEnv('backLog', 'sa.dashboard');

	$scope.loadItems = (/** @type {import('restangular').IService}*/ r, /** @type {Term} */ term) => {
		try {
			if (term) {
				let filters = {
					filters: [{ condition: 'where', column: 'school_id', value: $scope.school.id }],
					fields: [
						{
							condition: 'with',
							field: 'classes.class_level',
						},
						{
							condition: 'with',
							field: 'classes.sections',
							conditions: [
								{ condition: 'where', column: 'term_id', value: term.term_type.version !== '1.2' ? 'null' : term.id },
								{ condition: 'withCount', column: 'section_histories|where|term_id', value: term.id },
								{ condition: 'with', column: 'sclass.class_level', value: 'null' },
								{ condition: 'with', column: 'teacher', value: 'null' },
							],
						},
						{
							condition: 'with',
							field: 'class_group_type',
						},
					],
					has_fields: null,
				};

				return r
					.all(`class-group?${API.buildParams(filters.filters, filters.has_fields, filters.fields)}`)

					.getList();
			}
		} catch (error) {
			ErrorService.handleError(error);
		}
	};

	const [stream$, api] = API.rxQuery(['term.sections'], (r, term) => $scope.loadItems(r, term), {
		enabled: false,
		destroy$: this.componentDestroy$,
	});

	EffectService.setup(this, ({ onInit, takeUntilDestroyCb }) => {
		if (onInit) {
			takeUntilDestroyCb(stream$).subscribe(({ data, error, loading }) => {
				$scope.isReady = !loading;
				if (ErrorService.handleError(error) || !data) return;

				$scope.groups = data.plain();
				$scope.classes = $scope.groups.flatMap(({ classes }) => classes);
			});
		}
	});

	$scope.goto = function (sclass) {
		if (sclass !== undefined) {
			console.log('chosen class', sclass);
			SadminSetupClassLevelStore.set('selected_sclass', sclass);
			SadminSetupClassLevelStore.set('selected_term', $scope.term);

			$state.go('sa.school_setup_classes_detail.sections', { id: sclass.id });
		}
	};

	/**
	 *
	 * @param {*} sclass
	 * @param {Event} event
	 */
	$scope.remove = function (sclass, event) {
		event.stopPropagation();

		var confirm = $mdDialog
			.confirm()
			.title('Would you like to delete the "' + sclass.class_level.title + '" class now?')
			.textContent('This operation cannot be undone. All dependants of this class will also be cleared also.')
			.ariaLabel('Warning')
			.ok('Yes please!')
			.cancel('I dont think so');

		$mdDialog.show(confirm).then(() => {
			SClass.one(sclass.id)
				.remove()
				.then(() => {
					refresh_cache();
				});
		});
	};

	$scope.newClass = function () {
		if ($scope.groups.length == 0) {
			$winNotif.show({
				title: 'No Departments Avaiable',
				msg: 'To add a new Class, you must create a Department.',
				acceptText: 'Go To Departments',
				declineText: 'Dismiss',
				imgUrl: 'images/logo.png',
				timeout: 10000,
				hasButtons: true,
				accept: function () {
					$state.go('sa.school_setup_class_groups');
					$winNotif.hide();
				},
				decline: function () {
					$winNotif.hide();
				},
			});
		} else {
			$mdDialog
				.show({
					escapeToClose: false,
					clickOutsideToClose: false,
					bindToController: true,
					locals: {
						groups: $scope.groups,
						levels: $scope.levels,
						classes: $scope.classes,
					},
					templateUrl: 'views/pages/roles/sa/school_setup/classes/dialogs/add_class.html',
					controller: AddClassController,
				})
				.then(() => refresh_cache());
		}
	};

	let refresh_cache = () => {
		console.log('cache.info', cache.info());
		cache.removeAll();
		$scope.loadItems($scope.school, $scope.term);
		console.log('classes cache refreshed');
	};

	// $scope.refresh_cache = refresh_cache;
	$scope.refresh_cache = api.revalidate;

	$scope.onTermUpdate = (term) => {
		console.log('recieved term info ');
		$scope.term = term;
		lsfactory.setEnv('currentSelectedTerm', term);

		api.mutate(term);
		// $scope.loadItems($scope.school, term);
	};

	$scope.loadItems($scope.school, $scope.term);
}

saSchoolSetupClassesIndex.controller = saSchoolSetupClassesIndexCmpController;

appModule.component('saSchoolSetupClassesIndex', saSchoolSetupClassesIndex);

saSchoolSetupClassesIndex.selector = 'saSchoolSetupClassesIndex';

export { saSchoolSetupClassesIndex };

import template from './index.html';

/** @type {import('@srcAjs/components2/adminRoutes').IComponentOptions2} */
const AboutAndVersionCmp = {
	template,
	bindings: {
		//someInput: '@string,<object,&function'
	},
	transclude: {
		// #slotName: '?optionalPublicName'
	},
	selector: 'AboutAndVersion',
};

/** @this {ng.IController} */
function AboutAndVersionCmpController(
	$scope,
	$mdDialog,
	CONST_APP_VERSION,
	CONST_APP_BUILD_NUMBER,
	CONST_APP_BUILD_TIME,
	/** @type {lsfactory} */ lsfactory,
	/** @type {configService} */ configService
) {
	const vm = $scope;

	vm.versionText = configService.config.CONST_APP_VERSION;
	vm.buildTime = configService.config.CONST_APP_BUILD_TIME;

	vm.report = function () {
		vm.hide();
		lsfactory.setEnv('dialogReportFrom', 'about'); // Send something to the dialog
		$mdDialog.show({
			escapeToClose: true,
			clickOutsideToClose: true,
			templateUrl: 'views/pages/roles/sa/help/dialogs/report_issue.html',
			controller: 'ReportIssueController',
		});
	};

	vm.open_website = function () {
		window.open('https://schrep.info');
	};

	vm.status_website = function () {
		window.open('http://status.schrep.info');
	};

	vm.change_log = function () {
		vm.hide();
		lsfactory.setEnv('dialogCLFrom', 'about'); // Send something to the dialog
		$mdDialog.show({
			escapeToClose: true,
			clickOutsideToClose: true,
			templateUrl: 'views/pages/roles/sa/dialogs/change_log.html',
			controller: 'ChangeLogDialogController',
		});
	};
	vm.hide = function () {
		$mdDialog.hide();
	};
	vm.cancel = function () {
		$mdDialog.cancel();
	};
}

AboutAndVersionCmp.controller = AboutAndVersionCmpController;

appModule.component('aboutAndVersionCmp', AboutAndVersionCmp);

export { AboutAndVersionCmp };

import template from './index.html';

/** @type {import('@srcAjs/components2/adminRoutes').IComponentOptions2} */
const trClassDetail = {
	template,
	bindings: {
		//someInput: '@string,<object,&function'
	},
	transclude: {
		// #slotName: '?optionalPublicName'
	},
};

function trClassDetailCmpController(
	/** @type {import('angular').IScope} */ $scope,
	/** @type {TRFactory} */ TRFactory,
	/** @type {lsfactory} */ lsfactory,
	Term,
	/** @type {import('angular').ILocationService} */ $location,
	/** @type {EffectService} */ EffectService,
	/** @type {RxService} */ RxService,
	/** @type {ng.ui.StateService} */ $state,
	/** @type {ng.ui.UrlService} */ $urlService,
	/** @type {ng.ui.StateParams} */ $stateParams
) {
	$scope.teacher = lsfactory.getEnv('teacher');
	$scope.section = lsfactory.getEnv('teacher').section;
	$scope.g_tid = lsfactory.getEnv('g_selected_term_id');
	$scope.selected_term_handle = null;

	EffectService.setup(this, ({ onInit, takeUntilDestroyCb }) => {
		if (onInit) {
			takeUntilDestroyCb(TRFactory.trServiceAtom.stream$).subscribe((atom) => {
				const data = atom;
				const { section } = data;

				if (!section) return;
				const teacher = { ...lsfactory.getEnv('teacher') };
				teacher.section = section;
				lsfactory.setEnv('teacher', teacher);
			});

			const watchers = [
				$scope.$on('system.tr.environmentChanged', function (ev, data) {
					console.log('TR_ClassDetailController system.tr.environmentChanged triggered');
					initialize();
				}),
			];

			return () => watchers.forEach((e) => e());
		}
	});

	// $scope.$on('system.termChanged', function (event, term_id) {
	//     fetchTerm(term_id);
	// });

	function fetchTerm(term_id) {
		TRFactory.fetchTermDetails(term_id).then((cached_term) => {
			$scope.selected_term_handle = cached_term.term;
		});
	}
	
	function initialize() {
		$scope.env = lsfactory;
		$scope.school = lsfactory.getEnv('school');
		$scope.g_tid = $scope.env.g_selected_term_id;
		if ($scope.school !== undefined) {
			if (lsfactory.getEnv('g_selected_term_id') !== undefined && lsfactory.getEnv('g_selected_term_id') != null) {
				$scope.g_tid = lsfactory.getEnv('g_selected_term_id');
				$scope.$broadcast('system.termChanged', $scope.g_tid);
			}
		}
	}
}

trClassDetail.controller = trClassDetailCmpController;

appModule.component('trClassDetail', trClassDetail);

trClassDetail.selector = 'trClassDetail';

export { trClassDetail };

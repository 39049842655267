import './AppShellCmp.scss';
import template from './AppShellCmp.tpl.html';

/** @type {import('@srcAjs/components2/adminRoutes').IComponentOptions2} */
const appShellCmp = {
	template,
	bindings: {
		//someInput: '@string,<object,&function'
	},
	transclude: {
		// #slotName: '?optionalPublicName'
	},
	selector: 'appShellCmp',
};

/** @this {ng.IController} */
function appShellCmpController(
	/** @type auth */ auth,
	/** @type {atomosService} */ atomosService,
	$rootScope,
	$scope,
	$mdSidenav,
	$timeout,
	/** @type {ng.ui.StateService} */ $state,
	/** @type {TRFactory} */ TRFactory,
	/** @type {EffectService} */ EffectService,
	/** @type {ng.ui.UrlService} */ $urlService,
	/** @type {PAFactory} */ PAFactory
) {
	const svm = $scope;

	const authAtom = atomosService(auth.authData$, this);
	atomosService((get) => [get(authAtom), get(TRFactory.trServiceAtom)]).stream$.subscribe(([_authData, trAtom]) => {
		this.engaged_user_role = _authData.engagedUserRole;
		this.trAtom = trAtom;
	});

	const handleRoleInitialization = {
		pa: () => {
			PAFactory.initializeRole();
			return PAFactory.guardianRxq[1].destroyFn;
		},
		tr: (removeUrlChangeListener) => {
			this.disableTermSelector = ['tr.class', 'tr.subject'].some((state) => $state.includes(state));

			if (auth.getEngagedUserRole()?.role?.short !== 'tr' || removeUrlChangeListener) return () => null;

			return (removeUrlChangeListener = $urlService.onChange(() => {
				this.disableTermSelector = ['tr.class', 'tr.subject'].some((state) => $state.includes(state));
			}));
		},
		sa: () => {},
		aa: () => {},
		ga: () => {},
	};

	EffectService.setup(this, ({ onInit }) => {
		if (onInit) {
			let removeUrlChangeListener = undefined;
			atomosService(auth.authData$, this).stream$.subscribe((authData) => {
				if (!authData?.engagedUserRole) return (removeUrlChangeListener = null);

				removeUrlChangeListener = (handleRoleInitialization[authData.engagedUserRole.role_instance_type.short] ?? (() => null))(removeUrlChangeListener);
			});

			return () => (removeUrlChangeListener ?? (() => null))();
		}
	});

	this.TRFactory = TRFactory;

	$scope = this.$rootScope = $rootScope;
	console.log({ $rootScope, $scope, $state });

	svm.openAside = function () {
		$timeout(function () {
			$mdSidenav('aside').open();
		});
	};
}

appShellCmp.controller = appShellCmpController;

appModule.component('appShellCmp', appShellCmp);

export { appShellCmp };

import template from './index.html';

/** @type {import('@srcAjs/components2/adminRoutes').IComponentOptions2} */
const saSchoolSetupClassesSubSummary = {
	template,
	bindings: {
		//someInput: '@string,<object,&function'
	},
	transclude: {
		// #slotName: '?optionalPublicName'
	},
};



function saSchoolSetupClassesSubSummaryCmpController(
	SAFactory,
	$scope,
	$cookies,
	$stateParams,
	$state,
	$rootScope,
	$localStorage,
	$mdDialog,
	$mdToast,
	lsfactory,
	Restangular,
	SClass,
	Section
) {
	SAFactory.loadNotifications();
	$scope.class = lsfactory.getEnv('currentClassSelected');
	$state.current.data.title = $scope.class.class_level.title;
	$scope.isReady = false;

	if ($stateParams.id != '') {
		SClass.one($stateParams.id)
			.get()
			.then(
				function (res) {
					var obj_sub = res.plain();
					$scope.class = res;
				},
				function (err) {
					if (err.status == 404) {
					}
				}
			);
	}
}

saSchoolSetupClassesSubSummary.controller = saSchoolSetupClassesSubSummaryCmpController;

appModule.component('saSchoolSetupClassesSubSummary', saSchoolSetupClassesSubSummary);

saSchoolSetupClassesSubSummary.selector = 'saSchoolSetupClassesSubSummary';

export { saSchoolSetupClassesSubSummary }

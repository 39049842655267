import template from './TrShellCmp.tpl.html';

/** @type {import('@srcAjs/components2/adminRoutes').IComponentOptions2} */
const trShellCmp = {
	template,
	bindings: {
		//someInput: '@string,<object,&function'
	},
	transclude: true,
	selector: 'trShellCmp',
};

/** @this {ng.IController} */
function trShellCmpController(
	$scope,
	$mdDialog,
	/** @type {$moment} */ $moment,
	/** @type {getDateWithinTermBoundsFilter} */ getDateWithinTermBoundsFilter,
	/** @type {TRFactory} */ TRFactory,
	/** @type {ErrorService} */ ErrorService,
	/** @type {EffectService} */ EffectService,
	/** @type {atomosService} */ atomosService,
	/** @type {auth} */ auth,
	/** @type {compSetupService} */ compSetupService
) {
	compSetupService(this);

	let engaged = false;
	const MAX_TERMS_IN_CACHE = 3;

	const cleanUpCachedTerms = (/** @type {ScCache} */ sh_cache, /** @type {Term} */ selected_term) => {
		if (!selected_term) return;

		const /** @type {ScCache} */ old_cache = angular.copy(sh_cache);

		const { created_at, cached_classes, ...cterms } = old_cache;
		// const { created_at, ...cterms } = old_cache;

		const updatedCache = { created_at, cached_classes };
		// const /** @type {ScCache} */ updatedCache = /** @type {any} */ ({ created_at });

		const expired_cached_terms = [];

		for (const cterm of Object.values(cterms)) {
			if (!$moment(cterm.term.end).isBefore($moment(Date.now())) || cterm.term.status === 'active' || selected_term.id === cterm.term.id) {
				updatedCache[cterm.term.id] = cterm;
			} else {
				expired_cached_terms.push(cterm);
			}
		}

		if (expired_cached_terms.length > MAX_TERMS_IN_CACHE) engaged = false;

		if (engaged || expired_cached_terms.length < MAX_TERMS_IN_CACHE) return;

		engaged = true;

		$mdDialog
			.show($mdDialog.confirm({ textContent: 'Remove offline data for ended terms', ok: 'Yes please!', cancel: 'Nope' }))
			.then(() => {
				console.log('cached terms removed', expired_cached_terms);
				TRFactory.updateAtom((atom) => ({ ...atom, termsCache: updatedCache }));
			})
			.finally(() => (engaged = false));
	};

	EffectService.setup(this, ({ onInit }) => {
		if (onInit) {
			TRFactory.loadEnvironment();
			TRFactory.loadNotifications();

			const authAtom = atomosService(auth, this);
			atomosService((get) => [get(TRFactory.trServiceAtom), get(authAtom)], this).stream$.subscribe(([atom, _auth]) => {
				const data = /** @type {TrServiceAtom} */ (atom);
				const term = data.selectedTerm;
				console.log('trShell.atomosService.term', { data });

				if (!term) return;

				$scope.day_of_term_correspondence = `Day ${$moment(getDateWithinTermBoundsFilter(term)).diff(term.start, 'days')} of ${$moment(term.end).diff(term.start, 'days')}`;

				cleanUpCachedTerms(data.termsCache, term);
			});
		}
	});
}

trShellCmp.controller = trShellCmpController;

appModule.component('trShellCmp', trShellCmp);

export { trShellCmp };

import template from './index.html';

/** @type {import('@srcAjs/components2/adminRoutes').IComponentOptions2} */
const saSchoolSetupInfoIndex = {
	template,
	bindings: {
		//someInput: '@string,<object,&function'
	},
	transclude: {
		// #slotName: '?optionalPublicName'
	},
};



function saSchoolSetupInfoIndexCmpController(
	SAFactory,
	$scope,
	$cookies,
	$mdToast,
	lsfactory,
	$state,
	$rootScope,
	$localStorage,
	$mdDialog,
	Restangular,
	NgMap,
	$window,
	isoCountryList,
	/** @type {ErrorService} */ ErrorService
) {
	SAFactory.loadEnvironment();
	SAFactory.loadNotifications();
	$scope.env = $localStorage.environment;
	$scope.school = $scope.env.school;
	$scope.box = angular.copy($scope.school);
	$scope.isProcessing = false;
	var isoCountries = isoCountryList;
	$scope.isPhoto = false;
	$scope.image_set = false;
	$scope.isProcessing = false;
	$scope.myCroppedImage = null;
	$scope.cropType = 'rectangle';
	$scope.isReady = true

	$scope.countries = isoCountries;
	$scope.showMap = showMap;
	$scope.hideMap = hideMap;

	$scope.file_changed = function (element) {
		debugger;
		console.dir(element);
		var evt = { currentTarget: element };
		var file = evt.currentTarget.files[0];
		var reader = new FileReader();
		reader.onload = function (evt) {
			console.dir(evt);
			$scope.$apply(function ($scope) {
				$scope.myImage = evt.target.result;
				$scope.image_set = true;
			});
		};
		reader.readAsDataURL(file);
	};

	//uploading the signature to the database
	$scope.upload = function () {
		var school = $scope.school;
		$scope.isProcessing = true;
		//Will use third arguments
		Restangular.all('school/admin-signature/upload')
			.post({ school_id: school.id, myImage: $scope.myCroppedImage })
			.then(function (data) {
				var res_data = data.plain();
				$rootScope.$broadcast('signature.dataChanged', res_data);
				$scope.isProcessing = false;
				$scope.env.school = res_data;
				$mdDialog.hide().then(function () {
					$scope.cancelImage();
					var template = $mdToast.simple().textContent('Signature Uploaded').action('OK').highlightAction(true).hideDelay(5500);
					$mdToast.show(template);
				});
			})
			.catch(ErrorService.handleError);
	};

	$scope.$on('signature.dataChanged', function (event, data) {
		$scope.school = data;
	});

	$scope.cancelImage = function () {
		$scope.myCroppedImage = null;
		$scope.myImage = '';
		$scope.image_set = false;
	};
	$scope.setNewLocation = function (err, loc) {
		if (err) {
			console.log('err');
		}
		console.log('something amis', loc);
	};

	$('i.fa-school-logo').hide();

	function findCountryByID(country, index, arr) {
		return this == country.id; //*this* will be value of object submitted as thisArg in find
	}

	function findName(country_id) {
		let found_country = isoCountries.find(findCountryByID, country_id); /** country will become thisArg in function */
		return found_country ? found_country.text : found_country;
	}

	$scope.updatePhoto = function () {
		$mdDialog.show({
			escapeToClose: true,
			clickOutsideToClose: true,
			templateUrl: 'views/pages/roles/sa/school_setup/info/dialogs/upload_photo.html',
			controller: UploadPhotoDialogController,
		});
	};

	$scope.getClass = function (code) {
		if (code !== undefined && code != null) {
			return 'flag-icon flag-icon-' + code.toLowerCase();
		}
	};

	$scope.getBGColor = function (image, color) {
		if (image == '') {
			return color;
		} else {
			return 'none';
		}
	};

	$scope.getImageCSS = function (image) {
		if (image == '') {
			return 'url("images/weather.jpg")';
		} else {
			return 'url(' + image + ')';
		}
	};

	$scope.getLGImageCSS = function (image) {
		if (image == '') {
			return '';
		} else {
			return 'url(' + image + ')';
		}
	};

	$scope.dragEnd = function (event) {
		var lat = event.latLng.lat();
		var lng = event.latLng.lng();
		$scope.box.lat = lat;
		$scope.box.lng = lng;
	};

	$scope.sendFields = function () {
		$scope.isProcessing = true;
		Restangular.restangularizeElement(null, $scope.box, 'school')
			.put()
			.then(function (response) {
				$scope.isProcessing = false;
				console.dir(response.plain());
				var template = $mdToast.simple().textContent('Saved Successully').action('OK').highlightAction(true).hideDelay(6500);
				$mdToast.show(template);
			});
	};
	$scope.$on('logo.dataChanged', function (event, data) {
		$scope.box = data;
		$('.schlogobx').css({ 'background-size': 'cover' });

		console.log('Box Logo');
		console.dir($scope.box.logo);
		if ($scope.box.logo == null || $scope.box.logo === undefined) {
			$('i.fa-school-logo').show();
		} else if ($scope.box.logo != null || $scope.box.logo !== undefined) {
			$('i.fa-school-logo').hide();
			$('.schlogobx').hover(
				function () {
					$('i.fa-school-logo').show();
				},
				function () {
					$('i.fa-school-logo').hide();
				}
			);
		}
	});

	if ($scope.box.logo == null || $scope.box.logo === undefined) {
		$('i.fa-school-logo').show();
	} else if ($scope.box.logo != null || $scope.box.logo !== undefined) {
		$('i.fa-school-logo').hide();
		$('.schlogobx').hover(
			function () {
				$('i.fa-school-logo').show();
			},
			function () {
				$('i.fa-school-logo').hide();
			}
		);
	}

	$scope.updateCover = function () {
		$mdDialog.show({
			escapeToClose: true,
			clickOutsideToClose: true,
			templateUrl: 'views/pages/roles/sa/school_setup/info/dialogs/upload_cover.html',
			controller: UploadCoverController,
		});
	};

	$scope.showPreview = function () {
		$window.open('http://schrep.info/school/' + $scope.school.id, '_blank');
	};

	function showMap() {
		$scope.is_map_visible = true;
		console.log('showing map now');
	}

	function hideMap() {
		$scope.is_map_visible = false;
		console.log('hiding map now');
	}
}

saSchoolSetupInfoIndex.controller = saSchoolSetupInfoIndexCmpController;

appModule.component('saSchoolSetupInfoIndex', saSchoolSetupInfoIndex);

saSchoolSetupInfoIndex.selector = 'saSchoolSetupInfoIndex';

export { saSchoolSetupInfoIndex }

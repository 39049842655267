import template from './index.html';

/** @type {import('@srcAjs/components2/adminRoutes').IComponentOptions2} */
const saTeachersIndex = {
	template,
	bindings: {
		//someInput: '@string,<object,&function'
	},
	transclude: {
		// #slotName: '?optionalPublicName'
	},
};

/** @this {ng.IController} */
function saTeachersIndexCmpController(
	/** @type {SAFactory} */ SAFactory,
	$scope,
	$state,
	$mdBottomSheet,
	/** @type {lsfactory} */ lsfactory,
	/** @type {import("restangular").IService} */ Restangular,
	$filter,
	/** @type {API} */ API,
	/** @type {ErrorService} */ ErrorService,
	/** @type {saTeachersService} */ saTeachersService,
	/** @type {EffectService}*/ EffectService
) {
	SAFactory.loadEnvironment();
	SAFactory.loadNotifications();

	const svm = $scope;
	const vm = this;

	svm.teachers = undefined;
	svm.selected_teachers = [];
	svm.isLoading = true;
	svm.school = lsfactory.getEnv('school');
	lsfactory.setEnv('backLog', 'sa.dashboard');
	svm.loadTeachers = loadTeachers;
	$scope.isReady = true;

	svm.searchTeacher = (query) => (svm.search_teacher = query);

	svm.gotoImport = function () {
		$state.go('sa.teachers_import');
	};

	svm.getExcel = function () {
		console.log('Start Download of XSL file');
	};

	svm.showBottomSheet = function () {
		$mdBottomSheet.show({
			templateUrl: 'views/pages/roles/sa/teachers/bottom.index.html',
			controller: 'TeachersListBottomSheetCtrl',
		});
	};

	EffectService.setup(vm, ({ changes, takeUntilDestroyCb }) => {
		if (changes) {
			return () => null;
		}

		takeUntilDestroyCb(saTeachersService.teachersStore$).subscribe({
			next: (data) => {
				svm.teachers = $filter('orderBy')(data.teachers ?? [], 'firstname');
			},
			complete: () => console.log('EffectSetup.complete.fired'),
			error(error) {
				ErrorService.handleError(error);
			},
		});
	});

	// TODO: Implement logic to detect when items in the list are being selected and how many ...
	svm.goto = function (teacher) {
		if (!teacher) {
			return;
		}

		$state.go('sa.teachers_detail.profile', { id: teacher.id });
	};

	svm.updateTerm = (term) => {
		if (!term) return;

		svm.term = term;

		loadTeachers(term, svm.scope_by_term);
	};

	function loadTeachers(term, refresh = true, scope_by_term = true) {
		if (!term) {
			return;
		}
		svm.isLoading = true;
		saTeachersService
			.dispatch('getTeachers', { school: svm.school, term, refresh, scope_by_term })
			.catch((err) => {
				ErrorService.handleError();
				saTeachersService.dispatch('setTeachers', []);
			})
			.finally(() => (svm.isLoading = false));
	}
}

saTeachersIndex.controller = saTeachersIndexCmpController;

appModule.component('saTeachersIndex', saTeachersIndex);

saTeachersIndex.selector = 'saTeachersIndex';

export { saTeachersIndex };

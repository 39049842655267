import template from './index.html';

/** @type {import('@srcAjs/components2/adminRoutes').IComponentOptions2} */
const saGuardiansSubReports = {
	template,
	bindings: {
		//someInput: '@string,<object,&function'
	},
	transclude: {
		// #slotName: '?optionalPublicName'
	},
};



function saGuardiansSubReportsCmpController(
	SAFactory,
	$scope,
	$cookies,
	$stateParams,
	$mdToast,
	$state,
	$rootScope,
	$localStorage,
	$mdDialog,
	lsfactory,
	Restangular,
	Guardian,
	Student
) {
	SAFactory.loadEnvironment();
	SAFactory.loadNotifications();
	$scope.guardian = lsfactory.getEnv('currentGuardianSelected');
	$state.current.data.title =
		$scope.guardian.salutation + ' ' + $scope.guardian.firstname + ' ' + $scope.guardian.lastname + ' ( Guardian )';

	$scope.$on('editGuardian.newEntity', function (event, new_guardian) {
		if (new_guardian !== undefined) {
			$scope.guardian = new_guardian;
			$state.current.data.title =
				$scope.guardian.salutation + ' ' + $scope.guardian.firstname + ' ' + $scope.guardian.lastname + ' ( Guardian )';
		}
	});

	$scope.DemoByguardId = function () {
		var confirm = $mdDialog
			.confirm()
			.title('Download Guardian Demographics ?')
			.textContent('This will be Downloaded in a pdf format.')
			.ariaLabel('Warning')
			.ok('Yes please!')
			.cancel('Cancel');
		$mdDialog.show(confirm).then(
			function () {
				//Configuring and sending request for the report card
				console.dir($scope.guardian);
				var getLink = Restangular.all('report/by_guardian_id/' + $scope.guardian.id);
				getLink.getList().then(function (data) {
					var list = data.plain();
					var url = list[0];
					window.open(url, 'Download');
				});
			},
			function () {
				console.dir($scope.guardian.lastname);
			}
		);
	};
}

saGuardiansSubReports.controller = saGuardiansSubReportsCmpController;

appModule.component('saGuardiansSubReports', saGuardiansSubReports);

saGuardiansSubReports.selector = 'saGuardiansSubReports';

export { saGuardiansSubReports }

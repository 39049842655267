import template from './AccessCountryselectCmp.tpl.html';

import './AccessCountryselectCmp.scss';

const accessCountryselectCmp = {
	template,
	bindings: {
		$transition$: '<',
	},
};

function ChooseCountryController($localStorage, $state) {
	const vm = this;
	vm.isLoading = false;
	vm.country = null;
	vm.code = $localStorage.code ?? 'UN';
	vm.country_query = '';

	vm.updateCountry = (country) => {
		vm.country = country;
		vm.code = country?.iso_3166_2?.toLowerCase();
	};

	vm.selectCountry = (country) => new RegExp(vm.code, 'i').test(country.iso_3166_2);

	vm.submit = (country) => {
		$localStorage.code = country?.iso_3166_2;
		$state.go('access.signin', { country, country_code: country?.iso_3166_2 });
	};
}

accessCountryselectCmp.controller = ['$localStorage', '$state', ChooseCountryController];

appModule.component('accessCountryselectCmp', accessCountryselectCmp);

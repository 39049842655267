import template from './ReportDetailsCmp.tpl.html';

import './ReportDetailsCmp.scss';

const saReportDetailsCmp = {
	template,
	bindings: {
		$transition$: '<',
	},
};

class ComponentNameController {
	constructor($scope, $state) {
		this.$scope = $scope;
		this.$state = $state;
	}

	$onInit() {
		this.legacyController(this.$scope, this.$state, this.$transition$);
	}

	legacyController($scope, $state, $transition$) {
		$scope.report = $transition$.params().report;
		console.dir($transition$);

		const init = () => {
			let notify = {
				type: 'error', // info warning error
				title: 'Report Not Found',
				content: 'The report you requested is unavailable',
				timeout: 5000, //timeout default is 3200 ms you can change as you need
			};

			if (!$transition$.params().report || $transition$.params().name == '' || $scope.report.name != $transition$.params().name) {
				$scope.$emit('notify', notify);
				$state.go('sa.reports');
			}

			$state.current.data.title = $scope.report?.toolbar;
		};

		init();
	}
}

saReportDetailsCmp.controller = ComponentNameController;

appModule.component('saReportDetailsCmp', saReportDetailsCmp);
// export default component;

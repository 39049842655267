import template from './index.html';

/** @type {import('@srcAjs/components2/adminRoutes').IComponentOptions2} */
const saGuardiansDialogsChildSelector = {
	template,
	bindings: {
		//someInput: '@string,<object,&function'
		guardian: '<',
		school: '<',
		respawn: '&',
	},
	transclude: {
		// #slotName: '?optionalPublicName'
	},
};

function SaGuardiansDialogsChildSelectorController(Restangular, $scope, API, $mdDialog, ErrorService) {
	this.$onInit = () => {
		$scope.isLoading = true;
		$scope.box = {};
		$scope.selectedItem = undefined;
		$scope.students;
		$scope.guardian = this.guardian;

		$scope.school = this.school;
	};
	$scope.attachStudentToGuardian = (student) => {
		if (!student) {
			return;
		}
		return Restangular.one('guardian/add-child/' + $scope.guardian.id + '/' + student.id)
			.get()
			.then(() => {
				$scope.guardian.students.push(student);
			}, ErrorService.handleError)
			.finally(() => {
				$scope.selectedItem = undefined;
				$scope.searchText = '';
			});
	};
	$scope.removeChild = (id, $index) => {
		var confirm = $mdDialog
			.confirm()
			.title('Are you sure?')
			.textContent('This guardian will not be able to access any academic information in relation to the chosen student')
			.ariaLabel('Confirm')
			.ok('Yes please!')
			.cancel('I dont think so');

		$mdDialog
			.show(confirm)
			.then(() =>
				Restangular.one('guardian/remove-child/' + $scope.guardian.id + '/' + id)
					.get()
					.then(() => {
						$scope.guardian.students.splice($index, 1);
					}, ErrorService.handleError)
			)
			.catch(ErrorService.handleError)
			.finally(() => {
				this.respawn();
			});
	};

	$scope.next = function (year) {
		var y = parseInt(year);
		return y + 1;
	};

	$scope.getFullname = function (item) {
		return item.firstname + ' ' + item.lastname;
	};
	$scope.hide = function () {
		$mdDialog.hide();
	};

	$scope.cancel = function () {
		$mdDialog.cancel();
	};

	$scope.answer = function (answer) {
		$mdDialog.hide(answer);
	};

	$scope.updateTerm = (term) => {
		if (!term) return;

		$scope.isLoading = true;

		let params = {};

		params['filters'] = [{ column: 'school_id', condition: 'where', value: $scope.school.id }];

		params['has_fields'] = [{ field: 'section_histories', conditions: [{ column: 'term_id', condition: 'where', value: term.id }] }];

		let params_str = API.buildParams(params['filters'], params['has_fields']);

		Restangular.all(`student?${params_str}`)
			.getList()
			.then((res) => ($scope.students = res.plain()), ErrorService.handleError)
			.finally(() => ($scope.isLoading = false));
	};
}

saGuardiansDialogsChildSelector.controller = SaGuardiansDialogsChildSelectorController;

appModule.component('saGuardiansDialogsChildSelector', saGuardiansDialogsChildSelector);

saGuardiansDialogsChildSelector.selector = 'saGuardiansDialogsChildSelector';

export { saGuardiansDialogsChildSelector };

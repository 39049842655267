import template from './index.html';

/** @type {import('@srcAjs/components2/adminRoutes').IComponentOptions2} */
const saTeachersToolbar = {
	template,
	bindings: {
		//someInput: '@string,<object,&function'
	},
	transclude: {
		// #slotName: '?optionalPublicName'
	},
};

function saTeachersToolbarCmpController(
	SAFactory,
	$scope,
	$cookies,
	$stateParams,
	$mdToast,
	$state,
	$rootScope,
	$localStorage,
	$mdDialog,
	lsfactory,
	Restangular,
	Teacher,
	/**@type {saTeachersService} */ saTeachersService
) {
	const subs = [];
	$scope.teacher = lsfactory.getEnv('currentTeacherSelected');

	const vm = this;

	vm.$onInit = () => {
		subs[subs.length] = saTeachersService.teachersStore$.subscribe((data) => {
			$scope.teacher = data.teachers?.find((t) => +t.id === +$stateParams.id);
		}).unsubscribe;
	};

	vm.$onDestroy = () => {
		console.log('destroy');
		subs.forEach((sub) => sub());
	};

	//Activating the editing modal form for the teachers
	$scope.openEditDialog = function (teacher) {
		//var user = angular.copy($localStorage.user);
		lsfactory.setEnv('editTeacher', angular.copy(teacher));

		$mdDialog.show({
			escapeToClose: true,
			clickOutsideToClose: true,
			templateUrl: 'views/pages/roles/sa/teachers/dialogs/edit_teacher.html',
			controller: EditTeacherDialogController,
		});
	};

	$scope.$on('editTeacher.newEntity', function (event, new_teacher) {
		if (new_teacher !== undefined) {
			$scope.teacher = new_teacher;
		}
	});

	$scope.remove = function (teacher) {
		Teacher.one(teacher.id)
			.get()
			.then(function (res) {
				var tchr = res;
				var confirm = $mdDialog
					.confirm()
					.title('Would you like to delete "' + tchr.firstname + ' ' + tchr.lastname + '"?')
					.textContent('This operation cannot be undone. All dependants of this teacher will also be removed also.')
					.ariaLabel('Warning')
					.ok('Yes please!')
					.cancel('I dont think so');
				$mdDialog.show(confirm).then(
					function () {
						tchr.remove().then(function () {
							$scope.$broadcast('teachers.changeFired', $localStorage.environment);
							$state.go('sa.teachers_browse');
						});
					},
					function () {
						// Error Case
					}
				);
			});
	};
}

saTeachersToolbar.controller = saTeachersToolbarCmpController;

appModule.component('saTeachersToolbar', saTeachersToolbar);

saTeachersToolbar.selector = 'saTeachersToolbar';

export { saTeachersToolbar };

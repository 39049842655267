import { createSlice } from './src/store.mjs';

const initialState = { userToken: { token: /** @type {string|null,user:null} */ (null), user_id: null }, subscriptions: /** @type {(()=>void)[]} */ ([]) };
export const fcmSlice = createSlice({
	name: 'fcmSlice',
	initialState,
	reducers: {
		setUserToken: (state, /** @type {initialState['userToken']} */ userToken) => ({ ...state, userToken }),
		pushSubscription: (state, /** @type {initialState['subscriptions'][number]} */ subscription) => ({ ...state, subscriptions: [...state.subscriptions, subscription] }),
		popSubscription: (state) => ({ ...state, subscriptions: state.subscriptions.length === 1 ? [] : state.subscriptions.slice(1, state.subscriptions.length) }),
	},
});

import './UserProfileAvatarCmp.scss';

import template from './UserProfileAvatarCmp.tpl.html';

const userProfileAvatarCmp = {
	template,
	bindings: {
		showChangeText: '<',
		isRounded: '<',
		textColor: '<',
		thumbnail: '<',
		userName: '<',
		bgColor: '<',
		width: '<',
		hideImage: '<',
	},
	transclude: true,
};

class UserProfileAvatarCmpController {
	bg_color;

	/** @type {string} */ username = '#';

	/** @type {boolean} */ rounded;

	/** @type {string|number} */ _width;

	/** @ype {boolean} */ hideImage;

	constructor() {}

	get cShowImage() {
		return !this.hideImage;
	}

	set bgColor(bg_color) {
		if (!bg_color || bg_color === 'alternate') {
			this.bg_color = '#3a5de8';
			if (!this.textColor) this.textColor = '#fff';
		} else {
			this.bg_color = bg_color;
		}

		// console.log("::bgColor", this.bg_color)
	}

	/** @description default shape is rounded when no value is passed */
	set isRounded(toggle) {
		this.rounded = toggle !== false;
		// console.log("::isRounded", this.rounded)
	}

	set width(/** @type {string|number}*/ width) {
		switch (width) {
			case 'sm':
				this._width = 24;
				break;

			case 'none':
				this._width = 'none';
				break;

			default:
				if (typeof width === 'number') {
					this._width = `${width}px`;
				}
				if (typeof width === 'string') {
					this._width = width;
				}

				break;
		}
	}

	set textColor(textColor) {
		this.text_color = !textColor ? 'black' : textColor;
	}
}

userProfileAvatarCmp.controller = UserProfileAvatarCmpController;

appModule.component('userProfileAvatarCmp', userProfileAvatarCmp);

// export default component;

import './YearTermSelectorCmp.scss';

import template from './YearTermSelectorCmp.tpl.html';

const yearTermSelectorCmp = {
	template,
	/**
	 * @type {{
	 *		initial_term_id?: number,
	 *		dontSelectActive?: boolean,
	 *		alwaysCallUpdate?: boolean,
	 *		disAllowedFn?: (term:Term)=>boolean,
	 *		isDisabled?: boolean,
	 *		isRequired?: boolean,
	 *		cacheKey?: string,
	 *		onUpdate: ({term:Term})=>void,
	 *		school?: School,
	 *		label: string,
	 *	}}
	 */
	// @ts-ignore
	bindings: {
		initial_term_id: '<initialTermId',
		dontSelectActive: '<',
		alwaysCallUpdate: '<',
		disAllowedFn: '<',
		isDisabled: '<',
		isRequired: '<',
		cacheKey: '@',
		onUpdate: '&',
		school: '<',
		label: '@',
		// formName: "@"
	},
};

function yearTermSelectorCmpController(
	/** @type {QuickYearsWithTerms} */ QuickYearsWithTerms,
	$scope,
	/** @type {lsfactory} */ lsfactory,
	$filter,
	/** @type {auth}*/ auth,
	ErrorService,
	/** @type {EffectService} */ EffectService,
	/** @type {compSetupService} */ compSetupService,
	/** @type {atomosService} */ atomosService,
	/** @type {API} */ API
) {
	compSetupService(this, yearTermSelectorCmp.bindings);
	this.term_id = null;

	const [years$, { mutate: queryYears, revalidate: revalidateYears }] = API.rxQuery(
		['rxq.years.school'],
		(r, /** @type {{id:number}} */ { id }) =>
			/** @type {ng.restangular.ICollectionPromise<AcademicYear>} */ (
				r
					.all(
						'year?' +
							API.buildParams_v2({
								filters: [{ condition: 'where', column: 'school_id', value: id }],
								has_fields: null,
								fields: [{ field: 'terms.term_type', condition: 'with' }],
							})
					)
					.getList()
			),
		{ qInput$: undefined, enabled: false, destroy$: this.componentDestroy$ }
	);

	/** @type {string} */ this.cacheKey = null;
	/** @type {Term} */ this.term = null;

	EffectService.setup(
		this,
		({ onChanges, onInit }) => {
			const school = this.school ?? auth?.getEngagedUserRole()?.school ?? lsfactory.getEnv('school');
			if (onInit) {
				this.loadTerms({ school, cacheKey: this.cacheKey, term_id: this.initial_term_id });

				atomosService(years$, this).stream$.subscribe(({ loading, error }) => {
					this.loading = loading;
					ErrorService.handleError(error);
				});
			}

			if (onChanges) {
				this.loadTerms({ school, term_id: this.initial_term_id });
			}
		},
		() => [this.school, this.initial_term_id]
	);

	this.loadTerms = (/** @type {{school:School, cacheKey?:string, refresh?: boolean, term_id: number}} */ { school = null, cacheKey = this.cacheKey, refresh = false, term_id }) => {
		if (!school?.id) {
			return null;
		}

		const t_id = term_id;
		this.loading = true;

		return Promise.resolve(this.cacheKey && lsfactory.getEnv(this.cacheKey))
			.then((cachedYears) =>
				!cachedYears?.length
					? queryYears({ id: school.id }).then(({ data: years, error }) => ({ error, years: years?.plain() }))
					: { error: null, years: /** @type {AcademicYear[]} */ (cachedYears) }
			)
			.then(({ years, error }) => {
				this.years = years;

				if (this.cacheKey) lsfactory.setEnv(this.cacheKey, years);
			})
			.catch((error) => {
				ErrorService.handleError(error);
			})
			.finally(() => {
				this.years = this.years ?? [];
				this.selectInitial(this.years, t_id);
				this.loading = false;
			});
	};

	this.refresh = (term = this.term?.id) => {
		if (this.cacheKey) lsfactory.deleteEnv(this.cacheKey);
		revalidateYears();
		this.loadTerms({ term_id: term?.id, school: auth.getEngagedUserRole().school ?? this.school });
	};

	/**
	 * @typedef {{terms:[]}} Year
	 * @param {Year[]} years
	 * @returns {Year[]} years in descending order and terms in descending order
	 */
	const sortYears = (years) =>
		$filter('orderBy')(years, '-years').map((y) => {
			y.terms = $filter('orderBy')(y.terms, '-order');
			return y;
		});

	this.updateTerm = (/** @type {Term}*/ term) => {
		console.log('yearTermSelectorCmp.term updated', term);
		if (!term) return;

		if (!(term instanceof Object)) return this.onUpdate({ term: null });

		const year = { ...this.years.find((yr) => yr.id === term.year_id) };

		if (year) delete year.terms;

		term.year = year;

		this.onUpdate({ term });
	};

	this.selectInitial = (/** @type {Year[]}*/ years, initial_term_id) => {
		if (!years) return;

		const _terms = sortYears(years).flatMap((yr) => yr.terms);

		const findActiveTerm = (term) => !this.dontSelectActive && /^active$/.test(term.status);

		const findInitial = (term) => term.id === initial_term_id;

		this.term = _terms.find(findInitial) ?? _terms.find(findActiveTerm);

		this.updateTerm(this.term);

		$scope.$apply();
	};
}

yearTermSelectorCmp.controller = yearTermSelectorCmpController;

appModule.component('yearTermSelectorCmp', yearTermSelectorCmp);
// export default component;

export { yearTermSelectorCmp as yearTermSelecttorCmp };

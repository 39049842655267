import template from './index.html';

/** @type {import('@srcAjs/components2/adminRoutes').IComponentOptions2} */
const trClassSubPromotions = {
	template,
	bindings: {
		//someInput: '@string,<object,&function'
	},
	transclude: {
		// #slotName: '?optionalPublicName'
	},
};



function trClassSubPromotionsCmpController($scope, $cookies, $mdToast, $state, lsfactory, $rootScope, $localStorage, TRFactory, Restangular, Term) {
	TRFactory.loadEnvironment();
	TRFactory.loadNotifications();
	$scope.section = lsfactory.getEnv('teacher').section;
	lsfactory.setEnv('backLog', 'tr.home.dashboard');
	$state.current.data.title = $scope.section.sclass.class_level.title + ' (' + $scope.section.title + ')';
	$scope.selected_term_handle = null;
	$rootScope.isCTermDisabled = true;
	$scope.myValue = 0;

	$scope.$on('system.tr.environmentChanged', function (ev, data) {
		$scope.env = $localStorage.environment;
		$scope.school = $localStorage.environment.school;
		$scope.g_tid = $scope.env.g_selected_term_id;
		if ($scope.school !== undefined) {
			if (lsfactory.getEnv('g_selected_term_id') !== undefined && lsfactory.getEnv('g_selected_term_id') != null) {
				$scope.g_tid = lsfactory.getEnv('g_selected_term_id');
				$scope.$broadcast('system.termChanged', $scope.g_tid);
			}
		}
	});

	$scope.$on('system.termChanged', function (event, term) {
		if (term != undefined) {
			Term.one(term)
				.get()
				.then(
					function (res) {
						var term = res.plain();
						if (term.term_type.is_end == false) {
							$state.go('tr.class.students');
						} else {
							$scope.selected_term_handle = term;
						}
					},
					function (err) {
						if (err.status == 404) {
							// what if non existent
						}
					}
				);
		}
	});
}

trClassSubPromotions.controller = trClassSubPromotionsCmpController;

appModule.component('trClassSubPromotions', trClassSubPromotions);

trClassSubPromotions.selector = 'trClassSubPromotions';

export { trClassSubPromotions }

import './StudentTermSubjectEditCmp.scss';

import template from './StudentTermSubjectEditCmp.tpl.html';

/** @type {ng.IComponentOptions} */
const studentTermSubjectEditCmp = {
	template,
	bindings: {
		studentTermSubject: '<',
		subjectMount: '<',
		onUpdate: '&',
		onHide: '&',
		showDialog: '&',
	},
	transclude: {
		header: '?header',
		dialogDebug: '?dialogDebug',
	},
};

class ComponentNameController {
	/** @type {(response)=>any} */
	onHide;

	hide;

	/** @type {boolean} */
	showDebugToggle;

	/** @type {({studentTermSubject})=> Promise }*/
	onUpdate;

	/** @type {()=>boolean} */

	showDebug;

	CONST_APP_ENV;

	#ErrorService;

	constructor(/** @type {ErrorService} */ ErrorService, $mdDialog, /** @type {string} */ CONST_APP_ENV) {
		this.#ErrorService = ErrorService;
		this.CONST_APP_ENV = CONST_APP_ENV;
		this.showDebugToggle = false;
	}

	$onInit() {
		console.log('studentTermSubjectEditCmp', this.showDebug);
		this.hide = (response = false) => this.onHide({ response });
		this.showDebug = this.showDebug ?? (() => !/production/i.test(this.CONST_APP_ENV));
	}

	update(/** @type {StudentTermSubject} */ studentTermSubject) {
		this.onUpdate({ studentTermSubject })
			.then(this.hide)
			.catch(this.#ErrorService.handleError);
	}
}

studentTermSubjectEditCmp.controller = ['ErrorService', '$mdDialog', 'CONST_APP_ENV', ComponentNameController];

appModule.component('studentTermSubjectEditCmp', studentTermSubjectEditCmp);

import './StudentBilling.scss';

import template from './StudentBilling.tpl.html';

const studentBilling = {
	template,
	bindings: {},
};

class StudentBilling {
	chargeTemplateService;

	constructor(/** @type {import('../ChargeTemplateService').ChargeTemplateService} */ chargeTemplateService) {
		this.chargeTemplateService = chargeTemplateService;
	}

	$onInit() {
		this.refreshChargeTemplates();
	}

	refreshChargeTemplates() {
		this.chargeTemplateService.dispatch('refresh');
	}
}

studentBilling.controller = StudentBilling;

appModule.component('studentBilling', studentBilling);

import './style.scss';

import template from './NotificationsCmp.tpl.html';

/** @type {import('@srcAjs/components2/adminRoutes').IComponentOptions2} */
const notificationsCmp = {
	template,
	bindings: {
		//someInput: '@string,<object,&function'
		onClose: '&',
	},
	transclude: {
		// #slotName: '?optionalPublicName'
	},
	selector: '',
};

/** @this {ng.IController} */
function notificationsCmpController(
	$state,
	/** @type {ErrorService} */ ErrorService,
	/** @type {angular.IScope} */ $scope,
	/** @type {NotificationService} */ NotificationService,
	/** @type {fcmMessaging}*/ fcmMessaging,
	$localStorage,

	CONST_DEFAULT_PROFILE,
	/** @type {auth} */ auth,
	/** @type {EffectService} */ EffectService,
	/** @type {DialogService} */ DialogService,
	/** @type {API} */ API,
	/** @type {atomosService} */ atomosService,
	/** @type {compSetupService} */ compSetupService,
	/** @type {RxService} */ RxService,
	/** @type {ng.ITimeoutService} */ $timeout,
	/** @type {ng.IWindowService} */ $window
) {
	compSetupService(this, notificationsCmp.bindings);

	const vm = $scope;

	vm.showing = 'notifications';
	vm.user = $localStorage.user;
	vm.notifications = [];
	this.isLoading = false;
	this.wpTokens = null;

	const [wpToken$] = API.rxQuery(
		['rxq.wpTokens'],
		(r, /**@type {{user_id:number}} */ data) =>
			/** @type {ng.restangular.ICollectionPromise<{token:string,device:string,user_id:number}>} */ (
				r
					.all(
						'cloud-messaging?' +
							API.buildParams_v2({
								filters: [{ condition: 'where', column: 'user_id', value: data.user_id }],
							})
					)
					.getList()
			),
		{ /** @type {any} */ qInput$: auth.authData$.pipe(RxService.map((authd) => ({ user_id: auth.getEngagedUserRole().user_id }))), enabled: true, destroy$: this.componentDestroy$ }
	);

	EffectService.setup(this, ({ changes, onInit }) => {
		if (onInit) {
			atomosService(NotificationService.notificationsRxq[0], this).stream$.subscribe((ns) => {
				this.isLoading = ns.loading;

				if (!ns?.data || ErrorService.handleError(ns.error)) return;

				vm.notifications = ns.data?.plain();
				this.notifications = vm.notifications;
				vm.newNotifications = vm.notifications?.filter((x) => !x.read_at);
			});

			atomosService(fcmMessaging.subscriptions$, this).stream$.subscribe((subs) => (vm.subscriptions = subs));

			atomosService(wpToken$, this).stream$.subscribe(({ data, error }) => {
				if (!data || ErrorService.handleError(error)) return;
				this.wpTokens = data.plain();
			});

			vm.setupFcm();
		}
	});

	vm.read = /**@type {[]} */ (notifications) => {
		NotificationService.read(notifications.filter((nt) => !nt.read_at)).catch(ErrorService.handleError);
		if (notifications.length === 1) {
			vm.goto('details');
			vm.selectedNotification = notifications[0];
		}
	};

	vm.confirmDelete = (/** @type {NotificationItem} */ nt) => {
		return DialogService.dispatch('confirm', `Delete notification ${nt.data?.title ?? nt?.title ?? nt.id}`).then((res) => {
			if (!res) return;

			return NotificationService.deleteNotification([nt])
				.then(() => vm.goto('notifications'))
				.catch(ErrorService.handleError);
		});
	};

	vm.resolveImage = function (type) {
		if (type !== 'user') {
			return 'images/notify/system.jpg';
		}

		return vm.resolveProfile(vm.user.thumbnail);
	};

	vm.resolveProfile = function (thumbnail) {
		if (thumbnail === '') {
			return 'http://www.gravatar.com/avatar/' + md5(vm.user.email) + '.jpg?s=800&d=' + CONST_DEFAULT_PROFILE;
		} else {
			return thumbnail;
		}
	};

	vm.goto = function (page) {
		vm.showing = page;
		if (!vm.selectedNotification) return;
		focusDateInList();
	};

	function focusDateInList(delay = 800, nt = vm.selectedNotification) {
		if (!nt) return;

		$timeout(() => {
			const notificationHtmlRefId = '#notification-' + nt.id;
			const notificationElementRef = $window.document.querySelector(notificationHtmlRefId);
			console.log({ dateItemHtmlRefQuery: notificationHtmlRefId, notificationElementRef });
			notificationElementRef?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
		}, delay);
	}

	vm.hide = () => {
		this.onClose();
	};

	vm.notificationAction = (notification) => {
		if (/CreationRequested$/i.test(notification.type)) {
			$state
				.go('sa.delegated_requests')
				.then(() => vm.hide())
				.then(() => vm.read(notification));
		}
	};

	vm.setupFcm = (refresh = false) => {
		NotificationService.refresh(refresh);
		fcmMessaging.pusher.subscribe('app.highway').trigger('client-peek', { user_id: /** @type {AuthState} */ (auth.authData$.value).engagedUserRole.user_id });
		try {
			API.rxRevalidateQueries();
			console.log('Requesting permission...');
			Notification.requestPermission().then((permission) => {
				if (permission === 'granted') {
					console.log('Notification permission granted.');
					// TODO(developer): Retrieve a registration token for use with FCM.
					// In many cases once an app has been granted notification permission,
					// it should update its UI reflecting this.

					fcmMessaging.subscribe(auth.getEngagedUserRole());
				} else {
					console.log('Unable to get permission to notify.');
				}
			});
		} catch (error) {
			ErrorService.handleError(error);
			DialogService.dispatch('alert', 'Enable notifications to recieve important alerts when away');
		}
	};
}

notificationsCmp.controller = notificationsCmpController;

appModule.component('notificationsCmp', notificationsCmp);

notificationsCmp.selector = 'notificationsCmp';

export { notificationsCmp };

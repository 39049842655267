import './saGenerateStudentSectionHistoryReportCmp.scss';

import { SchoolReportTypePrefService } from '@srcAjs/components/SchoolReportTypePrefService';

import template from './saGenerateStudentSectionHistoryReportCmp.tpl.html';

const saGenerateStudentSectionHistoryReportCmp = {
	template,
	bindings: {
		school: '<',
		sectionHistories: '<',
		selectSectionHistory: '<',
		years: '<',
		students: '<',
		asDialog: '<',
		onClose: '&',
	},
	transclude: true,
};
/**
 * @this {{
 * school:School, years: AcademicYear[],
 * students: Student[], selectSectionHistory?: (sh:SectionHistory)=> boolean
 * section_histories?:SectionHistory[]
 * } & ng.IController}
 */
function saGenerateStudentSectionHistoryReportController(
	/** @type {import('restangular').IService} */ Restangular,
	/** @type {ng.IScope}*/ $scope,
	$window,
	/** @type {ErrorService} */ ErrorService,
	CONST_REPORT_TYPES,
	fullnameFilter,
	/** @type {EffectService} */ EffectService,
	/** @type {compSetupService} */ compSetupService
) {
	compSetupService(this);
	/**
	 * @type {typeof $scope & {
	 * students: Student[]
	 * years: AcademicYear[]
	 * sel_history: SectionHistory
	 * sel_histories:SectionHistory[]
	 * [key:string]: any|{}
	 * }}
	 */
	const svm = /** @type {any}*/ ($scope);

	EffectService.setup(
		this,
		({ onChanges, onInit }) => {
			if (onInit) {
				svm.levels = [];
				svm.isProcessing = false;
				svm.box = {};
				svm.years = this?.years;
				svm.section_history_ids = undefined;
				svm.CONST_REPORT_TYPES = [...CONST_REPORT_TYPES];
				svm.report_option = SchoolReportTypePrefService.get({ school_id: this.school.id });
				if (!this.students?.filter((m) => m)?.length) {
					return;
				}
				this.initStudents(this.students);
				this.initSectionHistories(this.sectionHistories);
				svm.isReady = true;
			}
			if (onChanges && this.students && this.school) {
				if (!this.students?.filter((m) => m)?.length) {
					return;
				}
				this.initStudents(this.students);
				this.initSectionHistories(this.sectionHistories);
				svm.isReady = true;
			}
		},
		() => [this.students, this.sectionHistories, this.school]
	);

	this.initStudents = (students) => {
		if (!students?.length) {
			return;
		}
		svm.students = (students ?? []).map((st) => ({ ...st, fullname: fullnameFilter(st) }));
		[svm.student] = svm.students.length === 1 ? svm.students : [];
		if (svm.student && !svm.report_option) {
			svm.report_option = SchoolReportTypePrefService.get(svm.student);
		}
		svm.student && svm.loadSectionHistories(svm.student);
	};

	this.initSectionHistories = (section_histories) => {
		if (!(section_histories?.length >= 0)) {
			return;
		}
		svm.sel_histories = section_histories;
		svm.selected_section_history = !this.selectSectionHistory ? null : svm.sel_histories.find(this.selectSectionHistory);
	};

	svm.loadSectionHistories = (student) => {
		try {
			if (!student || svm.sel_histories?.every(({ student_id }) => student_id === student.id)) {
				return;
			}

			svm.isProcessing = true;

			Restangular.one('student', student.id)
				.customGET('', { field_section_histories: 'true', 'fields[]': [] })
				.then((res) => res.plain())
				.then((shs) => this.initSectionHistories(shs.section_histories))

				.catch(ErrorService.handleError)
				.finally(() => {
					svm.isProcessing = false;
				});
		} catch (error) {
			ErrorService.handleError(error);

			svm.sel_history = [];
			svm.isProcessing = false;
		}
	};

	/**
	 * @param {String} report_option
	 * @returns {import('restangular').ICollection}
	 */
	const make_download_url = (p_shid, report_option) =>
		Restangular.all(`report/by_section_history_id/${p_shid}?v=1.2&shid=${p_shid}&report_option=${report_option.replaceAll(' ', '_')}&response_as=json`);

	/**
	 *
	 * @param {string[]} s_hids -  array of string ids
	 * @param {'compact_report_card' | 'detailed_report_card'} report_option
	 */
	svm.getReport = (selected_student, selected_section_history, report_option) => {
		if ([selected_student, selected_section_history, report_option].some((item) => !item)) {
			return;
		}

		svm.isProcessing = true;

		// svm.hide({ selected_student, selected_section_histories, report_option })

		const downloadLink = Restangular.all(
			`report/by_section_history_id/${selected_section_history.id}?response_as=pdf&v=1.2&shid=${selected_section_history.id}&report_option=${report_option.replaceAll(' ', '_')}`
		).getRestangularUrl();

		console.log('downloadLink', downloadLink);
		$window.open(downloadLink, '_blank');
		svm.hide();
		svm.isProcessing = false;
	};

	svm.next = (year) => {
		const y = parseInt(year);
		return y + 1;
	};

	svm.hide = (data = null) => {
		this.onClose(data);
		if (this.asDialog) {
			this.morphiousRef.doClose();
		}
	};

	svm.cancel = svm.hide;

	svm.answer = (answer) => {
		svm.hide(answer);
	};
}

saGenerateStudentSectionHistoryReportCmp.controller = saGenerateStudentSectionHistoryReportController;

appModule.component('saGenerateStudentSectionHistoryReportCmp', saGenerateStudentSectionHistoryReportCmp);
// export default component;

import template from './index.html';

/** @type {import('@srcAjs/components2/adminRoutes').IComponentOptions2} */
const trHomeSubProfile = {
	template,
	bindings: {
		//someInput: '@string,<object,&function'
	},
	transclude: {
		// #slotName: '?optionalPublicName'
	},
};

function trHomeSubProfileCmpController(
	/** @type {ng.material.IDialogService} */ $mdDialog,
	/** @type {import('restangular').IService} */ Restangular,
	$scope,
	$mdToast,
	$state,
	/** @type {ErrorService} */ ErrorService,
	/** @type {TRFactory} */ TRFactory,
	/** @type {lsfactory} */ lsfactory,
	/** @type {auth} */ auth,
	/** @type {API} */ API,
	/** @type {atomosService} */ atomosService,
	/** @type {compSetupService} */ compSetupService,
	/** @type {EffectService} */ EffectService
) {
	// TRFactory.initializeRole()
	compSetupService(this);
	TRFactory.loadEnvironment();
	const vm = $scope;
	vm.sals = ['Mr.', 'Mrs.', 'Ms.', 'Dr.', 'Prof.', 'Eld.', 'Rev.'];
	vm.gends = ['Male', 'Female'];
	vm.ms = ['Single', 'Married', 'Divorced'];
	vm.g_tid = lsfactory.getEnv('g_selected_term_id');

	vm.isReady = false;

	vm.selectCountry;

	vm.getDate = function (date) {
		return new Date(date);
	};

	EffectService.setup(this, ({ onInit }) => {
		if (onInit) {
			atomosService(auth.authData$, this).stream$.subscribe(({ engagedUserRole: teacher }) => {
				this.teacher = teacher;
			});
		}
	});
}

trHomeSubProfile.controller = trHomeSubProfileCmpController;

appModule.component('trHomeSubProfile', trHomeSubProfile);

trHomeSubProfile.selector = 'trHomeSubProfile';

export { trHomeSubProfile };

import template from './AccessForgotpasswordCmp.tpl.html';

import './AccessForgotpasswordCmp.scss';

const accessForgotpasswordCmp = {
	template,
	bindings: {},
};

function ForgotPasswordController($scope, ErrorService, auth) {
	const vm = this;
	vm.start = true;
	vm.done = false;
	vm.isPending = false;
	vm.notFound = false;
	vm.box = {
		email: '',
	};
	vm.sendEmail = function (event=null) {
		event && event.preventDefault();

		vm.isPending = true;

		auth
			.sendForgottenEmail(vm.box)
			.then(
				function (res) {
					vm.start = false;
					vm.done = true;
				},
				(error) => {
					vm.notFound = true;
					ErrorService.handleError(error);
				}
			)
			.finally(() => {
				vm.isPending = false;
			});
	};
}

accessForgotpasswordCmp.controller = ['$scope', 'ErrorService', 'auth', ForgotPasswordController];

appModule.component('accessForgotpasswordCmp', accessForgotpasswordCmp);

import template from './index.html';

/** @type {import('@srcAjs/components2/adminRoutes').IComponentOptions2} */
const saSchoolSetupAdminsIndex = {
	template,
	bindings: {
		//someInput: '@string,<object,&function'
	},
	transclude: {
		// #slotName: '?optionalPublicName'
	},
};



function saSchoolSetupAdminsIndexCmpController($scope, $cookies, $mdToast, $state, $rootScope, $localStorage, $http, SAFactory, Restangular, $mdDialog, lsfactory) {
	SAFactory.initializeRole();
	SAFactory.loadEnvironment();
	$scope.admins = [];
	$scope.env = $localStorage.environment;
	$scope.isReady = true;

	$scope.env = $localStorage.environment;
	var admins = Restangular.all('school/get/admins/' + $scope.env.school.id);
	admins.getList().then(function (data) {
		var list = data.plain();
		$scope.admins = list;
	});

	$scope.$on('admin.dataChanged', function (event, data) {
		var admins = Restangular.all('school/get/admins/' + $scope.env.school.id);
		admins.getList().then(function (data) {
			var list = data.plain();
			$scope.admins = list;
		});
	});

	$scope.newAdmin = function () {
		$mdDialog.show({
			escapeToClose: false,
			clickOutsideToClose: false,
			templateUrl: 'views/pages/roles/sa/school_setup/admins/dialogs/add_admin.html',
			controller: AddAdminDialogController,
		});
	};

	$scope.editAdmin = function (admin) {
		lsfactory.setEnv('dialogCurrentChosenAdmin', admin);
		$mdDialog.show({
			escapeToClose: false,
			clickOutsideToClose: false,
			templateUrl: 'views/pages/roles/sa/school_setup/admins/dialogs/edit_admin.html',
			controller: EditAdminDialogController,
		});
	};
}

saSchoolSetupAdminsIndex.controller = saSchoolSetupAdminsIndexCmpController;

appModule.component('saSchoolSetupAdminsIndex', saSchoolSetupAdminsIndex);

saSchoolSetupAdminsIndex.selector = 'saSchoolSetupAdminsIndex';

export { saSchoolSetupAdminsIndex }

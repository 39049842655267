import template from './index.html';

/** @type {import('@srcAjs/components2/adminRoutes').IComponentOptions2} */
const sSaOperationsCheckAttendanceIndex = {
	template,
	bindings: {
		//someInput: '@string,<object,&function'
	},
	transclude: {
		// #slotName: '?optionalPublicName'
	},
};

function sSaOperationsCheckAttendanceIndexCmpController(
	$scope,
	$mdDialog,
	lsfactory,
	/** @type {import("@srcAjs/factory/services/class-group").ClassGroupApi} */ ClassGroup,
	ErrorService,
	SAFactory,
	/** @type {getDateWithinTermBoundsFilter}*/ getDateWithinTermBoundsFilter,
	/** @type {moment}*/ $moment,
	/** @type {API} */ API,
	/** @type {import("restangular").IService} */ Restangular,
	/** @type {EffectService} */ EffectService,
	/** @type {compSetupService} */ compSetupService,
	/** @type {atomosService} */ atomosService
) {
	compSetupService(this);

	SAFactory.initializeRole();
	SAFactory.loadEnvironment();

	const vm = $scope;
	vm.DEFAULT_STATE = { title: 'N', sub_title: 'Not Available', class: 'attendance-nm' };
	vm.states = [
		{ title: 'P', sub_title: 'Present', class: 'bg-blue text-white', order: 1, reason: 'Present' },
		{ title: 'A', sub_title: 'Absent', class: 'bg-red text-white', order: 2, reason: 'Absent' },
		{ title: 'L', sub_title: 'Late', class: 'bg-green text-black', order: 3, reason: 'Late' },
		{ title: 'H', sub_title: 'Holiday', class: 'bg-yellow', order: 4, reason: 'Holiday' },
		{ title: 'N', sub_title: 'Clear', class: '', order: 5, reason: 'Clear' },
	];
	vm.attendance_date = null;
	vm.isLoading = false;

	vm.school = lsfactory.getEnv('school');
	vm.chosen_date = '';
	vm.today = new Date();
	vm.isCheckerLoading = true;
	vm.isTaken = false;
	vm.selectedTerm = null;
	vm.selectedTermObject = null;
	vm.section_id = null;
	vm.students_for_section = null;

	vm.classGroupChanged = (term) => vm.$broadcast('attendance_module.data_changed', term);
	vm.next = (year) => {
		const y = parseInt(year);
		return y + 1;
	};

	function goGetClassData({ school = vm.school, term = vm.selectedTermObject }) {
		vm.isReady = false;

		const /** @type {API_Params} */ params = {
				fields: [
					{
						condition: 'with',
						field: 'sections',
						conditions: [
							{
								condition: 'whereIn',
								column: 'term_id',
								value: [term.id],
							},
							{ condition: 'with', column: 'teacher.user' },
						],
					},

					{
						condition: 'with',
						field: 'class_level',
					},
				],
			};

		/** TODO: replace with rxq */
		/** @type {import('restangular').ICollectionPromise<ClassGroup>} */ (Restangular.all(`class?${API.buildParams_v2(params)}`).getList())
			.then((res) => (vm.class_group_data = res.plain()))
			.catch(ErrorService.handleError)
			.finally(() => (vm.isReady = true));
	}

	const [attendance$, rxMetaAttendance] = API.rxQuery(
		['rxq.term_sections_daily_attendance'],
		(r, /**@type {section_ids_encoded,chosen_date,term_id} */ { section_ids_encoded, chosen_date, term_id }) =>
			/** @type {ng.restangular.ICollectionPromise<any>} */ (
				r.all(`attendance?aggregations[group][date][]=${chosen_date}&aggregations[group][term_id]=${term_id}&${section_ids_encoded}`).getList()
			),
		{ /** @type {any} */ qInput$: undefined, enabled: false, destroy$: this.componentDestroy$ }
	);

	EffectService.setup(this, ({ onInit }) => {
		if (onInit) {
			atomosService(attendance$, this).stream$.subscribe((atRes) => {
				$scope.isLoading = atRes.loading;

				if (atRes.error) ErrorService.handle(atRes.error);

				if (!atRes?.data) return;

				vm.section_attendance_summaries = atRes.data.plain();
			});
		}
	});

	vm.date_chosen = (date, term, classes) => {
		if (!date || !term || !classes) return;

		vm.loading_section_att_summaries = true;
		const chosen_date = $moment(date).format('YYYY-MM-DD');
		vm.chosen_date = chosen_date;

		const section_ids = classes.flatMap((_class) => _class.sections.map(({ id }) => ({ id })));
		const section_ids_encoded = section_ids.map((section, idx) => `aggregations[group][section_id][${idx}][id]=${section.id}`).join('&');
		rxMetaAttendance.mutate({ section_ids_encoded, term_id: term.id, chosen_date });
	};

	/**
	 * @typedef {{status:string}} SectionAttSummary
	 * @param {SectionAttSummary} section_att_summary
	 */
	vm.isPresent = (section_att_summary) => section_att_summary.status.toLowerCase().includes('present');

	this.$onInit = () => {
		vm.$watch('class_group_data', (sclasses) => {
			if (!sclasses) return;
			vm.date_chosen(vm.attendance_date, vm.selectedTermObject, vm.class_group_data);
		});

		vm.$on('attendance_module.data_changed', (event, data) => {
			if (!data) return;

			const formatted_date = $moment().format('YYYY-MM-DD');
			goGetClassData({ date: formatted_date });
		});
	};
	vm.studentAttend = (section, sub_att_summaries) => {
		$mdDialog.show({
			escapeToClose: true,
			bindToController: true,
			controllerAs: '$ctrl',
			locals: {
				get_section: section,
				get_term: vm.selectedTermObject,
				get_attendance_date: vm.attendance_date,
				sub_att_summaries,
			},
			clickOutsideToClose: true,
			templateUrl: 'views/pages/roles/sa/operations/check_attendance/dialogs/check_attendance_dialog.html',
			controller: checkStudentAttendanceDialogController,
		});
	};

	vm.updateAttendanceDate = (/** @type {Date}*/ date) => {
		vm.attendance_date = date;
		vm.date_chosen(date, vm.selectedTermObject, vm.class_group_data);
	};
	vm.updateTerm = (/** @type {Term}*/ term) => {
		if (!term) return;

		vm.selectedTermObject = term;
		vm.selectedTerm = term.id;
		vm.section_attendance_summaries = null;
		vm.classGroupChanged(term);
		vm.attendance_date = getDateWithinTermBoundsFilter(term, new Date());
	};
}

sSaOperationsCheckAttendanceIndex.controller = sSaOperationsCheckAttendanceIndexCmpController;

appModule.component('sSaOperationsCheckAttendanceIndex', sSaOperationsCheckAttendanceIndex);

sSaOperationsCheckAttendanceIndex.selector = 'sSaOperationsCheckAttendanceIndex';

export { sSaOperationsCheckAttendanceIndex };

import template from './index.html';

/** @type {import('@srcAjs/components2/adminRoutes').IComponentOptions2} */
const saStudentsSubProfile = {
	template,
	bindings: {
		//someInput: '@string,<object,&function'
		student: '<',
	},
	transclude: {
		// #slotName: '?optionalPublicName'
	},
};

function saStudentsSubProfileCmpController($state, lsfactory) {
	const vm = this;

	lsfactory.setEnv('backLog', 'sa.students_browse');
	lsfactory.setEnv('backLogData', undefined);

	vm.gotoGuardian = function (guardian) {
		if (guardian !== undefined) {
			lsfactory.setEnv('currentGuardianSelected', guardian);
			lsfactory.setEnv('backLog', 'sa.students_detail.profile');
			lsfactory.setEnv('backLogData', { id: vm.student.id });
			$state.go('sa.guardians_detail.profile', { id: guardian.id });
		}
	};

	vm.$onInit = () => {
		console.log({ student: vm.student ?? vm.istudent });
		vm.selectCountry = (country) => country?.id === vm.student.country_id;
	};
}

saStudentsSubProfile.controller = saStudentsSubProfileCmpController;

appModule.component('saStudentsSubProfile', saStudentsSubProfile);

saStudentsSubProfile.selector = 'saStudentsSubProfile';

export { saStudentsSubProfile };

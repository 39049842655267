import "./CreateTermCmp.scss"

import template from "./CreateTermCmp.tpl.html"

const createTermCmp = {
	template,
	bindings: {
		admin_id: "<sadminId",
		types: "<termTypes",
		year: "<termYear",
		sub: "<",
	},
}

class ComponentNameController {
	#Restangular
	#$localStorage
	#$mdDialog
	#$rootScope

	constructor(Restangular, $scope, lsfactory, $mdDialog, $rootScope, $localStorage) {
		// $scope.admin_id = this.sadmin_id
		// $scope.year = this.termYear
		// $scope.sub = this.sub
		// $scope.types = this.termTypes
		this.#Restangular = Restangular
		this.#$localStorage = $localStorage
		this.#$mdDialog = $mdDialog
		this.#$rootScope = $rootScope
		this.isProcessing = false
		this.titles = ["First Term", "Second Term", "Third Term"]

		var sql_date = function (date) {
			return date.getFullYear() + "-" + (parseInt(date.getMonth()) + 1) + "-" + date.getDate()
		}

		this.addTerm = () => {
			var data = {
				start: sql_date(this.startDate),
				end: sql_date(this.endDate),
				year_id: this.year.id,
				term_types_id: this.tt.id,
				show_grades_deadline: false,
				grades_deadline_date: sql_date(this.endDate),
				status: "inactive",
				order: this.tt.number,
				sadmin_id: this.admin_id,
			}

			this.isProcessing = true

			this.#Restangular
				.one("year", data.year_id)
				.all("terms")
				.post(data)
				.then((data) => {
					this.hide()
					this.#$rootScope.$broadcast("system.sa.environmentChanged", this.#$localStorage.environment)
				})
		}
	}
	hide = () => this.#$mdDialog.hide()

	cancel = () => this.#$mdDialog.hide()

	answer = (answer) => this.#$mdDialog.hide(answer)
}

createTermCmp.controller = ["Restangular", "$scope", "lsfactory", "$mdDialog", "$rootScope", "$localStorage", ComponentNameController]

appModule.component("createTermCmp", createTermCmp)
// export default component

import './DialogCmp.scss';
import template from './DialogCmp.tpl.html';

const dialogCmp = /** @type {const} */ ({
	template,
	bindings: {
		// someInput: '@string,<object,&function'
		ariaLabel: '@',
		open: '<',
		onClose: '&',
		originRef: '<',
		onOpen: '&',
		clickOutsideToClose: '<',
		fullscreen: '<',
		dialogClasses: '@',
		hideClose: '<',
		cStyle: '<',
	},
	transclude: {
		dialogHeader: '?',
	},
	// controller: DialogCmpController,
	selector: 'dialogCmp',
});

// /** @type {import('angular-ui-router')} _UiRouter */

function dialogCmpController(
	/** @type {ng.material.IDialogService} */ $mdDialog,
	/** @type {import('angulartics').IAnalyticsService} */ $analytics,
	/** @type {ErrorService}*/ ErrorService,
	/** @type {EffectService} */ EffectService,
	/** @type {ng.ILocationService} */ $location,
	/** @type {ng.IScope} */ $scope,
	/** @type {ng.material.IPanelService} */ $mdPanel,
	/** @type {compSetupService} */ compSetupService,
	/** @type {DialogUrlService} */ DialogUrlService,
	/** @type {atomosService} */ atomosService
) {
	compSetupService(this, dialogCmp.bindings);

	this.panelRef = undefined;
	this.cleanUpUrlListener = () => null;
	const trackDialog = (/** @type {'dialogCmp:closed'|'dialogCmp:opened'} */ event) =>
		$analytics.eventTrack(event, { category: 'dialog_event', ariaLabel: this.ariaLabel ?? 'unaamedDialog' });

	this.activateUrlListener = () => {
		const removeUrlListener = $scope.$on('$locationChangeSuccess', (event, newUrl) => {
			const navigatedAway = !newUrl?.includes('dialog');
			// const navigatedAway = $location.search('dialog') !== this.ariaLabel;
			if (navigatedAway && this.open) {
				this.cleanUpUrlListener();
				this.doClose();
			}
		});

		const removeDialogUrlServiceSub = atomosService(DialogUrlService._atom.stream$, this).stream$.subscribe((urls) => {
			if (!urls?.includes(this.ariaLabel)) {
				this.cleanUpUrlListener();
				this.doClose();
			}
		});

		this.cleanUpUrlListener = () => {
			removeUrlListener();
			removeDialogUrlServiceSub.unsubscribe();
		};
	};

	EffectService.setup(
		this,
		({ onChanges }) => {
			if (onChanges && !this.open) {
				this.doClose();
			}

			if (!this.open || !onChanges) {
				return;
			}

			try {
				const panelPosition = $mdPanel
					.newPanelPosition()

					.absolute()
					.center();

				const animation = $mdPanel.newPanelAnimation();
				animation.withAnimation($mdPanel.animation.FADE).duration(200);

				const /** @type {ng.material.IPanelConfig} */ config = {
						attachTo: angular.element('body'),
						contentElement: this.dialogContainerElementRef ?? this.panelContainerRef,
						animation,
						position: panelPosition,
						panelClass: 'panel-cmp',
						clickOutsideToClose: this.clickOutsideToClose ?? true,
						escapeToClose: true,
						focusOnOpen: true,
						trapFocus: true,
						zIndex: 150,
						groupName: ['dialogGroup'],
						closeTo: angular.element(this.originRef),
						openFrom: angular.element(this.originRef),
						onCloseSuccess: (_, r) => this.doClose(r),
						onOpenComplete: this.doOpen,
					};

				$mdPanel
					.open(config)
					.then((panelRef) => (this.panelRef = panelRef))
					.catch((err) => {
						console.log('panelCmp.catch.fired');
						ErrorService.handleError(err);
						this.doClose();
					});
			} catch (error) {
				ErrorService.handleError(error);
			}

			return null;
		},
		() => [!!this.open]
	);

	this.doClose = (data = null) => {
		trackDialog('dialogCmp:closed');
		this.cleanUpUrlListener();
		this.open = false;

		if (!!this.onClose) {
			this.onClose({ open: false, data });
		}

		if (DialogUrlService._atom.get()?.includes(this.ariaLabel)) {
			DialogUrlService.dispatch('pop', this.ariaLabel);
		}

		$location.search('dialog', DialogUrlService._atom.get()).replace();

		if ($location.url().includes(encodeURI(this.ariaLabel))) {
			$location.search('dialog', null).replace().search('dialog', null).replace();
		}

		// $mdDialog.hide();

		this.panelRef?.hide(data);
		this.panelRef = undefined;
	};

	this.doOpen = () => {
		trackDialog('dialogCmp:opened');

		DialogUrlService.dispatch('push', this.ariaLabel);
		$location.search('dialog', DialogUrlService._atom.get()).replace();

		this.open = true;
		if (!!this.onOpen) {
			this.onOpen({ open: true });
		}
		this.activateUrlListener();
	};
}

dialogCmp.controller = dialogCmpController;

appModule.component('dialogCmp', dialogCmp);

dialogCmp.selector = 'dialogCmp';

export { dialogCmp };

import template from './SpLoader.tpl.html';

import './SpLoader.scss';

const spLoaderCmp = {
	template,
	bindings: {
		diameter: '@',
		mode: '@',
		show: '<',
	},
};

class spLoaderCmpController {
	/** @type {string} pixel or percent values */ diameter;

	/** @type {string} number between 0 -100 or indeterminate */ mode;

	/** @type {boolean} */ show;

	constructor(/** @type {EffectService}*/ EffectService, /** @type {compSetupService} */ compSetupService) {
		compSetupService(spLoaderCmp.bindings);

		EffectService.setup(
			this,
			({ onChanges }) => {
				if (onChanges) {
					this.cDiameter = this.diameter ?? '100%';
					this.cMode = this.mode ?? 'indeterminate';
				}
			},
			() => [this.diameter, this.mode, this.show]
		);
	}
}

spLoaderCmp.controller = spLoaderCmpController;

appModule.component('spLoader', spLoaderCmp);

import template from './AccessCmp.tpl.html';

import './AccessCmp.scss';

const accessCmp = {
	template,
	bindings: {
		$transition$: '<',
	},
	selector: 'accessCmp',
};

class ComponentNameController {
	$transition$;

	$state;

	/** @type {auth} */ auth;

	/** @type{ng.material.IDialogService} */ $mdDialog;

	/** @type {ErrorService} */ ErrorService;

	constructor(/** @type {auth}*/ auth, $mdDialog, ErrorService, /** @type {atomosService} */ atomosService) {
		atomosService(auth.authData$, this).stream$.subscribe((authData) => {
			if (!authData?.user) return;

			if (auth.getAuthenticatedUser()?.is_verified || auth.isLocked()) {
				return;
			}

			auth.loadUserModule(auth.getAuthenticatedUser());
		});
	}
}

accessCmp.controller = ComponentNameController;

appModule.component('accessCmp', accessCmp);

import template from './SectionSubMountCharts.tpl.html';

/** @type {import('@srcAjs/components2/adminRoutes').IComponentOptions2} */
const sectionSubMountCharts = {
	template,
	bindings: {
		//someInput: '@string,<object,&function'
		section: '<',
		asDialog: '<',
	},
	transclude: true,
	selector: 'sectionSubMountCharts',
};

/** @this {ng.IController} */
function sectionSubMountChartsController(
	/** @type {EffectService} */ EffectService,
	/** @type {compSetupService} */ compSetupService,
	/** @type {TRFactory} */ TRFactory,
	/** @type {ErrorService} */ ErrorService
) {
	compSetupService(this, sectionSubMountCharts.bindings);

	const [secSubMounts$, { mutate, revalidate: revSecSubMounts }] = TRFactory.sectionSubMountsRxQ(this);
	this.refresh = () => revSecSubMounts();
	this.mutate = mutate;

	EffectService.setup(
		this,
		({ onChanges, onInit }) => {
			if (onChanges && this.section) {
				if (this.asDialog === true) return;
				mutate(this.section);
			}

			if (onInit) {
				secSubMounts$.subscribe(({ data, error, loading }) => {
					this.isReady = !loading;
					if (error) return ErrorService.handleError(error);
					if (!data) return;
					this.subMounts = data.plain();
				});
			}
		},
		() => [this.section]
	);
}

sectionSubMountCharts.controller = sectionSubMountChartsController;

appModule.component('sectionSubMountCharts', sectionSubMountCharts);

export { sectionSubMountCharts };

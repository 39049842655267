import './saReportIndexCmp.scss';

import template from './saReportIndexCmp.tpl.html';

const saReportIndexCmp = {
	template,
	bindings: {},
};

class ComponentNameController {
	constructor(/** @type {ng.IScope} */ $scope) {
		this.legacyController($scope);
	}

	legacyController($scope) {
    $scope.isReady = true;
		$scope.reports = [
			{
				title: 'Terminal Progressive Report',
				toolbar: 'Generate Terminal Report',
				description: 'Generate the terminal progressive reports of all your chosen students',
				controller: 'SA_Report_Term_Report',
				name: 'term_report',
			},
		];
	}
}

saReportIndexCmp.controller = ComponentNameController;

appModule.component('saReportIndexCmp', saReportIndexCmp);
// export default component;

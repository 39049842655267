import template from './ToastCmp.tpl.html';

/** @type {import('@srcAjs/components2/adminRoutes').IComponentOptions2} */
const toastCmp = {
	template,
	bindings: {
		//someInput: '@string,<object,&function'
	},
	transclude: {
		// #slotName: '?optionalPublicName'
	},
	selector: 'toastCmp',
};

/** @type {import('angular').IController} */
function toastCmpController(/** @type {ErrorService} */ ErrorService, /** @type {EffectService} */ EffectService, /** @type {ng.IIntervalService} */ $interval) {
	/** @type {ParsedError[]} */
	this.alerts = null;

	/** @type {ReturnType<$interval>} */
	this.alertsInterval = null;

	EffectService.setup(this, ({ onInit, takeUntilDestroyCb }) => {
		if (onInit) {
			takeUntilDestroyCb(ErrorService.alerts$).subscribe({
				next: (alerts) => {
					console.log({ alerts });
					this.alerts = alerts;
					this.popper();
				},
				error: (error) => ErrorService.handleError(error),
			});
		}
	});

	this.pop = (/** @type {string}*/ uuid) => ErrorService.popAlert(uuid);

	this.popper = (alerts = this.alerts) => {
		const intervalBusy = !!this.alertsInterval;
		console.log({ intervalBusy });

		if (intervalBusy) {
			if (!this.alerts?.length) {
				$interval.cancel(this.alertsInterval);
			}
			// console.log('skip making new interval');
			return;
		}

		this.alertsInterval = $interval(() => {
			if (!this.alerts?.length || this.alerts.length <= 1) {
				// console.log('alertsInterval.clearInterval');
				$interval.cancel(this.alertsInterval);
				this.alertsInterval = null;
			}

			if (this.alerts.length) this.pop(this.alerts.at(0).uuid);
		}, 8_000);
	};

	this.clearAllAlerts = (alerts = this.alerts, alertsInterval = this.alertsInterval) => {
		if (alertsInterval) {
			$interval.cancel(alertsInterval);
		}

		if (alerts) {
			ErrorService.clearAllAlerts();
		}
	};
}

toastCmp.controller = toastCmpController;

appModule.component('toastCmp', toastCmp);

export { toastCmp };

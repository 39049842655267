import template from './index.html';

/** @type {import('@srcAjs/components2/adminRoutes').IComponentOptions2} */
const saSchoolSetupClassGroupsDetail = {
	template,
	bindings: {
		//someInput: '@string,<object,&function'
	},
	transclude: {
		// #slotName: '?optionalPublicName'
	},
};



function saSchoolSetupClassGroupsDetailCmpController(
	SAFactory,
	$scope,
	$cookies,
	$stateParams,
	$state,
	$rootScope,
	$localStorage,
	$mdDialog,
	$mdToast,
	lsfactory,
	Restangular,
	GradingSystem,
	ClassGroup
) {
	SAFactory.loadEnvironment();
	SAFactory.loadNotifications();
	lsfactory.setEnv('backLog', 'sa.school_setup_class_groups');
	$scope.class_group = lsfactory.getEnv('currentClassGroupSelected');
	$scope.isReady = false;

	if ($stateParams.id != '') {
		ClassGroup.one($stateParams.id)
			.get()
			.then(
				function (res) {
					var obj_sub = res.plain();
					$scope.class_group = res;
				},
				function (err) {
					if (err.status == 404) {
						$state.go('sa.school_setup_class_groups');
					}
				}
			);
	} else {
		$state.go('sa.school_setup_class_groups');
	}

	$rootScope.$watch('isReady', function (newValue, oldValue) {
		if (newValue !== undefined) {
			$scope.isReady = newValue;
		}
	});
}

saSchoolSetupClassGroupsDetail.controller = saSchoolSetupClassGroupsDetailCmpController;

appModule.component('saSchoolSetupClassGroupsDetail', saSchoolSetupClassGroupsDetail);

saSchoolSetupClassGroupsDetail.selector = 'saSchoolSetupClassGroupsDetail';

export { saSchoolSetupClassGroupsDetail }

import '../DialogService';
import './ManageChargeTemplatesCmp.scss';

import { Subscription } from 'rxjs';

import { chargeTemplateService } from '../ChargeTemplateService';
import template from './ManageChargeTemplatesCmp.tpl.html';

/** @typedef {import('../ChargeTemplateService').Charge} Charge */
/** @typedef {import('../ChargeTemplateService').ChargeTemplate} ChargeTemplate */

const manageChargeTemplate = {
	template,
	bindings: {},
};

class manageChargeTemplateController {
	/** @type {chargeTemplateService} */
	ChargeTemplateService;

	chargeTemplates;

	/** @type {ChargeTemplate} */
	tempChargeTemplate = { term_id: undefined, charges: [], meta: undefined, title: undefined, id: undefined };

	/** @type {Charge} */
	tempCharge = { amount: undefined, charge_template_id: undefined, description: undefined, meta: undefined, title: undefined };

	selectedTerm = null;

	selectedChargeTemplateID = null;

	/** @type {Subscription[]}*/ subs = [];

	/** @type {DialogService} */
	#dialogService;

	#ErrorService;

	constructor(chargeTemplateService, DialogService, /** @type {ErrorService} */ ErrorService, /** @type {API} */ API) {
		this.#dialogService = DialogService;
		this.ChargeTemplateService = chargeTemplateService;
		this.#ErrorService = ErrorService;

		this.subs.push(
			this.ChargeTemplateService.chargeTemplates$.subscribe((chargeTemplates) => {
				this.chargeTemplates = angular.copy(chargeTemplates);
				const tempChargeTemplate = this.selectedChargeTemplateID
					? chargeTemplates.find((ct) => ct.id === this.selectedChargeTemplateID)
					: { term_id: this.selectedTerm?.id, charges: [] };

				this.tempChargeTemplate = angular.copy(tempChargeTemplate);
				this.resetTempCharge();
			})
		);

		const chargeTypesReq = API.wrapWithCache({ callable: true, restangularElement: (r) => r.all('charge-types') });
		chargeTypesReq()
			.getList()
			.then((cTypes) => {
				this.chargeTypes = cTypes.plain();
				this.resetTempCharge(this.chargeTypes);
			})

			.catch(this.#ErrorService.handleError);
	}

	$onInit() {
		this.ChargeTemplateService.dispatch('refresh');
	}

	$onDestroy() {
		this.subs.forEach((sub) => sub.unsubscribe());
	}

	upsertChargeTemplate(chargeTemplate) {
		/** @type {Promise}*/ (this.ChargeTemplateService.dispatch('upsertChargeTemplate', chargeTemplate))
			.then((chargeTemplate) => {
				this.selectedChargeTemplateID = chargeTemplate.id;
				this.tempChargeTemplate = angular.copy(this.chargeTemplates.find((ct) => ct.id === chargeTemplate.id));
				this.resetTempCharge();
			})
			.catch(this.#ErrorService.handleError);
	}

	deleteChargeTemplate(/** @type {ChargeTemplate}*/ chargeTemplate, $event) {
		$event.stopPropagation();
		this.#dialogService
			.dispatch('confirm', `Delete charge template ${chargeTemplate.title}`)
			.then(() => this.ChargeTemplateService.dispatch('deleteChargeTemplate', chargeTemplate.id));
	}

	upsertCharge(/** @type {Charge}*/ newCharge, chargeTemplate = this.tempChargeTemplate) {
		this.ChargeTemplateService.dispatch('upsertCharge', { ...newCharge, charge_template_id: chargeTemplate.id }, chargeTemplate).catch(this.#ErrorService.handleError);
	}

	deleteCharge(/** @type {Charge} */ charge, chargeTemplate = this.tempChargeTemplate) {
		this.#dialogService.dispatch('confirm', `Delete charge template (${charge.title})`).then(() => this.ChargeTemplateService.dispatch('deleteCharge', charge, chargeTemplate));
	}

	sumCharges(/** @type {Charge[]} */ charges) {
		return charges ? charges.reduce((acc, chargeItem) => acc + chargeItem.amount, 0) : 'n/a';
	}

	updateTerm(term) {
		this.tempChargeTemplate.term_id = term?.id;
		this.selectedTerm = angular.copy(term);
	}

	editChargeTemplate(chargeTemplate) {
		this.tempChargeTemplate = angular.copy({ ...chargeTemplate });
		this.selectedChargeTemplateID = chargeTemplate.id;
	}

	resetTempCharge(chargeTypes = this.chargeTypes) {
		this.tempCharge = {
			charge_type_id: chargeTypes.find((c) => c.title === 'CREDIT').id,
		};
	}

	resetTemplateCharge() {
		this.tempChargeTemplate = this.chargeTemplates.find((ct) => ct.id === this.tempChargeTemplate?.id) ?? {
			term_id: this.selectedTerm?.id,
			charges: [],
		};
	}
}

manageChargeTemplate.controller = manageChargeTemplateController;

appModule.component('manageChargeTemplates', manageChargeTemplate);

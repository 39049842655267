function RouterGuardService(/** @type {auth}*/ auth, /** @type {} */ CONST_ENTRY_STATES) {
	const transitionName = (transition) => /** @type {string}*/(transition.to().name);
	const transitionData = (transition) => /** @type {{allowedfn:undefined|((...any)=>boolean),notAllowedfn:undefined|((...any)=>boolean)}} */(transition.to()?.data);
	const protectedTransition = (transition) =>
		Object.keys(CONST_ENTRY_STATES)
			.map((st) => st + '.')
			.some((state) => transitionName(transition).includes(state));

	const block = (transition) =>
		transition.router.stateService.target(CONST_ENTRY_STATES[auth.getEngagedUserRole()?.role?.short] ?? 'access.signin', auth.getEngagedUserRole(), { location: 'replace' });

	const stateTransitions = {
		isAllowed: (transition) => (transitionData(transition)?.allowedfn ?? function () { })(transition.to(), auth.getEngagedUserRole(), auth),

		isNotAllowed: (transition) => (transitionData(transition)?.notAllowedfn ?? function () { })(transition.to(), auth.getEngagedUserRole(), auth),

		isProtected: (transition) => protectedTransition(transition) && auth.isSignedIn() && transitionName(transition).includes(auth.getEngagedUserRole().role.short),
		unProtected: (transition) => transitionName(transition).includes('access.') && auth.isSignedOut(),
		isSignedIn: (transition) => auth.isSignedIn(),
		isSignedOut: (transition) => auth.isSignedOut(),
	};

	this.allowTransition = (transition) => {
		if (stateTransitions.isAllowed(transition) === true) {
			return true;
		}

		if (stateTransitions.isNotAllowed(transition) === true) {
			return block(transition);
		}

		if (stateTransitions.isProtected(transition) === true) {
			return true;
		}

		if (stateTransitions.unProtected(transition) === true) {
			return true;
		}

		return block(transition);
	};

	return this;
}

appModule.factory('RouterGuardService', RouterGuardService);

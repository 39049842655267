import template from './index.html';

/** @type {import('@srcAjs/components2/adminRoutes').IComponentOptions2} */
const saOperationsApproveScoresIndex = {
	template,
	bindings: {
		// someInput: '@string,<object,&function'
	},
	transclude: {
		// #slotName: '?optionalPublicName'
	},
};

function saOperationsApproveScoresIndexCmpController($scope, $mdToast, API, ErrorService, Restangular, SAFactory, lsfactory, $mdDialog) {
	SAFactory.initializeRole();
	SAFactory.loadEnvironment();
	const svm = $scope;

	svm.selectedTerm = null;
	svm.selectedTermObect = null;
	svm.current_state = null;
	svm.selected_class = null;
	svm.selected_section = null;
	svm.selected_subject_mount = null;
	svm.classes = [];
	svm.school = lsfactory.getEnv('school');
	svm.visible_section = 'show_initial';
	svm.fetchApproved = false;
	svm.approveAssessmentGroups = approveAssessmentGroups;

	svm.fetchClassSectionAssessmentsScores = fetchClassSectionAssessmentsScores;

	function fetchClassSectionAssessmentsScores(school = svm.school, term = svm.term, approved = true) {
		if (!school || !term) return;

		svm.fetchApproved = approved;
		svm.isReady = false;
		svm.selected_class = null;
		svm.selected_section = null;
		svm.selected_subject_mount = null;

		svm.visible_section = !approved ? 'show_initial' : 'show_classes';

		Restangular.all(`approve_scores/school/${school.id}/term/${term.id}/approved/${Number(approved)}`)
			.getList()
			.then((data) => data.plain().flatMap((cg) => cg.classes))
			.then(parseClasses, ErrorService.handleError)
			.finally(() => (svm.isReady = true));
	}

	svm.selectClass = (_class) => {
		svm.selected_class = _class;
		svm.visible_section = 'show_class_sections';
		svm.approvable_assessment_group_ids = _class.sections.flatMap((section) => section.subject_mounts.flatMap((sm) => sm.subject_assessment_groups.flatMap(({ id }) => ({ id }))));
		svm.approvable_assessment_entities_count = _class.sections
			.flatMap((section) => section.subject_mounts.flatMap((sm) => sm.subject_assessment_groups.flatMap((sag) => sag.subject_assessment_entities_count)))
			.reduce((acc, count) => acc + count, 0);
	};

	const mapClassUnApproved = (_class) => ({
		..._class,
		sections: _class.sections.map((section) => {
			section.assessment_entities_count = section.subject_mounts
				.flatMap((sm) => sm.subject_assessment_groups.flatMap((sag) => sag.subject_assessment_entities_count))
				.reduce((acc, count) => acc + count, 0);
			return section;
		}),
	});

	svm.selectClassSection = (class_section) => {
		svm.selected_section = {
			...class_section,
			subject_mounts: class_section.subject_mounts.map((sm) => {
				sm.assessment_entities_count = sm.subject_assessment_groups.flatMap((sag) => sag.subject_assessment_entities_count).reduce((acc, count) => acc + count, 0);
				return sm;
			}),
		};
		svm.approvable_assessment_group_ids = class_section.subject_mounts.flatMap((sm) => sm.subject_assessment_groups.flatMap(({ id }) => ({ id })));
		svm.approvable_assessment_entities_count = class_section.assessment_entities_count;

		svm.visible_section = 'show_subject_mounts';
	};

	svm.selectSubMount = (subm, fetchApproved) => {
		svm.isReady = false;
		const params = {
			filters: [{ condition: 'where', column: 'id', value: subm.id }],
			has_fields: null,
			fields: [
				{
					field: 'subject_assessment_groups.subject_assessment_entities',
					condition: 'with',
					conditions: [
						{ condition: 'where', column: 'in_review', value: Number(true) },
						{ condition: 'where', column: 'approved', value: Number(fetchApproved) },
					],
				},
				{
					field: 'term_students.section_history.student',
					condition: 'with',
				},
			],
		};

		Restangular.all(`subject-mount?${API.buildParams(params.filters, params.has_fields, params.fields, null, '1.4')}`)
			.getList()
			.then((res) => {
				subm = { ...subm, ...res.plain()[0] };
				svm.selected_subject_mount = subm;
				svm.approvable_assessment_group_ids = svm.selected_subject_mount.subject_assessment_groups.flatMap(({ id }) => ({ id }));
				svm.approvable_assessment_entities_count = svm.selected_subject_mount.subject_assessment_groups
					.map((sag) => sag.subject_assessment_entities.length)
					.reduce((acc, count) => acc + count, 0);

				svm.visible_section = 'show_assessment_entities';
			}, ErrorService.handleError)
			.finally(() => (svm.isReady = true));
	};

	svm.selectAssessmentGroup = (ag) => (svm.selected_assessment_group = ag);

	svm.postApprove = (subm) => (assessment_entity) => {
		if (!assessment_entity) return;

		const template = $mdToast.simple().textContent('Assessment Approved').action('OK').highlightAction(true).hideDelay(5500);
		$mdToast.show(template);

		const ag_index = subm.subject_assessment_groups.findIndex((ag) => +ag.id === +assessment_entity.subject_assessment_group_id);

		const aes = subm.subject_assessment_groups[ag_index].subject_assessment_entities.filter((ae) => ae.id !== assessment_entity.id);
		subm.subject_assessment_groups[ag_index] = {
			...subm.subject_assessment_groups[ag_index],

			subject_assessment_entities: aes,
			subject_assessment_entities_count: aes.length,
		};

		svm.approvable_assessment_entities_count -= 1;
	};

	svm.$on('approve_scores.data_changed', (event, data) => {
		if (data !== undefined) {
			fetchClassSectionAssessmentsScores(svm.school, data, false);
		}
	});

	svm.updateTerm = (term) => {
		if (!term) return;

		svm.term = term;
		svm.selectedTerm = term.id;
		svm.visible_section = 'show_initial';

		fetchClassSectionAssessmentsScores(svm.school, term, svm.fetchApproved);
	};

	const parseClasses = (classes) => {
		svm.classes = classes
			.map(mapClassUnApproved)
			.map((_class) => ({
				..._class,
				assessment_entities_count: _.map(_class.sections, 'assessment_entities_count').reduce((acc, val) => acc + val, 0),
			}))
			.filter((_class) => _class.assessment_entities_count > 0);

		svm.total_assessment_entities_count = svm.classes.reduce((acc, _class) => acc + _class.assessment_entities_count, 0);
		svm.total_class_sections = svm.classes.flatMap((_class) => _class.sections.flatMap((sect) => sect.assessment_entities_count)).filter((value) => Boolean(value)).length;
	};

	function approveAssessmentGroups(approvable_assessment_group_ids, approval = true) {
		$mdDialog
			.show(
				$mdDialog.confirm({
					title: 'Approve multiple assessments',
					textContent: `I confirm that i am approving ${svm.approvable_assessment_entities_count} total assessments`,
					ok: 'Yes!',
					cancel: 'Nope',
				})
			)
			.then((yes) => {
				if (!yes) return;

				svm.isReady = false;

				return Restangular.all('assessment/approve')
					.post({
						assessment_groups: approvable_assessment_group_ids,
						current_approval: 0,
						in_review: 1,
						assessment_entities_approval: approval,
					})

					.then(
						(data) => {
							$mdToast.showSimple(`Assessment entities approved: ${data.plain().updated_count}`);

							return fetchClassSectionAssessmentsScores(svm.school, svm.term, svm.fetchApproved);
						},
						(error) => {
							ErrorService.handleError(error);
							svm.isReady = true;
						}
					);
			});
	}
}

saOperationsApproveScoresIndex.controller = saOperationsApproveScoresIndexCmpController;

appModule.component('saOperationsApproveScoresIndex', saOperationsApproveScoresIndex);

saOperationsApproveScoresIndex.selector = 'saOperationsApproveScoresIndex';

export { saOperationsApproveScoresIndex };

import template from './AccessSigninCmp.tpl.html';

import './AccessSigninCmp.scss';

const accessSignupCmp = {
	template,
	bindings: {
		$transition$: '<',
	},
};

function SigninController(
	/** @type {import('angular-ui-router').IStateService} */ $state,
	$rootScope,
	CONST_ENTRY_STATES,
	/** @type {auth} */ auth,
	/** @type {ErrorService} */ ErrorService,
	/** @type {TRFactory} */ TRFactory,
	/** @type {SAFactory} */ SAFactory,
	/** @type {ReturnType<makeAAFactory>} */ AAFactory,
	/** @type {ReturnType<makePAFactory>} */ PAFactory
) {
	const vm = this;
	const entry_map = CONST_ENTRY_STATES;

	const roleViewInitializer = {
		sa: SAFactory.initializeRole,
		tr: TRFactory.loadEnvironment,
		aa: AAFactory.initializeRole,
		pa: PAFactory.initializeRole,
	};

	window.data_changed = false;

	vm.box = {
		username: '',
		password: '',
	};

	vm.code = undefined;
	vm.show_password = false;

	vm.$onInit = () => {
		// vm.code = vm.$transition$.params()?.country?.iso_3166_2 ?? vm.$transition$.params()?.country_code ?? $localStorage?.code;
		// if (!vm.code) {
		// 	return $state.go('access.choose_country', { origin: 'signin' });
		// }
	};

	vm.errorShow = false;
	vm.isLoading = false;
	vm.showPanel = true;
	vm.showError = function () {
		vm.errorShow = true;
	};

	vm.hideError = function () {
		vm.errorShow = false;
	};
	vm.showLoading = function () {
		vm.isLoading = true;
	};
	vm.hideLoading = function () {
		vm.isLoading = false;
	};

	/**
	 *
	 * @param {Event} event
	 */
	vm.startAuth = function (event) {
		event.preventDefault();

		const credentials = { username: vm.box.username, password: vm.box.password };
		$rootScope.allowSub = true;
		vm.showLoading();
		vm.hideError();

		auth
			.login(credentials)
			// .then(() => auth.getUser())
			.then((data) => auth.loadUserModule(data, $rootScope, $state, entry_map, roleViewInitializer))
			.catch(ErrorService.handleError)
			.finally(() => {
				$rootScope.allowSub = false;
				vm.hideLoading();
			});
	};

	vm.toggleShowPassword = (/** @type {boolean}*/ state) => {
		vm.show_password = !state;
		console.log('toggleShowPassword.fird', state, vm.show_password);
	};
}

accessSignupCmp.controller = SigninController;

appModule.component('accessSigninCmp', accessSignupCmp);
// component.controller = ComponentNameController;

// export default component;

import template from './index.html';

/** @type {import('@srcAjs/components2/adminRoutes').IComponentOptions2} */
const saSchoolSetupClassesSubSectionsEnrolledStudents = {
	template,
	bindings: {
		//someInput: '@string,<object,&function'
	},
	transclude: {
		// #slotName: '?optionalPublicName'
	},
};

function saSchoolSetupClassesSubSectionsEnrolledStudentsCmpController(
	$scope,
	ErrorService,
	$mdToast,
	$mdDialog,
	$state,
	SadminSetupClassLevelStore,
	$localStorage,
	lsfactory,
	Restangular
) {
	const svm = $scope;
	svm.sel_class = SadminSetupClassLevelStore.get('selected_sclass');
	svm.sel_section = SadminSetupClassLevelStore.get('selected_section');
	svm.sel_term = SadminSetupClassLevelStore.get('selected_term');

	svm.head_term_id = svm.sel_term.id;

	svm.school = lsfactory.getEnv('school');
	svm.isLoading = true;
	svm.school_students = [];

	var r_years = Restangular.all('year/by-school/' + svm.school.id);
	r_years.getList().then(
		function (data) {
			var list = data.plain();
			svm.years = list;
		},
		(err) => {
			console.log('Errors', err);
		}
	);

	svm.next = function (year) {
		var y = parseInt(year);
		return y + 1;
	};

	svm.openBroadSheet = function (term_id, section_id) {
		// alert("Open Broadsheet for Term ID: "+term_id+" AND Section ID: "+section_id +" at "+$localStorage.base_url);
		window.open($localStorage.base_url + '/broadsheet?section_id=' + section_id + '&term_id=' + term_id + '', '_blank');
	};

	svm.showEnrollmentDialog = function () {
		lsfactory.setEnv('dialogSelectedTerm', svm.sel_term); // Send something to the dialog
		$mdDialog.show({
			escapeToClose: true,
			clickOutsideToClose: true,
			templateUrl: 'views/pages/roles/sa/school_setup/classes/dialogs/enroll_student.html',
			controller: EnrollStudentDialogController,
		});
	};

	svm.$on('get-rid-of-send-student', function (ev, data) {
		var lookup = null;
		for (var i = 0; i < svm.students.length; i++) {
			if (svm.students[i].id == data.sh_id) {
				lookup = i;
			}
		}
		if (lookup != null) {
			svm.students.splice(lookup, 1);
			var template = $mdToast.simple().textContent('Student Moved Successfully').action('OK').highlightAction(true).hideDelay(5500);
			$mdToast.show(template);
		}
	});

	svm.$watch('term_id', function (newValue) {
		if (newValue !== undefined) {
			svm.isLoading = true;
			svm.students = [];
			// getStudentsDeprecated();
			getSectionInfoNew({ id: newValue }, svm.sel_section);
		}

		function getSectionInfoNew(_term, _section) {
			let url = `section?fields[]=section_histories&fields[]=section_histories_ranking&fields[]=teachers&fields[]=subject_mounts&filter[term_id]=${_term.id}&filter[section_ids][]=${_section.id}`;
			console.log(url);

			Restangular.all(url)
				.getList()
				.then((res) => res.plain())
				.then(([section]) => {
					svm.section_detailed = section;
					svm.students = section.section_histories_ranking;
					svm.subject_mounts = section.subject_mounts;
				})
				.catch((error) => ErrorService.handleError(error))
				.finally(() => {
					svm.isLoading = false;
				});
		}
	});

	function trimMovedStudents(trimmed_students) {
		const trimmed_student_ids = trimmed_students.map((student) => student.student_id);
		svm.students = svm.students.filter((student) => !trimmed_student_ids.includes(student.student_id));
	}

	function setSchoolStudents(students) {
		svm.school_students = students;
	}

	function reEnrollWithdrawnStudent(student) {
		var new_section = {
			student_id: student.id,
			term_id: svm.sel_term.id,
			section_id: svm.sel_section.id,
			reason: 'RE_ENROLL_WITHDRAWN_STUDENT',
		};

		console.log('new section_history', new_section);
		return Restangular.oneUrl('create_section_history', '')
			.post('', new_section)
			.then(
				(response) => {
					svm.students = [...svm.students].concat(response.plain()['new-sub-hist']);
					return response.plain()['new-sub-hist'];
				},
				(err) => {
					console.log('Errors', err);
				}
			);
	}

	svm.showStudentDialog = function (student) {
		console.dir(student);
		lsfactory.setEnv('dialogCurrentSelectedStudent', student);
		$mdDialog.show({
			escapeToClose: true,
			locals: {
				StudentList: [...svm.students],
				TrimMovedStudents: trimMovedStudents,
			},
			clickOutsideToClose: true,
			controller: ShowStudentEnrollDialogController,
			templateUrl: 'views/pages/roles/sa/school_setup/years/dialogs/show_student_enroll.html',
		});
	};

	svm.showReEnrollWithdrawnStudent = function () {
		// console.dir(student);
		// lsfactory.setEnv('dialogCurrentSelectedStudent', student);
		$mdDialog.show({
			escapeToClose: true,
			locals: {
				SchoolEnv: svm.school,
				ReEnrollWithdrawnStudent: reEnrollWithdrawnStudent,
				SetSchoolStudents: setSchoolStudents,
				SchoolStudents: svm.school_students,
			},
			clickOutsideToClose: true,
			controller: ShowReEnrollWithdrawnStudentDialogController,
			templateUrl: 'views/pages/roles/sa/school_setup/years/dialogs/show_re_enroll_withdrawn_student.html',
		});
	};

	svm.updateTermHead = (term) => {
		svm.term_id = term.id;
		svm.sub_term_id = term.id;
	};
	svm.updateTermSub = (term) => {
		svm.term_id = term.id;
		svm.head_term_id = term.id;
	};

	svm.attempSubjectMountDelete = (sub_mount) => {
		if (!sub_mount) return ErrorService.handleError(new Error('Invalid sub_mount parameter'));

		$mdDialog
			.show($mdDialog.confirm({ textContent: `Sure you want to delete ${sub_mount.subject.title}`, ok: 'Yes please!', cancel: 'Nope' }))

			.then(() => {
				svm.isLoading = true;

				Restangular.one('subject-mount', sub_mount.id)
					.remove()
					.then(() => {
						$state.reload();
					});
			})
			.catch(ErrorService.handleError)
			.finally(() => (svm.isLoading = false));
	};

	svm.disAllowTermHeadFn = (term) => {
		if (!term) return;

		if (!(svm.sel_term?.term_type?.version == '1.2')) return term?.term_type?.version == '1.2';

		return term.id != svm.sel_term.id;
	};
	$state.current.data.title = svm.sel_class.class_level.title + ' ( ' + svm.sel_section.title + ' )';
}

saSchoolSetupClassesSubSectionsEnrolledStudents.controller = saSchoolSetupClassesSubSectionsEnrolledStudentsCmpController;

appModule.component('saSchoolSetupClassesSubSectionsEnrolledStudents', saSchoolSetupClassesSubSectionsEnrolledStudents);

saSchoolSetupClassesSubSectionsEnrolledStudents.selector = 'saSchoolSetupClassesSubSectionsEnrolledStudents';

export { saSchoolSetupClassesSubSectionsEnrolledStudents };

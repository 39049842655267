/*
 * Import the functions you need from the SDKs you need
 * import { getAnalytics } from "firebase/analytics";
 */
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

import { config } from './firebase.config';
import Pusher from 'pusher-js';

// @ts-ignore
window.Pusher = Pusher;

/*
 * TODO: Add SDKs for Firebase products that you want to use
 * https://firebase.google.com/docs/web/setup#available-libraries
 */

/** @typedef {fcmMessagingService} fcmMessaging */

function fcmMessagingService(
	/** @type {import('restangular').IService} */ Restangular,
	/** @type {ng.material.IToastService} */ $mdToast,
	$analytics,
	/** @type {ErrorService} */ ErrorService,
	/** @type {NotificationService} */ NotificationService,
	/** @type {ng.IQService} */ $q,
	/** @type {AppStore} */ AppStore,
	/** @type {auth} */ auth,
	/** @type {configService} */ configService,
	/** @type {ng.IWindowService} */ $window,
	/** @type {DialogService} */ DialogService
) {
	/** @type {{token, subscriptions} & typeof this} */
	const svm = this;

	/** @typedef {import('pusher-js').Options} Options */
	const pusherAuth = /** @satisfies { Options['channelAuthorization']|Options['userAuthentication']} */ {
		transport: 'ajax',
		endpoint: configService.config.pusherConfig.authEndpoint,
		headers: {},
		headersProvider: () => {
			return {
				authorization: `bearer ${auth.authData$.value.token}`,
			};
		},
	};

	this.pusher = new Pusher(configService.config.pusherConfig.key, {
		...configService.config.pusherConfig,
		channelAuthorization: pusherAuth,
		userAuthentication: pusherAuth,
	});

	this.pusher.connection.bind('error', (error) => ErrorService.handleError(error?.data?.message, $window.location.href.includes('cy-test')));

	// const subscriptions = [];
	svm.subscriptions$ = AppStore.selector((s) => s.fcmSlice);
	const subscriptions$ = svm.subscriptions$;

	// Initialize Firebase
	const app = initializeApp(config.firebaseConfig);
	const /** @type {ReturnType<getMessaging>} */ messaging = getMessaging(app);

	svm.unsubscribe = (_subscriptions = subscriptions$.value.subscriptions) => {
		console.log('fcmMessaging.Unsubscribing from existing subscriptions: ', _subscriptions.length);

		while (subscriptions$.value.subscriptions.length) {
			console.log('fcm unsubbing');
			_subscriptions.pop()();
			AppStore.dispatch(AppStore.actions.fcmSlice.popSubscription(undefined));
		}
	};

	svm.setupListener = (subscriptions = subscriptions$.value.subscriptions) => {
		if (subscriptions.length >= 2) {
			return subscriptions;
		}

		console.log('fcmMessaging.setuplistener, existing', subscriptions.length);
		const sub = onMessage(messaging, (payload) => {
			console.log('fcmPushMessage:recieved. ', payload);

			if (!['body', 'title'].every((key) => key in payload.data)) {
				return;
			}

			const { title = 'Error: title not found', body = 'Error: body not found' } = payload.data;
			$analytics.eventTrack('fcm_message_delivered', { label: payload.collapseKey });
			$mdToast.show(
				$mdToast
					.simple()
					.textContent(title + '. ' + body)
					.position('top left')
					.hideDelay(15000)
					.highlightAction(true)
					.action('OK')
					.theme('md-primary')
			);
			NotificationService.refresh(true);
		});
		AppStore.dispatch(AppStore.actions.fcmSlice.pushSubscription(sub));

		console.log('fcmMessaging.setupListener completed, new subscriptions: ', subscriptions$.value);
		return subscriptions$.value.subscriptions;
	};

	svm.subscribe = (/** @type {EngagedUserRole} */ engagedUserRole, subscriptions = subscriptions$.value.subscriptions, token = subscriptions$.value.token) => {
		const deferred = $q.defer();

		if (!engagedUserRole || auth.isSignedOut() || !auth.isTokenOwner()) {
			deferred.reject();
			return deferred.promise;
			throw new IError('fcmMessaging.subscribe error: invalid args', { user: engagedUserRole });
		}

		/*
		 * Get registration token. Initially this makes a network call, once retrieved
		 * subsequent calls to getToken will return from cache.
		 */
		/*
		 * messaging
		 * 	.requestPermission()
		 * 	.then((permission) => {
		 * 		console.log('permission obtained', permission);
		 * 	})
		 * 	.catch((err) => ErrorService.handleError(err));
		 */
		new Promise((res, rej) => {
			//subscribe when there is no token
			if (!subscriptions?.length) {
				console.log('fcmMessaging.subbing');
				svm.setupListener(subscriptions);
			}
			res();
		})
			.then(() => {
				const getNewFcmMessagingToken = engagedUserRole.user_id !== subscriptions$.value.userToken.user_id;
				console.log({ getNewToken: getNewFcmMessagingToken });

				if (getNewFcmMessagingToken) {
					return getToken(messaging, { vapidKey: process.env.__CONST_PWA_VAPIDKEY__ });
				}

				return subscriptions$.value.userToken.token;
			})

			.then((currentToken) => {
				const noToken = !currentToken;

				console.log({ noToken });
				if (noToken) {
					deferred.reject({ noToken });
					return console.log('No registration token available. Request permission to generate one.');
					/*
					 * Send the token to your server and update the UI if necessary
					 * let user = lsfactory.getEnv("user")
					 */

					// ...
				}
				console.log({ currentToken });

				const isUserIntentLoggedIn = engagedUserRole.user_id === auth.authData$.value.user.user_instance.id;
				console.log({ isUserIntentLoggedIn, user: engagedUserRole });

				if (!isUserIntentLoggedIn) {
					return deferred.reject({ isUserIntentLoggedIn });
				}

				const tokenUserComboRegistered = currentToken === subscriptions$.value.userToken.token && auth.isTokenOwner();
				console.log({ tokenUserComboRegistered });

				if (tokenUserComboRegistered) {
					return deferred.resolve({ tokenUserComboRegistered });
				}

				const { platform, vendor, appVersion } = $window.navigator;

				Restangular.all('cloud-messaging')
					.post({
						user_id: engagedUserRole.user_id,
						token: currentToken,
						device: [platform, vendor, appVersion].join(' -- '),
						email: engagedUserRole?.user?.email,
					})
					.then(() => deferred.resolve({ token: currentToken, subscriptions }))
					.then(() => {
						AppStore.dispatch(AppStore.actions.fcmSlice.setUserToken({ token: currentToken, user_id: engagedUserRole.user_id }));
					})
					.catch((err) => {
						ErrorService.handleError(err);
						deferred.reject(new IError(err));
					});
			})
			.catch((error) => {
				ErrorService.handleError(error, true);
				deferred.reject(new IError(error.message, error));
			});

		return deferred.promise;
	};

	this.listenToAppChannels = () => {};

	this.listenToAppChannels();
}

appModule.service('fcmMessaging', fcmMessagingService);

// const analytics = getAnalytics(app);

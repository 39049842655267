import template from './index.html';

/** @type {import('@srcAjs/components2/adminRoutes').IComponentOptions2} */
const saStudentsIndex = {
	template,
	bindings: {
		//someInput: '@string,<object,&function'
	},
	transclude: {
		// #slotName: '?optionalPublicName'
	},
};



function saStudentsIndexCmpController(
	SAFactory,
	auth,
	$scope,
	$cacheFactory,
	$state,
	ErrorService,
	$timeout,
	Restangular,
	lsfactory,
	$mdBottomSheet,
	$mdToast,
	ClassGroup
) {
	SAFactory.loadEnvironment();
	SAFactory.loadNotifications();
	$scope.students = [];
	$scope.selected_students = [];
	$scope.groups = [];
	$scope.filter_current_term = 'active';
	$scope.search_student = '';
	$scope.obj = { selectedItem: null };
	$scope.selectedCount = 0;
	$scope.showOne = false;
	$scope.isLoading = true;
	$scope.school = lsfactory.getEnv('school');
	lsfactory.setEnv('backLog', 'sa.dashboard');
	$scope.enrollment_states = lsfactory.getEnv('school').enrollment_states;
	$scope.show_student_image = false;
	$scope.class_selected = false;
	$scope._term;
	let cache = $cacheFactory.get('students_cache') ?? $cacheFactory('students_cache');
	$scope.isReady = true;

	function fetchClassLevels(refresh = false) {
		$scope.isLoading = true;
		ClassGroup.get($scope.school, $scope._term, ['sections'], '1.2', cache, refresh)
			.then((sclasses) => {
				$scope.groups = sclasses;
			})
			.catch(ErrorService.handleError)
			.finally(() => {
				$scope.isLoading = false;
			});
	}

	$scope.setFilter = (query) => {
		$scope.search_student = query;
	};

	$scope.setFilterCurrentTerm = (term) => {
		if (!term) return;

		$scope._term = term;
		$scope.filter_current_term = term.id;
		$scope.clearStudents();
		fetchClassLevels();
	};

	$scope.getStudentsRefreshed = async ({ group, term, refresh }) => {
		console.log('call to refresh', refresh, !!refresh);
		if (!refresh) return;

		$cacheFactory.get('students_cache').removeAll();

		switch (refresh) {
			case 'ALL_CLASS_GROUPS':
				$scope.getAllClassStudents({ groups: $scope.groups, term });
				break;
			case 'SPECIFIC_CLASS_GROUP':
				await getStudents({ group, term });

				break;
			default:
				$mdToast.showSimple('Select something and try again!');
		}
		$scope.refresh_type = null;
	};

	$scope.getStudents = getStudents;

	/**
	 * @typedef {{group:SClass, term:Term}} TermClass
	 * @param {TermClass} param0
	 * @returns
	 */
	async function getStudents({ group, term }) {
		$scope.clearStudents();
		$scope.isLoading = true;
		$scope.refresh_type = 'SPECIFIC_CLASS_GROUP';
		console.log($scope.refresh_type);

		const students = await getClassStudents(group, term);

		console.log('first half students ready', $scope.filtered_students);
		$scope.class_selected = group;
		$scope.isLoading = false;

		$timeout(() => {
			$scope.filtered_students = [...students];
			$scope.students = [...students];
		}, 10);

		return;
	}

	/**
	 * @typedef {{SectionHistory,id:string}} Student
	 * @typedef {Student[]} SectionStudents
	 * @param {SClass} group
	 * @param {Term} term
	 * @description get all the students for each section in a class and return them as one array with all the students
	 * @returns {Promise<SectionStudents[]>} an array of studets belonging to the sectionstudents
	 */
	async function getClassStudents(group, term) {
		let term_id = term;
		let section_histories_group = group.sections.map((section) => fetchSectionHistories(term_id, section.id));
		const response = await Promise.all(section_histories_group);
		let students = response
			.reduce((_students, section_history_group) => [..._students, ...section_history_group], [])
			.map(({ student, ...section_history }) => ({ ...student, section_history, section_history_id: section_history.id }));

		return students;
	}

	/**
	 * @typedef {{groups: SClass[], term: Term}} TermClasses
	 * @typedef {{}} Section
	 * @typedef {{sections: Section[]}} SClass
	 * @typedef {{}} Term
	 * @param {TermClasses} param0
	 */
	$scope.getAllClassStudents = function getAllClassStudents({ groups, term }) {
		$scope.clearStudents();
		$scope.isLoading = true;
		$scope.refresh_type = 'ALL_CLASS_GROUPS';
		let class_students_groups = groups.map((_class) => getClassStudents(_class, term));

		Promise.all(class_students_groups).then((_students) => {
			$scope.class_selected = true;
			$scope.isLoading = false;

			$scope.filtered_students = $scope.students = _students.reduce(
				(_class_group, class_students) => (_class_group = [..._class_group, ...class_students]),
				[]
			);
			$timeout(() => {}, 10);

			return;
		});
	};

	$scope.getStudentsByEnrollmentState = async (enrollment_state_id) => {
		$scope.clearStudents();
		$scope.isLoading = true;
		let filters = {
			enrollment_state_id,
			school_id: $scope.school.id,
		};

		try {
			$scope.filtered_students = await Restangular.all('get_student_enrollment_states')
				.post({ filters })
				.then((res) => res.plain());
		} catch (error) {
			console.log('failed to fetch students by enrollment state', error);
			ErrorService.handleError(error);
		} finally {
			$scope.isLoading = false;
			$timeout(() => {}, 10);
		}
	};

	/**
	 * @typedef {{}} SectionHistory
	 * @param {string} term_id
	 * @param {string} section_id
	 * @returns {Promise<SectionHistory[]>} return section histories of the section
	 */
	function fetchSectionHistories(term_id, section_id) {
		// 'section/{sect_id}/students/by-term/{tid}
		return Restangular.one('section', section_id)
			.all('students/by-term/' + term_id)
			.withHttpConfig({ cache })
			.getList()
			.then((res) => res.plain());
	}

	function fetchStudent(school_id, section_history) {
		return Restangular.one('section_history', section_history).get();
	}

	$scope.clearStudents = function () {
		$scope.filtered_students = [];
		$scope.class_selected = false;
	};

	$scope.reloadData = function () {
		if (!$scope.school.students_count) {
			$scope.isLoading = false;
			return; //abort if school has no students
		}

		$scope.isLoading = true;
		fetchClassLevels($scope.school.id);
	};

	// $scope.reloadData()
	$scope.$on('students.changeFired', function (event, bigData) {
		$scope.reloadData();
	});

	$scope.gotoImport = function () {
		$state.go('sa.students_import');
	};

	$scope.showBottomSheet = function () {
		lsfactory.setEnv('selectedStudents', $scope.selected_students);
		$mdBottomSheet.show({
			templateUrl: 'views/pages/roles/sa/students/bottom.index.html',
			controller: 'StudentsListBottomSheetCtrl',
		});
	};

	$scope.emptyList = function () {
		$scope.selected_students = [];
		// angular.forEach($scope.students, function(student) {
		//     student.selected = false;
		// });
		console.dir($scope.selected_students);
	};

	// TODO: Implement logic to detect when items in the list are being selected and how many ...
	// $scope.goto = async function (student) {
	// 	if (student !== undefined) {
	// 		$scope.isLoadingg = true

	// 		lsfactory.setEnv("currentStudentSelected", student)
	// 		lsfactory.setEnv("backLog", "sa.students_browse")
	// 		$state.go(, )
	// 	}
	// }

	function filterStudentByClassLevel(student) {
		if ($scope.obj.selectedItem == null) return true;

		// console.log('sections',$scope.obj.selectedItem.sections);
		let section_ids = $scope.obj.selectedItem.sections.map((section) => section.id);
		// console.log('section_ids',section_ids);
		let found_sections = student.section_histories.filter((sh) => section_ids.includes(sh.section_id));

		return found_sections.length;
	}

	function filterStudentByTerm(student) {
		// console.log('calling filterby term',this);

		let current_section_histories = student.section_histories.filter((sh) => {
			let not_found = ['active', 'ended', 'inactive'].includes(this.toLowerCase());
			if (!not_found.length)
				//this is same as term, which is passed in filter
				return true; //return true if term is not any of these

			// console.log('term value',this);
			return sh.term.status.localeCompare(this, 'en', { sensititvity: 'base' }) == 0;
		}, this);

		// console.log('found current sections',current_section_histories,$scope.filter_current_term);
		return current_section_histories.length;
	}

	$scope.filterStudents = function ({ group = false, students, term }) {
		$scope.filtered_students = [];
		$scope.isLoading = true;
		$scope.obj.selectedItem = group;
		let allowed_sections = null;

		if (group) {
			allowed_sections = group.sections.map((section) => section.id);
			students = students
				.map((student) => {
					let pstudent = { ...student };
					if (group) pstudent.section_histories = pstudent.section_histories.filter((sh) => allowed_sections.includes(sh.section_id));
					return pstudent;
				})
				.filter(filterStudentByClassLevel)
				.filter(filterStudentByTerm, term);
		}

		$scope.filtered_students = students;
		$scope.isLoading = false;

		// console.log('filtered students',students);
	};
}

saStudentsIndex.controller = saStudentsIndexCmpController;

appModule.component('saStudentsIndex', saStudentsIndex);

saStudentsIndex.selector = 'saStudentsIndex';

export { saStudentsIndex }

import 'nvd3/build/nv.d3.min.css';
import 'd3';
import 'angular-nvd3';

import template from './ChartBasicCmp.tpl.html';

/** @type {import('@srcAjs/components2/adminRoutes').IComponentOptions2} */
const chartBasicCmp = {
	template,
	bindings: {
		//someInput: '@string,<object,&function'
		itemHals: '<',
		title: '@',
		sortFields: '<',
		xLabel: '@',
		yLabel: '@',
		axisLabels: '<',
		streams: '<',
		typeLineMultiBar: '@',
		cType: '@',
		halx: '@',
		stacked: '<',
		staggerLabels: '<',
		cOptions: '<',
		labelX: '@',
	},
	transclude: {
		// #slotName: '?optionalPublicName'
	},
	selector: 'chartBasicCmp',
};
/** @typedef {{itemHals:{[key:string]:any,type?:string|undefined,chartData:{title,low,high,avg,sum,values:number[]}}[],sortFields?:string[],id?,created_at?,updated_at?}} ItemHal*/

const initialPad = { title: 'origin', chartData: { values: [0, 0, 0], sum: 0, avg: 0, low: 0, high: 0, label: 'initial	' } };

/** @this {ng.IController} */
function chartBasicCmpController(
	/** @type {EffectService} */ EffectService,
	/** @type {ng.IFilterFilter} */ $filter,
	$scope,
	/** @type {compSetupService} */ compSetupService,
	/** @type {ng.IWindowService} */ $window,
	/** @type {ng.ITimeoutService} */ $timeout
) {
	compSetupService(this, /** @type {ItemHal&  chartBasicCmp['bindings']} */ (null));
	const vm = this;

	this.options = undefined;
	this.data = undefined;

	EffectService.setup(
		this,
		({ onChanges, onInit }) => {
			if (onInit) {
				return this.clearFloatingLabels;
			}

			if (onChanges && Array.isArray(this.itemHals) && this.halx) {
				if (this.cType) vm.initChart(this.cType ?? 'hal');
			}
		},
		() => [this.itemHals, this.streams, this.halx, this.cType, this.xLabel, this.yLabel, this.options, this.axisLabels]
	);

	this.clearFloatingLabels = () => $window.document.querySelectorAll('.nvtooltip')?.forEach((e) => e.remove());

	this.resize = (api) => {
		if (!api) return;

		this.api = api;
		$timeout(() => [api.update(), api.refresh()], 200);
	};

	const C_Makers = {
		hal: () => {
			const options = getOptions(true);
			options.chart.yAxis.axisLabel = options.chart.yAxis.axisLabel;
			options.chart.xAxis.axisLabel = options.chart.xAxis.axisLabel;
			return [options, getBars()];
		},
		median: () => {
			const options = getOptions();
			options.chart.type = 'lineChart';
			options.chart.yAxis.axisLabel = options.chart.yAxis.axisLabel ?? 'Median Score as %';
			options.chart.xAxis.axisLabel = options.chart.xAxis.axisLabel ?? 'Assessments';
			options.chart.showLegend = false;
			return [options, getMedians()];
		},
		frequencies: () => {
			const options = getOptions();
			options.chart.type = 'lineChart';
			options.chart.xAxis.tickFormat = (d) => d3.format('f')(d);
			options.chart.yAxis.axisLabel = options.chart.yAxis.axisLabel ?? 'No. of Students/Frequency';
			options.chart.xAxis.axisLabel = options.chart.xAxis.axisLabel ?? 'Score/Percentage %';
			options.chart.showLegend = false;
			options.chart.yAxis.tickValues = d3.range(0, this.itemHals?.[0]?.chartData?.values?.length ?? 0);
			return [options, getFrequencies()];
		},
	};

	this.initChart = (/** @type {keyof C_Makers} */ cType = this.cType) => {
		const [opts, data] = C_Makers[cType](cType === 'hal');
		vm.options = opts;
		vm.data = data;
	};

	/* Random Data Generator (took from nvd3.org) */
	function getBars() {
		const padedHals = [...(vm.typeLineMultiBar?.includes('line') ? [initialPad] : []), ...vm.itemHals];
		const res = (vm.itemHals?.stream ?? vm.streams ?? ['low', 'avg', 'high'])
			.map((keyOrStreamObj) => (typeof keyOrStreamObj === 'string' ? { key: keyOrStreamObj } : keyOrStreamObj))
			.map((keyOrStreamObj) => ({
				yAxis: 1,
				...keyOrStreamObj,
				values: padedHals.map((hal, i) => ({
					x: i,
					y: hal.chartData[keyOrStreamObj?.key],
				})),

				// for pieChart
				get y() {
					return (keyOrStreamObj.fnPieY ?? ((/** @type {{y:number}[]} */ vals) => vals.reduce((acc, v) => acc + (v?.y ?? 0), 0)))(this.values);
				},
				x: keyOrStreamObj.key,
			}));

		return res;
	}

	this.toggleLegend = (_data = this.data) => {
		_data.chart.showLegend = !_data?.chart?.showLegend;
		this.data = _data;
	};

	function getFrequencies() {
		return vm.itemHals.map((hal) => ({
			key: `${hal[vm.halx]}-frequncy`,
			type: 'line',
			area: true,
			visible: true,
			values: d3.range(0, 101, 5).map((x) => ({
				x,
				y: hal.chartData.values.filter((y) => x <= y && y <= x + 5).length,
			})),
		}));
	}
	function getMedians() {
		const padedHals = [...(vm.typeLineMultiBar?.includes('line') ? [initialPad] : []), ...vm.itemHals];
		return vm.itemHals.map((hal) => ({
			key: `${hal[vm.halx]}-medians`,
			type: 'line',
			area: true,
			visible: true,
			values: padedHals.map((hal, i) => ({
				x: i,
				y: [...hal.chartData.values].sort().at(Math.floor(hal.chartData.values.length / 2)),
			})),
		}));
	}

	function getOptions(showControls = false) {
		return {
			chart: {
				type: `${vm.typeLineMultiBar}Chart`,
				height: 300,
				donut: true,
				margin: {
					top: 50,
					right: 20,
					bottom: 45,
					left: 45,
				},

				x: (d) => d.x,
				y: (d) => d.y,
				pie: {
					startAngle: function (d) {
						return d.startAngle / 2 - Math.PI / 2;
					},
					endAngle: function (d) {
						return d.endAngle / 2 - Math.PI / 2;
					},
				},
				isArea: (d) => d.area,
				clipEdge: true,
				staggerLabels: vm.staggerLabels ?? false,
				duration: 300,
				stacked: vm.stacked,
				useInteractiveGuideline: true,
				showControls,
				visible: true,
				// interpolation: 'linear',
				// useVoronoi: true,
				xAxis: {
					axisLabel: vm.xLabel ?? vm.axisLabels?.xLabel,
					// rotateLabels: 60,
					tickFont: 12,
					tickFormat: function (d, i) {
						// console.log({ d, r, i, tl: vm.itemHals[d][vm.halx] });
						try {
							return vm.itemHals?.at(d)?.[vm.halx ?? 'title'];
						} catch (err) {
							console.log(err, [d, vm.itemHals]);
						}
						// return d3.format(',f')(d);
					},
				},
				yAxis: {
					axisLabel: vm.yLabel ?? vm.axisLabels?.yLabel,
					axisLabelDistance: -20,
					tickFormat: function (d) {
						return d3.format(',f')(d);
					},
				},
				zoom: vm?.zoom ?? {},
				title: {
					enable: !!vm.title,
					text: vm.title,
				},
				...(vm.cOptions ?? {}),
			},
		};
	}
}

chartBasicCmp.controller = chartBasicCmpController;

appModule.component('chartBasicCmp', chartBasicCmp);

export { chartBasicCmp };

import './SubjectStudentsPageCmp.scss';

import template from './SubjectStudentsPageCmp.tpl.html';

/** @type {import('@srcAjs/components2/adminRoutes').IComponentOptions2} */
const subjectStudentsPageCmp = {
	template,
	bindings: {
		$transition$: '<',
	},
	selector: 'subjectStudentsPageCmp',
};

class subjectStudentsPageCmpController {
	$transition$;

	/** @type {import("restangular").IService} */ #Restangular;

	/** @type {ErrorService} */ #ErrorService;

	/** @type {ng.material.IDialogService} */ $mdDialog;

	/** @type {SubjectMount} */ subject_mount;

	/** @type {angular.IScope} */ #$scope;
	#TRFactory;

	constructor(
		Restangular,
		ErrorService,
		$state,
		$mdDialog,
		$scope,
		/**@type {TRFactory} */ TRFactory,
		/**@type {EffectService} */ EffectService,
		/**@type {atomosService} */ atomosService
	) {
		this.#Restangular = Restangular;
		this.#ErrorService = ErrorService;
		this.$state = $state;
		this.$mdDialog = $mdDialog;
		this.#$scope = $scope;
		this.#TRFactory = TRFactory;

		EffectService.setup(this, ({ onInit }) => {
			if (onInit) {
				atomosService(TRFactory.trServiceAtom.stream$, this).stream$.subscribe((atom) => {
					
					if (!atom.termsCache) return;

					const { subject_mount } = atom.selectCachedSubjectMount(this.$transition$.params().subject_mount_id);
					this.term_students = subject_mount.term_students;
					this.subject_mount = subject_mount;
				});
			}
		});
	}

	update = (/** @type {StudentTermSubject} */ subject_term_student) =>
		this.#Restangular
			.all('student-term-subject/' + subject_term_student.id)
			.customPUT(subject_term_student)
			.then((response) => {
				const term_students = this.term_students.map((ts) => {
					if (ts.id === response.id) {
						const { remarks, strengths } = response;
						ts = { ...ts, remarks, strengths };
					}

					return ts;
				});

				this.#TRFactory.updateAtom((atom) => {
					atom.termsCache[atom.selectedTermId].subject_mounts[subject_term_student.subject_mount_id].subject_mount.term_students = term_students;
					return { ...atom };
				});
			})
			.catch(this.#ErrorService.handleError);

	lunchStudentTermSubjectEditModal = (/** @type {StudentTermSubject} */ studentTermSubject, /** @type {SubjectMount} */ subjectMount, ev) => {
		this.$mdDialog
			.show({
				template: `
				<student-term-subject-edit-cmp student-term-subject='$ctrl.studentTermSubject' 
					subject-mount="$ctrl.subjectMount" on-update='$ctrl.onUpdate(studentTermSubject)' 
					on-hide="$ctrl.onHide(response)"
					layout
					layout-align="center start"
					flex="95" flex-gt-sm="40"
					>
					<dialog-debug flex="100">
						<span>{{$ctrl.subjectMount}}</span>
					</dialog-debug>
				</student-term-subject-edit-cmp>`,
				targetEvent: ev.currentTarget,
				clickOutsideToClose: false,
				locals: {
					studentTermSubject: angular.copy(studentTermSubject),
					subjectMount,
					onUpdate: (response) => this.update(response),
					childCmpName: 'studentTermSubjectEditCmp',
					onHide: (response) => this.$mdDialog.hide(response),
				},
				controller: 'DumbController',
				bindToController: true,
				controllerAs: '$ctrl',
				autoWrap: false,
			})
			.catch(this.#ErrorService.handleError);
	};

	reloadClassSectionData(/** @typer {Term} */ term, reload = true) {}
}

subjectStudentsPageCmp.controller = subjectStudentsPageCmpController;
// component.controller = ComponentNameController

appModule.component('subjectStudentsPageCmp', subjectStudentsPageCmp);
// export default component;

export { subjectStudentsPageCmp };

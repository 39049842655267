import template from './index.html';

/** @type {import('@srcAjs/components2/adminRoutes').IComponentOptions2} */
const trClassSubSignature = {
	template,
	bindings: {
		//someInput: '@string,<object,&function'
	},
	transclude: {
		// #slotName: '?optionalPublicName'
	},
};



function trClassSubSignatureCmpController(
	$timeout,
	$scope,
	ErrorService,
	$mdToast,
	$state,
	lsfactory,
	$rootScope,
	$localStorage,
	$mdDialog,
	TRFactory,
	Restangular
) {
	TRFactory.loadEnvironment();
	TRFactory.loadNotifications();

	$scope.teacher = lsfactory.getEnv('teacher');
	$scope.section = lsfactory.getEnv('teacher').section;
	lsfactory.setEnv('backLog', 'tr.home.dashboard');
	$scope.g_tid = lsfactory.getEnv('g_selected_term_id');
	$state.current.data.title = $scope.section.sclass.class_level.title + ' (' + $scope.section.title + ')';
	$scope.students = [];
	$scope.isReady = true;
	$rootScope.isCTermLoading = false;
	$rootScope.isCTermDisabled = false;

	$scope.isPhoto = false;
	$scope.image_set = false;
	$scope.isProcessing = false;
	$scope.myCroppedImage = null;
	$scope.cropType = 'square';
	$scope._tab = 'preview';

	//Configuring the signature upload
	$scope.file_changed = function (element) {
		console.dir(element);
		var evt = { currentTarget: element };
		var file = evt.currentTarget.files[0];
		var reader = new FileReader();
		reader.onload = function (evt) {
			console.dir(evt);
			$scope.$apply(function ($scope) {
				$scope.myImage = evt.target.result;
				$scope.image_set = true;
			});
		};
		reader.readAsDataURL(file);
	};

	//uploading the signature to the database
	$scope.upload = function () {
		var teacher = $scope.teacher;
		$scope.isProcessing = true;
		//Will use third arguments
		Restangular.all('teacher/class-signature/upload')
			.post({ teacher_id: teacher.id, myImage: $scope.myCroppedImage })
			.then(function (data) {
				let { teacher_signature } = data.plain();
				$scope.teacher = { ...lsfactory.getEnv('teacher'), teacher_signature };
				lsfactory.setEnv('teacher', $scope.teacher);
				$scope.isProcessing = false;
				$scope._tab = 'preview';
				$mdDialog.hide().then(function () {
					$scope.cancelImage();
					var template = $mdToast.simple().textContent('Signature Uploaded').action('OK').highlightAction(true).hideDelay(5500);
					$mdToast.show(template);
				});
			}, ErrorService.handleError)
			.finally(() => ($scope.isProcessing = false));
	};

	$scope.cancelImage = function () {
		$scope.myCroppedImage = null;
		$scope.myImage = '';
		$scope.image_set = false;
	};

	// $scope.$on('system.termChanged', function (event, term) {
	//     $rootScope.isCTermLoading = true;
	//     $scope.isReady = false;
	//     if(term != undefined){
	//         var students = Restangular.all("teacher/get-term-section-students/"+$scope.teacher.section.id+"/"+term);
	//         students.getList().then(function(data){
	//             $scope.students = data.plain();
	//             $scope.isReady = true;
	//             $rootScope.isCTermLoading = false;
	//         });
	//     }
	// });

	$scope.$on('system.tr.environmentChanged', function (ev, data) {
		$scope.env = $localStorage.environment;
		$scope.school = $localStorage.environment.school;
		$scope.g_tid = $scope.env.g_selected_term_id;
		if ($scope.school !== undefined) {
			if (lsfactory.getEnv('g_selected_term_id') !== undefined && lsfactory.getEnv('g_selected_term_id') != null) {
				$scope.g_tid = lsfactory.getEnv('g_selected_term_id');
				$scope.$broadcast('system.termChanged', $scope.g_tid);
			}
		}
	});
}

trClassSubSignature.controller = trClassSubSignatureCmpController;

appModule.component('trClassSubSignature', trClassSubSignature);

trClassSubSignature.selector = 'trClassSubSignature';

export { trClassSubSignature }

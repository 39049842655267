import template from './index.html';

/** @type {import('@srcAjs/components2/adminRoutes').IComponentOptions2} */
const saGuardiansCreate = {
	template,
	bindings: {
		//someInput: '@string,<object,&function'
	},
	transclude: {
		// #slotName: '?optionalPublicName'
	},
};

function saGuardiansCreateCmpController($scope, ErrorService, Guardian, $state, $rootScope, $stateParams, /** @type {auth} */ auth, Restangular, TRFactory, $mdToast) {
	$scope.done = false;
	$scope.school = auth.getEngagedUserRole().school;
	$scope.students = [];
	$scope.selectedWards = [];
	$scope.isReady = true;

	/**
	 * @typedef {{next:string,disabled:boolean,selected:number,label: string , completed?}} FormState
	 *  @typedef { Map<string,FormState>} FormStateMap
	 */
	const /** @type {FormStateMap} */ formStates = {
			entering_basic_info: {
				next: 'select_photo',
				disabled: false,
				selected: 0,
				label: 'info',
			},

			select_photo: {
				next: 'finish',
				disabled: false,
				selected: 1,
				label: 'photo',
			},

			submit: {
				next: 'finish',
				selected: 1,
				disabledd: false,
			},

			finish: {
				next: 'entering_basic_info',
				disabled: true,
				selected: 2,
				label: 'finish',
			},

			failed: {
				next: 'entering_basic_info',
				disabled: true,
				selected: 2,
			},
		};

	$scope.formStates = formStates;

	$scope.formStatesTransition = (/** @type string */ currentStateKey) => {
		// set current state to value of next state
		/** @type FormState */ const currentState = $scope.formStates[currentStateKey];

		if (!currentState) return;

		currentState.completed = true;

		$scope.formStates[currentState.next].disabled = false;

		// set disabled to false
		$scope.currentFormStateKey = currentState.next;
	};

	const students = Restangular.all('student/by-school/' + $scope.school.id);

	students.getList().then((data) => {
		$scope.students = data.plain();

		if (/teacher/i.test(auth.getEngagedUserRole().role.title) && !$stateParams.student_request_index && $stateParams.student.id) {
			$scope.selectedWards = $scope.students.filter(({ id }) => id === $stateParams.student.id);
		}
	});

	$scope.autocompleteDemoRequireMatch = true;

	$scope.transformChip = function (chip) {
		if (angular.isObject(chip)) {
			return chip;
		}
		return { title: chip };
	};

	const template = {
		salutation: 'Mrs.',
		gender: 'Female',
		school_id: $scope.school.id,
		profession: 'parent',
		sadmin_id: auth.getEngagedUserRole().role_instance_id,
		myImage: '',
		children: '',
		user: {
			send_sms: true,
		},
	};

	$scope.sals = ['Mr.', 'Mrs.', 'Ms.', 'Dr.', 'Prof.'];
	$scope.gends = ['Male', 'Female'];

	$scope.clearForm = clearForm;

	clearForm();

	$scope.cancelImage = cancelImage;
	function cancelImage() {
		$scope.myImage = '';
		$rootScope.myImage = undefined;
		$scope.myCroppedImage = '';
		$scope.image_set = false;
	}

	function clearForm() {
		$scope.currentFormStateKey = 'entering_basic_info';
		$scope.myCroppedImage = '';
		$scope.cropType = 'square';
		$scope.image_set = false;

		if ($stateParams?.guardian_intent) {
			const { user, guardian } = $stateParams.guardian_intent;
			$scope.box = { ...guardian, user };
			$scope.submit_text = 'Update creation request';
		} else if ($stateParams?.guardian) {
			const { thumbnail, ...guardian } = $stateParams.guardian;
			$scope.box = { guardian, user: { image: thumbnail, is_set: true } };
			$scope.submit_text = 'Update guardian';
		} else {
			$scope.box = angular.copy(template);
			$scope.submit_text = 'Create new guardian';
		}

		$scope.new_guardian = null;
		$scope.selectedWards = [];
		cancelImage();
	}

	$scope.init = function () {
		const handleFileSelect = function (evt) {
			const [file] = evt.currentTarget.files;
			const reader = new FileReader();
			reader.onload = function (evt) {
				$scope.$apply(($scope) => {
					$scope.myImage = evt.target.result;
					$scope.box.user.is_set = true;
				});
			};
			reader.readAsDataURL(file);
		};
		angular.element(document.querySelector('#fileInput')).on('change', handleFileSelect);
	};

	$scope.browseGuardians = function () {
		$state.go('sa.guardians_browse');
	};

	$scope.createGuardian = function () {
		$scope.done = false;

		const { user, ...guardian } = $scope.box;

		const data = {
			user,
			guardian: { ...guardian, myImage: $scope.myImage },
			children: $scope.selectedWards,
			validate_guardian_creation_request: false,
		};

		if (/teacher/i.test(auth.getEngagedUserRole().role.title)) {
			data.validate_guardian_creation_request = true;
		}

		Guardian.post(data)
			.then(
				(res) => {
					if (/teacher/i.test(auth.getEngagedUserRole().role.title) && $stateParams.student) {
						if (_.isFinite($stateParams.student_request_index)) {
							if (_.isFinite($stateParams?.student_request_index) && _.isFinite($stateParams?.guardian_request_index)) {
								TRFactory.studentCreationRequests.removeGuardian($stateParams?.student_request_index, $stateParams?.guardian_request_index);
							}
							TRFactory.studentCreationRequests.addGuardian($stateParams.student_request_index, res.plain());
						} else {
							TRFactory.guardianCreationRequests.addExistingStudentGuardian(res.plain());
						}

						$mdToast.showSimple('New guardian added');
						$scope.goBack();
					}

					({ user: $scope.user, guardian: $scope.new_guardian } = res.plain());

					$scope.formStatesTransition('submit');
				},
				(err) => {
					ErrorService.handleError(err);
					$scope.formStatesTransition('failed');
				}
			)
			.finally(() => ($scope.done = true));
	};

	if (/teacher/i.test(auth.getEngagedUserRole().role.title)) {
		try {
			if (!$stateParams?.creation_type) {
				throw new Error('creation_type not specified on $state.Params');
			}

			switch ($stateParams.creation_type) {
				case 'new_student_guardian_creation': {
					if (!$stateParams?.student || !_.isFinite($stateParams?.student_request_index)) {
						throw new Error('Invalid parameters for student and student_request_index');
					}

					break;
				}
				case 'existing_student_guardian_creation': {
					if (!$stateParams?.student) throw new Error('Invalid student info provided');

					break;
				}

				default: {
					throw Error('Type of creation not specified');
				}
			}
		} catch (error) {
			ErrorService.handleError(error);
			$scope.goBack();
		}
	}

	$scope.updateCountry = (country) => {
		$scope.box.country_id = country?.id;
		$scope.box.country = country;
		$scope.country = country;
	};

	$scope.selectCountry = (country) => country.id === $scope.box.guardian.country_id;
}

saGuardiansCreate.controller = saGuardiansCreateCmpController;

appModule.component('saGuardiansCreate', saGuardiansCreate);

saGuardiansCreate.selector = 'saGuardiansCreate';

export { saGuardiansCreate };

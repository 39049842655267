import template from './AsideLeftCmp.tpl.html';

/** @type {import('@srcAjs/components2/adminRoutes').IComponentOptions2} */
const asideLeftCmp = {
	template,
	bindings: {
		//someInput: '@string,<object,&function'
		engagedUserRole: '<',
	},
	transclude: {
		// #slotName: '?optionalPublicName'
	},
	selector: 'asideLeftCmp',
};

/** @this {ng.IController} */
function asideLeftCmpController(/** @type {EffectService} */ EffectService, $rootScope, $scope) {
	EffectService.setup(
		this,
		({ onChanges }) => {
			if (onChanges) {
				this.$rootScope = $rootScope;
			}
		},
		() => [this.engagedUserRole]
	);
}

asideLeftCmp.controller = asideLeftCmpController;

appModule.component('asideLeftCmp', asideLeftCmp);

export { asideLeftCmp };

import { createSlice } from './src/store.mjs';

/** @type {{teachers:undefined|Teacher[],selectedTeacher:Teacher|undefined,selectedTermId:number|undefined}} */
const initialState = {
	teachers: undefined,
	selectedTeacher: undefined,
	selectedTermId: undefined,
};

export default createSlice({
	initialState,
	name: 'saTeachers',
	reducers: {
		setTeachers(state, teachers) {
			return { ...state, teachers };
		},
		setSelectedTeacher(state, selectedTeacher) {
			return { ...state, selectedTeacher };
		},
		setSelectedTermId(state, selectedTermId) {
			return { ...state, selectedTermId };
		},
	},
});

import './standardNavButtonsCmp.scss';

import template from './standardNavButtonsCmp.tpl.html';

const standardNavButtonsCmp = {
	template,
	bindings: {
		/*
		 * engagedUserRole: '<',
		 * user: '<',
		 */
	},
};

class standardNavButtonsCmpController {
	/** @type {ng.material.IPanelService} */ #$mdPanel;

	is_admin = false;

	note_count;

	/** @type {NotificationService} */ NotificationService;

	/** @type {ErrorService} */ ErrorService;

	/** @type {auth} */ auth;
	openDialogs$;

	constructor(
		$mdPanel,
		/** @type {NotificationService} */ NotificationService,
		/** @type {ErrorService} */ ErrorService,
		/** @type {auth} */ auth,
		/** @type {EffectService} */ EffectService,
		/** @type {atomosService} */ atomosService,
		/** @type {$moment} */ $moment,
		/** @type {navDialogAtomsService} */ navDialogAtomsService
	) {
		this.#$mdPanel = $mdPanel;
		this.NotificationService = NotificationService;
		this.ErrorService = ErrorService;
		this.auth = auth;
		this.watchers = [];
		this.timeFrames = ['2/mins', '10/mins', '30/mins', '12/hours'];
		this.tfAlertsMap = new Map();
		this.openDialog$ = navDialogAtomsService.openDialog$;

		EffectService.setup(this, ({ onInit }) => {
			if (onInit) {
				this.#$mdPanel.newPanelGroup('standardNavMenuPanelGroup', { maxOpen: 1 });

				// create atoms
				const [authAtom, notificationsAtom, pushAlertsAtom = NotificationService.pushAlertsAtom] = [this.auth.authData$, NotificationService.notifications$].map((sub) =>
					atomosService(sub, this)
				);

				// combine atoms
				atomosService((get) => [get(authAtom), get(notificationsAtom), get(pushAlertsAtom)], this).stream$.subscribe(
					([authData, notifications, pushAlerts]) => {
						this.pushAlerts = pushAlerts ?? [];
						this.initProps(authData);
						this.note_count = notifications.filter((n) => !n.read_at).length;

						this.tfAlertsMap.clear();
						this.pushAlerts.forEach(([time, alert, type]) => {
							const tFrameKey = this.timeFrames.find((fr) => $moment(time).isAfter($moment().subtract(Number(fr.split('/').at(0)), fr.split('/').at(1))));
							const alertKey = `${type}@${tFrameKey}`;
							this.tfAlertsMap.set(
								alertKey,
								[...(this.tfAlertsMap.get(alertKey) ?? []), [$moment(time).toDate(), alert]].sort(([a], [b]) => b - a)
							);
						});
						this.tfAlertKeys = Array(...this.tfAlertsMap.keys());
					}
				);
			}
		});
	}

	initProps = (/** @type {auth['authData$']['value']}*/ authData) => {
		if (!authData?.engagedUserRole) {
			return;
		}

		// this.engaged_user_role = newValue;
		this.engagedUserRole = angular.copy(authData.engagedUserRole);
		this.isSysAdmin = authData.user.user_instance.user_roles.some((u_role) => u_role.role.short === 'aa');

		// this.user = newValue.user;

		this.show_notification_btn = !!this.engagedUserRole;

		this.show_school_btn = !!this.engagedUserRole?.school;

		// this.engaged_user_role = this.engagedUserRole;
		this.is_admin = /School Administrator/.test(this.engagedUserRole.role.title);

		this.isTokenOwner = this.auth.isTokenOwner();

		console.log('standardNavButtonsCmp loaded', this.engagedUserRole);
	};

	onAccountPanelClose = (open) => {
		console.log('accountPanel.onClose', { open });
		this.openAccountPanel = open;
	};

	doCloseDialog() {
		this.openDialog$.next(null);
	}
}

standardNavButtonsCmp.controller = standardNavButtonsCmpController;

appModule.component('standardNavButtonsCmp', standardNavButtonsCmp);

// export default component;

import './StudentEnrollmentStateEditor.scss';

import template from './StudentEnrollmentStateEditor.tpl.html';

const studentEnrollmentStateEditorCmp = {
	template,
	/**
	 *  @type {{
	 * 	studentEnrollmentState,
	 * 	sectionHistory:SectionHistory,
	 * 	student:Student,
	 * 	update:({value})=>void,onToggleEditor:({value})=>void,onComplete:({value})=>void
	 * }}
	 */
	// @ts-ignore
	bindings: {
		studentEnrollmentState: '<',
		sectionHistory: '<',
		onToggleEditor: '&',
		onComplete: '&',
		student: '<',
		update: '<',
	},
};

class ComponentNameController {
	lsfactory;

	Restangular;

	$timeout;

	ErrorService;

	ClassGroup;

	isLoading;

	visible;

	enrollment_states;

	/** @type {Array<{id}>}*/ selected_other_section_histories;

	/** @type {typeof studentEnrollmentStateEditorCmp.bindings.studentEnrollmentState} */ studentEnrollmentState;

	/** @type {typeof studentEnrollmentStateEditorCmp.bindings.sectionHistory} */ sectionHistory;

	/** @type {typeof studentEnrollmentStateEditorCmp.bindings.onToggleEditor} */ onToggleEditor;

	/** @type {typeof studentEnrollmentStateEditorCmp.bindings.onComplete} */ onComplete;

	/** @type {typeof studentEnrollmentStateEditorCmp.bindings.student} */ student;

	/** @type {typeof studentEnrollmentStateEditorCmp.bindings.update} */ update;

	constructor(Restangular, lsfactory, $timeout, ErrorService, /** @type {import("@srcAjs/factory/services/class-group").ClassGroupApi} */  ClassGroup, API) {
		this.Restangular = Restangular;
		this.lsfactory = lsfactory;
		this.$timeout = $timeout;
		this.ErrorService = ErrorService;
		this.ClassGroup = ClassGroup;

		this.visible = false;
		this.isLoading = false;
		this.enrollment_states = lsfactory.getEnv('school').enrollment_states;
		this.API = API;
	}

	updateSubjectMounts = (section) => {
		if (!section) return;

		this.subject_mount_ids = section.subject_mounts.map(({ id }) => id);
	};

	submitEnrollmentForm = async (_data, update = true) => {
		this.isLoading = true;
		const data = angular.copy(_data);

		data.enrollment_state_id = data.new_enrollment_state.id;
		delete data.new_enrollment_state;
		delete data.prev_enrollment_state_id;

		if (this.section && this.subject_mount_ids) {
			data.section_id = this.section.id;
			data.subject_mount_ids = this.subject_mount_ids;
		}

		if (!update) delete data.created_at;

		const student_enrollment_states = [data];

		if (this?.selected_other_section_histories?.length) {
			for (const section_history of this.selected_other_section_histories) {
				student_enrollment_states.push({ ...data, student_id: section_history.student.id });
			}
			// data.other_section_history_ids = this.selected_other_section_histories.map(({ student }) => ({ studentid }))
		}

		try {
			const [response] = await this.Restangular.all('student_enrollment_states')
				.post({ student_enrollment_states })
				.then((res) => res.plain());
			this.student.student_enrollment_state = response.result;
			this.student.student_enrollment_states = [response.result];
			this.toggleEditor(response);
		} catch (error) {
			this.ErrorService.handleError(error);
		} finally {
			this.isLoading = false;
			this.$timeout(() => {}, 10);
		}
	};

	$onChanges = (changes) => {
		console.log('Enrollment editor changes', changes);
		this._sectionHistory = {
			...changes?.sectionHistory?.currentValue,
			...changes?.student?.currentValue?.last_section_history,
		};
		this._update = false;

		this._student_enrollment_state = {
			student_id: changes?.student?.currentValue?.id,
			term_id: changes?.studentEnrollmentState?.term_id ?? this?._sectionHistory?.term_id,
			mark_section_history_as_current: false,
		};

		if (changes?.studentEnrollmentState?.currentValue) {
			this._update = true;
			this._student_enrollment_state = {
				...this._student_enrollment_state,
				...{
					prev_enrollment_state_id: changes?.studentEnrollmentState?.currentValue?.enrollment_state_id ?? '',
					description: changes?.studentEnrollmentState?.currentValue?.description ?? '',
					created_at: changes?.studentEnrollmentState?.currentValue?.created_at ?? '',
				},
			};

			this._reset = { ...this._student_enrollment_state };
			this.$timeout(() => {}, 10);
		}
	};

	toggleEditor = (data = null) => {
		this.onToggleEditor({ data });
	};

	reset = () => {
		this._student_enrollment_state = { ...this._reset };
	};

	updateReEnrollmentTerm = (term) => {
		if (!term) return;

		this.isLoading = true;

		this._student_enrollment_state.term_id = term.id;

		this._student_enrollment_state.mark_section_history_as_current = term.status == 'active';

		this.ClassGroup.get(this.lsfactory.getEnv('school'), term, ['sections', 'subject_mounts'], '1.2')
			.then((sclasses) => (this.classes = sclasses))
			.catch(this.ErrorService.handleError)
			.finally(() => (this.isLoading = false));
	};

	loadSectionHistories = (add_other_section_histories) => {
		if (!add_other_section_histories || !this._sectionHistory) return;

		this.isLoading = true;
		const filters = [
			{ column: 'section_id', condition: 'where', value: this._sectionHistory.section_id },
			{ column: 'term_id', condition: 'where', value: this._sectionHistory.term_id },
			{ column: 'id', condition: 'whereNotIn', value: [this._sectionHistory.id] },
		];
		const fields = [{ field: 'student' }];

		this.Restangular.all(`section_history?${this.API.buildParams(filters, null, fields)}`)
			.getList()
			.then((res) => (this.other_section_histories = res.plain()))
			.catch(this.ErrorService.handleError)
			.finally(() => (this.isLoading = false));
	};
}

studentEnrollmentStateEditorCmp.controller = ['Restangular', 'lsfactory', '$timeout', 'ErrorService', 'ClassGroup', 'API', ComponentNameController];

appModule.component('studentEnrollmentStateEditor', studentEnrollmentStateEditorCmp);

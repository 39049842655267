import template from './index.html';

/** @type {import('@srcAjs/components2/adminRoutes').IComponentOptions2} */
const saOperationsApproveScoresDialogsCheckScoresDialog = {
	template,
	bindings: {
		//someInput: '@string,<object,&function'
		approve_ae: '<approveAe',
		approved: '<',
		approve_mount: '<approveMount',
	},
	transclude: {
		// #slotName: '?optionalPublicName'
	},
};

/** @this {ng.IController} */
function saOperationsApproveScoresDialogsCheckScoresDialogController(
	Restangular,
	$scope,
	ErrorService,
	$mdDialog,
	/** @type {import('@srcAjs/components/services.components.js/EffectService').EffectService} */ EffectService,
	/** @type {API} */ API
) {
	EffectService.setup(this, ({ changes, destroy$ }) => {
		if (changes) {
			return;
		}

		$scope.entity = this.approve_ae;
		$scope.subject_mount = this.approve_mount;
		$scope.approved = this.approved;

		const { term_students } = $scope.subject_mount;

		const student_term_subject_ids = term_students.map((item) => item.id) ?? [];
		// _.each( , function(itm) { this.push(_.pick(itm,"id")) }, student_term_subject_ids );
		const data = {
			ae_id: $scope.entity.id,
			stsids: student_term_subject_ids,
		};

		API.wrapWithCache({
			restangularElement: (r) => r.all('assessment/get'),
			callable: true,
			timeoutOrDestroy$: destroy$,
		})()
			.post(data)
			.then(
				(res) => {
					const list = res.plain();
					updateStudentUI(list, term_students);

					$scope.isLoading = false;
				},
				(err) => {
					ErrorService.handleError(err);
				}
			)
			.finally(() => ($scope.isReady = true));
	});

	$scope.isLoading = true;

	$scope.approve = function (approve) {
		const data = {
			ae_id: $scope.entity.id,
			assessment_entities_approval: approve,
		};
		Restangular.all('assessment/approve')
			.post(data)
			.then(
				(res) => {
					$scope.hide(res.plain());
				},
				(err) => {
					ErrorService.handleError(err);
				}
			);
	};

	function updateStudentUI(scores, term_students) {
		// merge_the_two($scope.subject_students, scores)
		const mapped_scores = _.keyBy(scores, 'student_term_subject_id');
		$scope.subject_students = term_students.map((t_st) => {
			t_st.score = mapped_scores[t_st.id] ?? { score: null, comment: null, id: crypto.randomUUID() ?? new Date() };
			return t_st;
		});
	}

	$scope.hide = function (ae = null) {
		$mdDialog.hide(ae);
	};

	$scope.cancel = function () {
		$mdDialog.cancel();
	};
}

saOperationsApproveScoresDialogsCheckScoresDialog.controller = saOperationsApproveScoresDialogsCheckScoresDialogController;

appModule.component('saOperationsApproveScoresDialogsCheckScoresDialog', saOperationsApproveScoresDialogsCheckScoresDialog);

saOperationsApproveScoresDialogsCheckScoresDialog.selector = 'saOperationsApproveScoresDialogsCheckScoresDialog';

export { saOperationsApproveScoresDialogsCheckScoresDialog };

import './SchoolLeaderBoardComponent.scss';

import template from './SchoolLeaderBoardComponent.tpl.html';

const schLeaderBoardCmp = {
	template,
	bindings: {
		class_group_stats: '<classGroupStats',
		teachers_stats: '<teachersStats',
		term: '<',
	},
};

/** @type {typeof schLeaderBoardCmp['bindings']} */
class SchoolLeaderBoardComponentController {
	COLUMNS = 3;

	constructor($filter, /** @type {EffectService} */ EffectService, /** @type {compSetupService} */ compSetupService) {
		// assertCompBindings(this, component.bindings);
		compSetupService(this, schLeaderBoardCmp.bindings);
		this.$filter = $filter;

		EffectService.setup(
			this,
			({ onChanges }) => {
				if (onChanges) {
					this.class_group_stats_chunks = this.class_group_stats;
					this.teachers_stats_chunks = this.$filter('orderBy')(this.teachers_stats, '-sum.week_total');
				}
			},
			() => [this.class_group_stats, this.teachers_stats, this.term]
		);
	}
}

schLeaderBoardCmp.controller = SchoolLeaderBoardComponentController;

appModule.component('schLeaderBoardCmp', schLeaderBoardCmp);

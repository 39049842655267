import template from './index.html';

/** @type {import('@srcAjs/components2/adminRoutes').IComponentOptions2} */
const saSchoolSetupClassGroupsIndex = {
	template,
	bindings: {
		// someInput: '@string,<object,&function'
	},
	transclude: {
		// #slotName: '?optionalPublicName'
	},
};

function saSchoolSetupClassGroupsIndexCmpController(
	SAFactory,
	$scope,
	$state,
	$rootScope,
	lsfactory,
	/** @type {Restangular} */ Restangular,
	$winNotif,
	/** @type {ClassGroupTypeApi} */ ClassGroupType,
	/** @type {DialogService} */ DialogService,
	/** @type {API} */ API,
	/** @type {compSetupService} */ compSetupService,
	/** @type {EffectService} */ EffectService,
	/** @type {import('@components/services.components.js/atomosService').atomosService} */ atomosService,
	/** @type {ClassGroupApi} */ ClassGroup,
	/** @type {RxService} */ RxService
) {
	SAFactory.loadEnvironment();
	SAFactory.loadNotifications();
	$scope.school = lsfactory.getEnv('school');
	$scope.groups = [];
	$scope.types = [];
	$scope.systems = [];
	$scope.forget_list = [];
	$scope.no_need_to_add_groups = true;
	$scope.showOne = false;
	$scope.isReady = false;

	compSetupService(this);

	const [classGroups$, rxMetaClassGroup] = ClassGroup.rxq(this);
	const [cgTypes$, rxMetaCGTypes] = ClassGroupType.rxq(this);

	EffectService.setup(this, ({ onInit }) => {
		if (onInit) {
			Restangular.all('grading-system/by-school/' + $scope.school.id)
				.getList()
				.then(function (data) {
					$scope.systems = data.plain();
				});

			atomosService(RxService.combineLatest({ cgRes: classGroups$, cgTypesRes: cgTypes$ }), this).stream$.subscribe(({ cgRes, cgTypesRes }) => {
				$scope.isReady = !cgRes?.loading || !cgTypesRes?.loading;

				if (!cgRes?.data || !cgTypesRes?.data) {
					return;
				}

				this.groups = cgRes.data.plain();
				$scope.groups = this.groups;

				const /** @type {ClassGroup[]} */ schoolTermClassGroups = cgRes.data.plain();
				$scope.types = cgTypesRes.data.plain();

				let prev_cgType_ids = schoolTermClassGroups.map((cg) => cg.class_group_type_id);

				$scope.types = $scope.types.map((cgType) => {
					cgType.disable = prev_cgType_ids.includes(cgType.id);

					return cgType;
				});

				const totalTypesCount = $scope.types.length;

				let usedTypesCount = $scope.types.filter((t) => t.disable).length;

				$scope.no_need_to_add_groups = usedTypesCount === totalTypesCount;
			});
		}
	});

	$scope.fetchClassGroups = (/** @type {Term}*/ term, school = $scope.school) => {
		if (!term?.id) {
			return;
		}

		$scope.term = term;

		rxMetaClassGroup.mutate({ school_id: school.id, term_id: ['1.3', '1.4', '1.2'].includes(term?.term_type.version) ? term.id : null });
	};

	$scope.gotoSummary = function (sclass) {
		lsfactory.setEnv('backLog', $state.current.name);
		lsfactory.setEnv('currentClassSelected', sclass);
		$state.go('sa.school_setup_classes_detail.summary', { id: sclass.id });
	};

	this.unsetSelectedClassGroup = () => {
		this.selectedClassGroup = null;
	};

	$scope.addClass = function (group) {
		$scope.under_construction();
	};

	$scope.gotoClass = function (sclass) {
		lsfactory.setEnv('backLog', $state.current.name);
		lsfactory.setEnv('currentClassSelected', sclass);
		$state.go('sa.school_setup_classes_detail.sections', { id: sclass.id });
	};

	$scope.visitG = function (group) {
		lsfactory.setEnv('backLog', $state.current.name);
		lsfactory.setEnv('currentGradingSystemSelected', group.grading_system);
		$state.go('sa.school_setup_grading_detail.entry', { id: group.grading_system_id });
	};

	$scope.loadItems = () => {
		$scope.fetchClassGroups($scope.term, $scope.school);
		return;
	};

	this.onCloseEditGroup = (data) => {
		if (data) {
			rxMetaClassGroup.revalidate();
			// $scope.loadItems();
		}
		this.selectedGroup = null;
	};

	$scope.goto = function (group) {
		lsfactory.setEnv('dialogGradingSystemList', $scope.systems);
		if (group !== undefined) {
			lsfactory.setEnv('currentClassGroupSelected', group);
			$state.go('sa.school_setup_class_groups_detail.summary', { id: group.id });
		}
	};

	$scope.remove = function (group) {
		return DialogService.dispatch('alert', 'Operation not allowed');
		DialogService.dispatch('confirm', 'Would you like to delete the "' + group.class_group_type.title + '" department now?').then(() => {
			// return Restangular.all('class-group/' + group.id)
			// 	.customDELETE()
			// 	.then(() => $scope.loadItems());
		});
	};

	this.onCloseAddDepartmentDialog = (data) => {
		if (data) {
			// $scope.loadItems();
			rxMetaClassGroup.revalidate();
		}
		this.openAddDepartmentDialog = false;
	};
	$scope.$on('class_groups.dataChanged', function (ev, data) {
		console.log('$scope triggered');
		// $scope.loadItems();
		rxMetaClassGroup.revalidate();
	});

	$scope.newClassGroup = () => {
		if ($scope.systems.length == 0) {
			return $winNotif.show({
				title: 'No Grading Systems Avaiable',
				msg: 'To add a department, you must create a grading system.',
				acceptText: 'Grading Systems',
				declineText: 'Dismiss',
				imgUrl: 'images/logo.png',
				timeout: 10000,
				hasButtons: true,
				accept: () => {
					$state.go('sa.school_setup_grading');
					$winNotif.hide();
				},
				decline: () => {
					$winNotif.hide();
				},
			});
		}
		this.openAddDepartmentDialog = true;
	};
}

saSchoolSetupClassGroupsIndex.controller = saSchoolSetupClassGroupsIndexCmpController;

appModule.component('saSchoolSetupClassGroupsIndex', saSchoolSetupClassGroupsIndex);

saSchoolSetupClassGroupsIndex.selector = 'saSchoolSetupClassGroupsIndex';

export { saSchoolSetupClassGroupsIndex };

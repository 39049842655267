import template from './index.html';

/** @type {import('@srcAjs/components2/adminRoutes').IComponentOptions2} */
const saNoticesIndex = {
	template,
	bindings: {
		//someInput: '@string,<object,&function'
	},
	transclude: {
		// #slotName: '?optionalPublicName'
	},
};



function saNoticesIndexCmpController(
	$scope,
	$cookies,
	$mdToast,
	$state,
	$mdDialog,
	$rootScope,
	$localStorage,
	$http,
	$compile,
	SAFactory,
	lsfactory,
	Restangular,
	Notice
) {
	SAFactory.loadEnvironment();
	$scope.env = $localStorage.environment;
	$scope.school = lsfactory.getEnv('school');
	$scope.notices = [];
	$scope.isReady = true;

	$scope.newNotice = function () {
		lsfactory.setEnv('dialogSchoolID', $scope.school.id);
		$mdDialog.show({
			escapeToClose: false,
			clickOutsideToClose: false,
			templateUrl: 'views/pages/roles/sa/notices/dialogs/add_notice.html',
			controller: AddNoticeDialogController,
		});
	};

	$scope.chooseTerm = function () {
		angular.element('#one_select_notice').triggerHandler('click');
	};

	$scope.remove = function (notice) {
		var confirm = $mdDialog
			.confirm()
			.title('Would you like to delete the selected notice now?')
			.textContent('This operation cannot be undone. It will be wiped from all viewing devices.')
			.ariaLabel('Warning')
			.ok('Yes please!')
			.cancel('I dont think so');
		$mdDialog.show(confirm).then(
			function () {
				Notice.one(notice.id)
					.get()
					.then(function (res) {
						var nt = res;
						nt.remove().then(function () {
							$scope.loadItems();
						});
					});
			},
			function () {}
		);
	};

	$scope.loadItems = function () {
		var notices = Restangular.all('notice/by-school/' + $scope.school.id);
		notices.getList().then(function (data) {
			var list = data.plain();
			$scope.notices = list;
			$scope.isLoading = false;
		});
	};

	$scope.editNotice = function (notice) {
		lsfactory.setEnv('dialogNoticeSelected', angular.copy(notice)); // Send something to the dialog
		$mdDialog.show({
			escapeToClose: false,
			clickOutsideToClose: false,
			templateUrl: 'views/pages/roles/sa/notices/dialogs/edit_notice.html',
			controller: EditNoticeDialogController,
		});
	};

	$scope.$on('notices.dataChanged', function (event, data) {
		$scope.isLoading = true;
		var notices = Restangular.all('notice/by-school/' + $scope.school.id);
		notices.getList().then(function (data) {
			var list = data.plain();
			$scope.notices = list;
			$scope.isLoading = false;
		});
	});

	$scope.$on('system.sa.environmentChanged', function (ev, data) {
		$scope.env = data;
		if ($scope.env.school != null) {
			var notices = Restangular.all('notice/by-school/' + $scope.school.id);
			notices.getList().then(function (data) {
				var list = data.plain();
				$scope.notices = list;
				$scope.isLoading = false;
			});
		} else {
			$scope.isTermLoading = false;
			$scope.isLoading = false;
		}
	});
}

saNoticesIndex.controller = saNoticesIndexCmpController;

appModule.component('saNoticesIndex', saNoticesIndex);

saNoticesIndex.selector = 'saNoticesIndex';

export { saNoticesIndex };

/**
 * @template { `${string}|${Condition_Values}|${string}` | string} T @typedef {T extends `${string}|${Condition_Values}|${string}` ? T : string } API_ColumnFieldQuery -  'column' or 'relation|condition|column'
 */

/**
 * @typedef {{field:API_ColumnFieldQuery} & (API_ConditionQuery |  {conditions: API_ConditionQuery[]})} API_FieldQuery
 */

/**
 * @typedef {{condition?:Condition_Values,column?:API_ColumnFieldQuery,value?, comparator?: Comparator_Values}} API_ConditionQuery
 */

/**
 *
 *  @typedef {{fields?: API_FieldQuery[], has_fields?: API_FieldQuery[], filters?: API_ConditionQuery[] ,sorts?:SortFieldQuery[], param_resolve_version?: "1.3" | "1.4"}} API_Params

 * 

 
 *
 * @typedef {'where'|'whereIn'|'whereNotIn'|'whereJsonContains'|'whereJsonDoesntContain'|'whereBetween'|'with'} Condition_Values
 *
 * @typedef {'>'|'<'|'>='|'<='|'='|'!='} Comparator_Values
 *
 *
 * @typedef {{condition:'simple'|'complex', column: string, value:'asc'|'desc'}} SortFieldQuery
 *
 *	-> getModel
 *	query ->fn ($q){
 *	where (fn($q)-> query,context)
 *	orWhere
 *	with
 *	count
 *	has
 *	whereHas
 *	context
 *  logic: >=,<=,=,!=
 *	}
 *
 *	field: 'students',
 *		conditions: [{ condition: 'whereHas', column: 'student_enrollment_states.enrollment_state|where|state', value: 'archived' }],
 *	 -> guardian ->{
 * 	with->student{
 * 		whereHas->{
 * 			$wq->column,''
 * 		}
 * 	}
 * 	}
 */

/**
 * @template {string} T
 *
 * @typedef { T extends ''? never: T extends `${infer Start}.${infer Rest}` ? [Start, ...PathToArray<Rest> ]: [T] } PathToArray
 *
 */
/**
 * @template {`${string}`} T
 * @typedef { T extends PathToArray<infer Q> ? Q: T } PathString
 */

/**
 *
 *
 * @typedef {  Q extends [] ? '': Q extends [infer First, ...infer Rest] ? Record<First & string,StringArrToRecord<Rest>> :never }  StringArrToRecord<  @template {string[]} Q >
 */
/** @typedef { T extends [] ? '' : T extends [infer Rec, ...infer Rest] ? Rec.field | RecordsArrayToRecord<Rest> } RecordsArrayToRecord< @template {Record<'field' & string,string>[]} T> */

/** @typedef {UnionToIntersection<StringArrToRecord<PathToArray<Q>>>}  PathToRecord< @template {string} Q>*/

/** @typedef {(U extends any ? (k: U) => void : never) extends ((k: infer I) => void) ? I : never} UnionToIntersection< @template U> */

/**
 * @typedef {PathToRecord<T[number]['field']>} ApiFieldsToPath < @template {readonly any[]} T>
 */

/**
 *  @typedef {[]|{}|string} ArrayRecord
 * @returns {[string,any][]}
 *
 */
const jsonToArray = (/** @type {ArrayRecord} */ input, parentKey, root) => {
	if (!_.isArray(input) && !(_.isObject(input) && !_.isArrayLike(input))) {
		return [[parentKey, input]];
	}

	if (_.isObject(input) && !_.isArrayLike(input)) {
		const objAsEntries = Object.entries(input)
			.map(([key, a], index) => {
				const tempParentKey = key.includes(parentKey) ? key : `${parentKey}[${key}]`;
				const remapped = jsonToArray(a, tempParentKey);
				return remapped;
			})
			.flatMap((sets) => sets);

		return objAsEntries;
	}

	if (!_.isArray(input)) {
		throw new IError('Error: input is not an array', { input });
	}
	const tempObjArr = input.map((a, index) => jsonToArray(a, `${parentKey}[${index}]`, root)).flatMap((item) => item);
	return tempObjArr;
};

/** @typedef {import('restangular').IService} Restangular */

function API(
	/** @type {ErrorService} */ ErrorService,
	/** @type {import('restangular').IService} */ Restangular,
	/** @type {ng.ICacheFactoryService} */ $cacheFactory,
	/** @type {auth} */ auth,
	/** @type {{get:CallableFunction}} */ $injector,
	/** @type {$moment} */ $moment
) {
	const RxService = /** @type {RxService} */ ($injector.get('RxService'));

	this.section = Restangular.service('section');
	this.student = Restangular.service('student');
	this.attendance = Restangular.service('attendance');

	/** @typedef {{dd_param_resolve?,dump_param_resolve?}} DebugParams */

	/**
	 * @param {API_ConditionQuery[]} filters
	 * @param {API_FieldQuery[]} has_fields
	 * @param {API_FieldQuery[]} fields
	 * @returns {string}
	 */
	this.buildParams = (filters = null, has_fields = null, fields = null, sorts = null, param_resolve_version = '1.3', /** @type {DebugParams} */ debug_params) => {
		try {
			const params = { filters, has_fields, fields, sorts, param_resolve_version };

			const tempParams = Object.entries(params)
				.filter(([, val]) => val)
				.flatMap(([key, val]) => jsonToArray(val, key));

			const paramUrlSearch = [tempParams, debug_params]
				.filter((i) => i)
				.map((i) => new URLSearchParams(i).toString())
				.join('&');

			return paramUrlSearch;
		} catch (error) {
			ErrorService.handleError(error);
		}
	};

	/** @template {API_Params} T	 */
	this.buildParams_v2 = (
		/** @type {T} */ { filters = null, has_fields = null, fields = null, sorts = null, param_resolve_version = '1.3' },
		/** @type {DebugParams} */ debug_params
	) => this.buildParams(filters, has_fields, fields, sorts, param_resolve_version, debug_params);

	function getScopeString(/** @type {string} */ scope_cache_key) {
		return ![null, '', undefined].includes(scope_cache_key) ? `_${scope_cache_key}_` : '_';
	}

	function getCache(
		/** @type {{scope_cache_key?:string, role_instance_type?: string}} */
		{ scope_cache_key = null, role_instance_type = auth.getEngagedUserRole()?.role_instance_type } = {}
	) {
		const cache_key = `${role_instance_type ?? 'GUEST'}${getScopeString(scope_cache_key)}api_cache_key`;

		return $cacheFactory.get(cache_key) ?? $cacheFactory(cache_key);
	}

	/**
	 * @typedef { 	import('restangular').IElement |import('restangular').ICollection} _Restangular
	 * @typedef {((t:Restangular)=> _Restangular)} RestangularInstanceCb
	 * @typedef {import('restangular').IService} Restangular
	 */

	/**
	 * @example
	 * const reload = API.wrapWithCache({
	 *	restangularElement: Restangular.all(`charge-templates?${API.buildParams_v2(params)}`),
	 *	callable: true,
	 *	});
	 *
	 * //now call reload to get data
	 *	reload(refresh)
	 *	.getList()
	 *	.then((res) => res?.plain())
	 *	.then((data) => this.chargeTemplates$.next(data));
	 *
	 * 	import('restangular').IElement |
	 * 	import('restangular').ICollection,
	 *  @template {{
	 * 	restangularElement: _Restangular | RestangularInstanceCb
	 *  cache?:ng.ICacheObject,
	 * 	refresh?:boolean,
	 * 	refreshAll?:boolean
	 *  callable?:boolean
	 * timeoutOrDestroy$?: import('rxjs').ObservableInput<any> | Promise
	 * }} T
	 
	 *
	 * @returns {T['callable'] extends true ? typeof refreshFunction: _Restangular }
	 *
	 */
	function wrapWithCache(/** @type {T}*/ { restangularElement: _re, cache = getCache({}), refresh = false, refreshAll = false, callable = false }) {
		const restangularInstance = _.isFunction(_re)
			? _re(/** @type {import('restangular').IService} */ ($injector.get('Restangular')).withConfig((c) => c.setDefaultHttpFields({ cache })))
			: _re.withHttpConfig({ cache });

		const removeRequestFromCache = () => cache.remove(restangularInstance.getRestangularUrl());

		if (refresh) {
			removeRequestFromCache();
		} else if (refreshAll) {
			cache.removeAll();
		}

		if (!callable) {
			return restangularInstance;
		}

		const refreshFunction = (shouldRemoveFromCache = false) => {
			if (shouldRemoveFromCache) {
				removeRequestFromCache();
			}
			return restangularInstance;
		};

		return refreshFunction;
	}

	function useVersion(version = '1.4') {
		return { param_resolve_version: version };
	}

	/** @description {true} if all items deleted else false */
	const cleanUpCKeysSet = (/** @type {Set<string>} */ cKeys, _map = revalidateMap) => {
		return [...cKeys.values()].every((_ckey) => {
			(_map.get(_ckey)?.abort ?? (() => null))('abort.cleanup');
			return _map.delete(_ckey);
		});
	};

	/** @type {Map<string,{revalidate:()=>void,stream$:typeof RxService.Observable<{data,loading,error,complete}>,abort:(reason?:string)=>void}>} */
	const revalidateMap = new Map();

	const isRxq = (q = '') => /^rxq\..+/.test(q);
	const isRxm = (q = '') => /^rxm\..+/.test(q);

	/**
	 * @template Input
	 * @template  {((m:Restangular,data?:Input)=> any )} T
	 * @template {Parameters<T>[1]|Parameters<T>[1]} TData
     * @template {import('rxjs').Observable<TData>|import('rxjs').ObservableInput<TData>|TData|undefined} QInput$
	 *
	 * @template {{
	 *		destroy$: import('rxjs').Observable
	 *		onSuccess?:(args:{data:Awaited<ReturnType<T>> & {plain:()=>Awaited<ReturnType<T>>} })=>any,
	 *		onError?:({error})=>any,
	 *		onComplete?:(opt?:any)=>any,
	 *		qInput$?: QInput$,
	 *		enabled?:boolean
	 *		expire?:number // milliseconds
	 *		interval?: number // miliseconds
	 *	}} T2

		@template { [`rxq.${string}`] | [`rxm.${string}`] } Key
	
	 *
	 * 
	 *
	 */
	function rxQuery(
		/** @type {Key}*/ keys,
		/** @type {T} */ fn,
		/** @type {T2} */ { qInput$, onSuccess, onError, onComplete, destroy$, enabled = true, expire = 1000 * 60 * 5, interval }
	) {
		if (!RxService.isObservable(destroy$) && destroy$ === undefined) {
			throw new IError('Error: destroy invalid, must be an observable, or set to false and call destroyFn');
		}
		// const stream$ = new RxService.BehaviorSubject({ data: /** @type {Awaited<ReturnType<T>>} */ (undefined), error: undefined, loading: false, pending: false });

		const revalidate$ = new RxService.BehaviorSubject(/**@type {boolean|number} */ (false));

		const restangular = Restangular.withConfig((c) => c.setDefaultHttpFields({ cache: getCache() }));

		const inputAsStream$ = () => /** @type {import('rxjs').BehaviorSubject<TData>} */ (RxService.isObservable(qInput$) ? qInput$ : new RxService.BehaviorSubject(qInput$));

		let expire_at = $moment().add('milliseconds', expire);

		let revalidatable = false;

		let abortFn;

		const revalidate = ({ revalidateAll = false } = {}) => {
			if (abortFn) abortFn();

			if (revalidateAll) {
				return rxRevalidateQueries();
			}

			revalidatable = true;
			revalidate$.next(Date.now());
		};

		let cKeys = null;
		const cKeysCleanUpSet = new Set();

		const setCKeys = (keys, _data) => {
			if (!_.isObject(_data)) {
				cKeys = JSON.stringify([...keys, _data]);
				return null;
			}
			const _dataToArr = Object.entries(_data).sort(([a], [b]) => a?.length - b?.length);
			cKeys = JSON.stringify([...keys, _dataToArr]);
			return cKeys;
		};

		const enabled$ = new RxService.BehaviorSubject(enabled);

		const enableFn = (/** @type {boolean} */ enable) => enabled$.next(enable);

		const input$ = inputAsStream$();
		const mutate = (/** @type { TData }*/ data, activateEnable = () => enabled$.next(true), shouldLog = false, _keys = keys) => {
			if (abortFn) abortFn();

			const promise = RxService.firstValueFrom(
				stream$.pipe(
					// RxService.tap((res) => console.log(`Api.rxQM.mutate.started[${_keys}]`, res)),
					RxService.skipWhile((res) => {
						const skip = !res?.error && !res?.data;
						// console.log(`Api.rxQM.mutate.skip[${_keys}]:`, skip, 'for res:', res);
						return skip;
					}),
					RxService.tap((res) => shouldLog && console.log(`Api.rxQM.mutate.completed[${_keys}]:`, keys[0], res, keys))
				)
			).then((mutationRes) => {
				if (_keys.join('.').includes('rxq')) revalidate();
				return mutationRes;
			});

			input$.next(data);

			if (activateEnable && !enabled$.getValue()) {
				activateEnable();
			}

			return promise;
		};

		let localDestroy$ = undefined;
		destroy$ = RxService.isObservable(destroy$) ? destroy$ : (localDestroy$ = new RxService.Subject());

		const _combinedInputs$ = RxService.combineLatest({
			input$,
			revalidate$,
			enabled$,
			interval: interval ? RxService.timer(0, interval).pipe(RxService.tap(() => (revalidatable = true))) : RxService.of(false),
		});

		let lastData = undefined;

		const doCleanUp = () => ({
			get removeKey() {
				return cKeysCleanUpSet.size && cleanUpCKeysSet(cKeysCleanUpSet);
			},
			cKeysCleanUpSet,
			kesFromMap: [...revalidateMap.keys()],
		});

		/** @type {import('rxjs').Observable<{data?:Awaited<ReturnType<typeof fn>> & {plain?:()=> Awaited<ReturnType<typeof fn>>} & ng.restangular.IResponse,loading?:boolean,pending?:boolean,error?:import('angular').IHttpResponse|boolean|Error,complete?:boolean,}>} */
		const pStream$ = _combinedInputs$
			.pipe(
				// RxService.finalize(() => console.log('rx.switchMap.combine.finalized', { ...doCleanUp() })),
				RxService.switchMap(({ /** @type {(...arg0)=>Promise} */ input$, revalidate$, enabled$ }) => {
					console.log({ 'rxQuery._revalidate$': revalidate$, _enabled$: enabled$, keys: JSON.stringify(keys) });

					if (!enabled$) {
						return RxService.of({ loading: undefined, pending: undefined });
					}

					const expired = expire_at.isBefore($moment());

					if (lastData?.getRestangularUrl && (expired || revalidatable)) {
						getCache().remove(lastData.getRestangularUrl());

						revalidatable = false;
						expire_at = expire_at.add('millisecond', expire);
					}

					/** @type {typeof pr_data$} */
					const loading$ = RxService.of({ loading: true, pending: true });
					// Convert the promise returned by fn to an observable

					const tempAbort$ = new RxService.Subject();

					// revalidateMap.get(cKeys)?.abort();
					// if (abortFn) abortFn();

					abortFn = (reason = 'abort.revalidate') => {
						const allowAbort = isRxq(keys.join('.'));
						console.log('rxQuery.abort.attemp', { allowAbort });

						if (allowAbort) {
							console.log('%c rxQuery.abort.fired key: ', 'background-color:green', { reason, keys, input$ });
							tempAbort$.next(Date.now());
							tempAbort$.complete();
						}

						abortFn = null;
					};

					const pr_data$ = RxService.from(
						fn(
							restangular.withConfig((c) =>
								c.setDefaultHttpFields({
									timeout: RxService.firstValueFrom(tempAbort$.pipe(RxService.tap(() => console.log('rxQuery.abort.promise.resolved', keys, input$)))),
									cache: getCache(),
								})
							),
							input$
						).then((res) => res)
					).pipe(
						RxService.map((data) => ({ data })),
						RxService.map((/** @type {ng.restangular.IResponse}*/ res) => {
							if (!revalidate$ && !keys.some(isRxm)) {
								// cKeysCleanUpSet.size && cleanUpCKeysSet(cKeysCleanUpSet);
								setCKeys(keys, input$);
								revalidateMap.set(cKeys, { revalidate, stream$: pr_data$, abort: abortFn });
								cKeysCleanUpSet.add(cKeys);
								console.log({ cKeys, cKeysCleanUpSet: [...cKeysCleanUpSet.entries()] });
							}

							let /** @type {{data?:Awaited<ReturnType<typeof fn>>,loading?:boolean,pending?:boolean,error?:boolean,complete?:boolean}} */ resOut = undefined;

							if (res?.data) {
								// console.log('tap.res.plain', res.data.plain());
								lastData = res?.data;
								resOut = { ...res };
								onSuccess && onSuccess(resOut);
							}

							if (res?.error) {
								lastData = undefined;
								resOut = {
									...res,
									get data() {
										return lastData;
									},
								};
								onError && onError(resOut);
							}

							if (res?.complete) {
								resOut = {
									...res,
									get data() {
										return lastData;
									},
								};
								onComplete && onComplete(resOut);
							}

							return resOut;
						}),
						RxService.catchError((error) => RxService.of({ error, loading: false, pending: false })),
						RxService.endWith({
							loading: false,
							pending: false,
							complete: true,
							get data() {
								return lastData;
							},
						}),
						RxService.tap((res) => {
							if (abortFn && (res.complete || res.error || res.data)) {
								console.log('%c rxQuery.abort.deactivate.fired', 'background:green', { keys, input$ });
								abortFn = null;
								revalidateMap.set(cKeys, { ...revalidateMap.get(cKeys), abort: null });
							}
						})
					);

					return RxService.concat(loading$, pr_data$);
				})
			)
			.pipe(
				RxService.switchMap((val) => {
					const obs$ = RxService.of(val);

					// apply replay behavior for rxq
					if (keys.some((key) => key.includes('rxq'))) {
						obs$.pipe(
							RxService.shareReplay({
								bufferSize: 1,
								refCount: true,
							})
						);
					}

					return obs$;
				})
			)
			.pipe(RxService.skipWhile((v) => [v?.data, v?.error, v?.loading, v].every((e) => [undefined, null].includes(e))))
			.pipe(
				RxService.takeUntil(
					destroy$.pipe(
						RxService.tap(() => {
							console.log('rxQuery.destroy$.fired', keys[0], { ...doCleanUp() });
							doCleanUp().removeKey;
						})
					)
				)
			);

		const stream$ = Object.defineProperties(pStream$, {
			value: {
				get() {
					return lastData;
				},
			},
		});

		const destroyFn = () => {
			if (localDestroy$) {
				localDestroy$?.next() && localDestroy$?.complete();
			}
			stream$.complete();
			pStream$.unsubscribe();
		};

		return /** @type  {[typeof stream$, {revalidate: typeof revalidate, input$: typeof input$, enabled$: typeof enabled$, destroyFn: ()=>void, mutate: typeof mutate , enableFn: (enable:boolean)=>void},ObservedValueOf<stream$>['data']]} */ ([
			stream$,
			{ revalidate, input$, enabled$, destroyFn, mutate, enableFn },
			null,
		]);
	}

	const rxRevalidateQueries = (keys = undefined) => {
		if (keys === undefined) {
			getCache().removeAll();
		}

		revalidateMap.forEach(({ revalidate }, _keyFromMap) => {
			if ((keys === undefined || _keyFromMap.includes(keys)) && revalidate) {
				revalidate();
			}
		});
		console.table([['rxRevalidateQueries.fired'], ...revalidateMap.entries()]);
	};

	const rxQueryUtils = (/** @type {string}*/ keys) => revalidateMap.get(keys);

	this.useVersion = useVersion;
	this.wrapWithCache = wrapWithCache;
	this.getCache = getCache;
	this.rxQuery = rxQuery;
	this.rxRevalidateQueries = rxRevalidateQueries;
	this.rxQueryUtils = rxQueryUtils;
}

appModule.service('API', API);

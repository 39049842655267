import { sectionHistoryInfoStatsCmp } from '@srcAjs/components/shared/SectionHistoryInfoStatsCmp/sectionHistoryInfoStatsCmp.component';
import template from './index.html';

/** @type {import('@srcAjs/components2/adminRoutes').IComponentOptions2} */
const paChildSectionHistoryDetails = {
	template,
	bindings: {
		//someInput: '@string,<object,&function'
	},
	transclude: {
		// #slotName: '?optionalPublicName'
	},
	selector: 'paChildSectionHistoryDetails',
	_imports: [sectionHistoryInfoStatsCmp],
};

/** @this {ng.IController} */
function paChildSectionHistoryDetailsController(
	$state,
	/** @type {ng.StateParamsService} */ $stateParams,
	fullnameFilter,
	/** @type {ng.IScope}*/ $scope,
	/** @type {API} */ API,
	/** @type {ErrorService}*/ ErrorService,
	/** @type {EffectService} */ EffectService,
	/** @type {compSetupService} */ compSetupService
) {
	compSetupService(this);
	/**
	 * @type { typeof $scope & {[key:string]: {}|any ,
	 *  section_history:SectionHistory,
	 * section:Section
	 * getSubjectGrade:(param0:SubjectMount)=>SubjectGrade}}
	 */
	const svm = /** @type {any}*/ ($scope);

	svm.coll_attendance = true;
	svm.isReady = false;

	EffectService.setup(this, ({ onInit }) => {
		if (onInit) {
			try {
				if ($stateParams.id === '' || !$stateParams.section_history || !$stateParams.student) {
					return $state.go('pa.dashboard');
				}

				const { student, id: section_history_id } = $stateParams;
				svm.section_history = student.section_histories.find((sh) => sh.id + '' === section_history_id);
				svm.student = student;
			} catch (error) {
				svm.isReady = true;
				ErrorService.handleError(error);
			}
		}
	});
}

paChildSectionHistoryDetails.controller = paChildSectionHistoryDetailsController;

appModule.component('paChildSectionHistoryDetails', paChildSectionHistoryDetails);

export { paChildSectionHistoryDetails };

import { createSlice } from './src/store.mjs';
import * as uuid from 'uuid';

/** @type {ParsedError[]} */
const initialState = [];
const errorSlice = createSlice({
	initialState,
	name: 'errorSlice',
	reducers: {
		set: (state, /** @type {ParsedError[]} */ payload) => [...payload],
		add: (state, /** @type {ParsedError} */ payload) => [...state, { ...payload, uuid: uuid.v1() }],
	},
});

export { errorSlice };

import './trLeaderboardCmp.scss';

import template from './trLeaderboardCmp.tpl.html';

const trLeaderboardCmp = {
	template,
	bindings: {
		school: '<',
		termId: '<',
		term: '<',
	},
};

const XRange = {
	month: {
		week: 5,
		day: 32,
	},
	week: {
		day: 7,
	},
};

class trLeaderboardCmpController {
	class_group_stats;

	teachers_stats;

	numbers;
	school;
	termId;
	term;

	/** @type {SchoolStatsService} */ SchoolStatsService;
	/** @type {ErrorService} */ ErrorService;
	/** @type {lsfactory} */ lsfactory;
	_changes$ = false;

	constructor(
		/** @type {API} */ API,
		lsfactory,
		SchoolStatsService,
		ErrorService,
		/** @type {EffectService} */ EffectService,
		/** @type {compSetupService} */ compSetupService,
		/** @type {$moment} */ $moment,
		/** @type {TRFactory} */ TRFactory,
		/** @type {atomosService} */ atomosService
	) {
		compSetupService(this);
		this.API = API;
		this.ErrorService = ErrorService;
		this.SchoolStatsService = SchoolStatsService;
		this.lsfactory = lsfactory;
		this.numbers = null;
		this.TRFactory = TRFactory;
		this.startDateM = $moment().startOf('week');
		this.startDate = this.startDateM.toDate();
		const cache = { attendance: {}, ents: {} };

		this.updateStartDate = (/**@type {Date} */ date, termId = this.cTermId) => {
			this._lastTermId = termId;
			this.startDateM = $moment(date);
			this.startDate = this.startDateM.toDate();
			const startEnd = this.getStartEndRange(this.startDateM);
			getAssessmentStats({ term_id: this.cTermId, ...startEnd });
			queryAttendanceStats({
				term_ids: [termId],
				section_ids: [],
				statsCols: ['term'],
				filters: [[this.graphInterval, startEnd.start]],
				scope: { level: 'term', period: 'daily' },
			});
		};

		this.getStartEndRange = (/** @type {typeof $moment.Moment} */ end, graphInterval = this.graphInterval) => {
			return {
				get start() {
					return $moment.toYmd($moment.isoToMoment(this.end).startOf(graphInterval));
				},
				end: $moment.toYmd($moment.isoToMoment(end).endOf(graphInterval)),
			};
		};

		// this.queryInputs$ = new RxService.Subject({ school_id: null, term_id: null });
		const [schoolTermStats$, { mutate }] = this.API.rxQuery(
			['rxq.school.stats'],
			(r, /** @type {{school_id,term_id}}*/ inputs) =>
				/** @type {ng.restangular.IPromise<{term,assessment_group_types:[],class_groups:[]}>} */
				(
					r
						.one('school/dash-numbers/' + inputs.school_id)
						.one('term', inputs.term_id)
						.get()
				),
			{
				/** @type {{school_id,term_id}} */
				qInput$: undefined,
				enabled: false,
				destroy$: this.componentDestroy$,
			}
		);
		this.fetchStatsQ = mutate;

		const [attendanceStats$, { mutate: queryAttendanceStats }] = TRFactory.attendanceStatsRxq(this);

		const [assessmentStats$, { mutate: getAssessmentStats }] = API.rxQuery(
			['rxq.assessment.stats'],
			(r, /**@type {{term_id,start,end}} */ { term_id, start, end }) =>
				/** @type {ng.restangular.ICollectionPromise<SubjectAssessmentEntity>} */ (
					r
						.all(
							'subject-assessment-entity?' +
								API.buildParams_v2({
									filters: [{ condition: 'whereBetween', column: 'created_at', value: [start, end] }],
									has_fields: [{ field: 'subject_assessment_group.subject_mount', conditions: [{ condition: 'where', column: 'term_id', value: term_id }] }],
								})
						)
						.getList()
				),
			{ /** @type {any} */ qInput$: undefined, enabled: false, destroy$: this.componentDestroy$ }
		);

		this.renderGraphs = (startDateM = this.startDateM, graphInterval, groupInterval) => {
			if (this.graphInterval === 'week') {
				this.groupInterval = groupInterval = 'day';
			}
			this.updateStartDate(startDateM.toDate());
		};

		this.renderAttendance = (/** @type {Attendance[]} */ attendance, startDate, graphInterval = this.graphInterval, groupInterval = this.groupInterval) => {
			const mappedInterval = { week: 'weekly', day: 'daily' }[groupInterval];
			const { start, end } = this.getStartEndRange(this.startDateM, graphInterval);
			const atKey = [start, graphInterval, groupInterval, attendance.map((at) => [at.term_id, at.day])];
			const maxDays = Math.abs($moment(start).diff($moment(end), groupInterval)) + 1;
			const attendanceHals = () =>
				Array(maxDays)
					.fill(null)
					.map((_, i) => {
						const dateM = $moment(start).add(i, groupInterval);
						const dateYmd_ddd = $moment.toYmd_ddd(dateM);
						const atd = attendance.find((at) => dateM.isSame($moment(at.day), groupInterval));
						const vm = this;
						return {
							title: dateYmd_ddd + '/' + groupInterval[0],
							/** @type {import('@srcAjs/components2/ChartBasicCmp/ChartBasicCmp.component').ItemHal['itemHals'][number]['chartData']} */
							chartData: {
								title: dateYmd_ddd + '/' + groupInterval,
								label: dateYmd_ddd,
								values: atd,
								absent: atd?.[`term_absent_${mappedInterval}`] ?? 0,
								present: atd?.[`term_present_${mappedInterval}`] ?? 0,
								late: atd?.[`term_late_${mappedInterval}`] ?? 0,
								total: atd?.[`term_section_histories_${mappedInterval}`] ?? 0,
								get population() {
									return vm.numbers.students * (groupInterval === 'day' ? 1 : 3);
								},
							},
						};
					});

			cache.attendance[atKey] = cache.attendance[atKey] ?? attendanceHals();
			this.attendanceHals = cache.attendance[atKey];
		};

		this.renderAssessment = (/** @type {SubjectAssessmentEntity[]} */ asmts, startDate, graphInterval = this.graphInterval, groupInterval = this.groupInterval) => {
			const { start, end } = this.getStartEndRange(this.startDateM, graphInterval);

			const entKey = [start, graphInterval, groupInterval, asmts.map((asmt) => asmt.id)];

			cache.ents[entKey] =
				cache.ents[entKey] ??
				/** @type {import('@srcAjs/components2/ChartBasicCmp/ChartBasicCmp.component').ItemHal['itemHals'][number]} */

				Array(XRange[graphInterval][groupInterval])
					.fill(null)
					.map((_, i) => {
						const dateM = $moment.isoToMoment(start).add(i, groupInterval);
						const dateYmd = $moment.toYmd_ddd(dateM);
						const values = asmts.filter((asmt) => ['created_at', 'due', 'issued'].some((field) => dateM.isSame($moment.isoToMoment(asmt[field]), groupInterval)));

						/** @type {import('@srcAjs/components2/ChartBasicCmp/ChartBasicCmp.component').ItemHal['itemHals']} */
						return {
							title: dateYmd + '/' + groupInterval[0],
							label: dateYmd,
							chartData: {
								title: dateYmd + '/' + groupInterval,
								values,
								all: values.length,
								...Object.fromEntries(
									['created_at', 'date_issued', 'date_due'].map((field) => [field + '_vals', asmts.filter((asmt) => $moment.isoToMoment(asmt[field]).isSame(dateM, groupInterval))])
								),
								get created_at() {
									return this.created_at_vals.length;
								},
								get date_issued() {
									return this.date_issued_vals.length;
								},
								get date_due() {
									return this.date_due_vals.length;
								},
								label: dateYmd,
							},
						};
					});

			this.dayHals = cache.ents[entKey];
		};

		EffectService.setup(
			this,
			({ onInit, onChanges }) => {
				if (onInit) {
					if (this.term) {
						this.startDateM = $moment.min($moment(), $moment.isoToMoment(this.term.end));
					}
					/** @type {Parameters<typeof $moment.Moment.startOf>[0]} */
					this.graphInterval = 'week';
					/** @type {Parameters<typeof $moment.Moment.startOf>[0]} */
					this.groupInterval = 'day';

					atomosService(schoolTermStats$, this).stream$.subscribe(({ data, loading, error }) => {
						this.isReady = !loading;
						if (error) return this.ErrorService.handleError(error);
						data && this.renderSchoolStats(data);
					});

					atomosService(attendanceStats$, this).stream$.subscribe(({ data, error, loading }) => {
						this.attendanceLoading = loading;
						if (error) return this.ErrorService.handleError(error);
						if (!data) return;

						this.attendanceRaw = data?.plain();
						this.renderAttendance(this.attendanceRaw);
					});

					atomosService(assessmentStats$, this).stream$.subscribe(({ data, error, loading }) => {
						this.assessmentLoading = loading;
						if (error) return this.ErrorService.handleError(error);
						if (!data) return;

						//make assessmentHals
						this.assessmentsRaw = data?.plain();
						this.renderAssessment(this.assessmentsRaw);
					});
				}

				if (onChanges || this.termId || this.school.id === (+this.term?.school_id ?? this.term.year.school_id)) {
					this.pushNextQueryInput(this.school?.id, this.cTermId);

					if (this._lastTermId !== this.cTermId) this.updateStartDate(this.startDateM.toDate());
				}
			},
			() => [this.school, this.termId, this.term]
		);
	}

	pushNextQueryInput(school_id, term_id) {
		return !!school_id && !!term_id && this.fetchStatsQ({ school_id, term_id });
	}

	get cTermId() {
		return this.termId ?? this.term?.id;
	}

	renderSchoolStats = (data) => {
		this.numbers = data.plain();
		console.log(this.numbers);

		const {
			term: { teachers, subject_mounts },
			subject_assessment_types,
			class_groups,
		} = angular.copy(this.numbers);
		this.class_group_stats = [...class_groups].map((cg) =>
			this.SchoolStatsService.getSubjectMountStats([...subject_mounts], this.SchoolStatsService.filterByClassGroup(cg), [...subject_assessment_types], cg, 'class_group')
		);

		this.teachers_stats = [...teachers].map((teacher) =>
			this.SchoolStatsService.getSubjectMountStats([...subject_mounts], this.SchoolStatsService.filterByTeacher(teacher), [...subject_assessment_types], teacher, 'teacher')
		);
	};
}

trLeaderboardCmp.controller = trLeaderboardCmpController;

appModule.component('trLeaderboardCmp', trLeaderboardCmp);

import template from './index.html';

/** @type {import('@srcAjs/components2/adminRoutes').IComponentOptions2} */
const trSubjectSubStudentsAnalytics = {
	template,
	bindings: {
		//someInput: '@string,<object,&function'
	},
	transclude: {
		// #slotName: '?optionalPublicName'
	},
};

function trSubjectSubStudentsAnalyticsCmpController(
	$scope,
	$state,
	$rootScope,
	$stateParams,
	$timeout,
	Restangular,
	/**@type {ErrorService} */ ErrorService,
	capitalizeInitialFilter,
	/**@type {TRFactory} */ TRFactory,
	/**@type {EffectService} */ EffectService,
	/**@type {atomosService} */ atomosService,
	/** @type {compSetupService} */ compSetupService,
	/** @type {auth} */ auth
) {
	compSetupService(this, trSubjectSubStudentsAnalytics.bindings);
	const vm = this;
	const [gradebookApi$, { revalidate: revalidateGradebook, mutate }] = TRFactory.gradebookRxQ(vm);

	$rootScope.isCTermDisabled = true;
	$scope.revalidateGradebook = revalidateGradebook;

	function array_sort(arrToSort, strObjParamToSortBy, sortAscending) {
		if (sortAscending == undefined) sortAscending = true; // default to true

		if (sortAscending) {
			arrToSort.sort(function (a, b) {
				return a[strObjParamToSortBy] > b[strObjParamToSortBy];
			});
		} else {
			arrToSort.sort(function (a, b) {
				return a[strObjParamToSortBy] < b[strObjParamToSortBy];
			});
		}
	}

	function colores_google(n) {
		var colores_g = [
			'#3a5de8',
			'#dc3912',
			'#ff9900',
			'#109618',
			'#990099',
			'#0099c6',
			'#dd4477',
			'#66aa00',
			'#b82e2e',
			'#316395',
			'#994499',
			'#22aa99',
			'#aaaa11',
			'#6633cc',
			'#e67300',
			'#8b0707',
			'#651067',
			'#329262',
			'#5574a6',
			'#3b3eac',
		];
		return colores_g[n % colores_g.length];
	}

	$scope.options = {
		chart: {
			type: 'multiBarChart',
			height: 320,
			margin: {
				top: 60,
				right: 20,
				bottom: 45,
				left: 50,
			},
			useInteractiveGuideline: false,
			color: function (d, i) {
				var key = i === undefined ? d : i;
				return d.color || colores_google(key);
			},
			clipEdge: true,
			duration: 500,
			stacked: true,
			xAxis: {
				axisLabel: 'Student',
				showMaxMin: true,
				tickFormat: function (d) {
					return d;
				},
			},
			yAxis: {
				axisLabel: 'Performance (Final Grade)',
				axisLabelDistance: -15,
				tickFormat: function (d) {
					return d3.format(',.1f')(d);
				},
			},
		},
	};

	function gen_header(assessment_groups) {
		var hdr = [];
		_.each(assessment_groups, function (group) {
			_.each(group.subject_assessment_entities, function (entity) {
				if (entity.gradable) {
					hdr.push(entity);
				}
			});
		});
		return hdr;
	}

	function gen_scores(header_array) {
		var scores = [];
		_.each($scope.myData.assessment_data.term_students, function (student) {
			var std_arr = [];
			_.each(header_array, function (assessment) {
				var bx = {
					score: null,
					comment: null,
					total: null,
				};
				_.each(assessment.subject_assessment_scores, function (score) {
					if (student.id == score.student_term_subject_id) {
						bx = score;
						bx.total = assessment.total;
					}
				});
				std_arr.push(bx);
			});
			scores.push(std_arr);
		});
		return scores;
	}

	function getScoreFromLot(student_term_subject_id, scores) {
		for (var x = scores.length - 1; x >= 0; x--) {
			if (scores[x].student_term_subject_id == student_term_subject_id) {
				return scores[x].score;
			}
		}
		return undefined; // Meaning Error
	}

	function addin_caculated_weights(term_students, assessment_groups) {
		// This is a verified calculation [Server Side Compliant]
		for (var i = 0; i < term_students.length; i++) {
			var total_groups = 0;
			var student_term_subject_id = term_students[i].id;
			for (var j = 0; j < assessment_groups.length; j++) {
				var sum_totals = 0;
				var sum_scores = 0;
				var group_weight = assessment_groups[j].subject_assessment_group.weight;
				for (let entity of Object.values(assessment_groups[j].subject_assessment_entities)) {
					if (entity.subject_assessment_entity.gradable) {
						// Factor in the grading
						var score = getScoreFromLot(student_term_subject_id, Object.values(entity.subject_assessment_scores));
						score = parseFloat(score ?? 0);
						var total = parseFloat(entity.subject_assessment_entity.total);
						sum_totals += total;
						sum_scores += score;
					}
				}
				sum_totals = sum_totals == 0 ? 1 : sum_totals;
				var total_percent = Math.round((sum_scores / sum_totals) * 100);
				var real_weighted_percent = (total_percent / 100) * group_weight;
				total_groups += real_weighted_percent;
			}
			term_students[i].avg_per = total_groups;
		}
	}

	function addin_fake_caculated_weights(term_students, score_data) {
		// TODO: This calculation needs to be verified ...
		for (var i = 0; i < term_students.length; i++) {
			var dat = score_data[i];
			var score_total = 0;
			var score_sums = 0;
			for (var j = 0; j < dat.length; j++) {
				if (dat[j].total != null && dat[j].score != null) {
					score_total += parseFloat(dat[j].total);
					score_sums += parseFloat(dat[j].score);
				}
			}
			if (score_total == 0) {
				term_students[i].avg_per = -1;
			} else {
				var tavg = score_sums / score_total;
				term_students[i].avg_per = tavg * 100;
			}
		}
	}

	function generateChartData(term_students) {
		var ret_data = [
			{
				key: 'Weighted Percentage (%) - Final Score',
				values: [],
			},
		];

		var sr = [];
		_.each(term_students, function (student) {
			var tx = student.avg_per;
			if (tx < 0) {
				tx = 0;
			}
			var p = {
				x: capitalizeInitialFilter(student.section_history.student.lastname) + '. ' + student.section_history.student.firstname,
				y: tx,
				orderNum: tx,
			};
			sr.push(p);
		});

		sr.sort(function (a, b) {
			if ((typeof b.orderNum === 'undefined' && typeof a.orderNum !== 'undefined') || a.orderNum > b.orderNum) {
				return -1;
			}
			if ((typeof a.orderNum === 'undefined' && typeof b.orderNum !== 'undefined') || a.orderNum < b.orderNum) {
				return 1;
			}
			return 0;
		});

		_.each(sr, function (sx) {
			ret_data[0].values.push(sx);
		});

		return ret_data;
	}

	EffectService.setup(this, ({ onInit }) => {
		if (onInit) {
			atomosService(TRFactory.trServiceAtom.stream$, this).stream$.subscribe((atom) => {
				const data = atom;
				$scope.school = auth.getEngagedUserRole().school;
				$scope.section = data.selectCachedSubjectMount().section;
				$scope.subjectMount = data.selectCachedSubjectMount();

				$scope.hallableSubMount = /** @type {SubjectMount} */ ({
					...data.selectCachedSubjectMount().subject_mount,
					subject_assessment_groups: Object.values(data.selectCachedSubjectMount().subject_assessment_groups).map((ag) => ({
						...ag.subject_assessment_group,
						subject_assessment_entities: Object.values(ag.subject_assessment_entities).map((cae) => ({
							...cae.subject_assessment_entity,
							subject_assessment_scores: Object.values(cae.subject_assessment_scores),
						})),
					})),
				});

				// loadStudentAnalytics(data.selectedTerm, false);
				// mutate(data.selectCachedSubjectMount()).then((res) => renderUi(res.data), ErrorService.handleError);
				mutate(data.selectCachedSubjectMount().subject_mount);
			});

			gradebookApi$.subscribe(({ data, error, loading }) => {
				$scope.isReady = !loading;
				if (error) return ErrorService.handleError(error);

				renderUi(data);
			});
		}
	});

	function renderUi(/** @type {import('rxjs').ObservedValueOf<typeof gradebookApi$>['data']} */ res) {
		if (!res) return;

		const response = res.plain();
		$scope.myData = response;

		let cSubMount = TRFactory.trServiceAtom.get().selectCachedSubjectMount();
		$scope.myData.assessment_data.term_students = cSubMount.subject_mount.term_students;

		$scope.header_array = gen_header($scope.myData.assessment_data.subject_assessment_groups);
		$scope.score_data = gen_scores($scope.header_array);
		console.dir($scope.myData.assessment_data.term_students);

		addin_caculated_weights($scope.myData.assessment_data.term_students, Object.values(cSubMount.subject_assessment_groups));
		// addin_caculated_weights($scope.myData.assessment_data.term_students, $scope.myData.assessment_data.subject_assessment_groups);
		// $rootScope.isCTermLoading = false
		$scope.data = generateChartData($scope.myData.assessment_data.term_students);
		$timeout(function () {
			window.dispatchEvent(new Event('resize'));
		}, 1000);
	}
}

trSubjectSubStudentsAnalytics.controller = trSubjectSubStudentsAnalyticsCmpController;

appModule.component('trSubjectSubStudentsAnalytics', trSubjectSubStudentsAnalytics);

trSubjectSubStudentsAnalytics.selector = 'trSubjectSubStudentsAnalytics';

export { trSubjectSubStudentsAnalytics };

import template from './PanelCmp.tpl.html';

/** @type {import('@srcAjs/components2/adminRoutes').IComponentOptions2} */
const panelCmp = {
	template,
	bindings: {
		//someInput: '@string,<object,&function'
		ariaLabel: '@',
		open: '<',
		onClose: '&',
		originRef: '<',
		onOpen: '&',
		clickOutsideToClose: '<',
		fullscreen: '<',
		dialogClasses: '@',
		xPositionAlignOffsetStartEnd: '@',
		yPositionAlignTopBottomBelowAbove: '@',
	},
	transclude: true,
};

/** @type {import('angular').IController} */
function PanelCmpController(
	/** @type {ng.material.IPanelService} */ $mdPanel,
	/** @type {import('angulartics').IAnalyticsService} */ $analytics,
	/** @type {ErrorService}*/ ErrorService,
	/** @type {EffectService} */ EffectService,
	/** @type {ng.ILocationService} */ $location,
	/** @type {ng.IScopeService} */ $scope,
	/** @type {compSetupService} */ compSetupService,
	/** @type {PanelUrlService} */ PanelUrlService,
	/** @type {atomosService} */ atomosService
) {
	compSetupService(this, panelCmp.bindings);
	const trackDialog = (/** @type {'panelCmp:closed'|'panelCmp:opened'} */ event) => {
		return $analytics.eventTrack(event, { category: 'dialog_event', ariaLabel: this.ariaLabel ?? 'unaamedDialog' });
	};

	this.cleanUpUrlListener = () => null;

	this.activateUrlListener = () => {
		this.cleanUpUrlListener();
		const removeUrlListener = $scope.$on('$locationChangeSuccess', (event, newUrl) => {
			const navigatedAway = !newUrl?.includes('panel');
			// const navigatedAway = $location.search('dialog') !== this.ariaLabel;
			if (navigatedAway && this.open) {
				this.cleanUpUrlListener();
				this.doClose();
			}
		});
		const removePanelUrlServiceSub = atomosService(PanelUrlService._atom.stream$, this).stream$.subscribe((urls) => {
			if (!urls?.includes(this.ariaLabel)) {
				this.cleanUpUrlListener();
				this.doClose();
			}
		});

		this.cleanUpUrlListener = () => {
			removeUrlListener();
			removePanelUrlServiceSub.unsubscribe();
		};
	};

	EffectService.setup(
		this,
		({ onChanges }) => {
			if (onChanges && !this.open) {
				this.doClose();
			}

			if (!this.open || !onChanges) {
				return;
			}

			const panelPosition = $mdPanel
				.newPanelPosition()
				.relativeTo(angular.element(this.originRef))
				.addPanelPosition(this.xPositionAlignOffsetStartEnd ?? $mdPanel.xPosition.ALIGN_END, this.yPositionAlignTobBottomBelowAbove ?? $mdPanel.yPosition.BELOW);

			const animation = $mdPanel.newPanelAnimation();
			animation.withAnimation($mdPanel.animation.FADE).duration(200);

			const /** @type {ng.material.IPanelConfig} */ config = {
					attachTo: angular.element('body'),
					contentElement: this.panelContainerRef,
					// controller: 'NotificationsController',
					// controllerAs: 'ctrl',
					animation,
					position: panelPosition,
					panelClass: 'panel-cmp',
					clickOutsideToClose: this.clickOutsideToClose ?? true,
					escapeToClose: true,
					focusOnOpen: true,
					trapFocus: true,
					zIndex: 150,
					groupName: ['standardNavMenuPanelGroup'],
					closeTo: angular.element(this.originRef),
					openFrom: angular.element(this.originRef),
					onCloseSuccess: (_, r) => this.doClose(r),
					onOpenComplete: this.doOpen,
					onRemoving: () => this.doClose(),
				};

			$mdPanel
				.open(config)
				.then((panelRef) => (this.panelRef = panelRef))
				.catch((err) => {
					console.log('panelCmp.catch.fired');
					ErrorService.handleError(err);
					this.doClose();
				});
		},
		() => [!!this.open]
	);

	this.doClose = (data = null) => {
		trackDialog('panelCmp:closed');
		this.cleanUpUrlListener();
		this.open = false;

		if (PanelUrlService._atom.get()?.includes(this.ariaLabel)) {
			PanelUrlService.dispatch('pop', this.ariaLabel);
		}
		$location.search('panel', PanelUrlService._atom.get()).replace();

		// this.panelRef = undefined;
		if (this.onClose) {
			this.onClose({ open: false, data });
		}
		this.panelRef?.hide(data);
		this.panelRef = undefined;
		return;
	};

	this.doOpen = () => {
		trackDialog('panelCmp:opened');
		this.open = true;

		PanelUrlService.dispatch('push', this.ariaLabel);
		$location.search('panel', PanelUrlService._atom.get()).replace();

		if (this.onOpen) {
			this.onOpen({ open: true });
		}

		this.activateUrlListener();
	};
}

panelCmp.controller = PanelCmpController;

appModule.component('panelCmp', panelCmp);

panelCmp.selector = 'panelCmp';

export { panelCmp };

import template from './index.html';

/** @type {import('@srcAjs/components2/adminRoutes').IComponentOptions2} */
const saSchoolSetupClassGroupsDialogsChooseLevel = {
	template,
	bindings: {
		// someInput: '@string,<object,&function'
		classGroup: '<',
		onClose: '&',
	},
	transclude: {
		// #slotName: '?optionalPublicName'
	},
	selector: 'saSchoolSetupClassGroupsDialogsChooseLevel',
};

/** @this {ng.IController} */
function saSchoolSetupClassGroupsDialogsChooseLevelController(/** @type {API} */ API, lsfactory, $state, /** @type {EffectService} */ EffectService) {
	const vm = this;
	vm.levels = [];
	vm.isProcessing = false;
	vm.box = {};

	EffectService.setup(this, ({ changes }) => {
		if (changes) {
			return;
		}

		vm.grp = vm.classGroup;

		const classes = API.wrapWithCache({
			callable: true,
			restangularElement: (r) => r.all('class/by-class-group/' + vm.classGroup.id),
		})();

		classes.getList().then((data) => {
			vm.classes = data.plain();
		});
	});

	vm.goto = function (sclass) {
		vm.hide();
		if (sclass !== undefined) {
			lsfactory.setEnv('currentClassSelected', sclass);
			lsfactory.setEnv('backLog', 'sa.school_setup_class_groups');
			$state.go('sa.school_setup_classes_detail.sections', { id: sclass.id });
		}
	};

	vm.hide = function () {
		vm.onClose();
	};

	vm.cancel = function () {
		vm.onClose();
	};

	vm.answer = function (_answer) {
		vm.onClose();
	};
}

saSchoolSetupClassGroupsDialogsChooseLevel.controller = saSchoolSetupClassGroupsDialogsChooseLevelController;

appModule.component('saSchoolSetupClassGroupsDialogsChooseLevel', saSchoolSetupClassGroupsDialogsChooseLevel);

export { saSchoolSetupClassGroupsDialogsChooseLevel };

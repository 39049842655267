import template from './index.html';

/** @type {import('@srcAjs/components2/adminRoutes').IComponentOptions2} */
const trSubjectDialogsAddEntity = {
	template,
	bindings: {
		//someInput: '@string,<object,&function'
		onClose: '&',
		onOpen: '&',
		action: '@',
		group: '<',
		entity: '<',
		term: '<',
	},
	transclude: {
		// #slotName: '?optionalPublicName'
	},
	selector: 'trSubjectDialogsAddEntity',
};

/** @this {ng.IController} */
function TrSubjectDialogsAddEntityController(
	$scope,
	$mdToast,
	/** @type {ErrorService}*/ ErrorService,
	// _ACTION,
	// /** @type {Term}*/ _TERM,
	// /** @type {SubjectAssessmentGroup}*/this.group,
	// /** @type {SubjectAssessmentEntity}*/ this.entity,
	// /** @type  {EffectService} */ EffectService,
	/** @type {atomosService} */ atomosService,
	/** @type {compSetupService} */ compSetupService,
	/** @type {TRFactory} */ TRFactory,
	/** @type {EffectService} */ EffectService,
	$stateParams,
	/** @type {Restangular} */ Restangular,
	/** @type {$moment} */ $moment
) {
	compSetupService(this, trSubjectDialogsAddEntity.bindings);

	const vm = this;

	EffectService.setup(
		this,
		({ onChanges }) => {
			if (onChanges) {
				// $scope.theGroup = lsfactory.getEnv("dialogAssessmentGroup");
				$scope.box = {};
				$scope.theGroup = this.group;
				const { title: ag_title, subject_assessment_entities: sae_s } = this.group;

				$scope.title_placeholder = `${ag_title}#${sae_s.length + 1}`;
				$scope.isProcessing = false;
				$scope.action = this.action;
				$scope.term = this.term;
				$scope.action = !this.entity ? 'create' : 'update';
				$scope.box = { ...(this.entity ?? setupCreate()) };
				$scope.box.title = !$scope.box?.title?.length ? $scope.title_placeholder : $scope.box.title;
			}
		},
		() => [this.action, this.group, this.entity, this.term]
	);

	function setupCreate() {
		return {
			date_issued: new Date(),
			date_due: new Date(),
			title: $scope.title_placholder,
			subject_assessment_group_id: $scope.theGroup.id,
			total: null,
			publish: false,
			gradable: true,
			sync_status: 'new_unsynced',
			id: `new_${Date.now()}`,
			notorizable: /homework/i.test(vm.group?.assessment_type?.title || vm.group.title),
		};
	}

	// Code goes here
	function right_format(moment_date) {
		if (moment_date != null && moment_date !== undefined) {
			return moment_date.format('YYYY-MM-DD');
		}
	}

	$scope.submit = (action, box) => {
		try {
			if (typeof action != 'string' || !box) throw new Error('Invalid action or box parameter provided to submit handler');

			switch (action.toLowerCase()) {
				case 'create':
					addEntity(box);
					break;
				case 'update':
					editEntity(box);
					break;
				default:
					throw new Error('Invalid action provided');
			}
		} catch (error) {
			ErrorService.handleError(error);
		}
	};

	function addEntity(box) {
		$scope.isProcessing = true;
		// Default properties
		var data = {
			title: box.title,
			subject_assessment_group_id: $scope.theGroup.id,
			total: box.total,
			date_issued: $moment(box.date_issued).format('YYYY-MM-DD'),
			date_due: $moment(box.date_due).format('YYYY-MM-DD'),
			publish: false,
			gradable: true,
			sync_status: 'new_unsynced',
			id: `new_${Date.now()}`,
			notorizable: box.notorizable,
		};

		TRFactory.updateAtom((atom) => {
			const sh_cache = atom.termsCache;
			let term_id = atom.selectedTermId;
			sh_cache[term_id].subject_mounts[$scope.theGroup.subject_mount_id].subject_assessment_groups[$scope.theGroup.id].subject_assessment_entities[data.id] = {
				subject_assessment_entity: data,
				subject_assessment_scores: {},
			};
			return atom;
		});

		$scope.isProcessing = false;

		$mdToast.show($mdToast.simple().textContent('Assessment created offline. Remember; sync local changes regularly.').hideDelay(15000).action('OK').highlightAction(true));

		vm.onClose();
	}

	function editEntity(box) {
		var data = {
			id: box.id,
			title: box.title,
			subject_assessment_group_id: $scope.theGroup.id,
			total: box.total,
			date_issued: right_format($moment(box.date_issued)),
			date_due: right_format($moment(box.date_due)),
			notorizable: box.notorizable,
		};

		$scope.isProcessing = true;

		Restangular.all('/subject-assessment-entity/' + data.id)
			.customPUT(data)
			.then((data) => data.plain())
			.then((data) => {
				$scope.isProcessing = false;
				TRFactory.updateAtom((atom) => {
					const c_ag = atom.termsCache[atom.selectedTermId].subject_mounts[$stateParams.subject_mount_id].subject_assessment_groups[data.subject_assessment_group_id];
					c_ag.subject_assessment_entities[data.id].subject_assessment_entity = data;
					return atom;
				});

				vm.onClose({ data });
			}, ErrorService.handleError)
			.finally(() => vm.onClose());
	}

	$scope.cancel = () => this.onClose();
}

trSubjectDialogsAddEntity.controller = TrSubjectDialogsAddEntityController;

appModule.component('trSubjectDialogsAddEntity', trSubjectDialogsAddEntity);

export { trSubjectDialogsAddEntity };

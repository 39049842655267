import template from './index.html';

/** @type {import('@srcAjs/components2/adminRoutes').IComponentOptions2} */
const trSubjectDialogsAddGroupCmp = {
	template,
	bindings: {
		//someInput: '@string,<object,&function'
		agroups: '<',
		group: '<',
		types: '<',
		subjectMount: '<',
		onClose: '&',
	},
	transclude: {
		// #slotName: '?optionalPublicName'
	},
	selector: 'trSubjectDialogsAddGroupCmp',
};

/** @this {ng.IController} */
function trSubjectDialogsAddGroupController(
	$scope,
	$mdDialog,
	/**@type {ErrorService}*/ ErrorService,
	SubjectAssessmentGroup,
	/**@type {EffectService} */ EffectService,
	/** @type {compSetupService} */ compSetupService,
	/** @type {Restangular} */ Restangular,
	/** @type {API} */ API,
	/** @type {DialogService} */ DialogService
) {
	compSetupService(this, trSubjectDialogsAddGroupCmp.bindings);
	this.computeGroupSum = () => this.agroups.reduce((acc, g) => acc + (g.weight ?? 0), 0);
	this.computeUnallocated = () => 100 - this.computeGroupSum();
	$scope.computeUnallocated = this.computeUnallocated;

	const [mxAssessmengGroup$, { mutate: mutateSubjectAssessmentGroup }] = API.rxQuery(
		['rxm.subject-assessment-group'],
		(r, /**@type {SubjectAssessmentGroup} */ data) => {
			const apiRes = r.all(['subject-assessment-group', data?.id ?? null].filter((x) => x).join('/'));
			return /** @type {ng.restangular.IPromise<SubjectAssessmentGroup>} */ (data.id ? apiRes.patch(data) : apiRes.post(data));
		},
		{ /** @type {any} */ qInput$: undefined, enabled: false, destroy$: this.componentDestroy$ }
	);

	EffectService.setup(
		this,
		({ onChanges }) => {
			if (onChanges) {
				$scope.isProcessing = false;
				this.action = typeof this.group?.length === 'number' ? 'create' : 'update';
				const disabledAgTypeIds = this.agroups.map((ag) => ag?.subject_assessment_type?.id);
				$scope.types = this.types.map((at) => ({ ...at, disabled: disabledAgTypeIds.includes(at.id) }));

				$scope.agroups = this.agroups;
				$scope.current_sum = this.computeGroupSum();
				$scope.real_sum = this.computeUnallocated();
				$scope.maxWeight = this.computeUnallocated() + (this.group.weight ?? 0);

				$scope.subjectMount = this.subjectMount;

				const subject_assessment_type = this.types.find((ty) => ty.id === this.group.subjct_assessment_type_id) ?? $scope.types.filter((_type) => !_type.disabled).at(0);

				$scope.box =
					this.action === 'create'
						? {
								subject_mount_id: this.subjectMount.id,
								subject_assessment_type,
								subject_assessment_type_id: subject_assessment_type?.id,
								title: subject_assessment_type?.title,
						  }
						: { ...this.group };

				const { subjectMount, agroups, types } = this;
				console.log({ subjectMount, agroups, types, current_sum: $scope.current_sum });
			}
		},
		() => [this.subjectMount, this.agroups, this.current_sum, this.types]
	);

	$scope.totalWeightsExceeded = {
		get() {
			return this.computeGroupSum() === 100 || this.types.filter((d) => !d.disabled).length;
		},
	};

	$scope.submit = (/**@type {SubjectAssessmentGroup} */ data, action = this.action) => {
		$scope.isProcessing = true;

		mutateSubjectAssessmentGroup(data).then((res) => {
			const { error, data, loading } = res;
			$scope.isProcessing = loading;
			if (error && ErrorService.handleError(error)) {
				return;
			}

			DialogService.dispatch('toast', 'Success: assessment group updated');

			this.onClose({ group: data.plain() });
		});
	};

	$scope.update = (data) => {
		$scope.isProcessing = true;
	};
}

trSubjectDialogsAddGroupCmp.controller = trSubjectDialogsAddGroupController;

appModule.component('trSubjectDialogsAddGroupCmp', trSubjectDialogsAddGroupCmp);

export { trSubjectDialogsAddGroupCmp };

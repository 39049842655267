import template from './AccountPanelCardCmp.tpl.html';

import './AccountPanelCardCmp.scss';

const accountPanelCardCmp = {
	template,
	bindings: {
		onClose: '&',
	},
};

function AccountPanelCardCmpController(
	$state,
	/** @type {ng.material.IDialogService} */ $mdDialog,
	CONST_APP_BUILD_TIME,
	/** @type {configService} */ configService,
	$window,
	/** @type {auth} */ auth,
	/** @type {ErrorService} */ ErrorService,
	/** @type {EffectService} */ EffectService,
	/** @type {navDialogAtomsService} */ navDialogAtomsService
) {
	const vm = this;
	vm.buildDate = 'Date ' + CONST_APP_BUILD_TIME;
	vm.buildVersion = configService.config.CONST_APP_VERSION;
	vm.openDialog$ = navDialogAtomsService.openDialog$;

	// this.panelRefCtrl = null;

	EffectService.setup(this, ({ onInit }) => {
		if (onInit) {
			vm.showing = 'loading';
			// vm.user = $localStorage.user;
			vm.user = auth.getEngagedUserRole().user;
			vm.engaged_user_role = auth.getEngagedUserRole();
		}
	});

	vm.showAbout = function () {
		vm.hide();

		$mdDialog.show({
			escapeToClose: true,
			clickOutsideToClose: true,
			templateUrl: 'views/pages/roles/sa/dialogs/about_schrep.html',
			controller: 'AboutDialogController',
		});
	};

	vm.hide = () => {
		this.onClose(false);
	};

	vm.logout = function () {
		// vm.hide();
		// $rootScope.$broadcast('system.logout', {});
		vm.hide(
			auth
				.logout()
				.catch(ErrorService.handleError)
				.finally(() => {
					$state.go('access.signin');
				})
		);
	};

	vm.gotoSettings = () => {
		vm.hide();
		switch (vm.engaged_user_role.role.short) {
			case 'sa':
				$state.go('sa.profile');
				break;
			case 'tr':
				$state.go('tr.home.profile');
				break;
			case 'aa':
				break;

			default:
				break;
		}
	};

	vm.getImageCSS = function (image) {
		return 'url(' + image + ')';
	};

	const openRoleSwitcher = ($event) => {
		$mdDialog.show({
			bindToController: true,
			controllerAs: '$ctrl',
			template: "<md-dialog><switch-role-cmp flex-gt-md='70' flex='95'></switch-role-cmp></md-dialog>",
			controller: 'DumbController',
			autoWrap: false,
			clickOutsideToClose: true,
		});
	};

	vm._openRoleSwitcher = ($event) => {
		vm.hide();
		openRoleSwitcher();
	};

	vm.checkForUpdate = () => {
		console.log('checkForUpdate.reload');
		$window.location.reload();
	};

	vm.openManageAccountDialog = () => {
		const user = auth.getEngagedUserRole().user;

		vm.hide();

		$mdDialog.show({
			template: '<md-dialog><manage-account-cmp class="m-sm h-full" dialog-ctrl="$ctrl" layout layout-align="center center" user="$ctrl.user"></manage-account-cmp></md-dialog>',
			controller: 'DumbDialogController',
			controllerAs: '$ctrl',
			locals: {
				user,
			},
			bindToController: true,
			autoWrap: false,
			multiple: true,
		});
	};
}

accountPanelCardCmp.controller = AccountPanelCardCmpController;
appModule.component('accountPanelCardCmp', accountPanelCardCmp);

const SLICE_STORE_KEY = 'saTeacherSliceStore';

function saTeachersService(
	/** @type {AppStore}*/ AppStore,
	/** @type {Record<string,Record<string,any>>} */ $localStorage,
	/** @type {RxService} */ RxService,
	/** @type {API} */ API
) {
	const vm = this;
	vm.teachersStore$ = AppStore.selector((store) => store.saTeachers);
	const { saTeachers: saTeacherActions } = AppStore.actions;

	AppStore.dispatch(saTeacherActions.setTeachers($localStorage.env[SLICE_STORE_KEY] ?? []));

	const actions = {
		setTeachers(/** @type {Teacher[]} */ teachers) {
			$localStorage.env[SLICE_STORE_KEY] = teachers;
			return AppStore.dispatch(saTeacherActions.setTeachers(teachers));
		},

		updateTeacher(/** @type {Teacher} */ teacher) {
			const tempTeachers = vm.teachersStore$.value.teachers.map((_teacher) => (_teacher.id === teacher.id ? teacher : _teacher));
			return this.setTeachers(tempTeachers);
		},

		deleteTeacher(/** @type {Teacher} */ teacher) {
			const tempTeachers = vm.teachersStore$.value.teachers.filter((_teacher) => _teacher.id !== teacher.id);
			return this.setTeachers(tempTeachers);
		},
		getTeachers({ term, refresh = true, scope_by_term = true, school }) {
			AppStore.dispatch(saTeacherActions.setSelectedTermId(term.id));
			const params = {
				filters: [{ condition: 'where', column: 'school_id', value: school.id }],
				fields: null,
				has_fields: null,
			};

			if (scope_by_term) {
				params.fields = [
					{ field: 'subject_mounts', condition: 'withCount', conditions: [{ condition: 'where', column: 'term_id', value: term.id }] },
					{
						field: 'sections',
						condition: 'withCount',
						conditions: [{ condition: 'where', column: 'term_id', value: term.term_type.version === '1.2' ? term.id : 'null' }],
					},
					{ field: 'user', condition: 'with' },
					{ field: 'country', condition: 'with' },
				];
			}

			const teachers = API.wrapWithCache({
				refresh,
				restangularElement: (r) => r.all(`teacher?${API.buildParams_v2(params)}`),
			});

			return teachers.getList().then((data) => {
				this.setTeachers(data.plain());
			});
		},
	};

	/** @template {keyof actions} T */
	vm.dispatch = (/**  @type {T} */ actionType, /** @type {Parameters<actions[T]>} */ ...payload) => /** @type {ReturnType<actions[T]>} */ (actions[actionType](...payload));

	return vm;
}

appModule.factory('saTeachersService', saTeachersService);

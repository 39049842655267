import { saGuardiansDialogsChildSelector } from '../SaGuardiansDialogsChildSelector/index.component';
import { saGuardiansToolbar } from '../SaGuardiansToolbar/index.component';
import template from './index.html';

/** @type {import('@srcAjs/components2/adminRoutes').IComponentOptions2} */
const saGuardiansDetail = {
	template,
	bindings: {
		//someInput: '@string,<object,&function'
		IGuardian: '<iguardian',
	},
	transclude: {
		// #slotName: '?optionalPublicName'
	},
};

function saGuardiansDetailCmpController(
	SAFactory,
	$scope,
	/** @type {import('angular-material').material.IDialogService} */ $mdDialog,
	lsfactory,
	Restangular,
	Guardian,
	ErrorService,
	$stateParams,
	/** @type {API} */ API
) {
	this.$onInit = () => {
		SAFactory.loadEnvironment();
		SAFactory.loadNotifications();
		const IGuardian = this.IGuardian;
		$scope.school = lsfactory.getEnv('school');
		$scope.guardian = IGuardian;
		$scope.students = [];
		$scope.term_id = $stateParams.term_id ?? null;

		$scope.$on('editGuardian.newEntity', function (event, new_guardian) {
			Guardian.one(
				IGuardian.id +
					`/?${API.buildParams_v2({
						filters: [{ condition: 'where', column: 'id', value: $stateParams.id }],
						has_fields: null,
						fields: [
							{
								condition: 'with',
								field: 'students',
								conditions: [{ condition: 'whereDoesntHave', column: 'student_enrollment_states.enrollment_state|where|state', value: 'archived' }],
							},
						],
					})}`
			)
				.get()
				.then((res) => ($scope.guardian = res.plain()))
				.catch(ErrorService.handleError);
		});

		$scope.getClass = function (code) {
			if (code !== undefined && code != null) {
				return 'flag-icon flag-icon-' + code.toLowerCase();
			}
		};

		$scope.doOpenChildSelector = (open = true) => {
			$scope.showChildSelectorDialog = open;
		};

		$scope.doCloseChildSelector = (open = false) => {
			$scope.showChildSelectorDialog = open;
		};

		$scope.doCloseQrDialog = (open = false) => {
			$scope.showQrDialog = open;
		};

		$scope.doOpenQrDialog = (open = true) => {
			$scope.showQrDialog = open;
		};

		// TODO: Implementing the mdDialog logic for the showing of the qr code dialog box
		$scope.view = function (guardianQR) {
			// lsfactory.setEnv("currentStudentQrcode",studentQR);
			$mdDialog.show({
				escapeToClose: false,
				clickOutsideToClose: true,
				templateUrl: 'views/pages/roles/sa/guardians/dialogs/qr_code_view.html',
				controller: ViewQrcodeDialogGuardianController,
			});
		};
	};
}

saGuardiansDetail.controller = saGuardiansDetailCmpController;

appModule.component('saGuardiansDetail', saGuardiansDetail);

saGuardiansDetail.selector = 'saGuardiansDetail';

export { saGuardiansDetail };

import template from './sectionHistoryInfoStatsCmp.tpl.html';

/** @type {import('@srcAjs/components2/adminRoutes').IComponentOptions2} */
const sectionHistoryInfoStatsCmp = {
	template,
	bindings: {
		//someInput: '@string,<object,&function'
		sectionHistory: '<',
		student: '<',
	},
	transclude: {
		// #slotName: '?optionalPublicName'
	},
	selector: 'sectionHistoryInfoStatsCmp',
};

/** @type {ng.IContrller} */
function sectionHistoryInfoStatsCmpController(
	$state,
	fullnameFilter,
	/** @type {ng.IScope}*/ $scope,
	/** @type {API} */ API,
	/** @type {import('restangular').IService} */ Restangular,
	/** @type {ErrorService}*/ ErrorService,
	/** @type {ng.material.IDialogService} */ $mdDialog,
	/** @type {$moment} */ $moment,
	/** @type {EffectService} */ EffectService,
	/** @type {compSetupService} */ compSetupService,
	/** @type {DialogService} */ DialogService,
	/** @type {atomosService} */ atomosService,
	/** @type {RxService} */ RxService
) {
	compSetupService(this, sectionHistoryInfoStatsCmp.bindings);
	/**
	 * @type { typeof $scope & {[key:string]: {}|any ,
	 *  section_history:SectionHistory,
	 * section:Section
	 * getSubjectGrade:(param0:SubjectMount)=>SubjectGrade}}
	 */
	const svm = /** @type {any}*/ ($scope);

	svm.coll_attendance = true;
	svm.isReady = false;

	const makeTermGradeHals = (sectionHistory = svm.section_history, subStats) => {
		//subject_grade and avg
		const halStreamMap = new Map([
			['cumulative', { key: 'cumulative', type: 'bar', yAxis: 1, disabled: false }],
			['jit', { key: 'jit', type: 'line', yAxis: 1, disabled: true }],
			['class_avg', { key: 'class_avg', type: 'line', yAxis: 1, disabled: false }],
			['class_max', { key: 'class_max', type: 'line', yAxis: 1, disabled: false }],
			['class_min', { key: 'class_min', type: 'line', yAxis: 1, disabled: false }],
			['max', { key: 'max', type: 'scatter', yAxis: 1, disabled: false }],
		]);
		const bucketStreamKey = (/** @type {StudentBucketGrade} */ bucket) => `${bucket.assessmeng_group_type_title}:${bucket.weight}`;

		/** @type {import('../ChartBasicCmp/ChartBasicCmp.component').ItemHal['itemHals']} */
		const smHals = sectionHistory.term_grades.GRADES.map((sub_grade) => ({
			title: sub_grade.subject.title,
			chartData: (() => {
				return {
					label: sub_grade.subject.title,
					...Object.fromEntries(sub_grade.buckets.map((b) => [bucketStreamKey(b), b.cumulative_weight])),
					class_min: Number(subStats.find((_sm) => _sm.subject_mount_id === sub_grade.subject_mount_id)?.sm_min_cumulative_weight ?? 0).toPrecision(2),
					class_max: Number(subStats.find((_sm) => _sm.subject_mount_id === sub_grade.subject_mount_id)?.sm_max_cumulative_weight ?? 0).toPrecision(2),
					class_avg: Number(subStats.find((_sm) => _sm.subject_mount_id === sub_grade.subject_mount_id)?.sm_cumulative_weight ?? 0).toPrecision(2),
					jit: sub_grade.jit_weight,
					cumulative: sub_grade.cumulative_weight,
					max: 100,
				};
			})(),
		}));

		svm.chartStreams = [...halStreamMap.values()].sort(({ order: orderA = 0 }, { order: orderB = 0 }) => orderA - orderB);
		svm.smHals = smHals;
	};

	const makeAssessmentHals = (/** @type {Array<SubjectAssessmentEntity>} */ assessments) => {
		svm.astStreams = [
			{ key: 'value', type: 'bar' },
			{ key: 'max', type: 'scatter' },
		];

		/** @type {import('../ChartBasicCmp/ChartBasicCmp.component').ItemHal[number]} */
		const tempAstHals = [
			['approved', (ae) => ae.approved],
			['pending', (ae) => !ae.approved],
			['all', (ae) => true],
		].flatMap(([status, filter]) =>
			assessments.filter(filter).map((ae) => ({
				title: `${ae.title}`,
				ae,
				smStatus: [ae.subject_assessment_group.subject_mount_id, status].join('@'),
				chartData: {
					title: ae.title,
					expected_score: ae.total,
					actual_score: ae.subject_assessment_scores.at(0)?.score ?? 0,
					value: (ae.subject_assessment_scores.at(0)?.fractional_score ?? 0) * 100,
					max: 100,
				},
			}))
		);

		svm.astHals = _.groupBy(tempAstHals, (hal) => hal.smStatus);
	};

	const initialize = () => {
		try {
			if ([this.sectionHistory, this.student].some((item) => [undefined, null, ''].includes(item))) {
				return DialogService.dispatch('simpleToast', 'No valid inputs for id,secton_history or student');
			}

			Promise.all([
				querySubmountStats({ term_id: this.sectionHistory.term_id, section_ids: [this.sectionHistory.section_id] }),
				querySectionHistoryMeta({ term_id: this.sectionHistory.term_id, section_history_id: this.sectionHistory.id }).then(({ data }) =>
					queryAssessments({
						subject_mount_ids: data
							.plain()
							.at(0)
							?.section.subject_mounts?.map(({ id }) => id),
						student_term_subject_ids: data
							.plain()
							.at(0)
							?.term_grades.GRADES?.map(({ student_term_subject_id: id }) => id),
					})
				),
			])

				.catch(ErrorService.handleError)

				.finally(() => (svm.isReady = true));
		} catch (error) {
			svm.isReady = true;
			ErrorService.handleError(error);
		}
	};

	const [submountStats$, { mutate: querySubmountStats, revalidate: refreshSubjmounStats$ }] = API.rxQuery(
		['rxq.submountStats.sh.parent'],
		(r, /**@type {{term_id,section_ids}} */ data) =>
			/** @type {ng.restangular.ICollectionPromise<{subject_mount_id:number,sm_min_jit_weight:number,sm_max_jit_weight:number,sm_min_cumulative_weight:number,sm_max_jit_weight:number,}>} */ (
				r.all('subject-mount/stats?json_data=' + JSON.stringify(data)).getList()
			),
		{ qInput$: undefined, enabled: false, destroy$: this.componentDestroy$ }
	);

	const [sectionHistory$, { mutate: querySectionHistoryMeta, revalidate: refreshSectionHistory$ }] = API.rxQuery(
		['rxq.sectionHistory'],
		(r, /**@type {{term_id,section_history_id}} */ { term_id, section_history_id }) =>
			/** @type {ng.restangular.ICollectionPromise<SectionHistory & {term_grades:{GRADES:[]},subject_mounts,section:Section,}>} */ (
				r
					.all(
						`section_history?${API.buildParams_v2({
							filters: [{ condition: 'where', column: 'id', value: section_history_id }],
							has_fields: null,
							fields: [
								{
									condition: 'with',
									field: 'section.subject_mounts',
									conditions: [
										{
											condition: 'where',
											column: 'term_id',
											value: term_id,
										},
										{
											condition: 'with',
											column: 'teacher.user',
										},
										{ condition: 'with', column: 'subject' },
									],
								},
								{
									condition: 'with',
									field: 'section',
									conditions: [
										{ condition: 'with', column: 'sclass.class_level' },
										{ condition: 'with', column: 'teacher.user' },
									],
								},

								{
									condition: 'with',
									field: 'attendances',
								},
							],
							param_resolve_version: '1.4',
						})}&post.process[0]=attach::ranking`
					)
					.getList()
			),
		{ qInput$: undefined, enabled: false, destroy$: this.componentDestroy$ }
	);

	const [assessments$, { mutate: queryAssessments, revalidate: refreshAssessments$ }] = API.rxQuery(
		['rxq.delayedAssessments.parent.sh'],
		(r, /**@type {{subject_mount_ids,student_term_subject_ids}} */ { subject_mount_ids, student_term_subject_ids }) =>
			/** @type {ng.restangular.ICollectionPromise<any>} */ (
				r
					.all(
						`assessment_entity?${API.buildParams_v2({
							filters: null,
							has_fields: [
								{
									field: 'subject_assessment_group',
									conditions: [
										{
											condition: 'whereIn',
											column: 'subject_mount_id',
											value: subject_mount_ids,
										},
									],
								},
							],
							fields: [
								{
									field: 'subject_assessment_group',
									condition: 'with',
									conditions: [
										{
											condition: 'whereIn',
											column: 'subject_mount_id',
											value: subject_mount_ids,
										},
										// { condition: "with", column: "subject" },
									],
								},
								{
									field: 'subject_assessment_scores',
									condition: 'with',
									conditions: [
										/*
										 * { condition: "whereHas", column: "student_term_subject|where|section_history_id", value: this.sectionHistoryId },
										 * { condition: "with", column: "student_term_subject|where|section_history_id", value: this.sectionHistoryId },
										 */
										{ condition: 'whereIn', column: 'student_term_subject_id', value: student_term_subject_ids },
									],
								},
							],
							sorts: [
								{
									condition: 'simple',
									column: 'oldest',
								},
							],
						})}`
					)
					.getList()
			),
		{ qInput$: undefined, enabled: false, destroy$: this.componentDestroy$ }
	);

	EffectService.setup(
		this,
		({ onInit, onChanges }) => {
			if (onInit) {
				const [shAtom, submountAtom] = [atomosService(sectionHistory$, this), atomosService(submountStats$, this)];

				atomosService((get) => ({ sh: get(shAtom), sm: get(submountAtom) }), this)
					.stream$.pipe(RxService.skipWhile((val) => !val.sh || !val.sm))
					.subscribe(({ sh: { data: res, error, loading }, sm: { data: smData, error: smError, loading: smLoading } }) => {
						svm.loadingSectionHistory = loading;
						if (error || smError) return ErrorService.handleError(error ?? smError);
						if (!res || !smData?.length) return;

						[svm.section_history] = res.plain();
						if (!svm.section_history) return DialogService.dispatch('alert', 'No Student(section_history) info found');

						svm.section = svm.section_history.section;
						svm.classTeacherFullname = fullnameFilter(svm.section_history.section.teacher);
						svm.student = svm.section_history.student;

						svm.mapped_subject_mounts = _.groupBy(svm.section_history.section.subject_mounts, 'id');
						svm.mapped_student_term_subjects = _.groupBy(svm.section_history.student_term_subjects, 'id');
						svm.section_history.section = { ...svm.section_history.section, ...this.sectionHistory.section };

						makeTermGradeHals(svm.section_history, smData.plain().at(0));
					});

				atomosService(assessments$, this).stream$.subscribe(({ data: res, loading, error }) => {
					svm.loadingAssessments = loading;
					if (error) return ErrorService.handleError(error);
					if (!res) return;

					svm.assessment_entities = res.plain();
					svm.pending_assessment_entities = res.plain().filter((sae) => $moment(sae.date_due).isAfter($moment(Date.now())));
					svm.approved_and_completed_assessment_entities = res.plain().filter((sae) => $moment(sae.date_due).isBefore($moment(Date.now())) && sae.approved);

					makeAssessmentHals(res.plain());
				});
			}

			if (onChanges || onInit) {
				initialize();
			}
		},
		() => [this.sectionHistory, this.student]
	);

	svm.getAttendances = (/** @type {string}*/ attendance_filter_key) => {
		try {
			if (!['present', 'absent', 'holiday', 'late'].includes(attendance_filter_key)) {
				throw new IError('Error: attendance_filter_key invalid', { attendance_filter_key });
			}

			return svm.section_history?.attendances?.filter(({ status }) => new RegExp(attendance_filter_key, 'i').test(status));
		} catch (err) {
			ErrorService.handleError(err);
		}
	};

	svm.getSubjectGrade = function (/** @type {SubjectMount}*/ { id }) {
		return svm.section_history?.term_grades?.GRADES?.find((tmg_grad) => tmg_grad?.subject_mount_id === id);
	};

	svm.showZeroWeightInfo = ({ assessment_type_title }, event) => {
		event.stopPropagation();
		DialogService.dispatch('alert', `This item [${assessment_type_title}] or any item with a line through it is included when computing final/overal scores`);
	};

	svm.selectSectionHistory = (/** @type {SectionHistory} */ sh, _, __, target_sh = svm.section_history) => sh.id === target_sh.id;

	svm.updateTerm = (term) => {
		svm.term = term;
	};

	svm.refresh = (refresh = true) => refreshAssessments$({ revalidateAll: true });

	const filterValidBuckets = (/** @type {StudentBucketGrade[]}*/ buckets) => {
		return Object.values(buckets ?? {})?.filter((bucket) => Number(bucket?.total) > 0);
	};
	svm.filterValidBuckets = filterValidBuckets;

	svm.sumBuckets = (/** @type {[]}*/ buckets = []) => buckets.reduce((acc, bucket) => parseFloat(Number(bucket.fractional_score ?? 0) + Number(acc)).toFixed(2), 0);

	svm.getSubjectMountBucketsTotal = (sm) => svm.sumBuckets(svm.filterValidBuckets(svm.getSubjectGrade(sm)?.buckets ?? []));

	svm.getMergedBucketTitles = (/** @type {[]}*/ buckets) =>
		buckets
			.filter(({ fractional_score }) => fractional_score > 0)
			.map(({ title, fractional_score, total, score_sum }) => `${title}(${Math.floor((fractional_score * total) / score_sum / 10) * 10}%)`)
			.join(' + ');

	svm.getAssessementsFilter = (sm) => (sae) => sae.subject_assessment_group.subject_mount_id === sm.id;

	svm.setLoadTeacherSubjectsAndClasses = (teacher) => (svm.loadTeacherSubjectsAndClasses = teacher);
}

sectionHistoryInfoStatsCmp.controller = sectionHistoryInfoStatsCmpController;

appModule.component('sectionHistoryInfoStatsCmp', sectionHistoryInfoStatsCmp);

export { sectionHistoryInfoStatsCmp };

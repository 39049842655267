import template from './index.html';

/** @type {import('@srcAjs/components2/adminRoutes').IComponentOptions2} */
const saStudentsSubReports = {
	template,
	bindings: {
		//someInput: '@string,<object,&function'
	},
	transclude: {
		// #slotName: '?optionalPublicName'
	},
};



function saStudentsSubReportsCmpController(
	SAFactory,
	$scope,
	$cookies,
	$stateParams,
	$mdToast,
	$state,
	$rootScope,
	$localStorage,
	$mdDialog,
	lsfactory,
	Restangular,
	Student
) {
	$scope.student = lsfactory.getEnv('currentStudentSelected');
	// TODO: Show name based on the desired format ( Last Name first OR First name first or however )
	$state.current.data.title = $scope.student.firstname + ' ' + $scope.student.lastname + ' ( Student )';

	$scope.$on('editStudent.newEntity', function (event, new_student) {
		if (new_student !== undefined) {
			$scope.student = new_student;
			$state.current.data.title = $scope.student.firstname + ' ' + $scope.student.lastname + ' ( Student )';
		}
	});

	//TODO : Show the dialog for the downloading of the demographics report card
	$scope.DemoByStId = function () {
		var confirm = $mdDialog
			.confirm()
			.title('Download Student Demographics ?')
			.textContent('This will be Downloaded in a pdf format.')
			.ariaLabel('Warning')
			.ok('Yes please!')
			.cancel('Cancel');
		$mdDialog.show(confirm).then(
			function () {
				//Configuring and sending request for the report card
				console.dir($scope.student);
				var getLink = Restangular.all('report/by_student_id/' + $scope.student.id);
				getLink.getList().then(function (data) {
					var list = data.plain();
					var url = list[0];
					window.open(url, 'Download');
				});
			},
			function () {
				console.dir($scope.student.lastname);
			}
		);
	};
}

saStudentsSubReports.controller = saStudentsSubReportsCmpController;

appModule.component('saStudentsSubReports', saStudentsSubReports);

saStudentsSubReports.selector = 'saStudentsSubReports';

export { saStudentsSubReports }

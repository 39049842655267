import './index.scss';
import template from './index.tpl.html';

/** @type {import('@srcAjs/components2/adminRoutes').IComponentOptions2} */
const schoolsAnalyticsShellCmp = {
	template,
	bindings: {
		//someInput: '@string,<object,&function'
	},
	transclude: {
		// #slotName: '?optionalPublicName'
	},
	selector: 'schoolsAnalyticsShellCmp',
};

/** @this {ng.IController} */
function schoolsAnalyticsShellCmpController() {}

schoolsAnalyticsShellCmp.controller = schoolsAnalyticsShellCmpController;

appModule.component('schoolsAnalyticsShellCmp', schoolsAnalyticsShellCmp);

export { schoolsAnalyticsShellCmp };

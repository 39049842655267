import template from './index.html';

/** @type {import('@srcAjs/components2/adminRoutes').IComponentOptions2} */
const saGuardiansIndex = {
	template,
	bindings: {
		//someInput: '@string,<object,&function'
	},
	transclude: {
		// #slotName: '?optionalPublicName'
	},
};

function saGuardiansIndexCmpController(
	SAFactory,
	$scope,
	$state,
	/** @type {ErrorService} */ ErrorService,
	/** @type {import("restangular").IService} */ Restangular,
	/** @type {import("@srcAjs/factory/services/class-group").ClassGroupApi} */  ClassGroup,
	lsfactory,
	$mdBottomSheet,
	/** @type {API} */ API,
	fullnameFilter,
	/** @type {DialogService} */ DialogService,
	/** @type {auth} */ auth
) {
	const vm = this;
	SAFactory.loadEnvironment();
	SAFactory.loadNotifications();
	$scope.guardians = [];
	$scope.selected_guardians = [];
	$scope.selectedCount = 0;
	$scope.showOne = false;
	$scope.isLoading = true;
	$scope.school = auth.getEngagedUserRole().school;
	$scope.term;
	$scope.refresh = false;
	$scope.search_guardian = '';
	$scope.isReady = true;
	vm.withStudentsEnrolledInSelectedTerm = true;

	const attachFullnameToGuardiansAndStudents = (guardian) => {
		guardian.user.fullname = fullnameFilter(guardian);
		guardian.students = guardian.students.map((student) => ({ ...student, fullname: fullnameFilter(student) }));
		return guardian;
	};

	$scope.setFilter = (query) => {
		$scope.search_guardian = query;
	};

	$scope.reloadData = function (term, sections, withStudentsEnrolledInSelectedTerm = vm.withStudentsEnrolledInSelectedTerm) {
		vm.withStudentsEnrolledInSelectedTerm = withStudentsEnrolledInSelectedTerm;
		vm.showChildren = withStudentsEnrolledInSelectedTerm;

		if (!term) return;

		$scope.isLoading = true;
		$scope.guardians = null;

		const /** @type {API_Params} */ params = {
				has_fields: [],
				fields: [
					{
						field: 'students',
						conditions: [
							{ condition: 'whereHas', column: 'section_histories|where|term_id', value: term.id },
							{ condition: 'with', column: 'section_histories|whereIn|section_id', value: sections.map(({ id }) => id) },
							{ condition: 'with', column: 'user' },
						],
					},
					{ field: 'user', condition: 'with' },
				],
			};

		try {
			if (withStudentsEnrolledInSelectedTerm) {
				params.has_fields.push({
					field: 'students.section_histories',
					conditions: [
						{ condition: 'where', column: 'term_id', value: term.id },
						{ condition: 'whereIn', column: 'section_id', value: sections.map(({ id }) => id) },
					],
				});
			}
			$scope.isLoading = true;
			Restangular.all(`guardian?${API.buildParams_v2(params)}`)
				.getList()
				.then((/** @type import("restangular").ICollection */ res) => {
					$scope.guardians = res.plain().map(attachFullnameToGuardiansAndStudents);
				}, ErrorService.handleError)

				.finally(() => ($scope.isLoading = false));
		} catch (error) {
			ErrorService.handleError(error);
			$scope.isLoading = false;
		}
	};

	$scope.reloadAllData = (term, sections) => {
		DialogService.dispatch('confirm', 'Load all guardians ever (could take a while)', 'Filter guardians', 'Yes (every  guardian in the school)', 'No (only guardian in this term)')
			.then((res) => {
				console.log('show guardians in school', { res });
				$scope.reloadData(term, sections, false);
			})
			.catch((res) => {
				console.log('show guardians in school', { res });
				$scope.reloadData(term, sections, true);
			});
	};

	$scope.loadArchivedGuardians = () => {
		const /** @type {API_FieldQuery[]} */ has_fields = [
				{
					field: 'students.student_enrollment_states.enrollment_state',
					conditions: [{ condition: 'where', column: 'state', value: 'archived' }],
				},
			];
		const /** @type {API_FieldQuery[]} */ fields = [
				{
					field: 'students',
					conditions: [{ condition: 'whereHas', column: 'student_enrollment_states.enrollment_state|where|state', value: 'archived' }],
				},
			];

		try {
			$scope.isLoading = true;
			Restangular.all(`guardian?${API.buildParams_v2({ has_fields, fields })}`)
				.getList()
				.then((/** @type {import("restangular").ICollection} */ res) => {
					$scope.guardians = res.plain().map(attachFullnameToGuardiansAndStudents);
				}, ErrorService.handleError)

				.finally(() => ($scope.isLoading = false));
		} catch (error) {
			ErrorService.handleError(error);
			$scope.isLoading = false;
		}
	};
	// $scope.reloadData()
	$scope.$on('guardians.changeFired', () => {
		$scope.reloadData();
	});

	$scope.gotoImport = function () {
		$state.go('sa.guardians_import');
	};

	$scope.showBottomSheet = function () {
		$mdBottomSheet.show({
			templateUrl: 'views/pages/roles/sa/guardians/bottom.index.html',
			controller: 'GuardiansListBottomSheetCtrl',
		});
	};

	$scope.updateTerm = (term) => {
		if (!term) return;
		$scope.isLoading = true;

		$scope.term = term;
		ClassGroup.get($scope.school, term, ['sections'], '1.2')
			.then((res) => {
				$scope.sclasses = res;
				$scope.all_class_sections = res.flatMap(({ sections }) => [...sections]);
			}, ErrorService.handleError)
			.finally(() => ($scope.isLoading = false));
	};
}

saGuardiansIndex.controller = saGuardiansIndexCmpController;

appModule.component('saGuardiansIndex', saGuardiansIndex);

saGuardiansIndex.selector = 'saGuardiansIndex';

export { saGuardiansIndex };

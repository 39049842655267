import './FormErrorMessagesCmp.scss';

import template from './FormErrorMessagesCmp.tpl.html';

const formErrorMessagesCmp = {
	template,
	/** @type {{elementRef: any}} */
	// @ts-ignore
	bindings: {
		elementRef: '<',
	},
};

class FormErrorMessagesCmpController {
	elementRef;

	constructor(/** @type {EffectService} */ EffectService, /** @type {ErrorService} */ ErrorService) {
		EffectService.setup(this, ({ onInit }) => {
			try {
				const elementRef = this.elementRef;
				if (onInit && elementRef) {
					console.log({ elementRef });
					this.requiredMsg = this.isFormElement(this.elementRef?.$name) ? 'has fields that are required' : 'is required';
				}
			} catch (error) {
				ErrorService.handleError(error);
			}
		});
	}

	isFormElement(name, query = 'form') {
		return name && new RegExp(name, 'i').test(query);
	}
}

formErrorMessagesCmp.controller = FormErrorMessagesCmpController;

appModule.component('formErrorMessagesCmp', formErrorMessagesCmp);

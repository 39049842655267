import './TermDateControlsCmp.scss';

import template from './TermDateControlsCmp.tpl.html';

/** @typedef {({value}) => void} OnUpdateEmitter - callback event emmiter */

/**@type {import('@srcAjs/components2/adminRoutes').IComponentOptions2} */
const termDateControlsCmp = {
	template,
	/** @type {{onUpdate:OnUpdateEmitter,date:Date, stepUnit: 'days'| 'weeks', term: Term,hideControls,disable}& any} */
	bindings: {
		onUpdate: '&',
		date: '<',
		term: '<',
		stepUnit: '@',
		hideControls: '<',
		disable: '<',
		showCalendar: '<',
		alwaysEmit: '<',
	},
	selector: 'termDateControlsCmp',
};

class termCateControlsCmpontroller {
	/** @type {getDateWithinTermBoundsFilter} */ getDateWithinTermBoundsFilter;

	/** @type {typeof termDateControlsCmp.bindings.onUpdate} */ onUpdate;

	/** @type {typeof termDateControlsCmp.bindings.date} */ date;

	/** @type {typeof termDateControlsCmp.bindings.stepUnit} */ stepUnit;

	/** @type {typeof termDateControlsCmp.bindings.term} */ term;

	/** @type {moment.Moment[]} */ week_days;

	/** @type {$moment} */ $moment;

	constructor(
		/** @type {getDateWithinTermBoundsFilter} */ getDateWithinTermBoundsFilter,
		$moment,
		/** @type {EffectService} */ EffectService,
		/** @type {compSetupService} */ compSetupService
	) {
		compSetupService(this, termDateControlsCmp.bindings);
		this.$moment = $moment;
		this.getDateWithinTermBoundsFilter = getDateWithinTermBoundsFilter;

		EffectService.setup(
			this,
			({ onChanges, onInit }) => {
				if (onInit && this.alwaysEmit) {
					this.update(this.date ?? new Date());
				}

				if (onChanges && this.term) {
					this.setWeekDays(this.term);
					this.setStartAndEnd(this.term);
					this.setTermDateCounterSummary(getDateWithinTermBoundsFilter(this.term, this.date));

					if (this.alwayEmit) {
						this.update(this.date ?? new Date());
					}
				}
			},
			() => [this.date, this.term, this.stepUnit, this.hideControls, this.disable]
		);
	}

	advanceDate = (
		/** @type {Parameters<$moment['Moment']['add']>[0]} */ increment,
		_date,
		stepUnit = /** @type {Parameters<$moment['Moment']['add']>[1]} */ (this.stepUnit ?? 'days')
	) => {
		const $moment_date = this.$moment.isoToMoment(_date).add(increment, stepUnit);

		this.update($moment_date.toDate());
	};

	update(/** @type {Date}*/ date) {
		this.setTermDateCounterSummary(date);
		this.onUpdate &&
			this.onUpdate({
				value: this.getDateWithinTermBoundsFilter(this.term, date),
				date: this.getDateWithinTermBoundsFilter(this.term, date),
				data: this.getDateWithinTermBoundsFilter(this.term, date),
			});
	}

	asDate(date_string) {
		console.log('asDate');
		if (!date_string) {
			return null;
		}
		return this.$moment.isoToMoment(date_string).toDate();
	}

	setWeekDays(/** @type {Pick<Term,'start'|'end'>} */ { start, end }) {
		console.log('setWeekDays');
		const net_term_days = this.$moment.isoToMoment(end).add(1, 'day').diff(this.$moment.isoToMoment(start), 'days');
		this.week_days = new Array(net_term_days)
			.fill(null)
			.map((_, index) => this.$moment.isoToMoment(start).add(index, 'days'))
			.filter((m) => m.weekday() >= 1 && m.weekday() <= 5);
	}

	setStartAndEnd(/** @type {Pick<Term,'start'|'end'>} */ { start, end }) {
		[this.start_date, this.end_date] = [start, end].map((ds) => this.asDate(ds));
	}

	setTermDateCounterSummary(/** @type {Date} */ date) {
		console.log('term_date_count_summary');
		if (!this.week_days?.length || !date) {
			return 'N/A';
		}

		const day = this.$moment.isoToMoment(date);
		const day_count = this.week_days.findIndex((m_day) => m_day.dayOfYear() === day.dayOfYear()) + 1;

		this.term_date_counter_summary = `${day_count ? day_count : 'N/A'} of ${this.week_days.length}`;
	}
}

termDateControlsCmp.controller = termCateControlsCmpontroller;

appModule.component('termDateControlsCmp', termDateControlsCmp);

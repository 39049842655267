import template from './CountrySelectInputCmp.tpl.html';

import './CountrySelectInputCmp.scss';

const countrySelectInputCmp = {
	template,
	bindings: {
		value: '<',
		selectValueFn: '&',
		onUpdate: '&',
		isRequired: '<',
		isDisabled: '<',
		showFlags: '<',
		name: '@',
		errorRef: '<',
	},
	transclude: {
		labelSlot: '?labelSlot',
	},
};

class ComponentNameController {
	value;

	/** @type {(country)=> boolean} */ selectValueFn;

	/** @type {(country)=>void} */ onUpdate;

	/** @type {boolean} */ isRequired;

	/** @type {boolean} */ isDisabled;

	/** @type {boolean} */ showFlags;

	/** @type {{}} */ errorRef;

	/** @type {isoCountryList} */ isoCountryList;

	/** @type {angular.IRootElementService} */ $element;

	constructor(isoCountryList, $element) {
		this.isoCountryList = isoCountryList;
		this.$element = $element;
	}

	$onInit() {
		this.cShowFlags = [undefined, null].includes(this.showFlags) ? true : this.showFlags;
		this.cIsRequired = [undefined, null].includes(this.isRequired) ? true : this.isRequired;
		this.isoCountryList.get().then((/** @type {[]}*/ countries) => {
			this.countries = countries;
			this.cValue = this.value ?? this.selectValueFn ? this.countries.find((country) => this.selectValueFn({ country })) : null;
			this.update(this.cValue);
		});

		this.$element.find('input').on('keydown', (ev) => {
			ev.stopPropagation();
		});
	}

	update(country) {
		this.onUpdate && this.onUpdate({ country });
	}
}

countrySelectInputCmp.controller = ['isoCountryList', '$element', ComponentNameController];

appModule.component('countrySelectInputCmp', countrySelectInputCmp);

import './SaDownloadsCmp.scss';

import template from './SaDownloadsCmp.tpl.html';

const saDownloadsCmp = {
	template,

	bindings: {},
};

class ComponentNameController {
	browsers = [
		{ browser: 'chrome', src: 'images/browsers/chrome.svg', text: 'Install Google Chrome Extension' },
		{ browser: 'firefox', src: 'images/browsers/mozilla.svg', text: 'Install Firefox Extension' },
		{ browser: 'opera', src: 'images/browsers/opera.svg', text: 'Install Opera Extension' },
		{ browser: 'safari', src: 'images/browsers/safari.svg', text: 'Install Safari Extension' },
	];

	osOptions = [
		{ os: 'mac', text: 'mac', icon: ['fa-mac'] },
		{ os: 'windows', text: 'windows', icon: ['fa-windows'] },
		{ os: 'linux', text: 'linux', icon: ['fa-linux'] },
		{ os: 'chrome', text: 'chrome', icon: ['fa-chrome'] },
	];
	constructor(/** @type {ng.IScope} */ $scope, deviceDetector, $window,CONST_APP_BUILD_TIME) {
		this.$scope = $scope;
		this.device = deviceDetector;
		this.$window = $window;
    this.$scope.CONST_APP_BUILD_TIME = CONST_APP_BUILD_TIME;
	}

	$onInit() {
		this.$scope.isReady = true;
		this.$scope.browsers = this.browsers.filter(({ browser }) => browser === this.device.browser);
		this.$scope.osOption = this.osOptions.find(({ os }) => ('' + this.device.os).includes(os));
	}
}

saDownloadsCmp.controller = ComponentNameController;

appModule.component('saDownloadsCmp', saDownloadsCmp);

import template from './index.html';

/** @type {import('@srcAjs/components2/adminRoutes').IComponentOptions2} */
const saStudentsCreate = {
	template,
	bindings: {
		//someInput: '@string,<object,&function'
	},
	transclude: {
		// #slotName: '?optionalPublicName'
	},
};



function saStudentsCreateCmpController(
	SAFactory,
	/** @type {ng.IScopeService} */ $scope,
	/** @type {ErrorService}*/ ErrorService,
	/** @type {ng.ITransitionService} */ $stateParams,
	/** @type {ng.material.IDialogService} */ $mdDialog,
	/** @type {ng.IStateService}*/ $state,
	ClassGroup,
	/** @type {auth} */ auth,
	TRFactory,
	Student,
	Restangular,
	$mdToast,
	/** @type {$moment} */ $moment,
	CONST_API_DATE_FORMAT,
	/** @type {lsfactory} */ lsfactory
) {
	const svm = $scope;
	svm.new_student = {};
	svm.school = auth.getEngagedUserRole().school;
	svm.selectedItem = null;
	svm.searchText = null;
	svm.guardians = [];
	svm.years = [];
	svm.classes = [];
	svm.gends = ['Male', 'Female'];
	svm.statuses = ['Active', 'Inactive'];
	svm.cropType = 'circle';
	svm.image_set = false;
	svm.sub_mounts = [];
	svm.selectedSubjects = [];
	const step_labels = ['info', 'photo', 'create_student', 'add_guardians'];
	svm.school_and_term = {};
	svm.isReady = true;
	svm.student_state = null;
	svm.today = null;

	const initializeController = () => {
		svm.steps = Object.fromEntries(step_labels.map((label, index) => [label, { index, label, completed: false }]));

		svm.selected = 0;
	};

	function checkForEngagedUser(engaged_user_role, section, sections) {
		if (/teacher/i.test(engaged_user_role.role.title)) {
			if (!section) {
				return $state.go('tr.class.student_creation_request', {}, { location: 'replace' }).then(() => new Error('No section provided'));
			}
			svm.box.section = sections.find((sc) => $stateParams.section.id == sc.id);
		}
	}

	const template = {
		student: {
			firstname: '',
			lastname: '',
			othername: '',
			country_id: readLastSelectedCountry() ?? 'ghana',
			birthdate: $moment().format(CONST_API_DATE_FORMAT),
			gender: 'Male',
			enrollment_state_id: 2,
			school_id: auth.getEngagedUserRole().school_id,
			sadmin_id: null,
			thumbnail: '',
		},
		section_history: {
			id: null,
			term_id: null,
			section_id: null,
		},
		subject_mount_ids: [],
	};

	svm.clearForm = function () {
		svm.today = new Date();

		const { student = null, student_intent = null } = $stateParams;

		svm.box = student ?? student_intent ?? angular.copy(template);

		if (svm.box.student.birthdate) {
			svm.dob = $moment(svm.box?.birthdate).toDate();
		}

		svm.isUpdate = _.isFinite($stateParams.student_request_index ?? $stateParams?.student?.id);

		svm.submit_text = svm.isUpdate
			? _.isFinite($stateParams?.student_request_index)
				? 'Update creation request'
				: 'Update student'
			: /teacher/i.test(auth.getEngagedUserRole().role.title)
			? 'Make creation request'
			: 'Create student';

		updateStudentState(svm.box.student);
	};

	svm.completeCreateStep = function ({
		student,
		section_history,
		subject_mount_ids,
		section,
		validate_student_creation_request = Boolean($stateParams?.student_request_index),
	}) {
		if (!student || !section_history || subject_mount_ids.length !== section.subject_mounts.length) {
			return $mdToast.showSimple('Error: please check your inputs and try again');
		}

		svm.done = false;
		svm.isReady = false;

		return Student.post({ student, section_history, subject_mount_ids, validate_student_creation_request })
			.then(
				(res) => {
					const plain_res = res.plain();

					if (plain_res.validate_student_creation_request) {
						if (_.isFinite($stateParams?.student_request_index)) {
							TRFactory.studentCreationRequests.removeStudent($stateParams?.student_request_index);
						}
						TRFactory.studentCreationRequests.addStudent(plain_res);

						return $state
							.go('tr.class.student_creation_request', {}, { location: 'replace' })
							.then(() => $mdToast.showSimple('New student added'))
							.finally(() => (svm.done = true));
					}

					svm.new_student = plain_res.student;
					return advanceStepForward('create_student');
				},
				(error) => {
					ErrorService.handleError(error);
					svm.selected = 0;
				}
			)
			.finally(() => {
				svm.done = true;
				svm.isReady = true;
			});
	};

	// svm.$watch('box.section',loadSectionInfo);

	svm.loadSectionInfo = loadSectionInfo;

	function loadSectionInfo(section) {
		if (!section) return;

		svm.sub_mounts = section.subject_mounts;
		svm.box.section_history = { ...svm.box.section_history, section_id: section.id };
		svm.box.subject_mount_ids = section.subject_mounts.map(({ id }) => id);
	}

	svm.completeInfoStep = advanceStepForward;

	svm.completeImageStep = advanceStepForward;

	svm.completeGuardianStep = advanceStepForward;

	function advanceStepForward(current_step_label) {
		try {
			if (!step_labels.includes(current_step_label)) throw new Error('Invalid step provided');

			svm.selected = (step_labels.lastIndexOf(current_step_label) + 1) % step_labels.length;
			svm.steps[current_step_label].completed = true;
		} catch (error) {
			ErrorService.handleError(error);
		}
	}

	svm.addGuardianField = (student) => {
		try {
			if (!student) throw new Error('Invalid student parameter provided');

			$mdDialog
				.show({
					autoWrap: false,
					bindToController: true,
					controllerAs: '$ctrl',
					locals: {
						student,
						school: svm.school,
					},
					clickOutsideToClose: true,
					controller: 'DumbController',
					template:
						'<quick-add-guardian on-close="$ctrl.close(data)" student="$ctrl.student" school="$ctrl.school" flex="70"></quick-add-guardian>',
				})
				.then((guardian) => {
					if (!guardian) return;

					alert('working guardian close');
				});
		} catch (error) {
			ErrorService.handleError(error);
		}
	};

	const fetchSchoolClassesAndSections = ({ school, term }) => {
		if (!school || !term) return;

		ClassGroup.get(school, term, ['sections', 'subject_mounts'], '1.2')
			.then((sclasses) => {
				svm.classes = sclasses;

				checkForEngagedUser(
					auth.getEngagedUserRole(),
					$stateParams?.section ??
						sclasses
							.flatMap((sc) => sc.sections)
							.find((section) => section.id == $stateParams?.student_instance?.student?.section_id),
					sclasses.flatMap((sc) => sc.sections)
				);
			})
			.catch(ErrorService.handleError)
			.finally(() => (svm.done = true));
	};

	const fetchUserRoleSchoolInfo = (engaged_user_role) => {
		if (!engaged_user_role || !/(teacher|school administrator)/i.test(engaged_user_role.role.title))
			ErrorService.handleError(new Error('User role not found'));

		return Restangular.one('school', engaged_user_role.school_id)
			.get()
			.then((school) => {
				svm.school = school.plain();
				svm.school_and_term.school = svm.school;
				svm.enrollment_states = svm.school.enrollment_states;
				svm.box.school_id = svm.school.id;
				svm.box.section_id = svm.box?.section_id ?? $stateParams?.section?.id;
				svm.sadmin_id = /school administrator/i.test(engaged_user_role.role.title) ? engaged_user_role.role_instance_id : null;
				$mdToast.showSimple('Enrollments loaded');
				return svm.school;
			})
			.catch(ErrorService.handleError);
	};

	svm.updateTerm = (term) => {
		if (!term || term.id === svm.box?.section_history.term_id) {
			return;
		}

		svm.box.section_history.term_id = term.id;
		console.log('term set for student', svm.box.term_id);

		svm.school_and_term.term = term;

		fetchUserRoleSchoolInfo(auth.getEngagedUserRole()).then((school) => fetchSchoolClassesAndSections({ school, term }));
	};

	svm.updateCountry = (country) => {
		if (!country) return;

		svm.box.student.country_id = country.id;
		readLastSelectedCountry(country.id);
	};

	svm.selectCountry = (/** @type {Country[]}*/ country) =>
		['country_code', 'name', 'id'].some((key) => `${country[key]}`.toLowerCase() === `${svm.box.student.country_id}`.toLowerCase());

	svm.updateImage = (value) => {
		svm.box.student.thumbnail = value;
		svm.box.student.is_set = Boolean(value);
	};

	svm.updateBirthdate = (/** @type {Date} */ dob) => (svm.box.student.birthdate = $moment(dob).format(CONST_API_DATE_FORMAT));

	svm.updateStudentState = updateStudentState;

	function updateStudentState(student_state) {
		svm.student_state = { ...student_state };
	}

	function readLastSelectedCountry(value) {
		const key = 'last_selected_country_string';
		if (!value) {
			return lsfactory.getEnv(key);
		}

		return lsfactory.setEnv(key, value);
	}

	const vm = this;

	vm.$onInit = () => {
		initializeController();

		svm.clearForm();
	};
}

saStudentsCreate.controller = saStudentsCreateCmpController;

appModule.component('saStudentsCreate', saStudentsCreate);

saStudentsCreate.selector = 'saStudentsCreate';

export { saStudentsCreate }

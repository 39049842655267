import template from './index.html';


/** @type {import('@srcAjs/components2/adminRoutes').IComponentOptions2} */
const saSchoolSetupGradingSubSummary = {
  template,
  bindings: {
    //someInput: '@string,<object,&function'
  },
  transclude: {
    // #slotName: '?optionalPublicName'
  },
};





function saSchoolSetupGradingSubSummaryCmpController(SAFactory,$scope,$cookies,$stateParams,$mdToast,$state,$rootScope,$localStorage,$mdDialog,lsfactory,Restangular,GradingSystem) {
  SAFactory.loadEnvironment();
  SAFactory.loadNotifications();
  $scope.sel_gs = lsfactory.getEnv('currentGradingSystemSelected');
  $state.current.data.title = $scope.sel_gs.title;
  
  $scope.$on("grading.dataChanged", function(ev, data){
        GradingSystem.one($stateParams.id).get().then(function(res){
              $scope.sel_gs = res;
              $state.current.data.title = $scope.sel_gs.title;
              lsfactory.setEnv('currentGradingSystemSelected', res);
            },function(err){
              if(err.status == 404){
              }
        });
  });

}



saSchoolSetupGradingSubSummary.controller = saSchoolSetupGradingSubSummaryCmpController;


appModule.component('saSchoolSetupGradingSubSummary', saSchoolSetupGradingSubSummary);

saSchoolSetupGradingSubSummary.selector = 'saSchoolSetupGradingSubSummary';

export { saSchoolSetupGradingSubSummary }
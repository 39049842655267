import { SchoolReportTypePrefService } from '@srcAjs/components/SchoolReportTypePrefService';
import './saGenerateClassSectionReportCmp.scss';

import template from './saGenerateClassSectionReportCmp.tpl.html';

const saGenerateClassSectionReportCmp = {
	template,
	bindings: {
		school: '<',
		years: '<',
	},
};

function ComponentNameController(Restangular, $scope, $window, $mdDialog, $mdToast, ErrorService, API, $filter, CONST_REPORT_TYPES) {
	this.$onInit = () => {
		$scope.school = this.school;
		$scope.classes = [];
		$scope.years = this.years;
		$scope.section_id = null;
		$scope.term_id = null;
		$scope.isProcessing = false;
		$scope.CONST_REPORT_TYPES = [...CONST_REPORT_TYPES];
		$scope.report_option = SchoolReportTypePrefService.get({ school_id: $scope.school.id });
	};

	$scope.downloadZip = function () {
		// console.time("TIME_CLASS_REPORT")
		$scope.isProcessing = true;

		// $rootScope.$broadcast("report-class-loading", { status: true })
		const template = $mdToast.simple().textContent('Initiating Download. Please wait ...').highlightAction(true).hideDelay(5000);
		$mdToast.show(template);

		const params = Object.entries({
			section_id: $scope.section_id,
			term_id: $scope.term_id,
			report_option: $scope.report_option.replaceAll(' ', '_'),
			v: '1.2',
			response_as: 'pdf',
		})
			.map(([key, val]) => `${key}=${val}`)
			.join('&');

		const download_link = Restangular.all(`bulk_report_download?${params}`).getRestangularUrl();
		console.log('download_link', download_link);
		$window.open(download_link, '_blank');
		$scope.isProcessing = false;
		$scope.hide();
	};

	$scope.next = function (year) {
		const y = parseInt(year);
		return y + 1;
	};

	$scope.hide = function () {
		$mdDialog.hide();
	};

	$scope.bds = function () {
		window.open('https://api.schrep.info/broadsheet?section_id=' + $scope.section_id + '&term_id=' + $scope.term_id + '');
	};

	$scope.cancel = function () {
		$mdDialog.cancel();
	};

	$scope.updateTerm = (term) => {
		if (!term) return;

		$scope.term = term;
		$scope.term_id = term.id;
		fetchTermClassSections($scope.school, term);
	};

	function fetchTermClassSections(school, term) {
		if (!school || !term) return;

		const params = {
			filters: null,
			has_fields: [{ field: 'class_group', conditions: [{ condition: 'where', column: 'school_id', value: school.id }] }],
			fields: [
				{
					field: 'sections',
					condition: 'with',
					conditions: [
						{
							condition: 'where',
							column: 'term_id',
							value: term.term_type.version === '1.2' ? term.id : 'null',
						},
						{
							condition: 'withCount',
							column: 'section_histories|where|term_id',
							value: term.id,
						},
					],
				},
				{
					field: 'class_level',
					condition: 'with',
				},
			],
		};

		return Restangular.all(`class?${API.buildParams(params.filters, params.has_fields, params.fields, null, '1.4')}`)
			.getList()
			.then((res) => ($scope.classes = $filter('orderBy')(res.plain(), '+class_level_id')), ErrorService.handleError);
	}
}

saGenerateClassSectionReportCmp.controller = ComponentNameController;

appModule.component('saGenerateClassSectionReportCmp', saGenerateClassSectionReportCmp);
// export default component;

import './BasicProfileCardCmp.scss';

import template from './BasicProfileCardCmp.tpl.html';

const basicProfileCardCmp = {
	template,
	bindings: {
		floatingContent: '<',
		textContent: '<',
		floatingClass: '<'
	},
	transclude: {
		pictureSlot: 'pictureSlot',
		floatingSlot: '?floatingSlot',
		contentSlot: '?contentSlot',
	},
};

class ComponentNameController {
	constructor() {}
}

basicProfileCardCmp.controller = ComponentNameController;

appModule.component('basicProfileCardCmp', basicProfileCardCmp);

import template from './AcessLockmeCmp.tpl.html';

import './AcessLockmeCmp.scss';

const accessLockmeCmp = {
	template,
	bindings: {},
};

function LockScreenController($scope, $cookies, $mdToast, $state, $rootScope, $localStorage, $http, auth, lsfactory, CONST_DEFAULT_PROFILE) {
	const vm = this;
	$rootScope.rootStopLoading = false;
	vm.box = {
		username: $rootScope.user.email,
		password: '',
	};
	vm.doLeave = function () {
		lsfactory.removeAll();
		$rootScope.authenticated = false;
		$rootScope.user = null;
		if (vm.env.mobile !== undefined) {
			if (vm.env.mobile) {
				$state.go('access_mobi.mobile_signin').then(function () {
					vm.env = null;
				});
			} else {
				$state.go('access.signin').then(function () {
					vm.env = null;
				});
			}
		} else {
			$state.go('access.signin').then(function () {
				vm.env = null;
			});
		}
	};

	vm.resolveProfile = function (thumbnail) {
		if (thumbnail == '') {
			return 'http://www.gravatar.com/avatar/' + md5(vm.user.email) + '.jpg?s=800&d=' + CONST_DEFAULT_PROFILE;
		} else {
			return thumbnail;
		}
	};

	vm.doUnlocking = function () {
		auth.unlock(vm.box).then(function (res) {
			if (res.status == 'success') {
				$localStorage.environment.locked = false;
				$state.go($localStorage.environment.lastState);
				$rootScope.authenticated = true;
			} else if (res.status == 'error') {
				var error = res.data.error;
				if (error == 'invalid_credentials') {
					var template = $mdToast.simple().textContent('Sorry. Please try again.').hideDelay(false);
					$mdToast.show(template);
				} else {
					var template = $mdToast.simple().textContent('FATAL: Unknown Error').hideDelay(false);
					$mdToast.show(template);
				}
			}
		});
	};
}

accessLockmeCmp.controller = [
	'$scope',
	'$cookies',
	'$mdToast',
	'$state',
	'$rootScope',
	'$localStorage',
	'$http',
	'auth',
	'lsfactory',
	'CONST_DEFAULT_PROFILE',
	LockScreenController,
];

appModule.component('accessLockmeCmp', accessLockmeCmp);

import template from './index.html';

/** @type {import('@srcAjs/components2/adminRoutes').IComponentOptions2} */
const privacyPolicyCmp = {
	template,
	bindings: {
		// someInput: '@string,<object,&function'
		onClose: '&',
	},
	transclude: {
		// #slotName: '?optionalPublicName'
	},
	selector: 'privacyPolicyCmp',
};

/** @this {ng.IController} */
function privacyPolicyCmpController($scope) {
	$scope.hide = (data) => {
		const close = this.onClose ?? (() => null);
		close(data);
	};

	$scope.cancel = function () {
		$scope.hide();
	};

	$scope.answer = function (answer) {
		$scope.hide();
	};
}

privacyPolicyCmp.controller = privacyPolicyCmpController;

appModule.component('privacyPolicyCmp', privacyPolicyCmp);

privacyPolicyCmp.selector = 'privacyPolicyCmp';

export { privacyPolicyCmp };

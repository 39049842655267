import template from './index.html';

/** @type {import('@srcAjs/components2/adminRoutes').IComponentOptions2} */
const trClassSubStudents = {
	template,
	bindings: {
		//someInput: '@string,<object,&function'
	},
	transclude: {
		// #slotName: '?optionalPublicName'
	},
};

function trClassSubStudentsCmpController(
	/** @type {ng.IScope} */ $scope,
	/** @type {ErrorService} */ ErrorService,
	$mdToast,
	/** @type {ng.material.IDialogService} */ $mdDialog,

	$state,
	/** @type {lsfactory} */ lsfactory,
	$rootScope,
	/** @type {import('restangular').IService} */ Restangular,
	/** @type {TRFactory} */ TRFactory,
	/** @type {API} */ API,
	$timeout,
	/** @type {auth} */ auth,
	/**@type {atomosService} */ atomosService,
	/** @type {compSetupService} */ compSetupService,
	/** @type {EffectService} */ EffectService
) {
	TRFactory.loadEnvironment();
	TRFactory.loadNotifications();

	compSetupService(this);

	/**
	 *  @type { typeof $scope & {
	 *	classes: SClass[]
	 *  teacher:Teacher
	 *  school: School
	 *  section:Section
	 *  section_histories: SectionHistory[]
	 *  [key:string]:any
	 *   }}
	 */
	const svm = $scope;

	svm.classes = [];
	svm.teacher = lsfactory.getEnv('teacher');
	svm.section = svm.teacher.section;
	svm.g_tid = lsfactory.getEnv('g_selected_term_id');
	lsfactory.setEnv('backLog', 'tr.home.dashboard');
	svm.school = auth.getEngagedUserRole().school;
	$state.current.data.title = svm.section.sclass.class_level.title + ' (' + svm.section.title + ')';
	svm.section_histories = [];
	svm.isReady = false;
	$rootScope.isCTermDisabled = false;
	svm.class_list_download_request = null;
	svm.show_student_image = false;
	svm.reloadClassSectionData = loadClassSectionData;
	svm.chosen_section_history;
	svm.next_term_start_date;

	/** @type {ReturnType<typeof API.wrapWithCache>} */
	let sectionHistoryApi = null;

	EffectService.setup(({ onInit }) => {
		if (onInit) return () => (this.openPersonalityEditorForm = false);
	});

	svm.$watch('chosen_section_history.promoted', (oldVal, newVal) => {
		if (newVal != null && newVal !== undefined) {
			console.log('Promoted is ' + newVal);
		}
	});

	svm.$on('chosen_section_history.dataChanged', (event, data) => {
		svm.chosen_section_history = data;
		for (let i = svm.section_histories.length - 1; i >= 0; i--) {
			if (svm.section_histories[i].id == data.id) {
				svm.section_histories[i] = data;
			}
		}
	});

	svm.downloadClassList = function () {
		svm.class_list_download_request.getList().then((data) => {
			const list = data.plain();
			console.dir(list);
			const url = list[0];
			window.open(url, 'Download');
		});
	};

	const [, { mutate: mutatePersonality }] = API.rxQuery(
		['rxm.personality.sh.tr'],
		(r, /**@type {{section_histories:{id,character?,interest?,attitude?,remarks?}[]}} */ someData) =>
			/** @type {ng.restangular.IPromise<{data:{updated_models:SectionHistory[],update_status:[number,boolean][]}}>} */ (r.all('section_history/personalities').post(someData)),
		{ qInput$: undefined, enabled: false, destroy$: this.componentDestroy$ }
	);

	svm.doPersonalityUpdate = (personality, section_history) => {
		this.loadingPersonality = mutatePersonality({ section_histories: [personality] })
			.then((res) => ErrorService.handleError(res.error) || [(this.openPersonalityEditorPanel = false), updateSectionHistoryInfo({ ...section_history, ...personality })])
			.finally(() => (this.loadingPersonality = false));
	};

	svm.openMenu = function ($mdOpenMenu, ev) {
		$mdOpenMenu(ev);
	};

	svm.backToList = function () {
		svm.chosen_section_history = null;
	};

	function loadClassSectionData(term, refresh = false) {
		svm.isReady = false;
		if (!term?.id) return;

		let restore_section_histories = [];

		[restore_section_histories, svm.section_histories] = [svm.section_histories, restore_section_histories];
		svm.g_tid = term.id;
		svm.class_list_download_request = Restangular.all('report/teacher-class-list/' + svm.teacher.section.id + '/' + term.id);

		const restangularElement = Restangular.all('teacher/get-term-section-students/' + svm.teacher?.section.id + '/' + term.id);

		sectionHistoryApi = API.wrapWithCache({
			restangularElement,
			refresh,
			cache: TRFactory.getApiCache(),
			callable: true,
		});

		if (typeof sectionHistoryApi !== 'function') {
			return;
		}
		/** @type {import('restangular').ICollectionPromise<SectionHistory>} */ (sectionHistoryApi().getList())
			.then((data) => data.plain())
			.then((section_histories) => {
				svm.section_histories = getOrUpdateCacheSectionSHList({ section_histories });
			})
			.catch((err) => {
				svm.section_histories = restore_section_histories;
				ErrorService.handleError(err);
			})
			.finally(() => (svm.isReady = true));
	}

	atomosService(TRFactory.trServiceAtom.stream$, this).stream$.subscribe((atom) => {
		svm.term = atom.selectedTerm;
		loadClassSectionData(svm.term);
	});

	function doPromotion(sect_his_ids, promote, destination_class_section_id, next_term_meta) {
		console.log('Promoted BOOL: ' + destination_class_section_id);
		console.dir(destination_class_section_id);
		svm.isSending = true;
		const data = {
			sh_ids: sect_his_ids,
			promoted: promote,
			promoted_to: destination_class_section_id,
			next_term_meta,
		};

		return Restangular.all('set_promo').post(data);
	}

	svm.savePromotion = function (sect_his, destination_class_section, promotion_state, promote_class_section) {
		svm.isSending = true;

		promoteClass(
			svm.section_histories.filter((st_sh) => (promote_class_section ? true : st_sh.id == sect_his.id)),
			destination_class_section,
			promotion_state,
			sect_his?.meta?.next_term
		);
	};

	function promoteClass(section_histories, destination_class_section, promotion_state, next_term_meta) {
		svm.isSending = true;

		return doPromotion(
			section_histories.map((sh) => sh.id),
			promotion_state,
			destination_class_section?.id,
			next_term_meta
		)
			.then((sec_his_responses) => {
				$mdToast.showSimple(`Students promotion updated: ${sec_his_responses.length} of ${section_histories.length}`);
				return loadClassSectionData(svm.term, true);
			}, ErrorService.handleError)
			.finally(() => (svm.isSending = false));
	}

	/*
	 * Defining the function for the jquery click hide and show
	 *  $("div#showing-profiles").hide()
	 */
	svm.chooseStudent = function (sect_his) {
		if (!sect_his) return;
		svm.chosen_section_history = sect_his;

		fetchGuardians(sect_his.student);

		svm.chosen_student_gc_requests = TRFactory.guardianCreationRequests
			.getGuardianCreationRequest()
			.filter(({ children }) => children.filter(({ id }) => id === sect_his.student_id).length);

		if (!sect_his.promoted_to) return;

		const params = {
			filters: [{ column: 'id', condition: 'where', value: [sect_his.promoted_to] }],
			has_fields: null,
			fields: null,
		};

		Restangular.all(`section?${API.buildParams(params.filters, params.has_fields, params.fields, null, '1.4')}`)
			.getList()
			.then(([section]) => (svm.destination_class_section = section), ErrorService.handleError);
	};

	svm.$watch(
		() => TRFactory.guardianCreationRequests.getGuardianCreationRequest(),
		(gc_reqs) => {
			if (!gc_reqs || !svm.chosen_section_history) return;

			svm.chosen_student_gc_requests = gc_reqs.filter(({ children }) => children.filter(({ id }) => id == svm.chosen_section_history.student_id).length);
		}
	);

	svm.removeGuardianRequest = (gc_req) => {
		try {
			const index = TRFactory.guardianCreationRequests.getGuardianCreationRequest().findIndex((_gc_req) => _gc_req == gc_req);

			if (!_.isFinite(index)) throw new Error('Data to delete not found');

			TRFactory.guardianCreationRequests.removeExistingStudentGuardian(index);
			$mdToast.showSimple('Guardian deleted');
		} catch (error) {
			ErrorService.handleError(error);
		}
	};

	svm.requestGuardianCreation = (gc_req) => {
		try {
			return TRFactory.guardianCreationRequests.sendGuardianCreationRequestNotification(gc_req, svm.engaged_user_role);
		} catch (error) {
			ErrorService.handleError(error);
		}
	};

	function fetchTermClassSections(term) {
		if (!term) return;

		svm.isSending = true;
		const params = {
			filters: null,
			has_fields: [
				{
					field: 'class_group',
					conditions: [{ condition: 'where', column: 'school_id', value: svm.school.id }],
				},
			],
			fields: [
				{
					field: 'class_level',
					condition: 'with',
				},
				{
					field: 'sections',
					condition: 'with',
					conditions: [{ condition: 'where', column: 'term_id', value: term.term_type.version !== '1.2' ? null : term.id }],
				},
			],
			sorts: [{ condition: 'complex', column: 'class_level_id', value: 'asc' }],
		};

		return Restangular.all(`class?${API.buildParams(params.filters, params.has_fields, params.fields, params.sorts, '1.4')}`)
			.getList()
			.then((res) => (svm.promotion_classes = res.plain()))
			.finally(() => (svm.isSending = false));
	}

	svm.disAllowedPromotionTerm = (term) => term.id <= svm.term.id || moment(term.end).isBefore(moment(Date.now()));

	svm.updateTerm = (term) => {
		if (!term) return;

		svm.promotion_term = term;
		fetchTermClassSections(term);
	};

	function fetchGuardians(student) {
		if (!student) throw Error('student parameter invalid');

		svm.guardians = null;

		const params = {
			filters: null,
			has_fields: [
				{
					field: 'students',
					condition: 'whereHas',
					conditions: [{ condition: 'where', column: 'students.id', value: student.id }],
				},
			],
			fields: [{ field: 'user', condition: 'with' }],
		};

		return Restangular.all(`guardian?${API.buildParams(params.filters, params.has_fields, params.fields, null, '1.4')}`)
			.getList()
			.then((res) => (svm.guardians = res.plain()), ErrorService.handleError);
	}

	svm.onPromotionSectionSelected = (section) => {
		if (!section) return;

		svm.chosen_section_history.promoted_to = section.id;
		svm.promotion_state = svm.promotion_classes.find((cl) => cl.id === section.s_class_id).class_level_id > svm.section.sclass.class_level_id ? 1 : 0;
	};

	svm.editStudentProfile = (/** @type {SectionHistory} */ section_history) => {
		$mdDialog
			.show({
				template: '<edit-student-profile-cmp student="$ctrl.student" flex layout layout-align="center start"></edit-student-profile-cmp>',
				controller: 'DumbDialogController',
				autoWrap: false,
				bindToController: true,
				controllerAs: '$ctrl',
				locals: {
					student: section_history.student,
				},
			})
			.then((/** @type {Student}*/ student) => {
				updateSectionHistoryInfo({ ...section_history, student: updateStudentInfo(student, section_history.student) });

				student &&
					$mdDialog.show($mdDialog.alert().title('Update successful').textContent('navigate to your dashboard and click the refresh button to get the changes').ok('Got it'));
			});
	};

	function updateSectionHistoryInfo(/** @type {SectionHistory} */ section_history) {
		sectionHistoryApi(true);

		svm.chosen_section_history = null;
		const new_section_histories = angular.copy(svm.section_histories).map((sh) => (sh.id === section_history.id ? section_history : sh));
		svm.section_histories = null;

		$timeout(() => {
			svm.chosen_section_history = angular.copy({ ...section_history });
			svm.section_histories = angular.copy(
				getOrUpdateCacheSectionSHList({
					section_histories: new_section_histories,
				})
			);
		});
	}

	function updateStudentInfo(/** @type {Student}*/ new_student, /** @type {Student} */ old_student) {
		const temp_student = Object.fromEntries(Object.entries(old_student).map(([key, value]) => [key, new_student[key] ?? value]));

		return /** @type {Student} */ (temp_student);
	}

	function getOrUpdateCacheSectionSHList(
		/**
		 * @type {{
		 * section_histories: SectionHistory[], sh_cache?: ScCache, section?:Section, term_id?
		 *}}
		 */ { section_histories, section = svm.section, sh_cache = lsfactory.getCache(true), term_id = section?.term_id ?? svm.term.id }
	) {
		if (section_histories) {
			sh_cache[term_id].sections[section.id].section_histories = section_histories;
			lsfactory.updateCache(sh_cache);
		}

		return sh_cache[term_id].sections[section.id].section_histories;
	}
}

trClassSubStudents.controller = trClassSubStudentsCmpController;

appModule.component('trClassSubStudents', trClassSubStudents);

trClassSubStudents.selector = 'trClassSubStudents';

export { trClassSubStudents };

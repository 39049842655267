/** @typedef {ReturnType<typeof EffectService>} EffectService */
/** @typedef {<T extends import('rxjs').Observable>(t:T)=> T} takeUntilDestroyCb */

/**
 * @example
 * function someComponentController(EffectService){
 * 		EffectService.setup( this, ({changes,destroy$})=>{
 * 				if(changes){
 * 					// perform some ops after inputs change
 * 					return ()=> console.log('this is the required cleanup handler for on changes')
 * 				}
 *
 * 				// using destroy$
 * 				takeUntilDestroyCb(someObservable$)
 * 				.subscribe({
 * 					next(data){console.log({data});},
 * 					complete(){
 * 						console.log('completed and resource released')
 * 					}
 * 				})
 * 				// perform ops for init
 * 				return ()=> console.log('replace with required init cleanup functionaity')
 *		 })
 * }
 *
 */
const EffectService = (/** @type {ErrorService} */ ErrorService, /** @type {RxService} */ RxService, /** @type {compSetupService} */ compSetupService) => {
	const setup = (
		/** @type {ng.IController } */ _this,
		onInitOrChanges = (
			/** @type {{changes?: undefined| ng.IOnChangesObject,onChanges?,onInit?, destroy$: import('rxjs').ObservableInput<RxService['Subject']>,takeUntilDestroyCb: takeUntilDestroyCb}} */ {
				changes = undefined,
				onChanges = false,
				destroy$,
			}
		) => {
			console.log('EffectService.default.onDestroy.fired', { changes, destroy$ });
			return null;
		},
		/** @type {(()=>Array<>)|false} */
		withCheck = false,
		console = { log: () => null }
	) => {
		try {
			if (!_this) {
				throw new IError('Error: _this is undefined', { _this });
			}

			let doCheck = false;
			let hasChanges = undefined;
			let lastCheck = withCheck && withCheck();

			compSetupService(_this);

			const destroy$ = _this.componentDestroy$ ?? new RxService.Subject();

			const takeUntilDestroyCb = /** @type {takeUntilDestroyCb} */ _this.takeUntilComponentDestroy$ ?? ((t) => t.pipe(RxService.takeUntil(destroy$)));

			const destroyers = {
				onInit: () => console.log('EffectService.default.onInit.onDestroy.fired', { _this: _this?.prototype?.constructor?.name ?? _this }),
				onChanges: () => console.log('EffectService.default.onChanges.onDestroy.fired', { _this: _this?.prototype?.constructor?.name ?? _this }),
				doCheck: () => console.log('EffectService.default.doCheck.onDestroy.fired', { _this: _this?.prototype?.constructor?.name ?? _this }),
			};

			_this.$onDestroy = () => {
				destroy$.next(Date.now());
				destroy$.complete();
				Object.entries(destroyers).forEach(([destroyEvent, destroyer]) => {
					console.log(`_this.$onDestroy.fired`, { destroyEvent, _this: _this.prototype?.constructor?.name ?? _this });
					destroyer();
				});
			};

			_this.$onInit = () => {
				console.log(`$onInit.fired`, { _this: _this.prototype?.constructor?.name ?? _this });
				destroyers.onInit = onInitOrChanges({ destroy$, takeUntilDestroyCb, onInit: true }) ?? destroyers.onInit;
			};

			_this.$onChanges = (changes) => {
				console.log(`$onChanges.fired`, { _this: _this.prototype?.constructor?.name ?? _this, changes });
				hasChanges = Object.entries(changes).length > 0 ? changes : undefined;
				destroyers.onChanges();
				destroyers.onChanges = onInitOrChanges({ changes, destroy$, takeUntilDestroyCb }) ?? destroyers.onChanges;
			};

			if (withCheck) {
				_this.$doCheck = () => {
					if (!hasChanges) {
						return;
					}
					doCheck = JSON.stringify(lastCheck) !== JSON.stringify(withCheck());
					console.log(`$doCheck.fired.withChanges`, { _this: _this.prototype?.constructor?.name ?? _this, doCheck: !!doCheck, withCheck: withCheck(), lastCheck });
					if (!doCheck) {
						hasChanges = false;
						doCheck = false;
						return;
					}
					doCheck = false;
					lastCheck = withCheck();

					destroyers.doCheck();
					destroyers.doCheck = onInitOrChanges({ onChanges: hasChanges, destroy$, takeUntilDestroyCb }) ?? destroyers.doCheck;
					hasChanges = false;
				};
			}
		} catch (error) {
			ErrorService.handleError(error);
		}
	};

	return { setup };
};

appModule.factory('EffectService', EffectService);

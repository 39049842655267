import template from './index.html';

/** @type {import('@srcAjs/components2/adminRoutes').IComponentOptions2} */
const changePasswordCmp = {
	template,
	bindings: {
		//someInput: '@string,<object,&function'
		userRole: '<',
		onClose: '&',
	},
	transclude: true,
	selector: 'changePasswordCmp',
};

/** @this {ng.IController} */
function changePasswordCmpController(
	$scope,
	$mdDialog,
	/** @type {ng.material.IToastService} */ $mdToast,
	ErrorService,
	/** @type {auth} */ auth,
	/** @type {EffectService} */ EffectService,
	/** @type {DialogService} */ DialogService,
	/** @type {ng.IFilterService} */ $filter
) {
	EffectService.setup(
		this,
		({ onChanges }) => {
			if (onChanges && this.userRole) {
				$scope.user = this.userRole.user;
				$scope.userRole = this.userRole;
			}
		},
		() => [this.userRole]
	);

	$scope.changePassword = (passwords) => {
		return new Promise((res) => {
			res(
				this.userRole.user_id === auth.getAuthenticatedUser().user_instance.id
					? true
					: DialogService.dispatch('confirm', `Confirm password change for: ${$filter('fullname')(this.userRole)}`)
			);
		}).then(() =>
			auth
				.changePassword({
					...passwords,
					user_id: this.userRole.user_id ?? this.userRole.user?.id,
				})
				.then(() => {
					this.onClose();
					this.openPasswordEditorDialog = false;
					DialogService.dispatch('alert', 'Password Updated');
				}, ErrorService.handleError)
		);
	};
}

changePasswordCmp.controller = changePasswordCmpController;

appModule.component('changePasswordCmp', changePasswordCmp);

export { changePasswordCmp };

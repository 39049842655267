import './index.scss';
import template from './index.tpl.html';
/** @type {import('@srcAjs/components2/adminRoutes').IComponentOptions2} */
const schoolsAnalyticsCmp = {
	template,
	bindings: {
		//someInput: '@string,<object,&function'
	},
	transclude: {
		// #slotName: '?optionalPublicName'
	},
	selector: 'schoolsAnalyticsCmp',
};

/** @this {ng.IController} */
function schoolsAnalyticsCmpController(ErrorService, /** @type {API} */ API, /** @type {compSetupService} */ compSetupService) {
	compSetupService(this);
	this.schools;

	const [stream$, { revalidate }] = API.rxQuery(['rxq.schools'], (r) => /** @type {ng.restangular.ICollectionPromise<School>} */ (r.all('school').getList()), {
		destroy$: this.componentDestroy$,
		onError: ({ error }) => ErrorService.handleError(error),
	});

	stream$.subscribe((data$) => {
		this.isLoadig = data$.loading;
		this.schools = data$.data;
	});

	this.loadSchools = revalidate;

	this.updateTerm = (/** @type {Term & {school_id?:undefined| number}} */ { ...term }) => {
		if (!term && +term.school_id === +this.school.id) {
			return;
		}

		this.term = term;
		console.log('scAdminAnalyticsSchools.term is set', this.term);
	};

	this.updateSchool = (/**@type {School}*/ { ...school }) => {
		this._school = school;

		if (this._school.id !== school.id) {
			this.term = null;
		}
	};
}

schoolsAnalyticsCmp.controller = schoolsAnalyticsCmpController;

appModule.component('schoolsAnalyticsCmp', schoolsAnalyticsCmp);

export { schoolsAnalyticsCmp };

// Custom AUTH Service
/** @typedef {import('@components/services.components.js/rxjs.factory').RxService}  RxService*/
/** @typedef {import('@components/services.components.js/store/auth.slice').AuthState} AuthState */
/** @typedef {import('@components/services.components.js/store/store').AppStore} AppStore */
/** @typedef {import('@components/services.components.js/atomosService').atomosService} atomosService */
/** @typedef {{user_instance: User,is_verified: boolean,status: boolean}} UserWithMeta */

function auth(
	/** @type {ng.IHttpService} */ $http,
	$localStorage,
	/** @type {ErrorService} */ ErrorService,
	/** @type {AppStore}*/ AppStore,
	/** @type {ng.$injector} */ $injector
) {
	const vm = this;

	const getLsFactory = () => /** @type {lsfactory} */ ($injector.get('lsfactory'));
	const getAnalytics = () => /** @type {angulartics.IAnalyticsService} */ ($injector.get('$analytics'));

	vm.authData$ = AppStore.selector((obs) => obs.auth);

	const {
		actions: { auth: authActions },
	} = AppStore;

	const skipAuthorizationWrap = (config = {}) => ({ ...config, headers: { ...config?.headers, skipAuthorization: true } });

	function getDefaultAuthData(/** @type {AuthState|undefined} */ nextAuthData = $localStorage?.authData) {
		return /** @type {AuthState} */ (nextAuthData ?? { token: undefined, engagedUserRole: undefined, user: undefined });
	}

	const oldStorageAuthReferencesCleanUp = {
		jwt: { cleanUpFn: () => [authActions.setToken] },
		user: { cleanUpFn: () => [authActions.setUser] },
		engaged_user_role: { cleanUpFn: (eur) => [authActions.setEngagedUserRole, eur] },
		engagedUserRole: { cleanUpFn: (eur) => [authActions.setEngagedUserRole, eur] },
		auth_token: { cleanUpFn: () => authActions.setToken },
		token: { cleanUpFn: () => authActions.setToken },
	};

	vm.cleanupOldStorageAuthReferences = () => {
		const oldStorageAuthReferenceKeys = /** @type {[keyof typeof oldStorageAuthReferencesCleanUp]} */ (Object.keys(oldStorageAuthReferencesCleanUp));

		const /** @type {[[oldStorageAuthReferenceKeys[number]]:any]} */ oldStorageAuthReferencesExist = oldStorageAuthReferenceKeys.reduce((acc, key) => {
				acc[key] = $localStorage[key];
				return acc;
			}, {});

		if (!Object.values(oldStorageAuthReferencesExist).some((val) => val !== undefined)) {
			console.log('cleanupOldStorageAuthReferences:no old keys to cleanup');
			return;
		}

		console.log('cleanupOldStorageAuthReferences: old keys to cleanup', oldStorageAuthReferencesExist);
		//write to authData if every attribute is detected
		if (Object.values(oldStorageAuthReferenceKeys).every((val) => val !== undefined)) {
			console.log('cleanupOldStorageAuthReferences: old keys to write to authData');
			oldStorageAuthReferenceKeys.forEach((key) => oldStorageAuthReferencesCleanUp[key].cleanUpFn($localStorage[key]));
			getAnalytics().eventTrack('oldAuthStorageKeys:rewritten');
		}

		oldStorageAuthReferenceKeys.forEach((key) => delete $localStorage[key]);
		console.log(
			`cleanupOldStorageAuthReferences: remove  old keys ${oldStorageAuthReferenceKeys.length}/${oldStorageAuthReferenceKeys.filter((key) => $localStorage[key] === undefined)}`,
			oldStorageAuthReferencesExist
		);
		getAnalytics().eventTrack('oldAuthStorageKeys:purged', { ...oldStorageAuthReferenceKeys });
	};

	vm.isSignedIn = () => (vm.authData$?.value?.token?.length ?? 0) > 0;

	vm.isSignedOut = () => !vm.isSignedIn();

	vm.getAuthenticatedUser = () => vm.authData$.value.user;

	vm.isTokenOwner = (engagedUserRole = vm.getEngagedUserRole()) => engagedUserRole.user_id === vm.getAuthenticatedUser().user_instance.id;

	vm.isLocked = () => vm.authData$.value?.locked;

	vm.lock = () => AppStore.dispatch(authActions.setLock(true));

	vm.unlock = () => AppStore.dispatch(authActions.setLock(false));

	vm.login = function (creds) {
		const login_url = $localStorage.base_url + '/api/v1/auth';

		return $http
			.post(login_url, creds, skipAuthorizationWrap())
			.then((res) => {
				const { token } = res.data;

				setToken(token);

				return { data: token, token };
			})
			.then(() => vm.getUser());
	};

	vm.logout = function () {
		const url = $localStorage.base_url + '/api/v1/auth/logout';

		return /** @type {ng.IHttpPromise<string>} */ $http.get(url).finally(() =>
			setAuthData(() => {
				getLsFactory().removeAll();
				return getDefaultAuthData();
			})
		);
	};

	vm.verifyCode = (/** @type {string} */ code) => {
		const endpoint = $localStorage.base_url + '/api/v1/auth/verify/code/' + code;

		return /** @type {ng.IHttpPromise<{status:boolean,token,user:User}>} */ ($http.get(endpoint, skipAuthorizationWrap()));
	};

	vm.sendSafeHeatbeat = function () {
		const url = $localStorage.base_url + '/secure-heartbeat';
		return /** @type {ng.IHttpPromise<string>} */ $http.get(url, skipAuthorizationWrap());
	};

	vm.unlock = function (creds) {
		const url = $localStorage.base_url + '/api/v1/auth/open-lock';
		return $http.post(url, creds).then(
			(data) => {
				const { token } = data.data;
				setToken(token);
				return { status: 'success', data: token };
			},
			(err) => {
				const error = err.data;
				return { status: 'error', data: error };
			}
		);
	};

	vm.getUser = function (/** @type {User|null} */ user) {
		const url = $localStorage.base_url + '/api/v1/auth/user' + (user?.id ? `/${user.id}` : '');

		return $http.get(url).then((/** @type {{data:UserWithMeta}} */ { data }) => {
			setUser(data);
			return data;
		});
	};

	vm.resendUserVerificationToken = function (user) {
		const url = $localStorage.base_url + '/api/v1/auth/resend';
		user.noToken = true;
		return $http.post(url, user).then((/** @type {{data:{success:string}}} */ res) => res.data);
	};

	vm.sendForgottenEmail = function (user) {
		const url = $localStorage.base_url + '/api/v1/auth/send-reset-email';
		user.noToken = true;
		return $http.post(url, user, skipAuthorizationWrap()).then((/** @type {{data:{status:boolean}}} */ { data }) => data);
	};

	vm.checkUserVerificationToken = function (user) {
		const url = $localStorage.base_url + '/api/v1/auth/check-token';
		user.noToken = true;
		return $http.post(url, user).then((/** @type {{data:{status: boolean, user: User}}} */ { data }) => data);
	};

	/** @typedef {{data:{data:{user:EngagedUserRole['user'],token:string}}}} ChangedUserFieldResponse */

	vm.changeEmail = function (/** @type {{email,  user_id}} */ user) {
		const url = $localStorage.base_url + '/api/v1/auth/change-email';
		user.noToken = true;
		return $http.post(url, user).then((/** @type {ChangedUserFieldResponse} */ { data }) => {
			setToken(data.data.token ?? this.authData$.value.token);
			return data;
		});
	};

	vm.changePassword = function (/** @type {{password,password_confirmation,  user_id}} */ user) {
		const url = $localStorage.base_url + '/api/v1/auth/change-password';
		user.noToken = true;
		return $http.post(url, user).then((/** @type {ChangedUserFieldResponse} */ { data }) => {
			setToken(data.data.token ?? this.authData$.value.token);
			return data;
		});
	};

	vm.changePhone = function (/** @type {{phone,  user_id}} */ user) {
		const url = $localStorage.base_url + '/api/v1/auth/change-phone';
		return $http.post(url, user).then((/** @type {ChangedUserFieldResponse} */ { data }) => {
			setToken(data.data.token ?? this.authData$.value.token);
			return data;
		});
	};

	vm.changeAccountImage = function (/** @type {{image,  user_id, scope}} */ { image = '', user_id, scope = 'thumbnail' }) {
		const url = $localStorage.base_url + '/api/v1/auth/change-account-image';
		return $http.post(url, { image, user_id, scope }).then((/** @type {ChangedUserFieldResponse} */ { data }) => {
			setToken(data.data.token ?? this.authData$.value.token);
			return data;
		});
	};

	vm.sendForgottenPassword = function (user) {
		const url = $localStorage.base_url + '/api/v1/auth/reset-password/' + user.token;
		user.noToken = true;
		return $http.post(url, user).then((/** @type {{data:{status:boolean}}} */ { data }) => data);
	};

	vm.getEngagedUserRole = () => this.authData$.value.engagedUserRole;

	vm.fetchEngagedUserRole = function (/** @type {Pick<EngagedUserRole,'role_instance_id' | 'user_id'> & {API:API}}*/ { role_instance_id, user_id, API }) {
		const /** @type {API_Params} */ params = {
				fields: [
					{ condition: 'with', field: 'role' },
					{ condition: 'with', field: 'role_instance' },
					{ condition: 'with', field: 'user' },
					{ condition: 'with', field: 'school' },
				],
				filters: [
					{ condition: 'where', column: 'role_instance_id', value: role_instance_id },
					{
						condition: 'where',
						column: 'user_id',
						value: user_id,
					},
				],
			};

		return $http
			.get(`${$localStorage.base_url}/api/v1/user_role?${API.buildParams_v2(params)}`)
			.then((/** @type {ng.IHttpResponse<EngagedUserRole[]>} */ { data: [engaged_user_role] }) => engaged_user_role);
	};

	/** @returns {UserRole} */
	vm.setEngagedUserRole = (/** @type {EngagedUserRole}*/ engagedUserRole) => {
		if (!engagedUserRole) throw new Error('Invalid user role provided');

		$localStorage.engaged_user_role = vm.makeEngagedUserRole(engagedUserRole);
		setEngagedUserRole(engagedUserRole);

		return $localStorage.engaged_user_role;
	};

	/** @return {EngagedUserRole} */
	vm.makeEngagedUserRole = (/** @type {EngagedUserRole} */ { user = $localStorage.user, ...user_role }) => {
		if (!user || !user_role) ErrorService.handleError(new Error('Invalid parameters for user or user_role'));

		const { user: user_role__user, ...plain_user_role } = { user: null, ...user_role };

		const { user_roles, ...plain_user } = { user_roles: null, ...(user_role__user ?? user) };

		if (user_role.user_id !== plain_user.id) {
			const error_msg = 'Issue with user info. Please logout and log in again';
			ErrorService.handleError(new Error(error_msg));
			throw new Error(error_msg);
		}

		const engagedUserRole = { ...plain_user_role, role_instance: { ...plain_user_role.role_instance, user: plain_user }, user: plain_user };

		return angular.copy(engagedUserRole);
	};

	vm.getEngagebleUser = (/** @type {User}*/ _user) => {
		const { user_roles, ...user } = _.clone(_user);
		const temp_user_roles = _.clone(user_roles);
		return temp_user_roles.map(({ user: u, ...ur }) => ({ ...ur, role_instance: { ...ur.role_instance, user }, user }));
	};

	vm.loadUserModule = (
		/** @type {Awaited<ReturnType<typeof vm.getUser>>} */ res,
		$rootScope,
		/** @type {import('angular-ui-router').IStateService} */ $state,
		/** @type {[]} */ entryMap,
		/** @type {RoleViewInitializer} */ roleViewInitializer
	) => {
		if (!res) throw new Error('Invalid user info');

		const { user_instance: user, is_verified: verified } = res;

		$rootScope.authenticated = true;

		const [first_user_role] = user.user_roles;

		vm.setEngagedUserRole(vm.makeEngagedUserRole({ user, ...first_user_role }));

		$localStorage.user = user;
		$rootScope.user = user;
		$rootScope.isReady = false;
		$localStorage.environment = { checked_in: false, locked: false, mobile: false };

		// Gear box for the various roles
		const roleInit = roleViewInitializer[first_user_role.role.short];

		if (!roleInit) {
			throw new Error('Invalid user role provided');
		}

		function navigateToHome(/** @type {EngagedUserRole}*/ engagedUserRole) {
			return $state.go(entryMap[engagedUserRole.role.short], engagedUserRole, { location: 'replace' }).then(() => {
				$rootScope.$broadcast('system.startupHook', {});
			});
		}

		if (!verified) {
			$rootScope.allowSub = true;

			return $state.go('access.sms-verify', { user, resend_code: true }, { location: 'replace' });
		}

		return roleInit instanceof Promise ? roleInit.then(() => navigateToHome(vm.getEngagedUserRole())) : navigateToHome(vm.getEngagedUserRole());
	};

	const setAuthData = (/** @type {AuthState|((value:AuthState)=>AuthState)} */ value) => {
		const nextAuthData = typeof value === 'function' ? value(vm.authData$.value) : value;
		$localStorage.authData = nextAuthData;
		// vm.authData$.next(nextAuthData);
		AppStore.dispatch(authActions.replace(nextAuthData));
		console.log({ nextAuthData });
	};

	/** @template {keyof AuthState} T*/
	const makeAuthFieldSetter = (/** @type {T}*/ field) => (/** @type {  AuthState[T]} */ value) =>
		setAuthData((/** @type {AuthState}*/ authData) => ({ ...authData, [field]: value }));

	const setToken = makeAuthFieldSetter('token');

	const setUser = makeAuthFieldSetter('user');

	const setEngagedUserRole = makeAuthFieldSetter('engagedUserRole');

	const handlers = { setAuthData, setToken, setEngagedUserRole, setUser };

	/** @template {keyof handlers} T */
	const dispatch = (/** @type {T} */ actionType, /** @type {Parameters<handlers[T]>} */ ...payload) => handlers[actionType](...payload);

	vm.dispatch = dispatch;

	function init() {
		setAuthData(getDefaultAuthData());
	}

	vm.refreshAuthToken = (oldToken = vm.authData$.value.token, /** @type {StateService} */ $state = $injector.get('$state')) => {
		if (['', undefined, null].some((i) => i === oldToken)) {
			throw new Error('Invalid token provided');
		}
		// const /** @type {ng.IHttpService} */ $http = $injector.get('$http');
		const CONST_APP_API_VERSION_STRING_URL = $injector.get('CONST_APP_API_VERSION_STRING_URL');
		const CONST_REST_API = $localStorage.base_url;

		const _config = {
			method: 'POST',
			headers: {
				Accept: '*/*',
				Authorization: `Bearer ${oldToken}`,
				skipAuthorization: true,
			},
			url: `${CONST_REST_API}${CONST_APP_API_VERSION_STRING_URL}/auth/refresh`,
			// url: `${CONST_REST_API}/auth/refresh`,
		};

		return $http(_config)
			.then((/** @type {ng.IHttpResponse<{token:string}>} */ error) => {
				// 	console.log(error, error.headers('Authorization'));
				const { token: newToken } = error.data;
				dispatch('setToken', newToken);
				return newToken;
			})
			.catch((error) => {
				vm.logout().finally(() => $state.go('access.signin'));
				return error;
			});
	};

	init();

	return vm;
}

appModule.factory('auth', auth);

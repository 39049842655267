import template from './index.html';

/** @type {import('@srcAjs/components2/adminRoutes').IComponentOptions2} */
const saTeachersSubProfile = {
	template,
	bindings: {
		// someInput: '@string,<object,&function'
	},
	transclude: {
		// #slotName: '?optionalPublicName'
	},
};

function saTeachersSubProfileCmpController(
	$scope,
	$stateParams,
	$state,
	/** @type {saTeachersService} */ saTeachersService,
	/** @type {EffectService} */ EffectService,
	/** @type {atomosService} */ atomosService
) {
	const vm = this;

	const trStoreAtom = atomosService(saTeachersService.teachersStore$, this);
	EffectService.setup(vm, ({ changes, takeUntilDestroyCb }) => {
		if (changes) {
			return;
		}

		atomosService((get) => [get(trStoreAtom)], this).stream$.subscribe(([data]) => {
			$scope.teacher = null;
			$scope.teacher = data.teachers?.find((t) => +t.id === +$stateParams.id);
			$state.current.data.title = $scope.teacher.salutation + ' ' + $scope.teacher.firstname + ' ' + $scope.teacher.lastname + ' ( Teacher )';

			$scope.term = { id: saTeachersService.teachersStore$.value.selectedTermId };
		});
	});
}

saTeachersSubProfile.controller = saTeachersSubProfileCmpController;

appModule.component('saTeachersSubProfile', saTeachersSubProfile);

saTeachersSubProfile.selector = 'saTeachersSubProfile';

export { saTeachersSubProfile };

'use strict';

import { appLogCmp } from '@srcAjs/components/roles/aa/AppLogCmp/AppLogCmp.component';
import { appShellCmp } from './AppShellCmp/AppShellCmp.component';
import { schoolsAnalyticsShellCmp } from '@srcAjs/components/roles/aa/SchoolsAnalyticsModule/schoolsAnalyticsShellCmp/index.component';
import { schoolsAnalyticsCmp } from '@srcAjs/components/roles/aa/SchoolsAnalyticsModule/SchoolsAnalyticsCmp/index.component';
import { aaHomeDashboard } from '@srcAjs/components/AaHomeDashboard';

/**
 * @ngdoc function
 * @name app.config:uiRouter
 * @description
 * # Config
 * Configure router for the System Administrator routes
 */

function SchrepAdminRoutes(/** @type {import("@uirouter/angularjs").StateProvider} */ $stateProvider) {
	$stateProvider
		// System Admin Routes
		.state('aa', {
			url: '/admin/:user_id/:role_instance_id',
			params: { user_id: null, role_instance_id: null },
			component: appShellCmp.selector,
		})
		.state('aa.dashboard', {
			url: '/dashboard',
			component: aaHomeDashboard.selector,
			data: { title: 'Admin Dashboard', folded: false, ngDynamicTitle: 'Schrep® | Administrator Dashboard' },
			// resolve: load(['scripts/controllers/roles/aa/dashboard.js', 'scripts/factory/roles/admin.factory.js'])
		})
		.state('aa.app_logs', {
			url: '/app_logs',
			component: appLogCmp.selector,
			data: { title: 'App Logs', folded: false, child: true, ngDynamicTitle: 'Schrep® | Administrator Dashboard' },
		})
		.state('aa.analytics', {
			url: '/analytics',
			component: schoolsAnalyticsShellCmp.selector,
			data: { title: 'Admin Dashboard', folded: false, ngDynamicTitle: 'Schrep® | Administrator Dashboard' },
		})
		.state('aa.analytics.schools', {
			url: '/',
			component: schoolsAnalyticsCmp.selector,
			data: { title: 'Admin Dashboard', folded: false, ngDynamicTitle: 'Schrep® | Administrator Dashboard' },
		})
		.state('aa.profile', {
			url: '/profile',
			controller: 'AA_ProfileController',
			templateUrl: 'views/pages/roles/aa/profile.html',
			data: { title: 'Profile', folded: false, child: true },
			// resolve: load(['scripts/controllers/roles/aa/profile.js'])
		});
}

appModule.config(['$stateProvider', SchrepAdminRoutes]);

export { SchrepAdminRoutes };

export const config = {
	// Your web app's Firebase configuration
	// For Firebase JS SDK v7.20.0 and later, measurementId is optional
	firebaseConfig: {
		apiKey: process.env.CONST_PWA_API_KEY,
		authDomain: process.env.CONST_PWA_AUTHDOMAIN,
		databaseURL: process.env.CONST_PWA_DATABASEURL,
		projectId: process.env.CONST_PWA_PROJECTID,
		storageBucket: process.env.CONST_PWA_STORAGEBUCKET,
		messagingSenderId: process.env.CONST_PWA_MESSAGINGSENDERID,
		appId: process.env.CONST_PWA_APPID,
		measurementId: process.env.CONST_PWA_MEASUREMENTID,
	},
};
